<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { vTruncationTitle } from "@/directives/truncationTitle";
import { Status, SurveyorStatus, SiteResponseStatus } from "@/types/_generated/api";

defineProps<{
  status: Status | SurveyorStatus | SiteResponseStatus;
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <span class="base-status-badge truncate">
    <span
      class="base-status-badge__dot"
      :class="`base-status-badge__dot--${status.toLowerCase()}`"
    ></span>
    <span v-truncation-title>{{ t(`common.statuses.${status}`) }}</span>
  </span>
</template>

<style scoped lang="scss">
.base-status-badge {
  display: inline-flex;
  align-items: center;
  gap: $spacing-1-5;
  font-size: $text-xs;
  line-height: $leading-none;
  padding: $spacing-1-5 $spacing-2;
  border-radius: $rounded-lg;
  background-color: $primary-2;
  border: 1px solid $primary-4;

  &__dot {
    width: $spacing-2;
    height: $spacing-2;
    border-radius: $rounded-full;
    flex-shrink: 0;

    &--complete,
    &--completed,
    &--published {
      background-color: $success-4;
    }
    &--underconstruction,
    &--inprogress {
      background-color: $info-4;
    }
    &--withdrawn,
    &--rejected {
      background-color: $error-4;
    }
    &--underreview {
      background-color: $warning-4;
    }
    &--abeyance,
    &--noprogress {
      background-color: $primary-5;
    }
    &--history,
    &--archived,
    &--notset {
      background-color: $primary-0;
      border: 1px solid $primary-9;
    }
  }

  @media print {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}
</style>
