<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { DeepReadonly } from "vue";
import type { ExecutiveReportAreaDto, ExecutiveReportCategoryDto } from "@/types/_generated/api";
import { vTruncationTitle } from "@/directives/truncationTitle";
import { useCombinedAreaData } from "./composables/useCombinedAreaData";

const props = defineProps<{
  areas: DeepReadonly<ExecutiveReportAreaDto[]>;
  categories: DeepReadonly<ExecutiveReportCategoryDto[]>;
}>();

const { t } = useI18n({ useScope: "global" });

const combinedAreaData = useCombinedAreaData(props.areas, props.categories);
</script>

<template>
  <tr v-if="combinedAreaData.length > 0">
    <td data-footer-title>{{ t("userWeb.blueRatingTable.areas") }}:</td>

    <td
      v-for="(area, index) in combinedAreaData"
      :key="index"
      :colspan="area.count"
      data-footer-cell
    >
      <span data-footer-cell-content>
        <span v-truncation-title>{{ area.name }}</span>
      </span>
    </td>
  </tr>
</template>

<style scoped lang="scss">
@import "./styles/styles";
</style>
