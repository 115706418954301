import { ref } from "vue";
import { getModule as getModuleService } from "@/services/modules";
import { ModuleDto } from "@/types/_generated/api";

export const useGetModule = () => {
  const isLoadingModule = ref(false);
  const module = ref<ModuleDto | null>(null);

  const getModule = async (moduleId: number) => {
    isLoadingModule.value = true;

    module.value = await getModuleService(moduleId);

    isLoadingModule.value = false;
  };

  return { getModule, isLoadingModule, module };
};
