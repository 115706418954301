import { i18n } from "@/i18n";
import { Status } from "@/types/_generated/api";
import { Option } from "@/types/Option";

const { t } = i18n.global;

export const SiteStatus = {
  Published: "Published" as Status,
  UnderConstruction: "UnderConstruction" as Status,
  Archived: "History" as Status,
} as const;

export const siteStatusOptions: readonly Option[] = [
  {
    title: t("sites.siteStatus.archived"),
    value: SiteStatus.Archived,
  },

  {
    title: t("sites.siteStatus.underConstruction"),
    value: SiteStatus.UnderConstruction,
  },

  {
    title: t("sites.siteStatus.published"),
    value: SiteStatus.Published,
  },
];
