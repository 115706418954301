<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTableFilter } from "@/composables/useTableFilter";
import { ModuleRules } from "@/constants/ModulePermission";
import { getClientsService } from "@/services/clients";
import { getModules } from "@/services/modules";
import { getUserModulePermissions } from "@/services/permissions";
import { getUser } from "@/services/users";
import { ClientDto, Role, UserDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import ToggleAddRemoveButton from "@/components/shared/ToggleAddRemoveButton.vue";
import { useEditModulePermissionsTableColumns } from "../composables/useEditModulePermissionsTableColumns";
import {
  type EditUserModulePermission,
  useEditUserModulePermissions,
} from "../composables/useEditUserModulePermissions";
import UserAccessStatus from "./UserAccessStatus.vue";
import UserSitePermissionsEditDrawer from "./UserSitePermissionEditDrawer/UserSitePermissionsEditDrawer.vue";

const props = defineProps<{
  userId: number;
}>();

const emit = defineEmits<{
  close: [void];
}>();

const { t } = useI18n({ useScope: "global" });

const isLoading = ref(false);
const selectedModuleId = ref<number | null>();
const showEditSitePermissionsDrawer = ref(false);
const clients = ref<ClientDto[]>([]);
const user = ref<UserDto>();
const modulePermissions = ref<EditUserModulePermission[]>([]);

const { save, add, remove, toEditUserModulePermissions, discardUnsavedChanges, setCompareBase } =
  useEditUserModulePermissions(props.userId, modulePermissions, () => {
    emit("close");
  });

const { columns } = useEditModulePermissionsTableColumns(user, clients);

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(modulePermissions, columns);

const openSitePermissionsDrawer = (moduleId: number) => {
  showEditSitePermissionsDrawer.value = true;
  selectedModuleId.value = moduleId;
};

const closeSitePermissionsDrawer = () => {
  showEditSitePermissionsDrawer.value = false;
  selectedModuleId.value = null;
};

onBeforeMount(async () => {
  isLoading.value = true;

  clients.value = (await getClientsService()) || [];
  user.value = (await getUser(props.userId))!;

  const modules = (await getModules()) || [];
  const userModulePermissions = (await getUserModulePermissions(props.userId)) || [];

  modulePermissions.value = toEditUserModulePermissions(
    props.userId,
    modules,
    userModulePermissions,
  );

  setCompareBase(modulePermissions);

  isLoading.value = false;
});

const close = async () => {
  if (await discardUnsavedChanges(modulePermissions)) {
    emit("close");
  }
};
</script>

<template>
  <BaseDrawer
    v-show="!showEditSitePermissionsDrawer"
    :title="t('users.userModulePermissonDrawerTitle', { fullName: user?.fullName })"
    :area-label="t('users.userModulePermissonDrawerTitle', { fullName: user?.fullName })"
    :is-loading="isLoading"
    :width="'100'"
    data-test="user-module-permissions-drawer"
    @close="close"
  >
    <BaseGridTable
      enable-row-click
      :rows="result.result"
      :columns="columns"
      storage-key="userModulePermissionsEditTable"
      :default-sort="[{ key: 'hasAccess' }, { key: 'clientName' }, { key: 'moduleName' }]"
    >
      <template #controls>
        <BaseFilter
          :search-phrase="searchPhrase"
          :search-key="searchKey"
          :search-keys="searchKeys"
          @update:search-phrase="setSearchPhrase($event)"
          @update:search-key="setSearchKey($event)"
        />
      </template>

      <template #column-hasAccess="{ row: { hasAccess } }">
        <UserAccessStatus :has-access="hasAccess" />
      </template>

      <template #column-moduleName="{ cell, row: { hasAccess } }">
        <span :class="{ 'user-module-permissons__cell-without-access': !hasAccess }">{{
          cell
        }}</span>
      </template>

      <template #column-clientName="{ cell, row: { hasAccess } }">
        <span :class="{ 'user-module-permissons__cell-without-access': !hasAccess }">{{
          cell
        }}</span>
      </template>

      <template v-for="(rule, i) in ModuleRules" #[`column-${rule}`]="{ row }" :key="i">
        <BaseCheckbox v-if="row.hasAccess" :checked="!!row[rule]" @change="row[rule] = $event" />
        <span v-else></span>
      </template>

      <template #actions="{ row }">
        <BaseButton
          v-if="user?.role === Role.EXT && row.hasAccess"
          variant="text"
          @click="openSitePermissionsDrawer(row.moduleId)"
        >
          {{ t("sites.sites") }}
        </BaseButton>

        <ToggleAddRemoveButton
          :item="row"
          :is-remove="(item) => (item as EditUserModulePermission).hasAccess"
          @add="(e) => add(e as EditUserModulePermission)"
          @remove="(e) => remove(e as EditUserModulePermission)"
        />
      </template>
    </BaseGridTable>

    <template #footer-left>
      <BaseButton @click="save">
        {{ t("common.actions.save") }}
      </BaseButton>
    </template>
  </BaseDrawer>

  <UserSitePermissionsEditDrawer
    v-if="showEditSitePermissionsDrawer && userId && selectedModuleId"
    :user-id="userId"
    :module-id="selectedModuleId"
    @close="closeSitePermissionsDrawer"
  />
</template>
<style scoped lang="scss">
.user-module-permissons {
  &__cell-without-access {
    opacity: 0.6;
  }
}
</style>
