import { Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { binaryOptions } from "@/helpers/options";
import { ClientDto, Role, UserDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import { type EditUserModulePermission } from "../composables/useEditUserModulePermissions";

export const useEditModulePermissionsTableColumns = (
  user: Ref<UserDto | undefined>,
  clients: Ref<ClientDto[]>,
) => {
  const { t } = useI18n({ useScope: "global" });

  const columns = computed(() => {
    const columns: ColumnDefinition<EditUserModulePermission>[] = [
      new ColumnDefinition({
        title: t("clients.client"),
        key: "clientName",
        searchOptions: clients.value?.map((c) => ({
          title: c.name,
          value: c.name,
        })),
      }),
      new ColumnDefinition({ title: t("modules.module"), key: "moduleName" }),
    ];

    if (user.value?.role === Role.EXT) {
      columns.push(
        new ColumnDefinition({ key: "createSite", title: t("users.createSite"), autoWidth: true }),
        new ColumnDefinition({
          key: "createSurvey",
          title: t("users.createSurvey"),
          autoWidth: true,
        }),
        new ColumnDefinition({ key: "editSurvey", title: t("users.editSurvey"), autoWidth: true }),
        new ColumnDefinition({
          key: "uploadStdRec",
          title: t("users.uploadStdRec"),
          autoWidth: true,
        }),
      );
    }

    if (user.value?.role === Role.INT) {
      columns.push(
        new ColumnDefinition({
          key: "editQuestionnaire",
          title: t("users.editQuestionnaire"),
          autoWidth: true,
        }),
        new ColumnDefinition({
          key: "uploadFiles",
          title: t("common.actions.uploadFiles"),
          autoWidth: true,
        }),
        new ColumnDefinition({
          key: "uploadStdRec",
          title: t("users.uploadStdRec"),
          autoWidth: true,
        }),
      );
    }

    columns.push(
      new ColumnDefinition({
        key: "hasAccess",
        title: t("users.permissions.hasAccess"),
        type: ColumnDataType.Boolean,
        searchOptions: binaryOptions,
      }),
    );

    return columns;
  });

  return { columns };
};
