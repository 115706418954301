<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { maxLength, minLength, regex, withI18nMessage } from "@/validation/i18n-validators";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

const props = withDefaults(
  defineProps<{
    password1: string;
    password2: string;
    showRequirements?: boolean;
  }>(),
  {
    showRequirements: true,
  },
);

defineEmits<{
  (event: "update:password1", value: string): void;
  (event: "update:password2", value: string): void;
}>();

const validations = {
  password1: {
    passwordMinLength: minLength(8),
    passwordMaxLength: maxLength(64),
    passwordUpperCase: withI18nMessage(regex(/([A-Z]|Å|Ä|Ö)/)),
    passwordLowerCase: withI18nMessage(regex(/[a-z]|å|ä|ö/)),
    passwordNumber: withI18nMessage(regex(/\d/)),
    passwordSpecialSymbol: withI18nMessage(regex(/[*\-+=!%/#\\|#$%&()?»«@£§€{}.;'<>_,"]+/)),
  },
  password2: {
    passwordMismatch: withI18nMessage((value: string) => value === props.password1),
  },
};

const { v$ } = useVuelidate(validations, props, {
  $autoDirty: true,
});
</script>

<template>
  <BaseAlert
    v-if="!v$.$errors.length && showRequirements"
    class="view-reset-password__alert"
    severity="info"
  >
    <div>
      <p class="view-reset-password__alert__description">
        {{ t("settings.password.rulesTitle") }}
      </p>
      <ul>
        <li
          v-for="(requirement, index) in [
            t('validations.password.rules.length'),
            t('validations.password.rules.lowerCase'),
            t('validations.password.rules.upperCase'),
            t('validations.password.rules.numbers'),
            t('validations.password.rules.specials'),
          ]"
          :key="index"
        >
          {{ requirement }}
        </li>
      </ul>
    </div>
  </BaseAlert>

  <BaseTextField
    :value="password1"
    class="base-drawer__field"
    type="password"
    :label="t('users.enterPassword')"
    :errors="v$.password1.$errors"
    data-test="user-edit-password-password1"
    @update:value="$emit('update:password1', $event as string)"
  />
  <BaseTextField
    :value="password2"
    class="base-drawer__field"
    type="password"
    :label="t('settings.password.newConfirm')"
    :errors="v$.password2.$errors"
    data-test="user-edit-password-password2"
    @update:value="$emit('update:password2', $event as string)"
  />
</template>
