<script setup lang="ts">
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const auth = useAuthStore();
const { t } = useI18n({ useScope: "global" });
const app = useAppStore();

interface Props {
  email: string;
  password: string;
}

const props = withDefaults(defineProps<Props>(), {
  email: "",
  password: "",
});

interface Form {
  isLoading: boolean;
  error: string;
  code: string;
}

const form: Form = reactive({
  code: "",
  isLoading: false,
  error: "",
});

const signIn2fa = async (event: Event) => {
  event.preventDefault();

  form.error = "";
  form.isLoading = true;

  try {
    await auth.signInCredentials(props.email, props.password, {
      mfaCode: form.code,
    });

    await auth.handleSuccessfulLogin();
    app.redirectOnLogin();
  } catch (e) {
    form.error = t("signIn.2fa.invalidCode");
  } finally {
    form.isLoading = false;
  }
};
</script>

<template>
  <form class="auth-signin" @submit="signIn2fa">
    <BaseTextField
      autofocus
      :tab="1"
      :label="t('signIn.2fa.code')"
      :disabled="form.isLoading"
      :value="form.code"
      type="password"
      @update:value="form.code = $event as string"
    />

    <BaseAlert v-if="form.error" severity="error">
      <p>{{ form.error }}</p>
    </BaseAlert>

    <BaseButton type="submit" :disabled="form.isLoading">
      {{ t("signIn.signIn") }}
    </BaseButton>
  </form>
</template>

<style scoped lang="scss">
.auth-signin {
  display: flex;
  flex-direction: column;
  width: 100%;
  .base-textfield,
  .base-alert {
    margin-bottom: $spacing-4;
  }
}
</style>
