<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { downloadFile } from "@/helpers/downloadFile";
import { toBase64 } from "@/helpers/toBase64";
import { sitesEvents } from "@/services/sites";
import { notify } from "@/stores/notifications";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseFileUpload, { FileUpload } from "@/components/base/BaseFileUpload/BaseFileUpload.vue";
import { FileFormat } from "@/components/base/BaseFileUpload/FileFormat";

defineProps<{
  moduleId: number;
}>();

defineEmits<{
  (event: "close"): void;
}>();

const { t } = useI18n({ useScope: "global" });

const getBody = async (files: FileUpload[]) => ({
  file: { fileName: files[0].file.name, file: await toBase64(files[0].file) },
});
</script>

<template>
  <BaseDrawer width="50" @close="$emit('close')">
    <template #title>
      {{ t("modules.importSites") }}
    </template>

    <BaseCard
      :title="t('common.information')"
      has-padding
      class="module-site-document__information"
    >
      <div>
        <p>
          {{ t("modules.importTemplateSiteDataText_p1") }}
        </p>
        <p>
          {{ t("modules.importTemplateSiteDataText_p2") }}
        </p>

        <p>
          {{ t("modules.importTemplateSiteDataText_p3") }}
        </p>

        <BaseButton @click="downloadFile(`/v1/modules/${moduleId}/sites/import-template`)">
          {{ t("modules.downloadTemplate") }}
        </BaseButton>
      </div>
    </BaseCard>

    <BaseCard :title="t('library.fileUpload.upload')" has-padding>
      <BaseFileUpload
        :file-type="FileFormat.Excel"
        :body="getBody"
        :max-files="1"
        :url="`modules/${moduleId}/sites/import`"
        :use-form-data="true"
        :show-detailed-errors="true"
        @files-uploaded="
          () => {
            notify.success(t('sites.site'), t('common.updated').toLowerCase());
            sitesEvents.post({ action: 'update', id: moduleId });
          }
        "
      />
    </BaseCard>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.module-site-document {
  &__information p {
    margin-bottom: $spacing-4;
    display: block;
  }
}
</style>
