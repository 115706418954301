import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import { GetLibraryFilesResponse, LibraryFileDto } from "@/types/_generated/api";
import { BaseCRUDMessage, BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

export const libraryEvents = new BroadcastService<BaseCRUDMessage>("library");

const { t } = i18n.global;

export const getLibraryFiles = async (
  moduleId: number,
  filter?: { fileExtensions?: string[]; onlyVisibleInUserWeb?: boolean },
): Promise<LibraryFileDto[] | null> => {
  try {
    const queryParts = [];

    if (filter?.fileExtensions?.length) {
      queryParts.push(`filterByFileExtensions=[${filter.fileExtensions.join(",")}]`);
    }

    if (filter?.onlyVisibleInUserWeb) {
      queryParts.push("onlyVisibleInUserWeb=true");
    }

    const query = queryParts.length ? `?${queryParts.join("&")}` : "";

    const {
      data: { libraryFiles },
    } = await http.get<GetLibraryFilesResponse>(`/modules/${moduleId}/library-files${query}`);
    return libraryFiles;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("library.libraryFiles"),
    });
  }
  return null;
};

export const deleteLibraryFile = async (moduleId: number, fileId: string): Promise<boolean> => {
  try {
    await http.delete(`/modules/${moduleId}/library-files/${fileId}`);

    libraryEvents.post({ action: "delete" });

    notify.success(t("library.fileUpload.file"), t("common.deleted").toLowerCase());

    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.delete").toLowerCase(),
      entity: t("library.fileUpload.file"),
    });
  }
  return false;
};
