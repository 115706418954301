import { Ref, computed } from "vue";
import { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { SiteResponseStatus } from "@/types/_generated/api";

// custom composables to categorize data and extract category names
export const useTrackedRecsGroupedBySiteResponseStatus = (
  trackedRecommendations: Ref<TrackedRecommendation[]>,
) => {
  const trackedRecsGroupedBySiteResponseStatus = computed(() =>
    trackedRecommendations.value.reduce(
      (acc: Record<SiteResponseStatus, TrackedRecommendation[]>, recommendation) => {
        const { siteResponseStatus } = recommendation;

        const recommendationsForStatus = acc[siteResponseStatus];

        if (!recommendationsForStatus) {
          acc[siteResponseStatus] = [];
        }

        recommendationsForStatus.push(recommendation);

        return acc;
      },
      {
        NotSet: [],
        NoProgress: [],
        UnderReview: [],
        Completed: [],
        Rejected: [],
        InProgress: [],
      } as Record<SiteResponseStatus, TrackedRecommendation[]>,
    ),
  );

  return {
    trackedRecsGroupedBySiteResponseStatus,
  };
};
