<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { BlueRatingRecommendationDto } from "@/types/_generated/api";
import { formatDate } from "@/helpers/formatDate";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";

defineProps<{ recommendation: BlueRatingRecommendationDto }>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <BaseDataDisplayList>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.completedDate')">
      <p>{{ formatDate.asDateOnly(recommendation.completedDate) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.completedComment')">
      <p>{{ recommendation.completedComment }}</p>
    </BaseDataDisplayListItem>
  </BaseDataDisplayList>
</template>
