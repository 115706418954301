<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });
const emits = defineEmits<{
  delete: [void];
}>();
</script>

<template>
  <BaseButton
    :title="t('common.actions.remove')"
    variant="text"
    color="error"
    @click="emits('delete')"
  >
    <BaseIcon icon="trash" size="small" />
  </BaseButton>
</template>
