<script setup lang="ts">
import { useSiteResponseStatusTranslations } from "@/composables/translations/useSiteResponseStatusTranslations";
import { SiteResponseStatus } from "@/types/_generated/api";
import UWOption from "@/components/base/UserWeb/Inputs/Select/UWOption.vue";
import UWSelect from "@/components/base/UserWeb/Inputs/Select/UWSelect.vue";

defineProps<{
  status: SiteResponseStatus;
}>();

const emit = defineEmits<{
  update: [SiteResponseStatus];
}>();

const { translations } = useSiteResponseStatusTranslations();

const options = Object.values(SiteResponseStatus).map((status) => ({
  value: status,
  label: translations[status],
}));
</script>

<template>
  <UWSelect :value="status" @change="emit('update', $event as SiteResponseStatus)">
    <UWOption v-for="option in options" :key="option.value" :value="option.value">
      {{ option.label }}
    </UWOption>
  </UWSelect>
</template>
