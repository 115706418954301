<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetLibraryFiles } from "@/composables/services/useGetLibraryFiles";
import { useDialog } from "@/composables/useDialog";
import { deleteLibraryFile, libraryEvents } from "@/services/library";
import ModuleLibraryDrawer from "./ModuleLibraryDrawer.vue";
import ModuleLibraryTable from "./ModuleLibraryTable.vue";

const props = defineProps<{ moduleId: number; isUserWeb: boolean }>();
const { t } = useI18n({ useScope: "global" });
const dialog = useDialog();

const showLibraryDrawer = ref(false);

const { getLibraryFiles, libraryFiles, hasError, isLoadingLibraryFiles } = useGetLibraryFiles();

libraryEvents.subscribe(async () => await getLibraryFiles(props.moduleId, props.isUserWeb));

onBeforeMount(async () => await getLibraryFiles(props.moduleId, props.isUserWeb));

const deleteFile = async (fileId: string) => {
  const isConfirmed = await dialog.openDeletePrompt(t("library.fileUpload.file"));

  if (isConfirmed) {
    await deleteLibraryFile(props.moduleId, fileId);
  }
};
</script>

<template>
  <ModuleLibraryTable
    v-if="!hasError"
    :module-id="moduleId"
    :files="libraryFiles ?? []"
    :is-loading="isLoadingLibraryFiles"
    :is-user-web="isUserWeb"
    @upload="showLibraryDrawer = true"
    @delete="deleteFile"
  />
  <ModuleLibraryDrawer
    v-if="showLibraryDrawer"
    :module-id="moduleId"
    @close="showLibraryDrawer = false"
  />
</template>
