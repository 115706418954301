import { ref } from "vue";
import { isDeepEqual } from "@/helpers/isDeepEqual";
import {
  BlueColor,
  SiteResponseStatus,
  SurveyorStatus,
  type RecommendationTrackingFilter,
} from "@/types/_generated/api";

export const useFilterRecTracking = (
  onUpdateFilter: (newFilter: RecommendationTrackingFilter) => void,
) => {
  const activeFilter = ref<RecommendationTrackingFilter>({
    recommendationColors: Object.values(BlueColor),
    siteResponseStatuses: Object.values(SiteResponseStatus),
    surveyorStatuses: [
      SurveyorStatus.NotSet,
      SurveyorStatus.NoProgress,
      SurveyorStatus.UnderReview,
      SurveyorStatus.Rejected,
      SurveyorStatus.InProgress,
    ],
    surveyDateMin: undefined,
    surveyDateMax: undefined,
    excludedSiteDefinitions: {
      custom1: [],
      custom2: [],
      custom3: [],
      custom4: [],
    },
    priority: undefined,
    recommendationNumber: undefined,
    recommendationHeadline: undefined,
    rcbaCostToCompleteMin: undefined,
    rcbaCostToCompleteMax: undefined,
  });

  const updateFilter = async (newFilter: RecommendationTrackingFilter) => {
    // If the filter has not changed, do nothing
    if (isDeepEqual(activeFilter.value, newFilter)) return;

    // Fetches updated table data based on new filter settings
    onUpdateFilter(newFilter);

    // Update the filter state with the incoming new values
    activeFilter.value = newFilter;
  };

  return {
    activeFilter,
    updateFilter,
  };
};
