<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { roles } from "@/helpers/options";
import { isSuperior } from "@/plugins/can";
import { Role } from "@/types/_generated/api";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const { t } = useI18n({ useScope: "global" });

interface Props {
  role: Role;
  value: Role;
  errors?: ErrorObject[];
}

const props = withDefaults(defineProps<Props>(), {
  errors: () => [],
});

const emit = defineEmits<{
  (event: "update:value", value: Role): void;
}>();

const availableRoles = computed(() => {
  if ([Role.SA, Role.SystemOwner].includes(props.role)) {
    return roles;
  }

  return roles.filter((r) => isSuperior(props.role, r.title as Role));
});

const onUpdate = (value: string) => emit("update:value", value as Role);
</script>

<template>
  <BaseSelect
    data-test="user-role-select"
    :aria-label="t('common.role')"
    :value="value"
    :label="t('common.role')"
    @change="onUpdate($event)"
  >
    <BaseOption v-for="option in availableRoles" :key="option.value" :value="option.value">
      {{ option.title }}
    </BaseOption>
  </BaseSelect>
</template>
