import { Ref, computed } from "vue";
import { toCurrency, toInteger, toPercent } from "@/helpers/formatNumber";
import { LossEstimateType, RiskCostBenefitAnalysisDto } from "@/types/_generated/api";

export const useRcba = (rcba: Ref<RiskCostBenefitAnalysisDto>) => {
  const priorSum = computed(() => {
    const sum = rcba.value.rcbaPropertyPrior + rcba.value.rcbaBiPrior + rcba.value.rcbaExtraPrior;

    return rcba.value.rcbaLossEstimateType === LossEstimateType.Percent ? Math.min(100, sum) : sum;
  });

  const afterSum = computed(() => {
    const sum = rcba.value.rcbaPropertyAfter + rcba.value.rcbaBiAfter + rcba.value.rcbaExtraAfter;

    return rcba.value.rcbaLossEstimateType === LossEstimateType.Percent ? Math.min(100, sum) : sum;
  });

  const formatSum = (sum: number) => {
    if (isNaN(sum) || typeof sum !== "number") {
      return "";
    }

    return rcba.value.rcbaLossEstimateType === LossEstimateType.Monetary
      ? toCurrency(sum, rcba.value.rcbaCurrency)
      : rcba.value.rcbaLossEstimateType === LossEstimateType.Percent
        ? toPercent(sum)
        : toInteger(sum);
  };

  const costBenefitRatio = computed((): string => {
    // Changes to this ratio calculation also needs to be reflected in the backend in SurveyRecommendation
    const reduction = priorSum.value - afterSum.value;

    if (reduction === 0 || rcba.value.rcbaCostToComplete === 0) {
      return "";
    }

    return `${toInteger(reduction / rcba.value.rcbaCostToComplete!)}:1`;
  });

  const hasExtra = computed(() => rcba.value.rcbaExtraPrior! > 0 || rcba.value.rcbaExtraAfter! > 0);

  const hasProbability = computed(
    () => !!rcba.value.rcbaProbability || rcba.value.rcbaProbability.trim().length > 0,
  );

  return {
    priorSum,
    afterSum,
    formatSum,
    costBenefitRatio,
    hasExtra,
    hasProbability,
  };
};
