<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { DeepReadonly } from "vue";
import type { GroupBy } from "@/components/mods/Filter/SiteDefinitions/types/GroupBy";
import type { SitesData } from "@/composables/services/useGetSitesData";
import type { ExecutiveReportLossScenarioDto } from "@/types/_generated/api";
import type { SortBy } from "@/views/UserWeb/ExecutiveSummary/composables/useSortExecSummary";
import { useDownloadFile } from "@/composables/downloadFileWithPostRequest";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import ModFilterSiteDefinitionsGroupBy from "@/components/mods/Filter/SiteDefinitions/ModFilterSiteDefinitionsGroupBy.vue";
import BlueRatingTableToolbar from "@/components/shared/BlueRatingTable/BlueRatingTableToolbar.vue";
import type { ExecutiveSummaryDrawer } from "../../types/ExecSummaryDrawers";
import { DrawerOption } from "../../constants/DrawerOption";
import ExecSummaryToolbarSortBy from "./ExecSummaryToolbarSortBy.vue";

const { downloadFileWithPostRequest } = useDownloadFile();

const props = defineProps<{
  moduleId: number;
  isLoadingData?: boolean;
  currentSortOrder: SortBy;
  groupBy: GroupBy;
  lossScenarios?: DeepReadonly<ExecutiveReportLossScenarioDto[]>;
  siteDefinitions: DeepReadonly<SitesData>;
}>();

defineEmits<{
  openDrawer: [ExecutiveSummaryDrawer];
  sortTable: [SortBy];
  groupTable: [GroupBy];
}>();

const { t } = useI18n({ useScope: "global" });
const handleDownload = () => {
  const url = `/v1/modules/${props.moduleId}/reports/executive-summary/excel`;
  downloadFileWithPostRequest(url);
};
</script>

<template>
  <BlueRatingTableToolbar>
    <template #left>
      <BaseButton
        variant="outlined"
        :disabled="isLoadingData"
        :title="t('common.filter')"
        @click="$emit('openDrawer', { type: DrawerOption.Filter })"
      >
        <BaseIcon size="medium" icon="filter" />
      </BaseButton>

      <ExecSummaryToolbarSortBy
        :disabled="isLoadingData"
        :loss-scenarios="lossScenarios"
        :current-sort-order="currentSortOrder"
        @sort-table="$emit('sortTable', $event)"
      />

      <ModFilterSiteDefinitionsGroupBy
        :disabled="isLoadingData"
        :site-definitions="siteDefinitions"
        :value="groupBy"
        @change="$emit('groupTable', $event)"
      />
    </template>

    <template #right>
      <BaseButton
        variant="outlined"
        :disabled="isLoadingData"
        :title="t('userWeb.executiveSummary.toolbar.excel')"
        @click="handleDownload"
      >
        <BaseIcon size="medium" icon="excel" />
      </BaseButton>
    </template>
  </BlueRatingTableToolbar>
</template>
