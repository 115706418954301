<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useDialog } from "@/composables/useDialog";
import { useRecommendationNavigation } from "@/composables/useRecommendationNavigation";
import {
  filterAndSortRecommendationsForReports,
  sortRecommendationsForReports,
} from "@/helpers/recommendationSorting";
import { RecRespRouteName } from "@/router/RouteName";
import {
  BlueRatingDto,
  BlueRatingRecommendationDto,
  RecommendationResponseDto,
  SiteResponseStatus,
  SurveyorStatus,
} from "@/types/_generated/api";
import RecResponseViewLayout from "@/components/app/RecResponse/RecResponseViewLayout.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseProgressBar from "@/components/base/BaseProgressBar.vue";
import OnlineRecReportHeader from "@/components/shared/OnlineRecReport/OnlineRecReportHeader.vue";
import OnlineRecReportHeaderSettings from "@/components/shared/OnlineRecReport/OnlineRecReportHeaderSettings.vue";
import OnlineRecReportLoading from "@/components/shared/OnlineRecReport/OnlineRecReportLoading.vue";
import OnlineRecReportMenu from "@/components/shared/OnlineRecReport/OnlineRecReportMenu.vue";
import OnlineRecReportRecommendation from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendation.vue";
import OnlineRecReportRecommendationAbeyanceProperties from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendationAbeyanceProperties.vue";
import OnlineRecReportRecommendationCompletedProperties from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendationCompletedProperties.vue";
import OnlineRecReportRecommendationProperties from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendationProperties.vue";
import OnlineRecReportRecommendationRcbaProperties from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendationRcbaProperties.vue";
import LatestRecommendationResponse from "@/components/shared/RecommendationResponse/LatestRecommendationResponse.vue";
import LatestRiskManagerComment from "@/components/shared/RecommendationResponse/LatestRiskManagerComment.vue";
import ExternalResponseAndCommentTimeline from "./components/ExternalResponseAndCommentTimeline.vue";
import ExternalResponseEdit from "./components/ExternalResponseEdit.vue";
import ExternalResponseFileList from "./components/ExternalResponseFileList.vue";
import { getRecResponseSurveyService, submitRecResponsesService } from "./services/recResponse";

const { t } = useI18n({ useScope: "global" });
const { openDialog } = useDialog();
const router = useRouter();

const isLoadingSurvey = ref(false);
const blueRating = ref<BlueRatingDto | null>(null);
const recommendations = ref<BlueRatingRecommendationDto[]>([]);
const moduleName = ref<string>("");
const siteName = ref<string>("");
const surveyDate = ref<string>("");
const allowedFileTypes = ref<string[]>([]);

// View Settings
const settings = ref({
  showTimeline: false,
  showAbeyance: false,
  showCompleted: false,
});

// Filter and sort recommendations
const categories = computed(() => blueRating.value?.areas.flatMap((a) => a.categories) ?? []);
const sortedRecommendations = computed(() =>
  filterAndSortRecommendationsForReports(recommendations.value, categories.value),
);
const sortedAbeyanceRecommendations = computed(() =>
  sortRecommendationsForReports(
    recommendations.value.filter((r) => r.surveyorStatus === SurveyorStatus.Abeyance),
    categories.value,
  ),
);
const sortedCompletedRecommendations = computed(() =>
  sortRecommendationsForReports(
    recommendations.value.filter((r) => r.surveyorStatus === SurveyorStatus.Completed),
    categories.value,
  ),
);
const allSortedRecommendations = computed(() => [
  ...sortedRecommendations.value,
  ...(settings.value.showAbeyance ? sortedAbeyanceRecommendations.value : []),
  ...(settings.value.showCompleted ? sortedCompletedRecommendations.value : []),
]);

const nbrOfUpdatedRecommendations = computed(
  () =>
    sortedRecommendations.value.filter(
      (r) =>
        (r.responseHistory.history[0]?.status ?? SiteResponseStatus.NotSet) !==
        SiteResponseStatus.NotSet,
    ).length,
);

// Navigation
const {
  selectedRecommendation,
  selectRecommendation,
  indexOfSelectedRecommendation,
  selectPreviousRecommendation,
  selectNextRecommendation,
} = useRecommendationNavigation(allSortedRecommendations);

onMounted(async () => {
  isLoadingSurvey.value = true;

  const response = await getRecResponseSurveyService();
  blueRating.value = response?.survey.blueRating ?? null;
  recommendations.value = response?.survey.recommendations ?? [];
  moduleName.value = response?.survey.moduleName ?? "";
  siteName.value = response?.survey.siteName ?? "";
  surveyDate.value = response?.survey.surveyDate ?? "";
  allowedFileTypes.value = response?.fileExtensions ?? [];

  selectedRecommendation.value = sortedRecommendations.value[0] ?? null;

  isLoadingSurvey.value = false;
});

const showRcba = computed(
  () =>
    !!(
      selectedRecommendation.value?.rcbaPropertyPrior ||
      selectedRecommendation.value?.rcbaPropertyAfter ||
      selectedRecommendation.value?.rcbaBiPrior ||
      selectedRecommendation.value?.rcbaBiAfter ||
      selectedRecommendation.value?.rcbaExtraPrior ||
      selectedRecommendation.value?.rcbaExtraAfter
    ),
);

const createResponse = (response: RecommendationResponseDto) => {
  if (selectedRecommendation.value) {
    selectedRecommendation.value.responseHistory.history.unshift(response);
  }
};

const updateResponse = (response: RecommendationResponseDto) => {
  if (selectedRecommendation.value) {
    const index = selectedRecommendation.value.responseHistory.history.findIndex(
      (r) => r.id === response.id,
    );
    if (index !== -1) {
      selectedRecommendation.value.responseHistory.history[index] = response;
    }
  }
};

const submitRecommendationResponse = async () => {
  const allLocked = sortedRecommendations.value.every(
    (r) => r.responseHistory.history[0]?.locked ?? false,
  );
  const allSet = sortedRecommendations.value.every(
    (r) =>
      (r.responseHistory.history[0]?.status ?? SiteResponseStatus.NotSet) !==
      SiteResponseStatus.NotSet,
  );

  if (!allLocked && !allSet) {
    return;
  }

  isLoadingSurvey.value = true;
  const survey = await submitRecResponsesService();
  isLoadingSurvey.value = false;

  if (!survey) {
    return;
  }

  blueRating.value = survey.blueRating;
  recommendations.value = survey.recommendations;
  moduleName.value = survey.moduleName ?? "";
  siteName.value = survey.siteName ?? "";
  surveyDate.value = survey.surveyDate ?? "";

  const goBack = await openDialog({
    title: t("recommendationResponse.responseSubmitted"),
    description: t("recommendationResponse.goToSummary"),
    confirmText: t("recommendationResponse.actions.go"),
    cancelText: t("recommendationResponse.actions.stay"),
  });

  if (goBack) {
    router.push({ name: RecRespRouteName.Summary });
  }
};
</script>

<template>
  <RecResponseViewLayout class="view-rec-resp-respond">
    <OnlineRecReportLoading v-if="isLoadingSurvey" />
    <OnlineRecReportHeader
      v-if="blueRating && !isLoadingSurvey"
      :module-name="moduleName"
      :site-name="siteName"
      :survey-date="surveyDate"
    >
      <template #default>{{ t("recResponse.recResponse") }}</template>
      <template #settings>
        <OnlineRecReportHeaderSettings
          :abeyance-value="settings.showAbeyance"
          :completed-value="settings.showCompleted"
          :timeline-value="settings.showTimeline"
          :show-abeyance="sortedAbeyanceRecommendations.length > 0"
          :show-completed="sortedCompletedRecommendations.length > 0"
          show-timeline
          @update:abeyance="settings.showAbeyance = $event"
          @update:completed="settings.showCompleted = $event"
          @update:timeline="settings.showTimeline = $event"
        />
      </template>
    </OnlineRecReportHeader>

    <div v-if="!isLoadingSurvey" class="view-rec-resp-respond__content">
      <div class="view-rec-resp-respond__content__nav">
        <div class="view-rec-resp-respond__content__nav__buttons">
          <BaseButton
            variant="outlined"
            :disabled="indexOfSelectedRecommendation <= 0"
            @click="selectPreviousRecommendation"
          >
            {{ t("common.actions.previous") }}
          </BaseButton>

          <BaseButton
            variant="outlined"
            :disabled="indexOfSelectedRecommendation >= allSortedRecommendations.length - 1"
            @click="selectNextRecommendation"
          >
            {{ t("common.actions.next") }}
          </BaseButton>
        </div>

        <OnlineRecReportMenu
          status="response"
          :recommendations="sortedRecommendations"
          :selected="selectedRecommendation ?? undefined"
          @select="selectRecommendation"
        />

        <div
          v-if="sortedRecommendations.length"
          class="view-rec-resp-respond__content__nav__progress"
        >
          <BaseProgressBar
            v-if="nbrOfUpdatedRecommendations < sortedRecommendations.length"
            :primary-progress="nbrOfUpdatedRecommendations"
            :max="sortedRecommendations.length"
          />
          <transition name="bounce" appear>
            <BaseButton
              v-if="nbrOfUpdatedRecommendations === sortedRecommendations.length"
              class="view-rec-resp-respond__content__nav__progress__submit"
              @click="submitRecommendationResponse"
            >
              {{ t("recommendationResponse.submitRecommendationResponse") }}
            </BaseButton>
          </transition>
        </div>

        <h2 v-if="settings.showAbeyance" class="view-rec-resp-respond__content__nav__title">
          {{ t("recommendationResponse.exemptRecommendations") }}
        </h2>
        <OnlineRecReportMenu
          v-if="settings.showAbeyance"
          status="response"
          :recommendations="sortedAbeyanceRecommendations"
          :selected="selectedRecommendation ?? undefined"
          @select="selectRecommendation"
        />

        <h2 v-if="settings.showCompleted" class="view-rec-resp-respond__content__nav__title">
          {{ t("recommendationResponse.completedRecommendations") }}
        </h2>
        <OnlineRecReportMenu
          v-if="settings.showCompleted"
          status="response"
          :recommendations="sortedCompletedRecommendations"
          :selected="selectedRecommendation ?? undefined"
          @select="selectRecommendation"
        />
      </div>

      <template
        v-for="recommendation in allSortedRecommendations"
        :key="`rec_${recommendation.recommendationId}`"
      >
        <div
          class="view-rec-resp-respond__content__recommendation"
          :class="{
            'view-rec-resp-respond__content__recommendation--current':
              selectedRecommendation === recommendation,
          }"
        >
          <!-- ABEYANCE -->
          <OnlineRecReportRecommendation
            v-if="recommendation.surveyorStatus === SurveyorStatus.Abeyance"
            hide-status
            :recommendation="recommendation"
          >
            <template #properties>
              <OnlineRecReportRecommendationAbeyanceProperties :recommendation="recommendation" />
            </template>
          </OnlineRecReportRecommendation>

          <!-- COMPLETED -->
          <OnlineRecReportRecommendation
            v-if="recommendation.surveyorStatus === SurveyorStatus.Completed"
            hide-status
            :recommendation="recommendation"
          >
            <template #properties>
              <OnlineRecReportRecommendationCompletedProperties :recommendation="recommendation" />
            </template>
          </OnlineRecReportRecommendation>

          <!-- RECOMMENDATIONS -->
          <OnlineRecReportRecommendation
            v-if="
              ![SurveyorStatus.Abeyance, SurveyorStatus.Completed].includes(
                recommendation.surveyorStatus,
              )
            "
            hide-status
            :recommendation="recommendation"
          >
            <template #properties>
              <OnlineRecReportRecommendationProperties
                :recommendation="recommendation"
                :categories="categories"
              />
            </template>

            <template v-if="showRcba" #rcba>
              <OnlineRecReportRecommendationRcbaProperties :rcba="recommendation" />
            </template>

            <template
              v-if="!settings.showTimeline && recommendation.responseHistory.history.length"
              #recommendation-response
            >
              <LatestRecommendationResponse
                :recommendation-response="recommendation.responseHistory"
                :title="t('recommendationResponse.responseSummary')"
              >
                <template #filelist="{ response }">
                  <ExternalResponseFileList :response="response" />
                </template>
              </LatestRecommendationResponse>
            </template>

            <template
              v-if="!settings.showTimeline && recommendation.riskManagerCommentHistory.length"
              #risk-manager-comment
            >
              <LatestRiskManagerComment
                :comments="recommendation.riskManagerCommentHistory"
                :title="t('recommendationResponse.commentSummary')"
              />
            </template>

            <template v-if="settings.showTimeline" #response-timeline>
              <ExternalResponseAndCommentTimeline
                :response-history="recommendation.responseHistory.history"
                :risk-manager-comment-history="recommendation.riskManagerCommentHistory"
              />
            </template>

            <template #comments>
              <ExternalResponseEdit
                :recommendation-id="recommendation.recommendationId"
                :response-history="recommendation.responseHistory.history"
                :allowed-file-types="allowedFileTypes"
                @update:response="updateResponse"
                @create:response="createResponse"
              />
            </template>
          </OnlineRecReportRecommendation>
        </div>
      </template>
    </div>
  </RecResponseViewLayout>
</template>

<style scoped lang="scss">
.view-rec-resp-respond {
  $nav-width: max(20rem, 30vw);
  $content-width: min(62rem, 60vw);
  $max-width: calc($nav-width + $content-width + $spacing-8);

  &__content {
    display: grid;
    gap: $spacing-8;
    max-width: $max-width;
    grid-template-columns: $nav-width $content-width;

    &__recommendation {
      display: none;
      &--current {
        display: block;
      }
    }

    &__nav {
      display: flex;
      flex-direction: column;
      gap: $spacing-4;

      &__title {
        font-weight: $font-semibold;
        margin-top: $spacing-4;
      }

      &__buttons {
        display: flex;
        gap: $spacing-2;
        justify-content: space-between;
      }

      &__progress {
        margin-top: $spacing-4;
        &__submit {
          width: 100%;
          transition: all $duration-300 cubic-bezier(0.47, 1.64, 0.41, 0.8);
        }
      }
    }

    @media print {
      display: block;
      &__nav {
        display: none;
      }
      &__recommendation {
        display: block;
        overflow-y: visible;
      }
    }
  }
}

.bounce-enter-to {
  transform: scale(1.2);
}
.bounce-leave-active {
  display: none;
}
</style>
