<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { SiteResponseColorCount, SiteResponseStatusCount } from "../types";
import SummaryChartsPie from "./SummaryChartsPie.vue";
import SummaryChartsStackBar from "./SummaryChartsStackBar.vue";

defineProps<{
  siteResponseStatuses: SiteResponseStatusCount;
  siteResponseStatusColors: SiteResponseColorCount;
}>();

const { t } = useI18n();
</script>

<template>
  <div class="summary-charts">
    <div class="summary-charts__item">
      <p class="summary-charts__item__title">
        {{ t("recResponse.summary.siteResponseStatus") }}
      </p>
      <SummaryChartsPie :site-response-statuses="siteResponseStatuses" />
    </div>

    <div class="summary-charts__item">
      <p class="summary-charts__item__title">
        {{ t("recResponse.summary.siteResponseBlueRatingChart") }}
      </p>
      <SummaryChartsStackBar :site-response-status-colors="siteResponseStatusColors" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.summary-charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-8;

  @media (max-width: $screen-md) {
    grid-template-columns: 1fr;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    height: 40rem;

    &__title {
      font-size: $text-base;
      font-weight: $font-semibold;
    }
  }
}
</style>
