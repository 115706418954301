<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { useTableFilter } from "@/composables/useTableFilter";
import { SortOrder } from "@/constants/SortOrder";
import { getServiceMessages, serviceMessageEvents } from "@/services/serviceMessages";
import { ServiceMessageDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import EditIconButton from "@/components/shared/EditIconButton.vue";
import SystemServiceMessageEditDrawer from "./SystemServiceMessageEditDrawer.vue";

const { t } = useI18n({ useScope: "global" });

const isLoading = ref(false);
const messages = ref<ServiceMessageDto[]>([]);

const selectedServiceMessageId = ref<number | null>();
const showServiceMessageEditDrawer = ref(false);

const columns: ColumnDefinition<ServiceMessageDto>[] = [
  new ColumnDefinition({
    title: t("serviceMessages.entity.id"),
    key: "id",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("serviceMessages.entity.active"),
    key: "active",
    autoWidth: true,
    type: ColumnDataType.Boolean,
  }),
  new ColumnDefinition({
    title: t("serviceMessages.entity.subject"),
    key: "subject",
  }),
  new ColumnDefinition({
    title: t("serviceMessages.entity.message"),
    key: "message",
    autoWidth: true,
  }),
];

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(messages, columns);

useBroadcastService(serviceMessageEvents, async () => {
  messages.value = (await getServiceMessages()) ?? [];
});

const openEditDrawer = (serviceMessageId?: number) => {
  showServiceMessageEditDrawer.value = true;
  selectedServiceMessageId.value = serviceMessageId;
};

const closeEditDrawer = () => {
  showServiceMessageEditDrawer.value = false;
  selectedServiceMessageId.value = null;
};

onBeforeMount(async () => {
  isLoading.value = true;
  messages.value = (await getServiceMessages()) ?? [];
  isLoading.value = false;
});
</script>

<template>
  <BaseGridTable
    enable-row-click
    :rows="result.result"
    :columns="columns"
    :is-loading="isLoading"
    :default-sort="[{ key: 'id', order: SortOrder.Descending }]"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #control-actions>
      <BaseButton data-test="create-service-message-button" @click="openEditDrawer()">
        {{ t("serviceMessages.createNew") }}
      </BaseButton>
    </template>

    <template #actions="{ row }">
      <EditIconButton
        data-test="edit-service-message-button"
        :title="t('common.actions.edit')"
        @click="openEditDrawer(row.id)"
      />
    </template>
  </BaseGridTable>
  <SystemServiceMessageEditDrawer
    v-if="showServiceMessageEditDrawer"
    :service-message-id="selectedServiceMessageId"
    @close="closeEditDrawer"
  />
</template>
