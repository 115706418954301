import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/helpers/formatDate";
import { UserWebRouteName } from "@/router/RouteName";
import type { DashboardHistoryItem } from "../types";
import mockData from "./mockdata.json";

interface MockdataSurveyHistoryItem {
  site: string;
  date: string;
  siteId: number;
  moduleId: number;
  surveyId: number;
}

interface MockdataSiteResponseHistoryItem {
  site: string;
  date: string;
  siteId: number;
  moduleId: number;
}

const transformSurveyHistoryData = (
  history: MockdataSurveyHistoryItem[],
): DashboardHistoryItem[] => {
  return history.map((item) => ({
    site: item.site,
    date: formatDate.asDateOnly(item.date),
    routerLink: {
      name: UserWebRouteName.Site,
      params: { siteId: item.siteId, moduleId: item.moduleId, surveyId: item.surveyId },
    },
  }));
};

const transformSiteResponseHistoryData = (
  history: MockdataSiteResponseHistoryItem[],
): DashboardHistoryItem[] => {
  return history.map((item) => ({
    site: item.site,
    date: formatDate.asDateOnly(item.date),
    routerLink: {
      name: UserWebRouteName.Site,
      params: { siteId: item.siteId, moduleId: item.moduleId },
    },
  }));
};

export const useGetHistory = () => {
  const { t } = useI18n({ useScope: "global" });

  const isLoadingHistory = ref(false);
  const surveyHistory = ref<DashboardHistoryItem[]>([]);
  const siteResponseHistory = ref<DashboardHistoryItem[]>([]);

  const historyColumns: { title: string; key: keyof DashboardHistoryItem }[] = [
    { title: t("userWeb.dashboard.table.site"), key: "site" },
    { title: t("userWeb.dashboard.table.date"), key: "date" },
  ];

  const fetchData = async () => {
    isLoadingHistory.value = true;

    try {
      // Simulate an asynchronous operation
      await new Promise((resolve) => setTimeout(resolve, 2000));

      surveyHistory.value = transformSurveyHistoryData(mockData.surveyHistory);
      siteResponseHistory.value = transformSiteResponseHistoryData(mockData.siteResponseHistory);
    } finally {
      isLoadingHistory.value = false;
    }
  };

  onMounted(fetchData);

  return {
    isLoadingHistory,
    historyColumns,
    surveyHistory,
    siteResponseHistory,
  };
};
