import { ref } from "vue";
import { createSurvey, updateSurveySettings } from "@/services/surveys";
import { CreateSurvey, UpdateSurveySettings } from "@/types/_generated/api";
import { SurveySettings } from "./useInitializeSurveySettings";

export const useSaveSurveySettings = () => {
  const isSavingSurveySettings = ref(false);

  const saveSurveySettings = async (
    surveySettings: SurveySettings,
    siteId: number,
    surveyId?: number | null,
  ) => {
    isSavingSurveySettings.value = true;

    await (surveyId
      ? updateSurveySettings({
          ...surveySettings,
          surveyId,
        } as UpdateSurveySettings)
      : createSurvey({
          ...surveySettings,
          siteId,
        } as CreateSurvey));

    isSavingSurveySettings.value = false;
  };

  return {
    isSavingSurveySettings,
    saveSurveySettings,
  };
};
