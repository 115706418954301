<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import { getModule } from "@/services/modules";
import { getQuestionnaire } from "@/services/questionnaire";
import { ModuleDto, QuestionnaireDto } from "@/types/_generated/api";
import QuestionnaireNav from "@/views/Admin/Questionnaire/components/QuestionnaireNav/QuestionnaireNav.vue";
import UserWebViewLayout from "@/components/app/UserWeb/UserWebViewLayout.vue";
import BaseSpinner from "@/components/base/BaseSpinner.vue";
import QuestionnaireViewer from "@/components/shared/Questionnaire/QuestionnaireViewer.vue";

const props = defineProps<{
  moduleId: number;
}>();

const { t } = useI18n({ useScope: "global" });
const { setBreadcrumbs } = useBreadcrumbs();

const module = ref<ModuleDto | null>();
const questionnaire = ref<QuestionnaireDto | null>();
const isLoading = ref(false);

setBreadcrumbs([{ label: t("navigation.questionnaire") }]);

onBeforeMount(async () => {
  isLoading.value = true;

  module.value = await getModule(props.moduleId);
  questionnaire.value = await getQuestionnaire(module.value!.currentQuestionnaireId);

  isLoading.value = false;
});
</script>

<template>
  <UserWebViewLayout>
    <div class="questionnaire-view">
      <BaseSpinner v-if="isLoading" class="questionnaire-view__spinner" />
      <div v-else-if="questionnaire!.areas.length < 1">
        {{ t("userWeb.questionnaire.noQuestionnaire") }}
      </div>

      <div v-else class="questionnaire-view__container">
        <div class="questionnaire-view__scroller">
          <QuestionnaireNav :questionnaire="questionnaire || undefined" />
        </div>

        <div class="questionnaire-view__scroller">
          <QuestionnaireViewer :questionnaire="questionnaire || undefined" />
        </div>
      </div>
    </div>
  </UserWebViewLayout>
</template>

<style scoped lang="scss">
.questionnaire-view {
  &__spinner {
    height: calc($view-height - $header-height);
  }

  &__container {
    display: grid;
    grid-template-columns: 350px 1fr;
  }

  &__scroller {
    height: $view-height;
    overflow: auto;
    scroll-behavior: smooth;
    scroll-padding: $spacing-4;
  }
}
</style>
