import { SortOrder } from "@/constants/SortOrder";
import {
  BlueColor,
  SurveyorStatus,
  type BlueRatingCategoryDto,
  type BlueRatingRecommendationDto,
} from "@/types/_generated/api";

const blueColorTable: Record<BlueColor, number> = {
  NotSet: 0,
  Blue: 1,
  Green: 2,
  Yellow: 3,
  Red: 4,
  Gray: 5,
};

const sortByColor = (recommendations: BlueRatingRecommendationDto[], order = SortOrder.Ascending) =>
  recommendations.toSorted((a, b) =>
    order === SortOrder.Ascending
      ? blueColorTable[a.categoryColorOverride] - blueColorTable[b.categoryColorOverride]
      : blueColorTable[b.categoryColorOverride] - blueColorTable[a.categoryColorOverride],
  );

const sortByAge = (recommendations: BlueRatingRecommendationDto[], order = SortOrder.Ascending) =>
  recommendations.toSorted((a, b) => {
    const aDate = a.originalSurveyDate ?? "";
    const bDate = b.originalSurveyDate ?? "";

    return order === SortOrder.Ascending ? aDate.localeCompare(bDate) : bDate.localeCompare(aDate);
  });

const sortByCategory = (
  recommendations: BlueRatingRecommendationDto[],
  categories: BlueRatingCategoryDto[],
  order = SortOrder.Ascending,
) => {
  const getCategoryByRecommendation = (recommendationId: number) =>
    categories.find((category) =>
      category.recommendations.some((rec) => rec.recommendationId === recommendationId),
    );

  return recommendations.toSorted((a, b) => {
    const catA = getCategoryByRecommendation(a.recommendationId);
    const catB = getCategoryByRecommendation(b.recommendationId);

    if (!catA || !catB || catA === catB) return 0;

    return order === SortOrder.Ascending
      ? catA.fullPosition.localeCompare(catB.fullPosition)
      : catB.fullPosition.localeCompare(catA.fullPosition);
  });
};

const sortByKeyRecommendation = (
  recommendations: BlueRatingRecommendationDto[],
  order = SortOrder.Ascending,
) =>
  recommendations.toSorted((a, b) =>
    order === SortOrder.Ascending
      ? // Number(boolean) returns 1 for true and 0 for false
        Number(b.isKeyRecommendation) - Number(a.isKeyRecommendation)
      : Number(a.isKeyRecommendation) - Number(b.isKeyRecommendation),
  );

const sortByAbeyanceLast = (recommendations: BlueRatingRecommendationDto[]) =>
  recommendations.toSorted((a, b) => {
    if (
      a.surveyorStatus !== SurveyorStatus.Abeyance &&
      b.surveyorStatus !== SurveyorStatus.Abeyance
    )
      return 0;
    if (
      a.surveyorStatus === SurveyorStatus.Abeyance &&
      b.surveyorStatus === SurveyorStatus.Abeyance
    )
      return 0;
    if (a.surveyorStatus === SurveyorStatus.Abeyance) return 1;
    return -1;
  });

export const sortRecommendationsForReports = (
  recommendations: BlueRatingRecommendationDto[],
  categories: BlueRatingCategoryDto[],
) => {
  let recs = recommendations;

  recs = sortByColor(recs);
  recs = sortByAge(recs);
  recs = sortByCategory(recs, categories);
  recs = sortByKeyRecommendation(recs);
  recs = sortByAbeyanceLast(recs);

  return recs;
};

/**
 * Hides recommendations with statuses Completed, Withdrawn and Abeyance
 * and returns the rest sorted by color, age, category, key recommendation, and abeyance status
 * @param recommendations
 * @param categories
 * @param showAbeyance Include recommendations with status Abeyance
 * @param showCompleted Include recommendations with status Completed
 * @returns
 */
export const filterAndSortRecommendationsForReports = (
  recommendations: BlueRatingRecommendationDto[],
  categories: BlueRatingCategoryDto[],
  showAbeyance: boolean = false,
  showCompleted: boolean = false,
) => {
  const notAllowedStatuses = [SurveyorStatus.Withdrawn];

  if (!showAbeyance) {
    notAllowedStatuses.push(SurveyorStatus.Abeyance);
  }

  if (!showCompleted) {
    notAllowedStatuses.push(SurveyorStatus.Completed);
  }

  const recs = recommendations.filter((r) => !notAllowedStatuses.includes(r.surveyorStatus));

  return sortRecommendationsForReports(recs, categories);
};
