<script setup lang="ts" generic="T extends { position: number }">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import BaseDropdownMenu, { MenuOption } from "./BaseDropdownMenu/BaseDropdownMenu.vue";
import { Props as BaseIconProps } from "./BaseIcon/BaseIcon.vue";

type KeyType = string | number | symbol;

const props = withDefaults(
  defineProps<{
    id: (item: T) => T[keyof T];
    items: T[];
    actions?: { icon: BaseIconProps["icon"]; label: string; event: string }[];
    disabled?: boolean;
  }>(),
  {
    actions: () => [],
    disabled: false,
  },
);

const emit = defineEmits<{
  (event: "update-order", value: { id: T[keyof T]; position: number }[]): void;
  (event: `action:${string}`, item: T): void;
}>();

const { t } = useI18n({ useScope: "global" });

const draggedIndex = ref<number>();
const droppedIndex = ref<number>();

const effectiveActions = (index: number, item: T): MenuOption[] => [
  ...props.actions.map((a) => ({
    icon: a.icon,
    label: a.label,
    action: () => emit(`action:${a.event}`, item),
  })),
  {
    icon: "arrow-up",
    label: t("library.orderedList.moveUp"),
    disabled: index === 0,
    action: () => onAction("moveup", item),
  },
  {
    icon: "arrow-down",
    label: t("library.orderedList.moveDown"),
    disabled: index === props.items.length - 1,
    action: () => onAction("movedown", item),
  },
];

const getNewOrder = (currentIndex: number, newIndex: number) => {
  const newPositions = props.items.map((item) => ({
    ...item,
  }));

  const [draggedItem] = newPositions.splice(currentIndex, 1);

  newPositions.splice(newIndex, 0, draggedItem);

  return newPositions.map((item, index) => ({
    id: props.id(item),
    position: index + 1,
  }));
};

const onAction = (action: string, item: T) => {
  if (action === "moveup" || action === "movedown") {
    const currentIndex = props.items.findIndex((x) => x === item);
    const newIndex = currentIndex + (action === "moveup" ? -1 : 1);

    emit("update-order", getNewOrder(currentIndex, newIndex));
  }
};

const onDragStart = (index: number) => (draggedIndex.value = index);
const onDragOver = (index: number) => (droppedIndex.value = index);

const drop = () => {
  emit("update-order", getNewOrder(draggedIndex.value!, droppedIndex.value!));

  draggedIndex.value = undefined;
  droppedIndex.value = undefined;
};
</script>

<template>
  <ul>
    <li
      v-for="(item, index) in items"
      :key="id(item) as KeyType"
      class="base-ordered-list__item"
      :class="{
        'base-ordered-list__item--dragging':
          draggedIndex === index && droppedIndex !== draggedIndex,
      }"
      :draggable="!disabled"
      @dragstart="onDragStart(index)"
      @dragover.prevent="onDragOver(index)"
      @drop="drop"
    >
      <div>
        <div class="base-ordered-list__item__content">
          <div
            v-if="index == droppedIndex && index !== draggedIndex"
            class="base-ordered-list__item__drop-here"
          >
            {{ t("library.orderedList.moveHere") }}
          </div>
          <slot name="item" :item="item">
            <div>
              {{ index }}
            </div>
          </slot>

          <slot name="actions" :item="item">
            <BaseDropdownMenu
              :disabled="disabled"
              :options="effectiveActions(index, item)"
              :position="['bottom', 'left']"
            />
          </slot>
        </div>
      </div>
    </li>
  </ul>
</template>

<style scoped lang="scss">
.base-ordered-list {
  &__item {
    &--reverse {
      flex-direction: row-reverse;
    }

    &--dragging:active {
      opacity: 0.3;
    }

    &__grabber {
      cursor: grab;
    }
    &__drop-here {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 3;
      text-align: center;
      border-radius: $rounded-base;
      border: 1px dashed $primary-5;
      background-color: rgba(226, 232, 240, 0.8);
      padding: $spacing-2;
      margin-bottom: $spacing-2;
    }

    &__content {
      display: grid;
      position: relative;
      gap: $spacing-2;
      align-items: center;
      grid-template-columns: 1fr auto;
    }
  }
}
</style>
