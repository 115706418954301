<script setup lang="ts">
import { computed } from "vue";
import { useSiteResponseStatusChartMetaData } from "@/composables/useSiteStatusMetaData";
import { toPercent } from "@/helpers/formatNumber";
import { SiteResponseStatus } from "@/types/_generated/api";
import BaseChart from "@/components/base/BaseChart/BaseChart.vue";
import { BORDER } from "@/components/base/BaseChart/constants/style";
import { SiteResponseStatusCount } from "../types";

const props = defineProps<{
  siteResponseStatuses: SiteResponseStatusCount;
}>();

const { statusData } = useSiteResponseStatusChartMetaData();

const seriesData = computed(() => {
  const series = Object.entries(props.siteResponseStatuses).map(([status, count]) => {
    const statusKey = statusData[status as SiteResponseStatus];

    return {
      name: statusKey.label,
      value: count,
      itemStyle: {
        color: statusKey.color,
        borderColor: BORDER.COLOR,
        borderWidth: BORDER.WIDTH,
      },
    };
  });

  return series.filter((item) => item.value > 0);
});

const option = {
  title: {
    left: "center",
  },
  tooltip: {
    trigger: "item",
    formatter: (params: { name: string; percent: number }) => {
      return `${params.name}: ${toPercent(params.percent, false, 2)}`;
    },
  },
  legend: {
    orient: "horizontal",
    type: "scroll",
    left: "center",
    data: seriesData.value.map((item) => item.name),
    itemStyle: {
      borderWidth: 0.5,
    },
  },
  series: [
    {
      type: "pie",
      radius: "55%",
      center: ["50%", "50%"],
      data: seriesData.value,
      label: {
        formatter: (params: { name: string; percent: number }) => {
          return `${params.name}: ${toPercent(params.percent)}`;
        },
      },
    },
  ],
};
</script>

<template>
  <BaseChart :option="option" />
</template>
