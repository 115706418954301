<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import BaseSpinner from "@/components/base/BaseSpinner.vue";
import ModInfoState, {
  type Props as ModInfoStateProps,
} from "@/components/mods/InfoState/ModInfoState.vue";
import RecTrackingBlueRatingChart from "./components/RecTrackingBlueRatingChart.vue";
import RecTrackingCategoryChart from "./components/RecTrackingCategoryChart.vue";
import RecTrackingStatusChart from "./components/RecTrackingStatusChart.vue";

const props = defineProps<{
  isLoading: boolean;
  recommendationTracking: TrackedRecommendation[];
  errorFetchingRecTracking: boolean;
}>();

const { t } = useI18n({ useScope: "global" });

const currentInfoState = computed<ModInfoStateProps["state"] | null>(() => {
  if (props.errorFetchingRecTracking) {
    return "error";
  } else if (props.recommendationTracking.length === 0) {
    return "empty";
  }
  return null;
});
</script>

<template>
  <div class="rec-tracking-overview">
    <BaseSpinner v-if="isLoading" class="rec-tracking-overview__spinner" />

    <template v-else>
      <ModInfoState v-if="currentInfoState" :state="currentInfoState" />

      <template v-else>
        <div class="rec-tracking-overview__content">
          <div class="rec-tracking-overview__chart">
            <p class="rec-tracking-overview__chart__title">
              {{ t("userWeb.recommendation.responseStatus") }}
            </p>
            <RecTrackingStatusChart :chart-data="recommendationTracking" />
          </div>

          <div class="rec-tracking-overview__chart">
            <p class="rec-tracking-overview__chart__title">
              {{ t("userWeb.recommendationTracking.siteResponseBlueRatingChart.title") }}
            </p>
            <RecTrackingBlueRatingChart :chart-data="recommendationTracking" />
          </div>
        </div>

        <div class="rec-tracking-overview__chart">
          <p class="rec-tracking-overview__chart__title">
            {{ t("userWeb.recommendationTracking.siteResponsePerCategoryChart.title") }}
          </p>
          <RecTrackingCategoryChart :chart-data="recommendationTracking" />
        </div>
      </template>
    </template>
  </div>
</template>

<style scoped lang="scss">
.rec-tracking-overview {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;

  &__spinner {
    height: calc($view-height - ($header-height * 2));
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-8;

    @media (max-width: $screen-md) {
      grid-template-columns: 1fr;
    }
  }

  &__chart {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    height: 40rem;

    &__title {
      font-size: $text-base;
      font-weight: $font-semibold;
    }
  }
}
</style>
