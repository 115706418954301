import { ref, readonly } from "vue";
import { getBlueLossValuesService } from "@/services/dataAnalysis";
import { BlueLossValueLookup } from "../types/BlueLossValueLookup";
import { DaatServerFilter } from "./useFilterServer";

export const useGetBlueLossValues = () => {
  const isLoadingBlueLossValues = ref(false);
  const hasError = ref(false);
  const blueLossValues = ref<BlueLossValueLookup | null>(null);

  const getBlueLossValues = async (moduleId: number, filter: DaatServerFilter) => {
    isLoadingBlueLossValues.value = true;
    hasError.value = false;

    try {
      blueLossValues.value = await getBlueLossValuesService(moduleId, filter);
    } catch {
      hasError.value = true;
    } finally {
      isLoadingBlueLossValues.value = false;
    }
  };

  return {
    blueLossValues,
    hasError: readonly(hasError),
    isLoadingBlueLossValues: readonly(isLoadingBlueLossValues),
    getBlueLossValues,
  };
};
