import Squire from "squire-rte";
import { Editor } from "../constants";

export const useToolbarActions = (editor: Squire | null) => {
  const executeCommand = (command: Editor.Command, attribute = "") => {
    if (!editor) return;

    switch (command) {
      case Editor.Command.Bold:
        editor.hasFormat(Editor.Format.Bold) ? editor.removeBold() : editor.bold();
        break;
      case Editor.Command.Italic:
        editor.hasFormat(Editor.Format.Italic) ? editor.removeItalic() : editor.italic();
        break;
      case Editor.Command.Underline:
        editor.hasFormat(Editor.Format.Underline) ? editor.removeUnderline() : editor.underline();
        break;
      case Editor.Command.Strikethrough:
        editor.hasFormat(Editor.Format.Strikethrough)
          ? editor.removeStrikethrough()
          : editor.strikethrough();
        break;
      case Editor.Command.TextSize:
        if (attribute) editor.setFontSize(attribute);
        break;
      case Editor.Command.TextColor:
        if (attribute) editor.setTextColor(attribute);
        break;
      case Editor.Command.UnorderedList:
        editor.hasFormat(Editor.Format.UnorderedList)
          ? editor.removeList()
          : editor.makeUnorderedList();
        break;
      case Editor.Command.OrderedList:
        editor.hasFormat(Editor.Format.OrderedList)
          ? editor.removeList()
          : editor.makeOrderedList();
        break;
      case Editor.Command.ClearFormatting:
        editor.removeAllFormatting();
        break;
      case Editor.Command.Image:
        if (attribute) {
          editor.insertImage(attribute, {
            alt: "",
            style:
              "display: block; max-width: 100%; height: auto; margin: 1em auto; text-align: center;",
            align: "center",
          });
        }
        break;
      default:
        throw new Error(`Unknown editor command: ${command}`);
    }
  };

  return { executeCommand };
};
