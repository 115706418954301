<script setup lang="ts">
import { useSlots } from "vue";
import { useI18n } from "vue-i18n";
import { BlueRatingRecommendationDto } from "@/types/_generated/api";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";

const slotNames = [
  "properties",
  "rcba",
  "recommendation-response",
  "risk-manager-comment",
  "response-timeline",
  "comments",
] as const;
type SlotName = (typeof slotNames)[number];

defineProps<{ recommendation: BlueRatingRecommendationDto; hideStatus?: boolean }>();

const { t } = useI18n({ useScope: "global" });
const slots = useSlots();

/**
 * Returns true if the slot is used and one or more slots after it are used.
 * @param slotName Any item from the `slotNames` array.
 */
const showSeparator = (slotName: SlotName) => {
  const index = slotNames.indexOf(slotName);
  return slots[slotName] && slotNames.slice(index + 1).some((slot) => slots[slot]);
};
</script>

<template>
  <div class="online-report-recommendation">
    <div class="online-report-recommendation__header">
      <h2 class="online-report-recommendation__header__title">
        {{ t("userWeb.onlineReport.recommendations.recommendation") }}
        {{ recommendation.fullPosition }}
      </h2>
      <BaseStatusBadge v-if="!hideStatus" :status="recommendation.surveyorStatus" />
    </div>

    <div class="online-report-recommendation__content">
      <template
        v-for="(slot, index) of slotNames"
        :key="`online-report-recommendation-slot-${index}`"
      >
        <slot :name="slot"></slot>

        <hr v-if="showSeparator(slot)" class="online-report-recommendation__content__divider" />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.online-report-recommendation {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: $spacing-2 0;
    border-bottom: 1px solid $primary-4;
    margin-bottom: $spacing-8;

    &__title {
      font-size: $text-lg;
      font-weight: $font-semibold;
    }
  }

  &__content {
    &__divider {
      margin: $spacing-4 0;
      border: none;
      border-top: 1px solid $primary-4;
    }
  }

  @media print {
    page-break-after: always;
    page-break-inside: avoid;

    &:last-child {
      page-break-after: auto;
    }
  }
}
</style>
