<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { TableLegend } from "@/components/shared/BlueRatingTable/types/types";
import type { ExecutiveReportCategoryDto, ExecutiveReportSurveyDto } from "@/types/_generated/api";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";
import BlueRatingTableDynamicHeight from "@/components/shared/BlueRatingTable/BlueRatingTableDynamicHeight.vue";
import BlueRatingTableLegend from "@/components/shared/BlueRatingTable/BlueRatingTableLegend.vue";
import BlueRatingTableRotatedHeader from "@/components/shared/BlueRatingTable/BlueRatingTableRotatedHeader.vue";
import { type SortBy, SortOption } from "../../composables/useSortExecSummary";
import { FilterColumn } from "../../constants/FilterColumn";
import { ClientFilter } from "../../types/FilterTypes";
import ExecSummaryCatDrilldownRow from "./ExecSummaryCatDrilldownRow.vue";

const props = defineProps<{
  categoryIndex: number;
  currentSortOrder: SortBy;
  clientFilter: ClientFilter;
  tableLegend: TableLegend | null;
  category: ExecutiveReportCategoryDto;
  surveys: ExecutiveReportSurveyDto[];
}>();

defineEmits<{
  closeDrawer: [void];
}>();

const { t } = useI18n({ useScope: "global" });

const categoryTitle = computed(() => {
  return `${props.category.position}. ${props.category.categoryName}`;
});

const showPercentage = computed(() =>
  props.clientFilter.selectedColumns.has(FilterColumn.Percentage),
);

const showScore = computed(() => props.clientFilter.selectedColumns.has(FilterColumn.Score));

const sortedSurveys = computed(() => {
  // No need to re-sort sites if we are sorting alphabetically
  if (props.currentSortOrder === SortOption.Alphabetical) {
    return props.surveys;
  }

  // However, if we sort by score, we need to re-sort, as the regular sort by score is based on survey total score
  return props.surveys.toSorted((a, b) => {
    const aCategoryData = a.categoryData[props.categoryIndex];
    const bCategoryData = b.categoryData[props.categoryIndex];

    return bCategoryData.score - aCategoryData.score;
  });
});
</script>

<template>
  <UWDrawer :drawer-title="categoryTitle" @close-drawer="$emit('closeDrawer')">
    <div>
      <BlueRatingTableDynamicHeight>
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <BlueRatingTableRotatedHeader
                v-for="(question, qIndex) in category.questions"
                :key="qIndex"
              >
                {{ `${question.position}. ${question.text}` }}
              </BlueRatingTableRotatedHeader>

              <th>{{ t("userWeb.blueRatingTable.surveyType") }}</th>
              <th v-if="showScore">{{ t("userWeb.blueRatingTable.score") }}</th>
              <th v-if="showPercentage">
                {{ `${t("userWeb.blueRatingTable.score")} (%)` }}
              </th>
            </tr>
          </thead>

          <tbody>
            <ExecSummaryCatDrilldownRow
              v-for="(survey, sIndex) in sortedSurveys"
              :key="sIndex"
              :show-percentage="showPercentage"
              :show-score="showScore"
              :category-index="categoryIndex"
              :survey="survey"
            />
          </tbody>

          <BlueRatingTableLegend v-if="tableLegend" :legend="tableLegend" />
        </table>
      </BlueRatingTableDynamicHeight>
    </div>
  </UWDrawer>
</template>

<style scoped lang="scss">
@import "@/components/shared/BlueRatingTable/styles/styles";
</style>
