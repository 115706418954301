<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { ref } from "vue";
import type { Option } from "@/types/Option";
import { useUniqueId } from "@/composables/useUniqueId";
import { vAutoFocus } from "@/directives/autoFocus";
import BaseInputDescription from "./BaseInputDescription.vue";
import BaseInputRequired from "./BaseInputRequired.vue";
import BaseLabel from "./BaseLabel.vue";

interface Props {
  value?: string | number | null | undefined;
  tab?: number;
  ariaLabel?: string;
  autocomplete?: string;
  autofocus?: boolean;
  label?: string;
  placeholder?: string;
  type?: string;
  readonly?: boolean;
  disabled?: boolean;
  errors?: ErrorObject[];
  required?: boolean;
  textAlign?: "left" | "center" | "right" | "unset";
  description?: string;
  min?: number | string;
  max?: number;
  maxlength?: number;
  list?: string[] | Option[];
  dataTest?: string;
  trim?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  value: undefined,
  tab: undefined,
  ariaLabel: undefined,
  autocomplete: undefined,
  label: "",
  placeholder: "",
  type: "text",
  readonly: false,
  disabled: false,
  errors: () => [],
  required: false,
  autofocus: false,
  textAlign: "unset",
  list: () => [],
  description: "",
  min: undefined,
  max: undefined,
  maxlength: undefined,
  dataTest: "",
  trim: false,
});

defineEmits<{
  (event: "update:value", value: string | number | null | undefined): void;
  (event: "change:value", value: string | number | null | undefined): void;
  (event: "clear"): void;
}>();

const input = ref();
const id = useUniqueId("base_textfield");

defineExpose({ input });

const getValue = (event: Event): string | number | null | undefined => {
  let { value } = event.target as HTMLInputElement;

  if (props.trim && typeof value === "string") {
    value = value.trim();
  }

  return props.type === "number" ? Number(value) : value;
};
</script>

<template>
  <div class="base-textfield">
    <div v-if="label" class="base-textfield__label">
      <BaseLabel :for-id="id" :data-test="`${dataTest}-label`" has-spacing>
        {{ label }}
      </BaseLabel>
      <BaseInputRequired v-if="required" :data-test="`${dataTest}-required`" />
    </div>

    <div
      class="base-textfield__box"
      :class="{
        'base-textfield__box--disabled': disabled || readonly,
        'base-textfield__box--error': errors.length,
      }"
    >
      <input
        :id="id"
        ref="input"
        v-auto-focus="autofocus"
        class="base-textfield__input"
        :tabindex="tab"
        :type="type"
        :placeholder="placeholder"
        :value="value"
        spellcheck="false"
        :aria-label="ariaLabel"
        :autocomplete="autocomplete"
        :list="`${id}_suggestions`"
        :readonly="readonly"
        :disabled="disabled"
        :min="min"
        :max="max"
        :maxlength="maxlength"
        :data-test="`${dataTest}`"
        @change="$emit('change:value', getValue($event))"
        @input="$emit('update:value', getValue($event))"
      />
    </div>
    <BaseInputDescription v-if="description && !errors.length" :description="description" />

    <div v-if="errors.length && !disabled">
      <p
        v-for="({ $message }, i) in errors"
        :key="i"
        :data-test="`${dataTest}-error`"
        class="base-textfield__error"
      >
        {{ $message }}
      </p>
    </div>

    <datalist v-if="list.length" :id="`${id}_suggestions`">
      <option
        v-for="(item, i) in list"
        :key="`${id}_suggestions_${i}`"
        :value="(item as Option).value ?? item"
      >
        {{ (item as Option).title ?? item }}
      </option>
    </datalist>
  </div>
</template>

<style scoped lang="scss">
@import "./styles";

.base-textfield {
  display: flex;
  flex-direction: column;

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__box {
    position: relative;
    display: flex;
    @include input;

    &--disabled {
      @include input-disabled;
    }

    &--error {
      @include input-error;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin-left: $spacing-2;
  }

  &__input {
    width: 100%;
    background: inherit;
    padding: $spacing-3;
    border: none;
    color: inherit;
    border-radius: $rounded-base;
    text-align: v-bind(textAlign);
  }

  &__error {
    color: $error-4;
    line-height: $leading-normal;
    margin-top: $spacing-1;
  }
}
</style>
