<script setup lang="ts">
import { isAxiosError } from "axios";
import { onBeforeMount, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { DefaultBaseResponse } from "@/constants/DefaultBaseResponse";
import { getActiveServiceMessages } from "@/services/serviceMessages";
import { handleError } from "@/services/shared/handleError";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";
import { GetSsoSettingsResponse, ServiceMessageLeanDto } from "@/types/_generated/api";
import BaseAlert, { Severity } from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const auth = useAuthStore();
const router = useRouter();
const app = useAppStore();
const { t } = useI18n({ useScope: "global" });

interface Props {
  email: string;
  password: string;
}

const props = withDefaults(defineProps<Props>(), {
  email: "",
  password: "",
});

const form = reactive({
  error: "",
  isLoading: false,
});

const serviceMessages = ref<ServiceMessageLeanDto[]>([]);

const ssoSettings = ref<GetSsoSettingsResponse>(
  Object.assign(new DefaultBaseResponse(), { showSsoLogin: false }),
);

defineEmits<{
  (event: "email", value: string): void;
  (event: "password", value: string): void;
  (event: "forgot"): void;
  (event: "two-factor"): void;
}>();

const signIn = async (event: Event) => {
  event.preventDefault();

  form.error = "";
  form.isLoading = true;

  try {
    await auth.signInCredentials(props.email, props.password);
    await auth.handleSuccessfulLogin();
    app.redirectOnLogin();
  } catch (e) {
    const isAxios = isAxiosError(e);
    if (
      isAxios &&
      e.response?.status === 401 &&
      e.response!.headers["x-blue-unverified-mfa-code"] === "true"
    ) {
      auth.is2fa = true;
    } else if (
      isAxios &&
      e.response?.status === 401 &&
      e.response!.headers["x-blue-locked-out"] === "true"
    ) {
      form.error = t("signIn.lockedOut");
      form.isLoading = false;
      return;
    } else if (isAxios && e.response?.status !== 401) {
      handleError(e);
      return;
    } else {
      form.error = t("signIn.wrongCredentials");
    }
  } finally {
    form.isLoading = false;
  }
};

const getSeverity = (message: ServiceMessageLeanDto): Severity => {
  switch (message.severity) {
    case "Information":
      return "info";
    case "Warning":
      return "warning";
    case "Error":
      return "error";
    default:
      return "info";
  }
};

const redirectToSSO = () => {
  let url = ssoSettings.value.url!;
  if (router.currentRoute.value.query?.go) {
    url = url.includes("?")
      ? `${url}&go=${router.currentRoute.value.query.go}`
      : `${url}?go=${router.currentRoute.value.query.go}`;
  }
  document.location.href = url;
};

onBeforeMount(async () => {
  ssoSettings.value = await auth.getSSOSettings();
  serviceMessages.value = (await getActiveServiceMessages()) || [];
});
</script>

<template>
  <div v-if="serviceMessages.length > 0" class="auth-signin-service-messages">
    <BaseAlert
      v-for="(message, index) in serviceMessages"
      :key="index"
      :severity="getSeverity(message)"
    >
      <div>
        <strong>{{ message.subject }}</strong>
        <p>{{ message.message }}</p>
      </div>
    </BaseAlert>
  </div>

  <form class="auth-signin" @submit="signIn">
    <div class="auth-signin__input-container">
      <BaseLabel>{{ t("common.email") }}</BaseLabel>
      <BaseTextField
        data-test="email-input"
        :autofocus="!form.error.length"
        autocomplete="username"
        :tab="1"
        :disabled="form.isLoading"
        :value="email"
        @update:value="$emit('email', $event as string)"
      />
    </div>

    <div class="auth-signin__input-container">
      <div class="auth-signin__password-label">
        <BaseLabel>{{ t("common.password") }}</BaseLabel>
        <button
          class="auth-signin__forgot-password"
          type="button"
          :tab="3"
          :disabled="form.isLoading"
          @click="$emit('forgot')"
        >
          {{ t("signIn.forgotPassword") }}?
        </button>
      </div>

      <BaseTextField
        data-test="password-input"
        type="password"
        :autofocus="form.error.length > 0"
        autocomplete="current-password"
        :aria-label="t('common.password')"
        :tab="2"
        :disabled="form.isLoading"
        :value="password"
        @update:value="$emit('password', $event as string)"
      />
    </div>

    <BaseAlert v-if="form.error" severity="error">
      <p>{{ form.error }}</p>
    </BaseAlert>

    <BaseButton type="submit" :disabled="form.isLoading">
      {{ t("signIn.signIn") }}
    </BaseButton>
  </form>

  <BaseButton
    v-if="ssoSettings.showSsoLogin"
    class="auth-signin__sso-btn"
    :disabled="form.isLoading"
    variant="text"
    @click="redirectToSSO"
  >
    {{ t("signIn.useSso") }}
  </BaseButton>
</template>

<style scoped lang="scss">
.auth-signin {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  width: 100%;

  &__input-container {
    display: flex;
    flex-direction: column;
    gap: $spacing-2;
  }

  &__password-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__forgot-password {
    color: $secondary-5;
    font-weight: $font-medium;
    transition: opacity $duration-100 ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  &__sso-btn {
    margin-top: $spacing-4;
    width: 100%;
  }
}

.auth-signin-service-messages {
  display: grid;
  grid-template-rows: 1fr;
  gap: $spacing-2;
  margin-bottom: $spacing-8;
}
</style>
