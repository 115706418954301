<script setup lang="ts">
import { toRef } from "vue";
import { RecReportTextDto } from "@/types/_generated/api";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDivider from "@/components/base/BaseDivider.vue";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { SectionKeys, SectionFormData, UpdateFormField } from "../types";
import { useFormStructure } from "../composables/useFormStructure";
import { FieldType } from "../constants";

const props = defineProps<{
  isLoading: boolean;
  selectedSectionKey: SectionKeys;
  selectedSectionForm: SectionFormData;
}>();

const emit = defineEmits<{
  "update:form-field": [UpdateFormField];
}>();

const { currentSectionData } = useFormStructure(toRef(props, "selectedSectionKey"));

const updateField = (key: UpdateFormField["key"], value: UpdateFormField["value"]) => {
  emit("update:form-field", { sectionKey: props.selectedSectionKey, key, value });
};

const getTextValue = (value: UpdateFormField["value"], isCustom: boolean | undefined) => {
  if (isCustom) {
    return value as string;
  }

  const recReportText = value as RecReportTextDto;

  return recReportText?.custom ?? recReportText?.default;
};
</script>

<template>
  <div class="rec-report-section-form">
    <div v-for="(field, index) in currentSectionData" :key="field.key">
      <BaseCheckbox
        v-if="field.type === FieldType.Checkbox"
        :disabled="isLoading"
        :label="field.label"
        :checked="selectedSectionForm[field.key as UpdateFormField['key']] as boolean"
        @change="updateField(field.key as UpdateFormField['key'], $event)"
      />

      <div v-else-if="field.type === FieldType.Text">
        <BaseLabel has-spacing>{{ field.label }}</BaseLabel>
        <BaseTextField
          :disabled="isLoading"
          :value="
            getTextValue(selectedSectionForm[field.key as UpdateFormField['key']], field.isCustom)
          "
          @update:value="updateField(field.key as UpdateFormField['key'], $event as string)"
        />
      </div>

      <div v-else-if="field.type === FieldType.TextArea">
        <BaseLabel has-spacing>{{ field.label }}</BaseLabel>
        <BaseTextArea
          :disabled="isLoading"
          :value="
            getTextValue(selectedSectionForm[field.key as UpdateFormField['key']], field.isCustom)
          "
          @update:value="updateField(field.key as UpdateFormField['key'], $event as string)"
        />
      </div>

      <div v-else-if="field.type === FieldType.Editor">
        <BaseLabel has-spacing>{{ field.label }}</BaseLabel>
        <BaseEditor
          :key="`${selectedSectionForm.sectionType}-${field.key}`"
          :disabled="isLoading"
          :value="
            getTextValue(selectedSectionForm[field.key as UpdateFormField['key']], field.isCustom)
          "
          :enable-image-upload="field.enableImages"
          @input="updateField(field.key as UpdateFormField['key'], $event)"
        />
      </div>

      <div v-else-if="field.type === FieldType.Select">
        <BaseLabel has-spacing>{{ field.label }}</BaseLabel>
        <BaseSelect
          :disabled="isLoading"
          :value="selectedSectionForm[field.key as UpdateFormField['key']] as string"
          @change="updateField(field.key as UpdateFormField['key'], $event)"
        >
          <BaseOption v-for="option in field.options" :key="option.value" :value="option.value">
            {{ option.title }}
          </BaseOption>
        </BaseSelect>
      </div>

      <BaseDivider
        v-if="index !== currentSectionData.length - 1"
        class="rec-report-section-form__divider"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.rec-report-section-form {
  display: flex;
  flex-direction: column;

  &__divider {
    margin: $spacing-4 0;
  }
}
</style>
