import { useI18n } from "vue-i18n";
import { useTabs } from "@/composables/useTabs";

export enum RecommendationDrawerTabs {
  General = "General",
  Images = "Images",
  CostBenefit = "CostBenefit",
  SiteResponseSummary = "SiteResponseSummary",
  SiteResponseHistory = "SiteResponseHistory",
}

export const useBlueRatingRecommendationDrawerTabs = () => {
  const { t } = useI18n({ useScope: "global" });

  const tabs = [
    { label: t("blueRating.recommendation.general"), name: RecommendationDrawerTabs.General },
    {
      label: t("blueRating.recommendation.uploadPhotos"),
      name: RecommendationDrawerTabs.Images,
    },
    {
      label: t("blueRating.recommendation.costBenefitAnalysis"),
      name: RecommendationDrawerTabs.CostBenefit,
    },
    {
      label: t("blueRating.recommendation.recommendationResponse.summary"),
      name: RecommendationDrawerTabs.SiteResponseSummary,
    },
    {
      label: t("blueRating.recommendation.recommendationResponse.history"),
      name: RecommendationDrawerTabs.SiteResponseHistory,
    },
  ];

  const { activeTab, changeTab } = useTabs(tabs, RecommendationDrawerTabs.General);

  return {
    activeTab,
    changeTab,
    tabs,
  };
};
