<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { SurveyReportRecipientDto } from "@/types/_generated/api";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import SurveyReportMailRecipient from "./ReportMailRecipient.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  recipients: SurveyReportRecipientDto[];
  errors: ErrorObject[];
}>();

defineEmits<{
  delete: [SurveyReportRecipientDto];
  edit: [SurveyReportRecipientDto];
}>();
</script>

<template>
  <BaseCard
    :title="t('survey.surveyReportMail.recipients')"
    class="survey-report-mail__recipient-list"
  >
    <span v-if="recipients.length" class="survey-report-mail__recipient-list__click-to-edit">
      {{ t("survey.surveyReportMail.clickToEdit") }}
    </span>
    <SurveyReportMailRecipient
      v-for="(r, i) in recipients"
      :key="i"
      :recipient="r"
      @delete="$emit('delete', r)"
      @edit="$emit('edit', r)"
    />
    <div v-if="errors.length" class="survey-report-mail__recipient-list__error">
      {{ t("survey.surveyReportMail.recipientListError") }}
    </div>
  </BaseCard>
</template>

<style lang="scss" scoped>
.survey-report-mail__recipient-list {
  display: flex;
  flex-direction: column;
  gap: $spacing-2;

  &__click-to-edit,
  &__error {
    padding: 0 0 $spacing-3 0;
  }

  &__error {
    color: $error-4;
  }
}
</style>
