import { ComputedRef, computed, ref } from "vue";
import { BlueRatingRecommendationDto } from "@/types/_generated/api";

export const useRecommendationNavigation = (
  recommendations: ComputedRef<BlueRatingRecommendationDto[]>,
) => {
  const selectedRecommendation = ref<BlueRatingRecommendationDto | null>(null);

  const selectRecommendation = (recommendationId: number) => {
    selectedRecommendation.value =
      recommendations.value.find((r) => r.recommendationId === recommendationId) ?? null;
  };

  const indexOfSelectedRecommendation = computed(() =>
    recommendations.value.findIndex(
      (r) => r.recommendationId === selectedRecommendation.value?.recommendationId,
    ),
  );

  const selectPreviousRecommendation = () =>
    indexOfSelectedRecommendation.value > 0
      ? (selectedRecommendation.value =
          recommendations.value[indexOfSelectedRecommendation.value - 1] ?? null)
      : null;

  const selectNextRecommendation = () =>
    indexOfSelectedRecommendation.value < recommendations.value.length - 1
      ? (selectedRecommendation.value =
          recommendations.value[indexOfSelectedRecommendation.value + 1] ?? null)
      : null;

  return {
    selectedRecommendation,
    selectRecommendation,
    indexOfSelectedRecommendation,
    selectPreviousRecommendation,
    selectNextRecommendation,
  };
};
