<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BasePill from "../BasePills/BasePill.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{ tags?: string[] }>();
</script>

<template>
  <div v-if="tags && tags.length > 0" class="base-file-attachment-tags">
    <small>{{ t("survey.preSurveyEmail.tags") }}</small>
    <BasePill v-for="tag in tags" :key="tag" :label="tag" />
  </div>
</template>

<style lang="scss" scoped>
.base-file-attachment-tags {
  display: flex;
  align-items: center;
  gap: $spacing-1;
}
</style>
