import { Directive } from "vue";

/**
 * Sets the title attribute of an element to its text content if the text is truncated.
 * @example
 * <div v-truncation-title>
 *  This text will have a title attribute if it is truncated.
 * </div>
 */

const truncationTitle: Directive<HTMLElement> = {
  mounted(element) {
    // Add the 'truncate' class to the element
    element.classList.add("truncate");

    // Check if the element's text is truncated by comparing its scrollWidth to its clientWidth
    const isTruncated = () => element.scrollWidth > element.clientWidth;

    // Event listener for 'mouseover' event
    // It checks if the element's content is truncated when hovered over
    // If truncated, sets the 'title' attribute to the element's text content
    element.addEventListener("mouseover", () => {
      if (isTruncated()) {
        element.title = element.textContent || "";
      } else {
        element.removeAttribute("title");
      }
    });
  },
};

export { truncationTitle as vTruncationTitle };
