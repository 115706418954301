<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetSurvey } from "@/composables/services/useGetSurvey";
import { formatDate } from "@/helpers/formatDate";
import { getLibraryFiles } from "@/services/library";
import {
  getSurveyEmailTemplate,
  getSurveyReportMails,
  sendSurveyEmail,
  toggleSurveyReportMailIsActiveService,
} from "@/services/surveys";
import {
  LibraryFileDto,
  ReportMailDto,
  SendSurveyReportMail,
  SurveyEmail,
} from "@/types/_generated/api";
import { minLength, required } from "@/validation/i18n-validators";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import ReportMailAddRecipient from "./components/ReportMailAddRecipient.vue";
import ReportMailAttachments from "./components/ReportMailAttachments.vue";
import ReportMailHistoryTable from "./components/ReportMailHistoryTable.vue";
import ReportMailRecipientList from "./components/ReportMailRecipientList.vue";
import {
  useSurveyReportMailTabs,
  SurveyReportMailTab,
} from "./composables/useReportMailDrawerTabs";
import { useReportMailRecipients } from "./composables/useReportMailRecipients";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  surveyId: number;
  moduleId: number;
}>();

const emits = defineEmits<{
  (event: "close"): void;
}>();

const defaultCodeExpirationHours = 2;
const isLoadingHistory = ref(false);
const files = ref<LibraryFileDto[]>([]);
const history = ref<ReportMailDto[] | null>([]);

const { survey, getSurvey, isLoadingSurvey } = useGetSurvey();
const { recipients, recipient, addRecipient, deleteRecipient, popRecipient } =
  useReportMailRecipients();

const { activeTab, changeTab, tabs } = useSurveyReportMailTabs();

const form = ref<SendSurveyReportMail>({
  subject: "",
  body: "",
  recipients: [],
  codeExpirationHours: defaultCodeExpirationHours,
  includeLibraryFileIds: [],
  surveyId: props.surveyId,
});

const isLoading = computed(() => isLoadingSurvey.value || isLoadingHistory.value);

onMounted(async () => {
  isLoadingHistory.value = true;

  history.value = await getSurveyReportMails(props.surveyId);
  await getSurvey(props.surveyId);

  if (!survey.value) {
    throw new Error("Survey not found");
  }

  if (survey.value?.participants.length) {
    for (const participant of survey.value.participants) {
      addRecipient({
        email: participant.email,
        mobile: participant.mobile,
        includeRecommendationResponseLink: false,
        reminderDates: [],
        continueRemindingWhenSiteResponseIsGiven: false,
        firstName: participant.firstName,
        lastName: participant.lastName,
        company: participant.company,
        title: participant.title,
      });
    }
  }

  files.value = (await getLibraryFiles(survey.value.moduleId)) ?? [];

  const template = await getSurveyEmailTemplate(
    survey.value.siteId,
    SurveyEmail.RecommendationReport,
  );

  form.value.subject = template?.subject ?? "";
  form.value.body = template?.body ?? "";

  isLoadingHistory.value = false;
});

const globalv$ = useVuelidate({ $scope: "recipient" });

const v$ = useVuelidate(
  {
    subject: { required },
    body: { required },
    recipients: { required, minLength: minLength(1) },
  },
  form,
  {
    $autoDirty: true,
    $scope: "main",
  },
);

const send = async () => {
  form.value.recipients = recipients.value;
  v$.value.$validate();
  globalv$.value.$reset();

  if (v$.value.$invalid) {
    return;
  }

  const response = await sendSurveyEmail(form.value);

  if (response) {
    emits("close");
  }
};

const setCodeExpirationHours = (value: string | number | null | undefined) => {
  const hours = Number(value);

  if (isNaN(hours)) {
    form.value.codeExpirationHours = defaultCodeExpirationHours;
  } else if (hours < 1) {
    form.value.codeExpirationHours = 1;
  } else if (hours > 9999) {
    form.value.codeExpirationHours = 9999;
  } else if (hours % 1 !== 0) {
    form.value.codeExpirationHours = Math.floor(hours);
  } else {
    form.value.codeExpirationHours = hours;
  }
};

const toggleSetActive = async (id: ReportMailDto["id"], isActive: boolean) => {
  await toggleSurveyReportMailIsActiveService(props.surveyId, id, isActive);
  const mail = history.value?.find((m) => m.id === id);
  if (mail) {
    mail.isActive = isActive;
  }
};
</script>

<template>
  <BaseDrawer
    class="survey-report-mail"
    :title="
      t('survey.surveyReportMail.title', {
        survey: formatDate.asDateOnly(survey?.surveyDate),
      })
    "
    width="auto"
    :is-loading="isLoading"
    @close="$emit('close')"
  >
    <BaseDrawerTabs
      v-if="history?.length"
      :current-tab="activeTab"
      :tabs="tabs"
      @change="changeTab"
    />

    <div v-show="activeTab === SurveyReportMailTab.Main" class="survey-report-mail__main">
      <div class="survey-report-mail__main-content">
        <BaseTextField
          v-model:value="form.subject"
          :label="t('survey.preSurveyEmail.subject')"
          :errors="v$.subject.$errors"
        />
        <BaseTextArea
          v-model:value="form.body"
          :label="t('survey.preSurveyEmail.body')"
          :rows="10"
          :errors="v$.body.$errors"
        />

        <BaseTextField
          :value="form.codeExpirationHours"
          type="number"
          :label="t('survey.surveyReportMail.codeExpirationHours')"
          :max="9999"
          :min="1"
          @change:value="setCodeExpirationHours"
        />

        <ReportMailAttachments
          :files="files"
          :include-library-file-ids="form.includeLibraryFileIds"
          :survey="survey"
          @add-attachment="form.includeLibraryFileIds = [...form.includeLibraryFileIds, $event]"
          @delete-attachment="
            form.includeLibraryFileIds = form.includeLibraryFileIds.filter((f) => f !== $event)
          "
        />
      </div>

      <BaseCard :title="t('survey.surveyReportMail.addRecipient')">
        <ReportMailAddRecipient :module-id="moduleId" :recipient="recipient" @add="addRecipient" />
      </BaseCard>

      <ReportMailRecipientList
        :recipients="recipients"
        :errors="v$.recipients.$errors"
        @delete="deleteRecipient"
        @edit="popRecipient"
      />
    </div>

    <div
      v-if="history?.length"
      v-show="activeTab === SurveyReportMailTab.History"
      class="survey-report-mail__history"
    >
      <ReportMailHistoryTable
        :history="history"
        :is-loading="isLoading"
        @on-toggle-active="toggleSetActive"
      />
    </div>

    <template #footer-left>
      <BaseButton @click="send">
        {{ t("common.actions.send") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.survey-report-mail {
  max-width: 40vw;
  min-width: 500px;

  &__main,
  &__history {
    padding: $spacing-6 $spacing-12;
  }

  &__main {
    @include grid-columns(1);
    gap: $spacing-6;

    &-content {
      display: flex;
      flex-direction: column;
      gap: $spacing-4;
    }
  }
}
</style>
