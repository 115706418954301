<script setup lang="ts">
import { computed, type DeepReadonly } from "vue";
import { useI18n } from "vue-i18n";
import type { SitesData } from "@/composables/services/useGetSitesData";
import type { CustomDefinitionNumber } from "@/types/SiteDefinitions";
import BaseLabel from "@/components/base/BaseLabel.vue";
import UWOption from "@/components/base/UserWeb/Inputs/Select/UWOption.vue";
import UWOptionPlaceholder from "@/components/base/UserWeb/Inputs/Select/UWOptionPlaceholder.vue";
import UWSelect from "@/components/base/UserWeb/Inputs/Select/UWSelect.vue";
import { GroupBy } from "./types/GroupBy";

export interface Props {
  useLabel?: boolean;
  disabled?: boolean;
  value: GroupBy;
  siteDefinitions: DeepReadonly<SitesData>;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  change: [GroupBy];
}>();

const { t } = useI18n({ useScope: "global" });

const siteDefinitionOptions = computed(() => {
  const options: Record<CustomDefinitionNumber, string | undefined> = {
    1: props.siteDefinitions.siteDefinition1,
    2: props.siteDefinitions.siteDefinition2,
    3: props.siteDefinitions.siteDefinition3,
    4: props.siteDefinitions.siteDefinition4,
  };

  // Exclude empty definitions so we don't display them as options
  const filteredOptions = Object.entries(options)
    .filter(([_, value]) => value) // Ensure the value is truthy
    .map(([key, value]) => ({
      customDefinitionNumber: parseInt(key),
      name: value,
    }));
  return filteredOptions;
});

const handleSelectionChange = (selectedOption: string) => {
  const emitValue = selectedOption
    ? (parseInt(selectedOption) as CustomDefinitionNumber)
    : undefined;
  emit("change", emitValue);
};
</script>

<template>
  <div>
    <BaseLabel v-if="useLabel" has-spacing>{{ t("userWeb.filter.groupBy") }}</BaseLabel>

    <UWSelect :disabled="disabled" :value="value ? `${value}` : ''" @change="handleSelectionChange">
      <UWOptionPlaceholder v-if="!useLabel">
        {{ t("userWeb.filter.groupBy") }}
      </UWOptionPlaceholder>
      <UWOption value="">-- {{ t("common.none") }} --</UWOption>
      <UWOption
        v-for="siteDefinition in siteDefinitionOptions"
        :key="siteDefinition.customDefinitionNumber"
        :value="`${siteDefinition.customDefinitionNumber}`"
      >
        {{ siteDefinition.name }}
      </UWOption>
    </UWSelect>
  </div>
</template>
