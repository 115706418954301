<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useModuleUsersOptions } from "@/composables/useModuleUsersOptions";
import {
  email as emailValidator,
  mobile as mobileValidator,
  required,
} from "@/validation/i18n-validators";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDateField from "@/components/base/BaseDateField.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import BasePillDeleteButton from "@/components/base/BasePills/BasePillDeleteButton.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import { type ReportMailRecipient } from "../composables/useReportMailRecipients";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  moduleId: number;
  recipient?: ReportMailRecipient;
}>();

const emits = defineEmits<{
  update: [ReportMailRecipient];
  add: [ReportMailRecipient];
}>();

const currentRecipient = ref<ReportMailRecipient>({
  email: "",
  mobile: "",
  includeRecommendationResponseLink: false,
  reminderDates: [],
  continueRemindingWhenSiteResponseIsGiven: false,
});

const { moduleUserOptions, moduleUsers } = useModuleUsersOptions(props.moduleId);

watch(
  () => props.recipient,
  (recipient) => {
    currentRecipient.value = { ...recipient! };
  },
);

const v$ = useVuelidate(
  {
    email: { email: emailValidator, required },
    mobile: { mobile: mobileValidator, required },
  },
  currentRecipient,
  {
    $autoDirty: true,
    $scope: "recipient",
  },
);

const addOrUpdateRecipient = () => {
  v$.value.$validate();

  if (v$.value.$invalid) {
    return;
  }

  emits("add", currentRecipient.value);

  nextTick(() => {
    v$.value.$reset();
  });
};

const updateFromModuleUser = (userId: string) => {
  const user = moduleUsers.value?.find((u) => u.id === Number(userId));

  if (!user) {
    throw new Error("User not found");
  }

  currentRecipient.value = {
    email: user.email,
    mobile: user.mobilePhoneNumber,
    includeRecommendationResponseLink: false,
    reminderDates: [],
    continueRemindingWhenSiteResponseIsGiven: false,
    firstName: user.firstName,
    lastName: user.lastName,
    company: user.company,
    title: user.title,
  };
};
</script>

<template>
  <div class="survey-report-mail__add-recipient">
    <BaseSelect
      :label="t('survey.surveyReportMail.selectExistingUser')"
      @change="updateFromModuleUser($event as string)"
    >
      <BaseOption v-for="option in moduleUserOptions" :key="option.value" :value="option.value">
        {{ option.title }}
      </BaseOption>
    </BaseSelect>

    <div class="survey-report-mail__add-recipient__manual">
      <hr />
      {{ t("survey.surveyReportMail.orFillIn") }}
      <hr />
    </div>
    <div class="survey-report-mail__add-recipient__contact">
      <BaseTextField
        :label="t('common.email')"
        :placeholder="t('common.email')"
        :value="currentRecipient.email"
        :errors="v$.email.$errors"
        @update:value="currentRecipient.email = $event as string"
      />

      <BaseTextField
        :label="t('common.mobile')"
        :placeholder="t('common.mobile')"
        :value="currentRecipient.mobile"
        autocomplete="tel"
        :errors="v$.mobile.$errors"
        @update:value="currentRecipient.mobile = $event as string"
      />
    </div>
    <BaseCheckbox
      :label="t('survey.surveyReportMail.includeRedommendationResponseLink')"
      :checked="currentRecipient.includeRecommendationResponseLink"
      @change="currentRecipient.includeRecommendationResponseLink = $event as boolean"
    />

    <BaseDateField
      v-if="currentRecipient.includeRecommendationResponseLink"
      :label="t('survey.surveyReportMail.addReminderDates')"
      :disabled="currentRecipient.reminderDates.length == 4"
      @update:value="
        currentRecipient.reminderDates = [...currentRecipient.reminderDates, $event as string]
      "
    />

    <div
      v-if="currentRecipient.reminderDates.length"
      class="survey-report-mail__add-recipient__reminder-dates"
    >
      <BasePill
        v-for="(date, i) in currentRecipient.reminderDates"
        :key="i"
        clickable
        :label="date"
        :color="'#0f172a'"
        :background-color="'#cbd5e1'"
      >
        <template #post>
          <BasePillDeleteButton
            @click.stop="
              {
                currentRecipient.reminderDates.filter((_, index) => index !== i);
              }
            "
          />
        </template>
      </BasePill>
    </div>

    <BaseCheckbox
      v-if="
        currentRecipient.includeRecommendationResponseLink && currentRecipient.reminderDates.length
      "
      :label="t('survey.surveyReportMail.continueRemindingWhenSiteResponseIsGiven')"
      :checked="currentRecipient.continueRemindingWhenSiteResponseIsGiven"
      @change="currentRecipient.continueRemindingWhenSiteResponseIsGiven = $event"
    />

    <BaseButton variant="outlined" @click="addOrUpdateRecipient">
      {{ t("common.actions.add") }}
    </BaseButton>
  </div>
</template>

<style lang="scss" scoped>
.survey-report-mail {
  &__add-recipient {
    display: grid;
    gap: 1rem;

    &__manual {
      display: flex;
      padding-top: $spacing-4;
      align-items: center;
      flex-direction: row;

      hr {
        display: inline-block;
        border-top: 1px solid $primary-4;
        width: 100%;
        margin: $spacing-4;
      }
    }

    &__reminder-dates {
      display: flex;
      flex-wrap: wrap;
      gap: $spacing-2;
    }

    &__contact {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $spacing-3;
    }
  }
}
</style>
