import { Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useTabs } from "@/composables/useTabs";
import { SurveySettings } from "./useInitializeSurveySettings";
import { useValidateSurveySettings } from "./useValidateSurveySettings";

export enum SurveyEditTab {
  General = "General",
  Participants = "Participants",
  InsuredValues = "InsuredValues",
}

export const useSurveyEditTabs = (surveySettings: Ref<SurveySettings | null>) => {
  const { validationState } = useValidateSurveySettings();

  const { t } = useI18n({ useScope: "global" });

  type SurveyEditDrawerTab = "general" | "participants";

  const tabs = computed(() => [
    {
      label: t("survey.general"),
      name: SurveyEditTab.General,
      icon: tabIcons.value.general,
    },
    {
      label: `${t("survey.participants.title")} (${surveySettings.value?.participants.length ?? 0})`,
      name: SurveyEditTab.Participants,
      icon: tabIcons.value.participants,
    },
    {
      label: t("survey.insuredValues.title"),
      name: SurveyEditTab.InsuredValues,
    },
  ]);

  const tabIcons = computed(() => {
    const { surveyDate, spoeCheckerUserId, status, participants } = validationState;

    return {
      general: surveyDate || spoeCheckerUserId || status ? "warning" : "",
      participants: Object.values(participants).some((p) => p.email || p.mobile) ? "warning" : "",
    } as Record<SurveyEditDrawerTab, "warning" | undefined>;
  });

  const { activeTab, changeTab } = useTabs(tabs.value, SurveyEditTab.General);

  return {
    tabs,
    changeTab,
    activeTab,
  };
};
