<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import type { NavTab } from "@/components/base/BaseNavTabs/BaseNavTabs.vue";
import { useGetClient } from "@/composables/services/useGetClient";
import { authorize } from "@/plugins/can";
import { AdminRouteName } from "@/router/RouteName";
import { createModule } from "@/services/modules";
import { ClientDto, Role } from "@/types/_generated/api";
import { Breadcrumb } from "@/types/Breadcrumb";
import AdminHeader from "@/components/app/Admin/AdminHeader.vue";
import AdminViewLayout from "@/components/app/Admin/AdminViewLayout.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import StandardRecEditDrawer from "../Templates/components/StandardRecommendationEditDrawer/StandardRecEditDrawer.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  clientId: number;
}>();

const { getClient } = useGetClient();

const TABS: NavTab[] = [
  { title: t("modules.modules"), path: AdminRouteName.ClientModules },
  { title: t("modules.userPermissionsList"), path: AdminRouteName.ClientUsers },
  { title: t("clients.clientDetails"), path: AdminRouteName.ClientDetails },
];

const client = ref<ClientDto | null>(null);
const showStandardRecDrawer = ref(false);

const breadcrumbs = computed((): Breadcrumb[] | undefined =>
  client.value
    ? [
        {
          title: t("navigation.clients"),
          route: { name: AdminRouteName.Clients },
        },
        { title: client.value.name },
      ]
    : undefined,
);

onBeforeMount(async () => {
  client.value = await getClient(props.clientId);
});
</script>

<template>
  <AdminHeader :breadcrumbs="breadcrumbs" />

  <AdminViewLayout :tabs="TABS">
    <template #controls>
      <BaseButton
        v-if="authorize.isSuperiorTo(Role.INT)"
        data-test="client-create-module-button"
        @click="() => createModule(clientId)"
      >
        {{ t("modules.newModule") }}
      </BaseButton>

      <BaseButton
        v-if="authorize.isSuperiorOrEqualTo(Role.INT)"
        class="view-template__controls__button"
        @click="showStandardRecDrawer = true"
      >
        {{ t("standardRecommendations.newStandardRecommendation") }}
      </BaseButton>
    </template>

    <StandardRecEditDrawer
      v-if="showStandardRecDrawer"
      :pre-selected-client-id="clientId"
      @close="showStandardRecDrawer = false"
    />

    <router-view />
  </AdminViewLayout>
</template>
