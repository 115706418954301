import { useI18n } from "vue-i18n";
import { BlueColor } from "@/types/_generated/api";

export const useBlueColorTranslations = () => {
  const { t } = useI18n({ useScope: "global" });

  const translations: { [status in BlueColor]: string } = {
    NotSet: t("blueRating.colors.notSet"),
    Blue: t("blueRating.colors.blue"),
    Green: t("blueRating.colors.green"),
    Yellow: t("blueRating.colors.yellow"),
    Red: t("blueRating.colors.red"),
    Gray: t("blueRating.colors.gray"),
  };

  return {
    translations,
  };
};
