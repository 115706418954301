<script setup lang="ts">
import { computed } from "vue";
import type { CustomDefinitionKey } from "@/types/SiteDefinitions";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { SiteDefinitionSuggestionsDto, SiteDto } from "@/types/_generated/api";
import { required, requiredIf } from "@/validation/i18n-validators";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { SiteDefinitionProperty } from "./SiteEditDrawer.vue";

const props = defineProps<{
  isLoading: boolean;
  site: SiteDto;
  siteDefinitionSuggestions: SiteDefinitionSuggestionsDto[];
}>();

defineEmits<{
  "update:site-definition": [
    {
      siteDefinition: CustomDefinitionKey;
      value: string;
    },
  ];
}>();

const siteDefinitions = computed(() => {
  const definitions = [];

  for (let i = 1; i <= 4; i++) {
    const suggestions = props.siteDefinitionSuggestions.find((s) => s.siteDefinitionNumber === i);

    // If the module does not have a site definition for that index, skip it
    if (!props.site[`moduleSiteDefinition${i}` as SiteDefinitionProperty]) {
      continue;
    }

    definitions.push({
      siteDefinition: `custom${i}` as CustomDefinitionKey,
      label: props.site[`moduleSiteDefinition${i}` as SiteDefinitionProperty],
      forceSuggestion: suggestions?.forceSuggestion ?? false,
      suggestions: suggestions?.suggestions || [],
    });
  }

  return definitions;
});

const validationField = computed(() => {
  const validations: { [k in CustomDefinitionKey]: object } = {
    custom1: { required },
    custom2: {
      required: requiredIf(() => !!props.site.moduleSiteDefinition2),
    },
    custom3: {
      required: requiredIf(() => !!props.site.moduleSiteDefinition3),
    },
    custom4: {
      required: requiredIf(() => !!props.site.moduleSiteDefinition4),
    },
  };

  return {
    $autoDirty: true,
    ...validations,
  };
});

const siteForValidation = computed(() => {
  const site: {
    custom1: string | undefined;
    custom2: string | undefined;
    custom3: string | undefined;
    custom4: string | undefined;
    $autoDirty: boolean;
  } = {
    custom1: undefined,
    custom2: undefined,
    custom3: undefined,
    custom4: undefined,
    $autoDirty: true,
  };

  siteDefinitions.value.forEach((sd) => {
    site[sd.siteDefinition] = props.site[sd.siteDefinition];
  });

  return site;
});

const { v$, addRef } = useVuelidate(validationField.value, siteForValidation, {
  $registerAs: "site-definitions",
  $autoDirty: true,
});
</script>

<template>
  <div class="site-edit-definitions">
    <BaseSkeleton v-if="isLoading" :set="4" height="2.5rem" width="100%" mb="1rem" />

    <div v-for="sd in siteDefinitions" :key="sd.siteDefinition" class="site-edit-definitions__row">
      <BaseSelect
        v-if="sd.forceSuggestion && sd.suggestions.length"
        :ref="(el) => addRef(el, sd.siteDefinition)"
        :errors="v$[sd.siteDefinition]?.$errors"
        required
        :label="sd.forceSuggestion ? sd.label : '&nbsp;'"
        :value="site[sd.siteDefinition]"
        @change="
          $emit('update:site-definition', {
            siteDefinition: sd.siteDefinition,
            value: $event,
          })
        "
      >
        <BaseOption v-for="s in sd.suggestions" :key="s" :value="s">
          {{ s }}
        </BaseOption>
      </BaseSelect>
      <BaseTextField
        v-else
        :ref="(el) => addRef(el, sd.siteDefinition)"
        required
        :list="sd.suggestions"
        :errors="v$[sd.siteDefinition]?.$errors"
        :label="sd.label"
        :value="site[sd.siteDefinition]"
        @update:value="
          $emit('update:site-definition', {
            siteDefinition: sd.siteDefinition,
            value: $event as string,
          })
        "
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.site-edit-definitions {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
</style>
