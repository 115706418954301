<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { formatDate } from "@/helpers/formatDate";

const { t } = useI18n({ useScope: "global" });

defineProps<{
  isNew: boolean;
  surveyDate?: string;
  siteName?: string;
}>();
</script>

<template>
  <div>
    {{
      isNew
        ? t("survey.editSurvey.editSurvey", { date: formatDate.asDateOnly(surveyDate) })
        : t("survey.createNewSurvey", { date: formatDate.asDateOnly(surveyDate) })
    }}
  </div>
  <div class="survey-edit-drawer__header__subtitle">
    {{ t("survey.editSurvey.onSite", { siteName }) }}
  </div>
</template>

<style scoped lang="scss">
.survey-edit-drawer__header {
  &__subtitle {
    padding-top: $spacing-1;
    font-size: $text-sm;
  }
}
</style>
