<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  BlueRatingSurveyTab,
  useQueryNavigation,
} from "@/composables/query-navigation/useQueryNavigation";
import { useBroadcastService } from "@/composables/useBroadcastService";
import { getCategoryById } from "@/helpers/survey-helpers";
import {
  calculateCategoryColorService,
  getBlueRatingService,
  surveyReplyEvents,
} from "@/services/blueRating";
import { getModule } from "@/services/modules";
import { recommendationEvents } from "@/services/recommendations";
import { surveyEvents } from "@/services/surveys";
import { ModuleDto } from "@/types/_generated/api";
import { type BlueRating } from "@/types/Survey";
import BlueRatingNav from "@/views/Admin/BlueRating/components/BlueRatingNav/BlueRatingNav.vue";
import BlueRatingSurvey from "@/views/Admin/BlueRating/components/BlueRatingSurvey.vue";
import AdminHeader from "@/components/app/Admin/AdminHeader.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import SurveyEditDrawer from "../Sites/components/SurveyEditDrawer/SurveyEditDrawer.vue";
import { useBlueRatingBreadcrumbs } from "./composables/useBlueRatingBreadcrumbs";
import { useBlueRatingNavToggle } from "./composables/useBlueRatingNavToggle";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  moduleId: number;
  siteId: number;
  surveyId: number;
  tab?: BlueRatingSurveyTab;
}>();

const module = ref<ModuleDto | null>(null);
const survey = ref<BlueRating | null>(null);
const showNavigationMenu = ref(true);
const showSurveyEditDrawer = ref(false);

const navigation = useQueryNavigation();
const { toggleExpanded, collapseOrExpandAll } = useBlueRatingNavToggle(survey);
const { breadcrumbs } = useBlueRatingBreadcrumbs(module, props.siteId, survey);

const updateSurvey = async () =>
  (survey.value = {
    ...(survey.value || {}),
    ...((await getBlueRatingService(props.surveyId)) || {}),
  } as BlueRating);

useBroadcastService(recommendationEvents, updateSurvey);
useBroadcastService(surveyEvents, updateSurvey);

useBroadcastService(surveyReplyEvents, async (evt) => {
  if (evt.action === "update") {
    const category = getCategoryById(survey.value!, evt.categoryId);

    if (!category) {
      throw new Error("Category not found");
    }

    const categoryColor = await calculateCategoryColorService(
      survey.value!.surveyId,
      category.categoryId,
    );

    category.color = categoryColor!;
  }
});

onBeforeMount(async () => {
  survey.value = await getBlueRatingService(props.surveyId);
  module.value = await getModule(props.moduleId);
});
</script>

<template>
  <AdminHeader :breadcrumbs="breadcrumbs" />

  <main class="view-blue-rating">
    <BlueRatingNav
      v-if="survey"
      :survey="survey"
      :is-visible="showNavigationMenu"
      @collapse-or-expand-all="collapseOrExpandAll"
      @toggle-expanded="toggleExpanded"
      @toggle-visible="showNavigationMenu = !showNavigationMenu"
      @navigate="navigation.changeTab(BlueRatingSurveyTab.Survey)"
    />

    <BlueRatingSurvey v-if="survey && module" :survey="survey" :module="module" :tab="tab">
      <template #actions>
        <BaseStatusBadge :status="survey.status" />

        <BaseButton data-test="survey-settings-button" @click="showSurveyEditDrawer = true">
          {{ t("blueRating.surveySettings") }}
        </BaseButton>
      </template>
    </BlueRatingSurvey>
  </main>

  <SurveyEditDrawer
    v-if="showSurveyEditDrawer"
    :module-id="moduleId"
    :site-id="siteId"
    :survey-id="surveyId"
    @close="showSurveyEditDrawer = false"
  />
</template>

<style scoped lang="scss">
.view-blue-rating {
  display: grid;
  grid-template-columns: auto 1fr;

  &__general-recommendations {
    margin-top: $spacing-8;

    &-title {
      margin-bottom: $spacing-4;
      color: $primary-7;
      text-align: center;
    }
  }

  &__progress-legend {
    display: flex;
    align-items: center;
    font-size: $text-sm;
    gap: $spacing-1;

    &__color {
      border-radius: $rounded-base;
      width: 1rem;
      height: 1rem;

      &--completed {
        background-color: $secondary-4;
      }

      &--missing {
        background-color: $secondary-2;
      }
    }
  }
}
</style>
