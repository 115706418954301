import { Ref, computed, readonly, ref } from "vue";
import type { GroupBy } from "@/components/mods/Filter/SiteDefinitions/types/GroupBy";
import type {
  ExecutiveReportSurveyDto,
  SearchExecutiveSummaryResponse,
} from "@/types/_generated/api";
import { sortByKeys } from "@/helpers/sort";

export type SurveyGroup = {
  group: string;
  surveys: SearchExecutiveSummaryResponse["surveys"];
};

export const NO_VALUE_GROUP = "NO_VALUE";

export const useGroupedSurveys = (surveys: Ref<ExecutiveReportSurveyDto[]>) => {
  const groupBy = ref<GroupBy>();

  const groupedSurveys = computed(() => {
    const groups = (surveys.value ?? []).reduce((acc, survey) => {
      // Convert custom definition number to index
      const index = (groupBy.value ?? 0) - 1;

      // Set group name or no value set
      const group = index > -1 ? survey.siteDefinitions[index] : NO_VALUE_GROUP;

      // Check if group exists
      const existingGroup = acc.find((g) => g.group === group);

      if (existingGroup) {
        existingGroup.surveys.push(survey);
      } else {
        acc.push({
          group,
          surveys: [survey],
        });
      }

      return acc;
    }, [] as SurveyGroup[]);

    // Sort groups by group name
    return sortByKeys(groups, { key: "group" });
  });

  const updateGroupBy = (newGroupBy: GroupBy) => {
    groupBy.value = newGroupBy;
  };

  return { groupBy: readonly(groupBy), groupedSurveys, updateGroupBy };
};
