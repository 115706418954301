import { computed } from "vue";
import { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";

export const useGroupBySiteResponseStatus = (chartData: TrackedRecommendation[]) => {
  const groupedBySiteResponseStatus = computed(() =>
    chartData.reduce(
      (acc, recommendation) => {
        const { siteResponseStatus } = recommendation;
        const key = siteResponseStatus;
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      },
      {
        NotSet: 0,
        NoProgress: 0,
        UnderReview: 0,
        Completed: 0,
        Rejected: 0,
        InProgress: 0,
      },
    ),
  );

  return {
    groupedBySiteResponseStatus,
  };
};
