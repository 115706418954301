<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { vTruncationTitle } from "@/directives/truncationTitle";
import { BlueColor, Status } from "@/types/_generated/api";

const props = defineProps<{
  status: Status | BlueColor;
  size?: "small" | "medium";
}>();

const { t } = useI18n({ useScope: "global" });

const badgeClasses = computed(() => ({
  "base-blue-rating-status-badge": true,
  [`base-blue-rating-status-badge--${props.status.toLowerCase()}`]: true,
  [`base-blue-rating-status-badge--${props.size || "medium"}`]: true,
}));
</script>

<template>
  <span v-truncation-title :class="badgeClasses">
    <slot>
      {{ t(`common.statuses.${props.status}`) }}
    </slot>
  </span>
</template>

<style scoped lang="scss">
.base-blue-rating-status-badge {
  display: inline-flex;
  align-items: center;
  padding: $spacing-1-5 $spacing-2;
  border-radius: $rounded-lg;
  border: 1px solid transparent;
  font-size: $text-xs;
  line-height: $leading-none;

  &--small {
    padding: 1px 2px;
    min-width: 15px;
    justify-content: center;
  }

  @include blue-rating-styles;

  @media print {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}
</style>
