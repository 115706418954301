<script setup lang="ts">
import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import currencies from "@/assets/currencies.json";
import { useRcba } from "@/composables/useRcba";
import { toCurrency } from "@/helpers/formatNumber";
import { LossEstimateType, RiskCostBenefitAnalysisDto } from "@/types/_generated/api";
import { Option } from "@/types/Option";
import BaseDivider from "@/components/base/BaseDivider.vue";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    rcba: RiskCostBenefitAnalysisDto;
  }>(),
  {
    disabled: false,
    rcba: () => ({
      rcbaDescription: "",
      rcbaProbability: "",
      rcbaPropertyPrior: 0,
      rcbaPropertyAfter: 0,
      rcbaBiPrior: 0,
      rcbaBiAfter: 0,
      rcbaExtraPrior: 0,
      rcbaExtraAfter: 0,
      rcbaCostToComplete: 0,
      rcbaCurrency: "USD",
      rcbaLossEstimateType: LossEstimateType.Percent,
    }),
  },
);

const emit = defineEmits<{
  <Key extends keyof RiskCostBenefitAnalysisDto>(
    event: "update",
    key: Key,
    value: RiskCostBenefitAnalysisDto[Key],
  ): void;
}>();

const lossEstimateOptions: readonly Option[] = [
  {
    title: LossEstimateType.Unknown,
    value: LossEstimateType.Unknown,
  },
  {
    title: LossEstimateType.Monetary,
    value: LossEstimateType.Monetary,
  },
  {
    title: LossEstimateType.Percent,
    value: LossEstimateType.Percent,
  },
];

const { priorSum, afterSum, formatSum, costBenefitRatio, hasExtra, hasProbability } = useRcba(
  toRef(props.rcba),
);

const setCurrency = (event: string | number | null | undefined) => {
  const currency = String(event ?? "")
    .trim()
    .toUpperCase();
  emit("update", "rcbaCurrency", currency);
};
</script>

<template>
  <section class="blue-rating-cost-benefit">
    <BaseTextField
      class="blue-rating-cost-benefit__two-thirds truncate"
      data-test="rcba-currency"
      :label="t('blueRating.recommendation.currency')"
      :value="rcba.rcbaCurrency"
      :disabled="disabled"
      :list="currencies"
      :maxlength="4"
      @change:value="setCurrency"
    />

    <BaseSelect
      :label="t('blueRating.recommendation.lossEstimateType')"
      :value="rcba.rcbaLossEstimateType"
      :disabled="disabled"
      data-test="rcba-loss-estimate-type"
      @change="($event) => emit('update', 'rcbaLossEstimateType', $event as LossEstimateType)"
    >
      <BaseOption v-for="option in lossEstimateOptions" :key="option.value" :value="option.value">
        {{ option.title }}
      </BaseOption>
    </BaseSelect>

    <div
      :class="{
        'blue-rating-cost-benefit__scenario': true,
        'blue-rating-cost-benefit__scenario__full-width': !hasProbability,
      }"
    >
      <BaseLabel has-spacing>
        {{ t("blueRating.recommendation.scenarioDescription") }}
      </BaseLabel>
      <BaseEditor
        :disabled="disabled"
        :custom-height="{ min: '200px' }"
        :value="rcba.rcbaDescription"
        data-test="rcba-description"
        @input="($event) => emit('update', 'rcbaDescription', $event)"
      />
    </div>

    <BaseTextField
      v-if="hasProbability"
      :label="t('blueRating.recommendation.probability')"
      :disabled="disabled"
      :value="rcba.rcbaProbability"
      data-test="rcba-probability"
      @update:value="($event) => emit('update', 'rcbaProbability', $event as string)"
    />

    <BaseDivider class="blue-rating-cost-benefit__divider" />

    <span><!--intentionally left blank--></span>
    <BaseLabel class="blue-rating-cost-benefit__align-center">
      {{ t("blueRating.recommendation.toImplementation") }}
    </BaseLabel>
    <BaseLabel class="blue-rating-cost-benefit__align-center">
      {{ t("blueRating.recommendation.afterImplementation") }}
    </BaseLabel>
    <BaseLabel class="blue-rating-cost-benefit__justify-end">
      {{ t("blueRating.recommendation.property") }}
    </BaseLabel>

    <BaseTextField
      type="number"
      :disabled="disabled"
      :value="rcba.rcbaPropertyPrior"
      :max="rcba.rcbaLossEstimateType === LossEstimateType.Percent ? 100 : undefined"
      data-test="rcba-property-prior"
      @update:value="
        ($event) =>
          emit(
            'update',
            'rcbaPropertyPrior',
            rcba.rcbaLossEstimateType === LossEstimateType.Percent
              ? Math.min(100, $event as number)
              : ($event as number),
          )
      "
    />
    <BaseTextField
      type="number"
      :value="rcba.rcbaPropertyAfter"
      :disabled="disabled"
      :max="rcba.rcbaLossEstimateType === LossEstimateType.Percent ? 100 : undefined"
      data-test="rcba-property-after"
      @update:value="
        ($event) =>
          emit(
            'update',
            'rcbaPropertyAfter',
            rcba.rcbaLossEstimateType === LossEstimateType.Percent
              ? Math.min(100, $event as number)
              : ($event as number),
          )
      "
    />

    <BaseLabel class="blue-rating-cost-benefit__justify-end">
      {{ t("blueRating.recommendation.businessInterruption") }}
    </BaseLabel>
    <BaseTextField
      type="number"
      :disabled="disabled"
      :value="rcba.rcbaBiPrior"
      :max="rcba.rcbaLossEstimateType === LossEstimateType.Percent ? 100 : undefined"
      data-test="rcba-bi-prior"
      @update:value="
        ($event) =>
          emit(
            'update',
            'rcbaBiPrior',
            rcba.rcbaLossEstimateType === LossEstimateType.Percent
              ? Math.min(100, $event as number)
              : ($event as number),
          )
      "
    />
    <BaseTextField
      type="number"
      :value="rcba.rcbaBiAfter"
      :disabled="disabled"
      :max="rcba.rcbaLossEstimateType === LossEstimateType.Percent ? 100 : undefined"
      data-test="rcba-bi-after"
      @update:value="
        ($event) =>
          emit(
            'update',
            'rcbaBiAfter',
            rcba.rcbaLossEstimateType === LossEstimateType.Percent
              ? Math.min(100, $event as number)
              : ($event as number),
          )
      "
    />

    <BaseLabel v-if="hasExtra" class="blue-rating-cost-benefit__justify-end">
      {{ t("blueRating.recommendation.extra") }}
    </BaseLabel>
    <BaseTextField
      v-if="hasExtra"
      type="number"
      :disabled="disabled"
      :value="rcba.rcbaExtraPrior"
      :max="rcba.rcbaLossEstimateType === LossEstimateType.Percent ? 100 : undefined"
      data-test="rcba-extra-prior"
      @update:value="
        ($event) =>
          emit(
            'update',
            'rcbaExtraPrior',
            rcba.rcbaLossEstimateType === LossEstimateType.Percent
              ? Math.min(100, $event as number)
              : ($event as number),
          )
      "
    />
    <BaseTextField
      v-if="hasExtra"
      type="number"
      :value="rcba.rcbaExtraAfter"
      :disabled="disabled"
      :max="rcba.rcbaLossEstimateType === LossEstimateType.Percent ? 100 : undefined"
      data-test="rcba-extra-after"
      @update:value="
        ($event) =>
          emit(
            'update',
            'rcbaExtraAfter',
            rcba.rcbaLossEstimateType === LossEstimateType.Percent
              ? Math.min(100, $event as number)
              : ($event as number),
          )
      "
    />

    <BaseLabel class="blue-rating-cost-benefit__justify-end">
      {{ t("blueRating.recommendation.total") }}
    </BaseLabel>
    <span class="blue-rating-cost-benefit__total">
      {{ formatSum(priorSum) }}
    </span>
    <span class="blue-rating-cost-benefit__total">
      {{ formatSum(afterSum) }}
    </span>

    <BaseDivider class="blue-rating-cost-benefit__divider" />

    <BaseLabel class="blue-rating-cost-benefit__two-thirds blue-rating-cost-benefit__justify-end">
      {{ t("blueRating.recommendation.estimatedCostToComplete", { currency: rcba.rcbaCurrency }) }}
    </BaseLabel>
    <BaseTextField
      type="number"
      :disabled="disabled"
      :value="rcba.rcbaCostToComplete"
      data-test="rcba-cost-to-complete"
      @update:value="($event) => emit('update', 'rcbaCostToComplete', $event as number)"
    />

    <BaseLabel class="blue-rating-cost-benefit__two-thirds blue-rating-cost-benefit__justify-end">
      {{ t("blueRating.recommendation.estimatedRiskReduction") }}
    </BaseLabel>
    <span class="blue-rating-cost-benefit__total">
      {{ formatSum(priorSum - afterSum) }}
    </span>

    <BaseLabel
      v-if="rcba.rcbaLossEstimateType === LossEstimateType.Monetary"
      class="blue-rating-cost-benefit__two-thirds blue-rating-cost-benefit__justify-end"
    >
      {{ t("blueRating.recommendation.costBenefitRatio") }}
    </BaseLabel>
    <span
      v-if="rcba.rcbaLossEstimateType === LossEstimateType.Monetary"
      class="blue-rating-cost-benefit__total"
    >
      {{ costBenefitRatio }}
    </span>

    <span
      v-if="rcba.rcbaLossEstimateType === LossEstimateType.Percent"
      class="blue-rating-cost-benefit__row blue-rating-cost-benefit__justify-end"
    >
      {{
        t("blueRating.recommendation.investmentGivesRiskReduction", {
          cost: toCurrency(rcba.rcbaCostToComplete, props.rcba.rcbaCurrency),
          riskReduction: formatSum(priorSum - afterSum),
        })
      }}
    </span>
  </section>
</template>

<style scoped lang="scss">
.blue-rating-cost-benefit {
  display: grid;
  gap: $spacing-2;
  padding: $spacing-4;

  &__two-thirds {
    padding-right: $spacing-4;
    grid-column: 1/3;
  }

  &__align-center {
    align-self: center;
  }

  &__justify-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: $spacing-4;
  }

  &__row {
    padding-left: $spacing-4;
    padding-right: $spacing-4;
    grid-column: span 3;
    height: 40px;
  }

  &__total {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: $spacing-3;
    height: 40px;
  }

  &__scenario {
    grid-column: 1/3;
    margin: $spacing-3 0 $spacing-2 0;
    &__full-width {
      grid-column: 1/4;
    }
  }

  &__divider {
    grid-column: 1/4;
    margin-bottom: $spacing-2;
  }
}
</style>
