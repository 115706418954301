import axios from "axios";
import { useI18n } from "vue-i18n";
import type { Address, LocationResponse } from "@/types/LocationResponse";
import { handleError } from "@/services/shared/handleError";
import { useAppStore } from "@/stores/app";

const lookupLocationUrl = "https://dev.virtualearth.net/REST/v1/Locations";

export const useLocationLookup = () => {
  const app = useAppStore();
  const { t } = useI18n({ useScope: "global" });

  // Create a new axios instance since we don't want
  // any of the defaults from helpers/http.ts
  const http = axios.create();

  const getLocationFromAddress = async (
    street: string,
    zip: string,
    city: string,
    country: string,
  ): Promise<{ longitude: number; latitude: number } | null> => {
    const address = [street.trim(), zip.trim(), city.trim(), country.trim()]
      .filter(Boolean)
      .join(" ");

    try {
      const {
        data: { resourceSets },
      } = await http.request<LocationResponse>({
        method: "GET",
        url: `${lookupLocationUrl}/${window.encodeURIComponent(address)}?key=${app.settings.bingMapKey}`,
      });

      if (resourceSets.length > 0 && resourceSets[0].resources.length > 0) {
        const [
          {
            resources: [{ point }],
          },
        ] = resourceSets;
        if (point && point.coordinates?.length === 2) {
          return { latitude: point.coordinates[0], longitude: point.coordinates[1] };
        }
      }
    } catch (error) {
      handleError(error, {
        action: t("common.actions.fetch").toLowerCase(),
        entity: t("map.location"),
      });
    }
    return null;
  };

  const getAddressFromLocation = async (
    latitude: number,
    longitude: number,
  ): Promise<Address | null> => {
    try {
      const {
        data: { resourceSets },
      } = await http.request<LocationResponse>({
        method: "GET",
        url: `${lookupLocationUrl}/${latitude},${longitude}?key=${app.settings.bingMapKey}`,
      });

      if (resourceSets.length > 0 && resourceSets[0].resources.length > 0) {
        const [
          {
            resources: [{ address }],
          },
        ] = resourceSets;
        return address ?? null;
      }
    } catch (error) {
      handleError(error, {
        action: t("common.actions.fetch").toLowerCase(),
        entity: t("map.location"),
      });
    }
    return null;
  };

  return {
    getLocationFromAddress,
    getAddressFromLocation,
  };
};
