<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { computed, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { ModuleDto } from "@/types/_generated/api";
import { CustomDefinitionNumber } from "@/types/SiteDefinitions";
import { useModuleValidation } from "@/views/Admin/Modules/composables/useModuleValidation";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import BasePillDeleteButton from "@/components/base/BasePills/BasePillDeleteButton.vue";
import BasePillsInput from "@/components/base/BasePills/BasePillsInput.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const props = defineProps<{
  module: ModuleDto;
  number: CustomDefinitionNumber;
  showClear: boolean;
}>();

const emits = defineEmits<{
  (event: "update:module", module: ModuleDto): void;
  (event: "clear", module: ModuleDto): void;
}>();

const { t } = useI18n({ useScope: "global" });

const { siteDefinitionsValidation } = useModuleValidation();

const { v$ } = useVuelidate(
  siteDefinitionsValidation,

  toRef(() => props.module),
  {
    $registerAs: `edit-module-site-definition-${props.number}`,
    $autoDirty: true,
  },
);

const dialog = useDialog();

const clear = async () => {
  const entity = t("modules.siteDefinition");

  const confirm = await dialog.openDialog({
    title: t("common.deletePrompt.title", { entity }),
    description: t("common.deletePrompt.message", { entity }),
  });

  if (!confirm) {
    return;
  }

  emits("clear", {
    ...props.module,
    [`siteDefinition${props.number}`]: "",
    [`suggestions${props.number}`]: [],
    [`forceSuggestion${props.number}`]: false,
  });
};

const suggestions = computed(() => [...new Set(props.module[`suggestions${props.number}`])]);

const addSuggestion = (suggestion: string) =>
  emits("update:module", {
    ...props.module,
    [`suggestions${props.number}`]: [...suggestions.value, suggestion],
  });

const deleteSuggestion = (index: number) =>
  emits("update:module", {
    ...props.module,
    [`suggestions${props.number}`]: suggestions.value.filter((_, i) => i !== index),
  });

const suggestionsError = computed((): ErrorObject[] =>
  props.module[`suggestions${props.number}`]?.some((x) => x.length > 50)
    ? [
        {
          $message: t("validations.maxLength", { max: 50 }),
          $params: props.module[`suggestions${props.number}`]?.length,
        } as unknown as ErrorObject,
      ]
    : [],
);

const isForceSuggestionsDisabled = computed(() => suggestions.value.length === 0);
</script>
<template>
  <BaseCard
    :title="t('modules.siteDefinition') + ' ' + number"
    data-test="module-edit-site-definition"
  >
    <div class="edit-module-site-definition">
      <BaseTextField
        :label="t('common.name')"
        :value="module[`siteDefinition${number}`]"
        :maxlength="100"
        :errors="v$[`siteDefinition${props.number}`]?.$errors"
        @update:value="
          $emit('update:module', {
            ...module,
            [`siteDefinition${number}`]: $event,
          })
        "
      />

      <BasePillsInput
        :label="t('modules.suggestions')"
        :values="suggestions"
        :add-on-space="false"
        :errors="suggestionsError"
        :max-length="50"
        @add="addSuggestion($event)"
      >
        <template #pill="{ value, index }">
          <BasePill :label="value">
            <template #post>
              <BasePillDeleteButton @click="deleteSuggestion(index)" />
            </template>
          </BasePill>
        </template>
      </BasePillsInput>

      <div class="edit-module-site-definition__checkbox">
        <BaseCheckbox
          :disabled="isForceSuggestionsDisabled"
          :label="t('modules.forceSuggestion')"
          :checked="module[`forceSuggestion${number}`]"
          @change="
            $emit('update:module', {
              ...module,
              [`forceSuggestion${number}`]: $event,
            })
          "
        />
      </div>

      <BaseButton
        variant="text"
        color="error"
        :title="`${t('common.actions.delete')} ${t('modules.siteDefinition')}`"
        class="edit-module-site-definition__delete-button"
        @click="clear"
      >
        <BaseIcon icon="trash" />
      </BaseButton>
    </div>
  </BaseCard>
</template>

<style scoped lang="scss">
.edit-module-site-definition {
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr 1fr auto auto;
  padding: $spacing-4;
  gap: $spacing-4;

  &__checkbox {
    display: flex;
    flex-direction: column;
    gap: $spacing-5;
    align-items: center;
    margin-top: $spacing-12;
  }

  &__delete-button {
    margin-top: $spacing-10;
  }
}
</style>
