<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDebounce } from "@/composables/useDebounce";
import { saveCategoryNotesService } from "@/services/blueRating";
import { BlueColor, BlueRatingCategoryDto } from "@/types/_generated/api";
import BaseAutoSaveStatus, { type State } from "@/components/base/BaseAutoSaveStatus.vue";
import BaseBlueRatingStatusBadge from "@/components/base/BaseBlueRatingStatusBadge.vue";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BlueRatingAddRecMenu from "../BlueRatingAddRecMenu.vue";
import BlueRatingCard from "../BlueRatingCard.vue";
import BlueRatingDescriptionToggle from "../BlueRatingDescriptionToggle.vue";
import BlueRatingCategoryNotApplicableButton from "./BlueRatingCategoryNotApplicableButton.vue";

const props = defineProps<{
  surveyId: number;
  isSurveyPublished: boolean;
  category: BlueRatingCategoryDto;
  hideDescription: boolean;
  replySaveStates: Record<number, State>;
}>();

defineEmits<{
  "new-recommendation": [void];
  "copy-standard-rec": [void];
  "edit-recommendation": [number];
  "toggle-description": [void];
  "toggle-all-questions-to-na": [void];
}>();

const { t } = useI18n({ useScope: "global" });
const debounce = useDebounce();

const notesStatus = ref<State>("idle");

const isNotApplicable = computed(() =>
  props.category!.questions.every((q) => q.value === BlueColor.Gray),
);

const isSaving = computed(() =>
  Object.values(props.replySaveStates).some((state) => state === "saving"),
);

const limitColors = computed(
  (): Record<string, { color: BlueColor; limit: number; label?: string }> => {
    return {
      blue: { color: BlueColor.Blue, limit: props.category.blueLimit },
      green: { color: BlueColor.Green, limit: props.category.greenLimit },
      yellow: { color: BlueColor.Yellow, limit: props.category.yellowLimit },
      notset: {
        color: BlueColor.NotSet,
        limit: props.category.maxScore,
        label: t("blueRating.category.totalLimit"),
      },
    };
  },
);

const updateNotes = async (html: string) => {
  debounce(async () => {
    notesStatus.value = "saving";
    const success = await saveCategoryNotesService(props.surveyId, props.category.categoryId, html);
    notesStatus.value = success ? "success" : "error";
  }, 2000);
};
</script>

<template>
  <div class="blue-rating-category">
    <BlueRatingCard
      :id="`category_${category.categoryId}`"
      :title="category.name"
      :position="category.fullPosition"
      :recommendations="category.recommendations"
      @click:recommendation="$emit('edit-recommendation', $event)"
    >
      <template #controls>
        <BlueRatingAddRecMenu
          :title="
            isNotApplicable
              ? t('blueRating.recommendation.addRecommendationOnGrey')
              : t('blueRating.recommendation.addRecommendation')
          "
          :category="category"
          :disabled="isSurveyPublished || isNotApplicable"
          @new-recommendation="$emit('new-recommendation')"
          @copy-standard-rec="$emit('copy-standard-rec')"
        />

        <BlueRatingDescriptionToggle
          class="blue-rating-category__control-button"
          :show-description="!hideDescription"
          @toggle="$emit('toggle-description')"
        />

        <BlueRatingCategoryNotApplicableButton
          class="blue-rating-category__control-button"
          :category="category"
          :is-not-applicable="isNotApplicable"
          :is-survey-published="isSurveyPublished"
          :is-saving="isSaving"
          @click="$emit('toggle-all-questions-to-na')"
        />
      </template>

      <div class="blue-rating-category__wrapper">
        <div v-if="category.gradeBy === 'DefinedLimits'" class="blue-rating-category__limits">
          <template v-for="item in limitColors" :key="item.color">
            <BaseBlueRatingStatusBadge :status="item.color">
              {{ item.label ? item.label + item.limit : item.limit }}
            </BaseBlueRatingStatusBadge>
          </template>
        </div>

        <div class="blue-rating-category__notes">
          <div class="blue-rating-category__notes-editor">
            <BaseLabel>
              {{ t("blueRating.category.notes") }}
            </BaseLabel>
            <BaseEditor :value="category.notes" @input="updateNotes" />
          </div>

          <div class="blue-rating-category__notes-footer">
            <small>
              {{ t("blueRating.category.notesInformation") }}
            </small>
            <BaseAutoSaveStatus :state="notesStatus" />
          </div>
        </div>
      </div>
    </BlueRatingCard>
  </div>
</template>

<style scoped lang="scss">
.blue-rating-category {
  [id^="category_"] {
    scroll-margin-top: 1rem;
  }

  &__control-button {
    padding: $spacing-3 $spacing-2;
  }

  &__wrapper {
    padding: $spacing-4;
  }

  &__limits {
    display: flex;
    flex-direction: row;
    gap: $spacing-2;
    margin-bottom: $spacing-4;
  }

  &__notes {
    &-editor {
      display: flex;
      flex-direction: column;
      gap: $spacing-2;
      margin-bottom: $spacing-3;
    }

    &-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
