<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import { updateUserPassword } from "@/services/users";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseModal from "@/components/base/BaseModal.vue";
import PasswordForm from "@/components/shared/PasswordForm.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  userId: number | null;
}>();

const state = reactive({
  password1: "",
  password2: "",
});

const emit = defineEmits<{
  (event: "close"): void;
}>();

const v$ = useVuelidate();

const changePassword = async () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    const success = await updateUserPassword({
      userId: props.userId!,
      password: state.password1,
    });

    if (success) {
      closeModal();
    }
  }
};

const closeModal = () => {
  emit("close");
};
</script>

<template>
  <BaseModal
    :title="`${t('users.changePassword')} ${t('common.dialog')}`"
    data-test="user-edit-password-modal"
    @close="closeModal"
  >
    <PasswordForm
      v-model:password1="state.password1"
      v-model:password2="state.password2"
      :show-requirements="false"
    />

    <template #controls>
      <BaseButton data-test="user-edit-password-confirm" @click="changePassword">
        {{ `${t("common.actions.confirm")} ${t("users.changePassword")}` }}
      </BaseButton>

      <BaseButton variant="outlined" @click="closeModal">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
