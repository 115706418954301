<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";
import { useAuthStore } from "@/stores/auth";
import AuthForgot from "@/views/Auth/components/AuthForgot.vue";
import AuthMfa from "@/views/Auth/components/AuthMfa.vue";
import AuthSignedOut from "@/views/Auth/components/AuthSignedOut.vue";
import AuthSignIn from "@/views/Auth/components/AuthSignIn.vue";
import AuthWrapper from "@/views/Auth/components/AuthWrapper.vue";
import BaseAlert from "@/components/base/BaseAlert.vue";

const { t } = useI18n({ useScope: "global" });
const auth = useAuthStore();

interface Form {
  email: string;
  password: string;
}

const view = ref<string>(ViewAuthStatus.SignIn);
const user = reactive<Form>({
  email: "",
  password: "",
});

// change view after user signed out
const { query } = useRoute();
if (query.reason === ViewAuthStatus.SignedOut) view.value = ViewAuthStatus.SignedOut;
if (query.reason === ViewAuthStatus.SessionExpired) view.value = ViewAuthStatus.SessionExpired;
if (query.reason === ViewAuthStatus.SsoFailed) view.value = ViewAuthStatus.SsoFailed;

const heading = computed(() => {
  if (auth.is2fa) {
    return t("signIn.2fa.enterCode");
  }

  switch (view.value) {
    case ViewAuthStatus.SignIn:
      return t("signIn.signIn");
    case ViewAuthStatus.Forgot:
      return t("signIn.forgotPassword");
    case ViewAuthStatus.SignedOut:
      return t("signIn.signedOut");
    default:
      return t("signIn.signIn");
  }
});
</script>

<template>
  <AuthWrapper class="view-auth" :heading="heading">
    <BaseAlert v-if="false" severity="warning" class="view-auth__alert">
      <p>Blue is currently under maintenance. The application will be back online in 1 hour.</p>
    </BaseAlert>

    <AuthSignIn
      v-if="view === ViewAuthStatus.SignIn && !auth.is2fa"
      :email="user.email"
      :password="user.password"
      @two-factor="view = ViewAuthStatus.TwoFactor"
      @forgot="view = ViewAuthStatus.Forgot"
      @email="user.email = $event"
      @password="user.password = $event"
    />

    <AuthMfa v-if="auth.is2fa" :email="user.email" :password="user.password" />

    <AuthForgot
      v-if="view === ViewAuthStatus.Forgot"
      :email="user.email"
      @return="view = ViewAuthStatus.SignIn"
      @email="user.email = $event"
    />

    <AuthSignedOut
      v-if="
        view === ViewAuthStatus.SignedOut ||
        view === ViewAuthStatus.SessionExpired ||
        view === ViewAuthStatus.SsoFailed
      "
      :view="view"
      @redirect="view = ViewAuthStatus.SignIn"
    />
  </AuthWrapper>
</template>

<style scoped lang="scss">
.view-auth {
  &__alert {
    margin-bottom: $spacing-4;
  }
}
</style>
