<script setup lang="ts">
import type { TableLegend } from "@/components/shared/BlueRatingTable/types/types";
import type {
  SearchExecutiveSummaryResponse,
  ExecutiveReportLossScenarioDto,
  ModuleDto,
} from "@/types/_generated/api";
import type { ClientFilter } from "@/views/UserWeb/ExecutiveSummary/types/FilterTypes";
import { GroupBy } from "@/components/mods/Filter/SiteDefinitions/types/GroupBy";
import BlueRatingTableDynamicHeight from "@/components/shared/BlueRatingTable/BlueRatingTableDynamicHeight.vue";
import BlueRatingTableLegend from "@/components/shared/BlueRatingTable/BlueRatingTableLegend.vue";
import type { SurveyGroup } from "../../composables/useGroupedSurveys";
import type { ExecutiveSummaryDrawer } from "../../types/ExecSummaryDrawers";
import { DrawerOption } from "../../constants/DrawerOption";
import ExecSummaryTableGroup from "./ExecSummaryTableCells/ExecSummaryTableGroup.vue";
import ExecSummaryTableSurveyRows from "./ExecSummaryTableCells/ExecSummaryTableSurveyRows.vue";
import ExecSummaryTableFooter from "./ExecSummaryTableFooter.vue";
import ExecSummaryTableHeader from "./ExecSummaryTableHeader/ExecSummaryTableHeader.vue";

defineProps<{
  legend: TableLegend | null;
  currencyCode: string;
  abeyanceShort: ModuleDto["abeyanceShort"];
  clientFilter: ClientFilter;
  executiveSummary: SearchExecutiveSummaryResponse;
  groupedSurveys: SurveyGroup[];
  groupBy: GroupBy;
  lossScenarios: ExecutiveReportLossScenarioDto[];
}>();

const emit = defineEmits<{
  openDrawer: [ExecutiveSummaryDrawer];
}>();

const onCellClick = (surveyId: number, index: number) => {
  emit("openDrawer", { type: DrawerOption.CatRec, params: { surveyId, index } });
};

const onHeaderClick = (categoryIndex: number) => {
  emit("openDrawer", { type: DrawerOption.CatDrilldown, params: { categoryIndex } });
};
</script>

<template>
  <div class="exec-summary-table">
    <BlueRatingTableDynamicHeight>
      <table>
        <ExecSummaryTableHeader
          v-if="executiveSummary.categories"
          :currency-code="currencyCode"
          :client-filter="clientFilter"
          :category-columns="executiveSummary.categories"
          :loss-scenario-columns="lossScenarios"
          @open-drawer="onHeaderClick"
        />

        <tbody v-if="executiveSummary.surveys">
          <template v-if="groupBy">
            <template v-for="({ group, surveys }, index) in groupedSurveys" :key="index">
              <ExecSummaryTableGroup
                v-if="surveys.length"
                :group="group"
                :rows="surveys"
                :client-filter="clientFilter"
                :loss-scenarios="lossScenarios"
                :abeyance-short="abeyanceShort"
                @open-drawer="onCellClick($event.surveyId, $event.index)"
              />
            </template>
          </template>
          <template v-else>
            <ExecSummaryTableSurveyRows
              :client-filter="clientFilter"
              :loss-scenarios="lossScenarios"
              :abeyance-short="abeyanceShort"
              :rows="executiveSummary.surveys"
              @open-drawer="onCellClick($event.surveyId, $event.index)"
            />
          </template>
        </tbody>

        <ExecSummaryTableFooter
          :client-filter="clientFilter"
          :areas="executiveSummary.areas"
          :categories="executiveSummary.categories"
          :total-average="executiveSummary.scorePercentAverage"
        />

        <BlueRatingTableLegend v-if="legend" :legend="legend" />
      </table>
    </BlueRatingTableDynamicHeight>
  </div>
</template>

<style scoped lang="scss">
@import "@/components/shared/BlueRatingTable/styles/styles";

.exec-summary-table {
  padding-top: $table-padding-top;
  overflow: auto;
}
</style>
