import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { sortByKeys } from "@/helpers/sort";
import { getUsers } from "@/services/users";
import { ModuleDto, UserLeanDto } from "@/types/_generated/api";

export type UserGroup = { title: string; users: UserLeanDto[] };

const getContactUsers = (
  users: UserLeanDto[],
  module: ModuleDto,
  noCompanyTranslation: string,
): UserGroup[] => {
  // Only EXT users
  let extUsers = users.filter((u) => u.role === "EXT");

  // Add current contact if not in list
  if (module.contactId && !extUsers.some((u) => u.id === module.contactId)) {
    extUsers.unshift({
      id: module.contactId,
      fullName: module.contactFullName,
      email: module.contactEmail,
    } as UserLeanDto);
  }

  // Sort by fullName so each group is sorted
  extUsers = sortByKeys(extUsers, { key: "fullName" });

  // Group by company
  const companyUserMap = new Map<string, UserLeanDto[]>();
  for (const user of extUsers) {
    const title = user.company?.length ? user.company : noCompanyTranslation;
    companyUserMap.set(title, [...(companyUserMap.get(title) ?? []), user]);
  }

  // Convert Map to UserGroup[] and sort by Company name
  return sortByKeys(
    Array.from(companyUserMap.entries()).map(([company, users]) => ({
      title: company,
      users,
    })),
    { key: "title" },
  );
};

export const useModuleUsers = () => {
  const { t } = useI18n({ useScope: "global" });
  const contactUsers = ref<UserGroup[]>([]);
  const accountEngineerUsers = ref<UserGroup[]>([]);

  const fetchUsers = async (module: ModuleDto) => {
    const [allUsers, moduleUsers] = await Promise.all([
      getUsers({ onlyActive: true }).then((users) => users ?? []),
      getUsers({ moduleId: module.moduleId, onlyActive: true }).then((users) => users ?? []),
    ]);

    contactUsers.value = getContactUsers(allUsers, module, t("users.noCompany"));

    accountEngineerUsers.value = [
      {
        title: t("modules.baUsersWithModuleAccess"),
        users: moduleUsers.filter((u) => u.role === "BA"),
      },
      {
        title: t("modules.intUsersWithModuleAccess"),
        users: moduleUsers.filter((u) => u.role === "INT"),
      },
      {
        title: t("users.roles.SO"),
        users: allUsers.filter((u) => u.role === "SystemOwner"),
      },
      {
        title: t("users.roles.SA"),
        users: allUsers.filter((u) => u.role === "SA"),
      },
      {
        title: t("users.roles.BA"),
        users: allUsers.filter((u) => u.role === "BA" && !moduleUsers.some((mu) => mu.id === u.id)),
      },
      {
        title: t("users.roles.INT"),
        users: allUsers.filter(
          (u) => u.role === "INT" && !moduleUsers.some((mu) => mu.id === u.id),
        ),
      },
    ];

    const allAccountEngineers = accountEngineerUsers.value.flatMap((u) => u.users);

    if (
      module.accountEngineerId &&
      !allAccountEngineers.some((u) => u.id === module.accountEngineerId)
    ) {
      accountEngineerUsers.value.unshift({
        title: t("users.user"),
        users: [
          {
            id: module.accountEngineerId,
            fullName: module.accountEngineerFullName,
            role: module.accountEngineerRole,
            email: module.accountEngineerEmail,
          } as UserLeanDto,
        ],
      });
    }
  };

  return {
    contactUsers,
    accountEngineerUsers,
    fetchUsers,
  };
};
