import { computed, type DeepReadonly } from "vue";
import type { ExecutiveReportAreaDto, ExecutiveReportCategoryDto } from "@/types/_generated/api";

export const useCombinedAreaData = (
  areas: ExecutiveReportAreaDto[] | DeepReadonly<ExecutiveReportAreaDto[]>,
  categories: ExecutiveReportCategoryDto[] | DeepReadonly<ExecutiveReportCategoryDto[]>,
) => {
  return computed(() => {
    // Create a map to count categories per area
    const areaCountMap = new Map<number, number>();

    // Count categories for each area
    categories.forEach((category) => {
      const currentCount = areaCountMap.get(category.areaId) || 0;
      areaCountMap.set(category.areaId, currentCount + 1);
    });

    // Filter and combine areas with their counts
    return areas
      .map((area) => ({
        name: area.areaName,
        percent: area.scorePercentAverage,
        count: areaCountMap.get(area.areaId) || 0, // Used to determine the colspan of the area in the footer
      }))
      .filter((area) => area.count > 0); // Filter out areas with no categories
  });
};
