<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { useUniqueId } from "@/composables/useUniqueId";
import { OptionValue } from "@/types/Option";
import BaseInputDescription from "../BaseInputDescription.vue";
import BaseInputRequired from "../BaseInputRequired.vue";
import BaseLabel from "../BaseLabel.vue";

interface Props {
  value?: OptionValue;
  label?: string;
  ariaLabel?: string;
  errors?: ErrorObject[];
  required?: boolean;
  dataTest?: string;
  disabled?: boolean;
  description?: string;
}

defineProps<Props>();

const emit = defineEmits<{
  (event: "change", value: string): void;
}>();

const id = useUniqueId("base_select");

const handleSelectChange = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  const newValue = target.value;
  emit("change", newValue);
};
</script>

<template>
  <div class="base-select__wrapper">
    <div v-if="label" class="base-select__wrapper__label">
      <BaseLabel :for-id="id" :data-test="`${dataTest}-label`" has-spacing>
        {{ label }}
      </BaseLabel>
      <BaseInputRequired v-if="required" />
    </div>

    <select
      :id="id"
      class="base-select"
      :value="value"
      :aria-label="ariaLabel"
      :data-test="dataTest"
      :disabled="disabled"
      @change="handleSelectChange"
    >
      <slot></slot>
    </select>

    <BaseInputDescription v-if="description && !errors?.length" :description="description" />

    <div v-if="errors?.length && !disabled">
      <p v-for="({ $message }, i) in errors" :key="i" class="base-select__error">
        {{ $message }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.base-select__wrapper {
  display: flex;
  flex-direction: column;

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.base-select {
  cursor: pointer;
  padding: $spacing-3 $spacing-10 $spacing-3 $spacing-3;
  border: 1px solid $primary-6;
  border-radius: $rounded-base;
  min-height: 2.7rem;

  /* Arrow */
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='20' height='20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M12.78 6.22a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06 0L3.22 7.28a.75.75 0 011.06-1.06L8 9.94l3.72-3.72a.75.75 0 011.06 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right $spacing-3 top 50%;
  background-size: 1rem auto;

  /* Error */
  &__error {
    color: $error-4;
    line-height: $leading-normal;
    margin-top: $spacing-1;
  }
}

.base-select:disabled {
  background-color: $primary-2;
  cursor: not-allowed;
}
</style>
