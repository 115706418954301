import { readonly, ref, type Ref } from "vue";
import { customLocaleCompare } from "@/helpers/customLocaleCompare";
import { SearchExecutiveSummaryResponse } from "@/types/_generated/api";

export enum SortOption {
  Alphabetical = "alphabetical",
  Score = "score",
}

export type SortBy = SortOption | string;

export const useSortExecSummary = (
  executiveSummary: Ref<SearchExecutiveSummaryResponse | undefined>,
) => {
  const currentSortOrder = ref<SortBy>(SortOption.Alphabetical);

  const sortTable = (sortBy: SortBy) => {
    if (sortBy === currentSortOrder.value || !executiveSummary.value) return;

    // Update the current sort order
    currentSortOrder.value = sortBy;

    const { surveys } = executiveSummary.value;

    switch (sortBy) {
      case SortOption.Alphabetical:
        surveys.sort((a, b) =>
          customLocaleCompare(
            a.siteDefinitions.join("/").toLocaleLowerCase(),
            b.siteDefinitions.join("/").toLocaleLowerCase(),
          ),
        );
        break;
      case SortOption.Score:
        surveys.sort((a, b) => b.totalScore - a.totalScore);
        break;
      default:
        // Sort by insured values pdBi. Highest pdBi (total of PD and BI) first.
        surveys.sort((a, b) => {
          const aPdBi =
            a.insuredValues.find((ls) => ls.lossScenarioId === Number(sortBy))?.pdBi || 0;
          const bPdBi =
            b.insuredValues.find((ls) => ls.lossScenarioId === Number(sortBy))?.pdBi || 0;
          return bPdBi - aPdBi;
        });
        break;
    }
  };

  return { sortTable, currentSortOrder: readonly(currentSortOrder) };
};
