<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useSiteResponseStatusChartMetaData } from "@/composables/useSiteStatusMetaData";
import { BlueColor } from "@/types/_generated/api";
import { ORDERED_SITE_RESPONSE_STATUSES } from "@/views/UserWeb/RecTracking/constants/orderedSiteResponseStatuses";
import BaseChart from "@/components/base/BaseChart/BaseChart.vue";
import { BlueRatingStatusColor } from "@/components/base/BaseChart/constants/colors";
import { BORDER, Y_AXIS_STYLE } from "@/components/base/BaseChart/constants/style";
import { truncateLabel, tooltipFormatter } from "@/components/base/BaseChart/helpers/";
import { SiteResponseColorCount } from "../types";

const props = defineProps<{
  siteResponseStatusColors: SiteResponseColorCount;
}>();

const { t } = useI18n({ useScope: "global" });
const { statusData } = useSiteResponseStatusChartMetaData();
const BLUE_RATINGS: BlueColor[] = Object.values(BlueColor);

const colors = computed(() => {
  return BLUE_RATINGS.reduce(
    (colors, color) => {
      colors[color] = BlueRatingStatusColor[color];
      return colors;
    },
    {} as Record<BlueColor, string>,
  );
});

const labels = computed(() =>
  ORDERED_SITE_RESPONSE_STATUSES.map((status) => statusData[status].label),
);

const generateSeriesData = (categorizedData: SiteResponseColorCount): number[][] => {
  // Initialize a 2D array with dimensions based on the number of colors and response statuses
  const data: number[][] = Array.from({ length: BLUE_RATINGS.length }, () =>
    Array(ORDERED_SITE_RESPONSE_STATUSES.length).fill(0),
  );

  // Loop through each site response status
  ORDERED_SITE_RESPONSE_STATUSES.forEach((status, statusIndex) => {
    BLUE_RATINGS.forEach((color, colorIndex) => {
      data[colorIndex][statusIndex] = categorizedData[status][color];
    });
  });

  return data;
};

const seriesData = generateSeriesData(props.siteResponseStatusColors);

// Rotate labels depending on the number of labels and the screen width
const rotateLabels = BLUE_RATINGS.length > 10 || window.innerWidth < 1536;

const option = {
  legend: { selectedMode: true, type: "scroll" },
  grid: {
    top: 50,
    left: 35,
    right: 50,
    bottom: 0,
    containLabel: true,
  },
  yAxis: {
    type: "value",
    name: t("userWeb.recommendationTracking.siteResponseBlueRatingChart.yAxisName"),
    ...Y_AXIS_STYLE,
  },
  xAxis: {
    type: "category",
    data: labels.value,
    axisLabel: {
      rotate: rotateLabels ? -45 : 0,
      formatter: (value: string) => {
        if (rotateLabels) {
          return truncateLabel(value, 20);
        }
        return value;
      },
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: tooltipFormatter,
  },
  series: BLUE_RATINGS.map((label, index) => ({
    name: label,
    type: "bar",
    stack: "total",
    data: seriesData[index],
    itemStyle: {
      color: colors.value[label as keyof typeof colors.value],
      borderColor: BORDER.COLOR,
      borderWidth: BORDER.WIDTH,
    },
  })),
};
</script>

<template>
  <BaseChart :option="option" />
</template>
