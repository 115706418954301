<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Tab, useTabs } from "@/composables/useTabs";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { getModule, updateModule } from "@/services/modules";
import { ModuleDto } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import { useModuleUsers } from "../../../composables/useModuleUsers";
import ModuleEditDrawerBlueRating from "./ModuleEditDrawerBlueRating.vue";
import ModuleEditDrawerEmailTemplates from "./ModuleEditDrawerEmailTemplates.vue";
import ModuleEditDrawerGeneral from "./ModuleEditDrawerGeneral.vue";
import ModuleEditDrawerLossScenarios from "./ModuleEditDrawerLossScenarios.vue";
import ModuleEditDrawerSiteDefinitions from "./ModuleEditDrawerSiteDefinitions.vue";
import ModuleEditDrawerUserWeb from "./ModuleEditDrawerUserWeb.vue";

enum ModuleEditTab {
  General = "General",
  SiteDefinition = "SiteDefinition",
  BlueRating = "BlueRating",
  UserWeb = "UserWeb",
  EmailTemplates = "EmailTemplates",
  LossScenarioDefinitions = "LossScenarioDefinitions",
}

const props = defineProps<{
  moduleId: number;
}>();

const emits = defineEmits<{ (event: "close"): void }>();
const v$ = useVuelidate({ $autoDirty: true });
const { t } = useI18n({ useScope: "global" });
const { setCompareBase, discardUnsavedChanges } = useUnsavedChanges();

const isLoading = ref(false);
const module = ref<ModuleDto | null>();

const { contactUsers, accountEngineerUsers, fetchUsers } = useModuleUsers();

onBeforeMount(async () => {
  isLoading.value = true;

  module.value = await getModule(props.moduleId);

  if (!module.value) {
    isLoading.value = false;
    return;
  }

  await fetchUsers(module.value);

  setCompareBase(module);

  isLoading.value = false;
});

const closeDrawer = async () => {
  if (await discardUnsavedChanges(module)) {
    emits("close");
  }
};

const save = async () => {
  v$.value.$validate();

  if (anyErrors.value || !module.value) {
    return;
  }

  await updateModule(module.value);

  setCompareBase(module);
};

const hasErrors = (instanceName: string): boolean =>
  !!v$.value?.$getResultsForChild(instanceName)?.$errors.length;

const anyErrors = computed(
  (): boolean =>
    [
      "edit-module-general",
      "edit-module-blue-rating",
      "edit-module-user-web",
      "edit-module-email-templates",
      "edit-module-loss-scenarios",
      "edit-module-site-definitions",
    ].some((instanceName) => hasErrors(instanceName)) ||
    [1, 2, 3, 4].some((i) => hasErrors(`edit-module-site-definition-${i}`)) ||
    [1, 2, 3, 4, 5].some((i) => hasErrors(`edit-module-loss-scenario-${i}`)),
);

const tabs = computed<Tab<ModuleEditTab>[]>(() => [
  {
    label: t("blueRating.recommendation.general"),
    name: ModuleEditTab.General,
    icon: hasErrors("edit-module-general") ? "warning" : undefined,
  },
  {
    label: t("modules.siteDefinition"),
    name: ModuleEditTab.SiteDefinition,
    icon: [
      "edit-module-site-definitions",
      ...[1, 2, 3, 4].map((i) => `edit-module-site-definition-${i}`),
    ].some((instanceName) => hasErrors(instanceName))
      ? "warning"
      : undefined,
  },
  {
    label: t("modules.moduleSettingsBlueRating"),
    name: ModuleEditTab.BlueRating,
    icon: hasErrors("edit-module-blue-rating") ? "warning" : undefined,
  },
  {
    label: t("modules.userWeb"),
    name: ModuleEditTab.UserWeb,
    icon: hasErrors("edit-module-user-web") ? "warning" : undefined,
  },
  {
    label: t("modules.emailTemplates"),
    name: ModuleEditTab.EmailTemplates,
    icon: hasErrors("edit-module-email-templates") ? "warning" : undefined,
  },
  {
    label: t("modules.lossScenarioDefinitions"),
    name: ModuleEditTab.LossScenarioDefinitions,
    icon:
      hasErrors("edit-module-loss-scenarios") ||
      [1, 2, 3, 4, 5].some((i) => hasErrors(`edit-module-loss-scenario-${i}`))
        ? "warning"
        : undefined,
  },
]);

const { activeTab, changeTab } = useTabs(tabs.value, ModuleEditTab.General);
</script>

<template>
  <BaseDrawer
    :width="'auto'"
    :is-loading="isLoading"
    :title="t('modules.editModule')"
    class="edit-module-drawer"
    data-test="module-edit-drawer"
    @close="closeDrawer"
  >
    <BaseDrawerTabs
      :current-tab="activeTab"
      :tabs="tabs"
      data-test="module-tabs"
      @change="changeTab"
    />

    <div v-if="module" class="edit-module-drawer__container">
      <div v-show="activeTab === ModuleEditTab.General">
        <ModuleEditDrawerGeneral
          :module="module"
          :contacts="contactUsers"
          :account-engineers="accountEngineerUsers"
          @update:module="module = $event"
        />
      </div>

      <div v-show="activeTab === ModuleEditTab.SiteDefinition">
        <ModuleEditDrawerSiteDefinitions :module="module" @update:module="module = $event" />
      </div>

      <div v-show="activeTab === ModuleEditTab.BlueRating">
        <ModuleEditDrawerBlueRating :module="module" @update:module="module = $event" />
      </div>

      <div v-show="activeTab === ModuleEditTab.UserWeb">
        <ModuleEditDrawerUserWeb :module="module" @update:module="module = $event" />
      </div>

      <div v-show="activeTab === ModuleEditTab.EmailTemplates">
        <ModuleEditDrawerEmailTemplates :module="module" @update:module="module = $event" />
      </div>

      <div v-show="activeTab === ModuleEditTab.LossScenarioDefinitions">
        <ModuleEditDrawerLossScenarios :module="module" @update:module="module = $event" />
      </div>
    </div>

    <template #footer-left>
      <BaseButton :disabled="anyErrors" data-test="module-edit-save-button" @click="save">
        {{ t("common.actions.save") }}
      </BaseButton>

      <BaseLabel v-if="anyErrors" class="edit-module-drawer__footer-warning">
        {{ t("common.fixValidationErrorsBeforeSave") }}
      </BaseLabel>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.edit-module-drawer {
  @include grid-columns(1);
  min-width: max(1000px, 40vw);
  max-width: max(1000px, 40vw);

  &__container {
    padding: $spacing-4;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }

  &__footer-warning {
    margin-right: $spacing-4;
  }
}
</style>
