import { useI18n } from "vue-i18n";

export const useSiteResponseStatusTranslations = () => {
  const { t } = useI18n({ useScope: "global" });

  const translations = {
    NotSet: t("common.statuses.NotSet"),
    NoProgress: t("common.statuses.NoProgress"),
    UnderReview: t("common.statuses.UnderReview"),
    Completed: t("common.statuses.Completed"),
    Rejected: t("common.statuses.Rejected"),
    InProgress: t("common.statuses.InProgress"),
  };

  return {
    translations,
  };
};
