<script setup lang="ts">
import { computed, toRef } from "vue";
import { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { useSiteResponseStatusChartMetaData } from "@/composables/useSiteStatusMetaData";
import { BlueColor, SiteResponseStatus } from "@/types/_generated/api";
import { BlueRatingStatusColor } from "@/components/base/BaseChart/constants/colors";
import { useTrackedRecsGroupedBySiteResponseStatus } from "../composables/useTrackedRecsGroupedBySiteResponseStatus";
import { ORDERED_SITE_RESPONSE_STATUSES } from "../constants/orderedSiteResponseStatuses";
import RecTrackingStackBarChart from "./RecTrackingStackBarChart.vue";

const props = defineProps<{
  chartData: TrackedRecommendation[];
}>();

const { statusData } = useSiteResponseStatusChartMetaData();
const { trackedRecsGroupedBySiteResponseStatus } = useTrackedRecsGroupedBySiteResponseStatus(
  toRef(props.chartData),
);

const BLUE_RATINGS: BlueColor[] = Object.values(BlueColor);

const seriesColors = computed(() => {
  return BLUE_RATINGS.reduce(
    (colors, color) => {
      colors[color] = BlueRatingStatusColor[color];
      return colors;
    },
    {} as Record<string, string>,
  );
});

const chartLabels = computed(() =>
  ORDERED_SITE_RESPONSE_STATUSES.map((status) => statusData[status].label),
);

const generateSeriesData = (
  categorizedData: Record<SiteResponseStatus, TrackedRecommendation[]>,
  siteResponseStatuses: SiteResponseStatus[],
  blueRatingArray: BlueColor[],
): number[][] => {
  // Initialize a 2D array with dimensions based on the number of colors and response statuses
  const data: number[][] = Array.from({ length: BLUE_RATINGS.length }, () =>
    Array(siteResponseStatuses.length).fill(0),
  );

  // Loop through each site response status
  siteResponseStatuses.forEach((status, statusIndex) => {
    // Get the array of tracked recommendations for the current site response status
    // If there are no tracked recommendations for this status, use an empty array
    const dataForStatus = categorizedData[status] || [];

    // Loop through each tracked recommendation for the current site response status
    dataForStatus.forEach((item) => {
      // Find the index of the recommendation's color in the blueRatingArray
      const ratingIndex = blueRatingArray.indexOf(item.color);

      // If the color is found in the blueRatingArray, increment the corresponding element in the data array
      if (ratingIndex !== -1) {
        data[ratingIndex][statusIndex]++;
      }
    });
  });

  return data;
};

const seriesData = generateSeriesData(
  trackedRecsGroupedBySiteResponseStatus.value,
  ORDERED_SITE_RESPONSE_STATUSES,
  BLUE_RATINGS,
);
</script>

<template>
  <RecTrackingStackBarChart
    :labels="chartLabels"
    :colors="seriesColors"
    :series="BLUE_RATINGS"
    :data="seriesData"
  />
</template>
