<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDrawerState } from "@/composables/useDrawerState";
import { useTableFilter } from "@/composables/useTableFilter";
import { enumToOptions } from "@/helpers/mappers/enumToOptions";
import { binaryOptions } from "@/helpers/options";
import { getModulePermissions } from "@/services/permissions";
import { ModulePermissionDto, Role } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseGridTableEntityLinkHeader from "@/components/base/BaseGridTable/components/BaseGridTableEntityLinkHeader.vue";
import UserLink from "@/components/shared/UserLink.vue";
import UserDetailsDrawer from "../../Users/components/UserDetailsDrawer/UserDetailsDrawer.vue";

const props = defineProps<{ moduleId: number }>();

const { t } = useI18n({ useScope: "global" });

const rows = ref<ModulePermissionDto[]>([]);
const isLoading = ref(false);

onBeforeMount(async () => {
  isLoading.value = true;

  rows.value = (await getModulePermissions(props.moduleId)) || [];

  isLoading.value = false;
});

const columns = computed(() => {
  const columns: ColumnDefinition<ModulePermissionDto>[] = [
    new ColumnDefinition({ title: t("common.name"), key: "userName" }),
    new ColumnDefinition({
      title: t("common.role"),
      key: "userRole",
      searchOptions: enumToOptions(Role),
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("users.createSite"),
      key: "createSite",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("users.createSurvey"),
      key: "createSurvey",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("users.editSurvey"),
      key: "editSurvey",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("users.uploadStdRec"),
      key: "uploadStdRec",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("users.editQuestionnaire"),
      key: "editQuestionnaire",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
    new ColumnDefinition({
      title: t("common.actions.uploadFiles"),
      key: "uploadFiles",
      type: ColumnDataType.Boolean,
      searchOptions: binaryOptions,
      autoWidth: true,
    }),
  ];

  return columns;
});

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(rows, columns);

const {
  closeDrawer: closeUserDetailDrawer,
  drawerData: userId,
  openDrawer: openUserDetailDrawer,
  showDrawer: showUserDetailDrawer,
} = useDrawerState<number>();
</script>

<template>
  <BaseGridTable
    :rows="result.result"
    :total-row-count="result.all.length"
    :columns="columns"
    :is-loading="isLoading"
    storage-key="modulePermissionsTable"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #header-userName="{ column }">
      <BaseGridTableEntityLinkHeader :title="column.title" :label="column.title" />
    </template>

    <template #column-userName="{ row }">
      <UserLink
        :label="row.userName"
        :user-id="row.userId"
        :user-role="row.userRole"
        @click="openUserDetailDrawer"
      />
    </template>
  </BaseGridTable>

  <UserDetailsDrawer
    v-if="showUserDetailDrawer && userId"
    :user-id="userId"
    @close="closeUserDetailDrawer"
  />
</template>
