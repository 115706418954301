<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { isAxiosError } from "axios";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import http from "@/helpers/http";
import { AppRouteName } from "@/router/RouteName";
import { handleError } from "@/services/shared/handleError";
import AuthWrapper from "@/views/Auth/components/AuthWrapper.vue";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import PasswordForm from "@/components/shared/PasswordForm.vue";

const { t } = useI18n({ useScope: "global" });

const password1 = ref("");
const password2 = ref("");
const isLoading = ref(false);
const isSuccess = ref(false);
const isExpiredToken = ref(false);

const props = defineProps<{
  email: string;
  token: string;
}>();

const v$ = useVuelidate();
const router = useRouter();

const resetPassword = async (event: Event) => {
  event.preventDefault();

  if (v$.value.$invalid) {
    return;
  }

  try {
    await http.post(`auth/reset-password`, {
      email: props.email,
      token: props.token,
      password: password1.value,
    });

    isSuccess.value = true;
  } catch (error) {
    if (isAxiosError(error) && error.status === 403) {
      isExpiredToken.value = true;
    } else {
      handleError(error);
    }
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <AuthWrapper class="view-reset-password" :heading="t('common.resetPassword')">
    <form v-if="!isSuccess" class="view-reset-password__form" @submit="resetPassword">
      <PasswordForm v-model:password1="password1" v-model:password2="password2" />

      <BaseButton type="submit" :disabled="isLoading">
        {{ t("common.actions.confirm") }}
      </BaseButton>
    </form>

    <div v-if="isExpiredToken">
      <BaseAlert severity="error">
        <p>
          {{ t("signIn.passwordReset.expiredToken") }}
        </p>
      </BaseAlert>

      <BaseButton @click="router.push({ name: AppRouteName.Auth })">
        {{ t("signIn.return") }}
      </BaseButton>
    </div>

    <div v-if="isSuccess" class="view-reset-password__success">
      <BaseAlert severity="success">
        <p>
          {{ t("signIn.newPasswordSuccess") }}
        </p>
      </BaseAlert>

      <BaseButton @click="router.push({ name: AppRouteName.Auth })">
        {{ t("signIn.return") }}
      </BaseButton>
    </div>
  </AuthWrapper>
</template>

<style scoped lang="scss">
.view-reset-password {
  .base-alert,
  .base-textfield {
    margin-bottom: $spacing-4;
  }

  &__alert {
    &__description {
      margin-bottom: $spacing-2;
    }

    li {
      list-style-position: inside;
      list-style-type: square;
      margin-bottom: $spacing-2;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__success {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
</style>
