<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { languages } from "@/helpers/options";
import { Language } from "@/types/_generated/api";
import { OptionValue } from "@/types/Option";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const { t } = useI18n({ useScope: "global" });

interface Props {
  value: Language;
  errors?: ErrorObject[];
}

withDefaults(defineProps<Props>(), { errors: () => [] });

const emit = defineEmits<{
  (event: "update:value", option: Language): void;
}>();
const onUpdate = (value: OptionValue) => emit("update:value", value as Language);
</script>

<template>
  <BaseSelect
    data-test="user-language-select"
    :aria-label="t('common.language')"
    :label="t('common.language')"
    :value="value"
    @change="onUpdate($event)"
  >
    <BaseOption v-for="option in languages" :key="option.value" :value="option.value">
      {{ option.title }}
    </BaseOption>
  </BaseSelect>
</template>
