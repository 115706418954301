<script setup lang="ts">
import { reactive, type DeepReadonly } from "vue";
import { useI18n } from "vue-i18n";
import type { SitesData } from "@/composables/services/useGetSitesData";
import type { CustomDefinitionKey, CustomDefinitionNumber } from "@/types/SiteDefinitions";
import { LossScenarioDto, SurveyType } from "@/types/_generated/api";
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDivider from "@/components/base/BaseDivider.vue";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";
import ModFilterDateRange from "@/components/mods/Filter/DateRange/ModFilterDateRange.vue";
import ModFilterSiteDefinitions from "@/components/mods/Filter/SiteDefinitions/ModFilterSiteDefinitions.vue";
import ModFilterSiteDefinitionsGroupBy from "@/components/mods/Filter/SiteDefinitions/ModFilterSiteDefinitionsGroupBy.vue";
import ModFilterSurveyType from "@/components/mods/Filter/SurveyType/ModFilterSurveyType.vue";
import ModMultiselect from "@/components/mods/Multiselect/ModMultiselect.vue";
import type { DaatClientFilter } from "../composables/useFilterClient";
import { DaatServerFilter } from "../composables/useFilterServer";
import { FilterChartOptions } from "../constants/FilterChartOptions";
import DAATFilterLossScenarios from "./DAATFilterLossScenarios.vue";

const props = defineProps<{
  sitesData: DeepReadonly<SitesData>;
  clientFilter: DaatClientFilter;
  serverFilter: DaatServerFilter;
  showLossScenarios: boolean;
  lossScenarios: LossScenarioDto[];
}>();

const emit = defineEmits<{
  closeDrawer: [void];
  "update:server-filter": [DaatServerFilter];
  "update:client-filter": [DaatClientFilter];
}>();

const { t } = useI18n({ useScope: "global" });

const pendingServerFilter = reactive<DaatServerFilter>({
  ...props.serverFilter,
  excludedSiteDefinitions: { ...props.serverFilter.excludedSiteDefinitions },
});

const pendingClientFilter = reactive<DaatClientFilter>({ ...props.clientFilter });

const updateExcludedSiteDefinitions = (key: CustomDefinitionKey, value: Set<string>) => {
  pendingServerFilter.excludedSiteDefinitions[key] = Array.from(value);
};

const applyFilter = (closeDrawer: () => void) => {
  emit("update:client-filter", pendingClientFilter);
  emit("update:server-filter", pendingServerFilter);
  closeDrawer();
};
</script>

<template>
  <UWDrawer size="small" :drawer-title="t('common.filter')" @close-drawer="$emit('closeDrawer')">
    <div class="daat-filter-drawer">
      <div class="daat-filter-drawer__row">
        <ModFilterSurveyType
          :selected-survey-type="pendingServerFilter.surveyType || SurveyType.WEXT"
          @update:survey-type="pendingServerFilter.surveyType = $event"
        />
        <ModFilterSiteDefinitionsGroupBy
          use-label
          :site-definitions="sitesData"
          :value="pendingServerFilter.groupBySiteDefinition as CustomDefinitionNumber"
          @change="pendingServerFilter.groupBySiteDefinition = $event"
        />
      </div>

      <BaseDivider />

      <ModFilterDateRange
        :start-date="pendingServerFilter.surveyDateMin ?? ''"
        :end-date="pendingServerFilter.surveyDateMax ?? ''"
        @update:start-date="pendingServerFilter.surveyDateMin = $event"
        @update:end-date="pendingServerFilter.surveyDateMax = $event"
      />

      <BaseDivider />

      <template v-if="showLossScenarios">
        <BaseAccordion :label="t('userWeb.lossScenarios')" expanded-by-default>
          <DAATFilterLossScenarios
            :selected-options="pendingServerFilter.lossScenarioIds"
            :loss-scenarios="lossScenarios"
            @update:selected="pendingServerFilter.lossScenarioIds = $event"
          />
        </BaseAccordion>

        <BaseDivider />
      </template>

      <BaseAccordion :label="t('userWeb.daat.filter.chartOptions')">
        <ModMultiselect
          :options="[
            { label: t('userWeb.daat.filter.autoscaleYaxis'), value: FilterChartOptions.Autoscale },
            {
              label: t('userWeb.daat.filter.percentageYaxis'),
              value: FilterChartOptions.Percentage,
            },
          ]"
          :selected-options="pendingClientFilter.selectedChartOptions"
          @update:selected="pendingClientFilter.selectedChartOptions = new Set($event)"
        />
      </BaseAccordion>

      <BaseDivider />
      <ModFilterSiteDefinitions
        :sites-data="sitesData"
        :excluded-sets="{
          custom1: new Set(pendingServerFilter.excludedSiteDefinitions.custom1),
          custom2: new Set(pendingServerFilter.excludedSiteDefinitions.custom2),
          custom3: new Set(pendingServerFilter.excludedSiteDefinitions.custom3),
          custom4: new Set(pendingServerFilter.excludedSiteDefinitions.custom4),
        }"
        @update:excluded-sets="updateExcludedSiteDefinitions"
      />
    </div>

    <template #footer="{ onCloseDrawer }">
      <BaseButton @click="applyFilter(onCloseDrawer)">
        {{ t("userWeb.filter.apply") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="onCloseDrawer">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </UWDrawer>
</template>

<style scoped lang="scss">
.daat-filter-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-4;
  }
}
</style>
