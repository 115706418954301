import { AxiosError, AxiosResponse } from "axios";
import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import {
  CreateSurvey,
  GetLastPublishedSurvey,
  GetLastPublishedSurveyResponse,
  GetSurvey,
  GetSurveyEmailTemplateResponse,
  GetSurveyFilesResponse,
  GetSurveyReportMailHistoryResponse,
  GetSurveyResponse,
  ReportMailDto,
  SendPreSurveyEmail,
  SendSurveyReportMail,
  SurveyDto,
  SurveyEmail,
  SurveyLeanDto,
  UpdateSurveySettings,
  UploadSurveyFile,
} from "@/types/_generated/api";
import { BaseCRUDMessage, BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

export const surveyEvents = new BroadcastService<BaseCRUDMessage>("surveys");

const { t } = i18n.global;

export const getSurveys = async (siteId: number): Promise<SurveyLeanDto[] | null> => {
  try {
    const {
      data: { surveys },
    } = await http.get<{ surveys: SurveyLeanDto[] }>(`/sites/${siteId}/surveys`);
    return surveys;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("surveys.surveys"),
    });
  }
  return null;
};

// ("/v1/sites/{SiteId}/email-templates/{Type}", "GET")
export const getSurveyEmailTemplate = async (
  siteId: number,
  type: SurveyEmail,
): Promise<GetSurveyEmailTemplateResponse | null> => {
  try {
    const response = await http.get<GetSurveyEmailTemplateResponse>(
      `/sites/${siteId}/email-templates/${type}`,
    );

    return response.data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("surveys.surveyEmailTemplate"),
    });
    return null;
  }
};

export const createSurvey = async (settings: CreateSurvey): Promise<number | null> => {
  try {
    const response = await http.post(`/surveys/`, settings);

    const createdSurveyId = response.data.surveyId;

    notify.success(t("surveys.survey"), t("common.created").toLowerCase());

    surveyEvents.post({ action: "create" });

    return createdSurveyId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("surveys.survey"),
    });
    return null;
  }
};

export const updateSurveySettings = async (settings: UpdateSurveySettings): Promise<boolean> => {
  try {
    await http.put(`/surveys/${settings.surveyId}`, settings);

    notify.success(t("survey.surveySettings"), t("common.updated").toLowerCase());
    surveyEvents.post({ action: "update" });

    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update").toLowerCase(),
      entity: t("survey.surveySettings"),
    });
  }
  return false;
};

export const sendPreSurveyEmail = async (settings: SendPreSurveyEmail): Promise<number | null> => {
  try {
    const response = await http.post(`/surveys/${settings.surveyId}/pre-survey-mail`, settings);

    if (response) {
      const createdPreSurveyMail = response.data;
      notify.success(
        t("survey.preSurveyEmail.preSurveyEmail"),
        t("common.actions.send").toLowerCase(),
      );

      return createdPreSurveyMail;
    } else {
      return null;
    }
  } catch (error) {
    handleError(error, {
      action: t("common.actions.send").toLowerCase(),
      entity: t("survey.preSurveyEmail.preSurveyEmail"),
    });
    return null;
  }
};
//surveys/{SurveyId}/files
export const getSurveyFiles = async (surveyId: number): Promise<GetSurveyFilesResponse | null> => {
  try {
    const { data } = await http.get<GetSurveyFilesResponse>(`/surveys/${surveyId}/files`);

    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: "documents",
    });
  }

  return null;
};

// surveys/{SurveyId}/files/{Id}
export const getSurveyReport = async (
  surveyId: number,
  fileId: string,
): Promise<GetSurveyFilesResponse | null> => {
  try {
    const respons = await http.get<GetSurveyFilesResponse>(`/surveys/${surveyId}/files/${fileId}`);

    if (respons) {
      const document = respons.data;
      return document || null;
    }
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("survey.uploadReport.file"),
    });
  }

  return null;
};

export const uploadSurveyReport = async (settings: UploadSurveyFile): Promise<number | null> => {
  try {
    const response = await http.post(`/surveys/${settings.surveyId}/files/${settings.type}`, {
      file: settings.file,
    });

    if (response) {
      const res = response.data;
      notify.success("Document", t("common.actions.uploaded").toLowerCase());

      return res;
    } else {
      return null;
    }
  } catch (error) {
    handleError(error, {
      action: t("common.actions.upload").toLowerCase(),
      entity: t("survey.uploadReport.file"),
    });
    return null;
  }
};
//surveys/{SurveyId}/files/{FileId}
export const deleteSurveyReport = async (surveyId: number, fileId: string) => {
  try {
    await http.delete(`/surveys/${surveyId}/files/${fileId}`);
    surveyEvents.post({ action: "delete" });
  } catch (error) {
    handleError(error, {
      action: t("common.actions.delete").toLowerCase(),
      entity: t("survey.uploadReport.file"),
    });
  }
};

export const sendSurveyEmail = async (settings: SendSurveyReportMail): Promise<number | null> => {
  try {
    const response = await http.post(`/surveys/${settings.surveyId}/send-report`, settings);

    if (response) {
      const createdSurveyReportMail = response.data;
      notify.success(t("survey.surveyReportMail.success"), t("common.actions.send").toLowerCase());

      return createdSurveyReportMail;
    } else {
      return null;
    }
  } catch (error) {
    handleError(error, {
      action: t("common.actions.send").toLowerCase(),
      entity: t("survey.surveyReportMail.surveyReportMail"),
    });
    return null;
  }
};

export const toggleSurveyReportMailIsActiveService = async (
  surveyId: number,
  reportMailId: number,
  isActive: boolean,
): Promise<void> => {
  try {
    await http.post(`/surveys/${surveyId}/report-mail/${reportMailId}`, { isActive });
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update").toLowerCase(),
      entity: t("survey.surveyReportMail.surveyReportMail"),
    });
  }
};

export const getSurveyReportMails = async (surveyId: number): Promise<ReportMailDto[] | null> => {
  try {
    const response = await http.get<GetSurveyReportMailHistoryResponse>(
      `/surveys/${surveyId}/report-mails`,
    );

    return response.data.history;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("survey.surveyReportMail.surveyReportMail"),
    });
  }

  return null;
};

export const requestSpoeCheck = async (surveyId: number): Promise<boolean> => {
  try {
    await http.post(`/surveys/${surveyId}/request-spoe-check`);
    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.request").toLowerCase(),
      entity: t("survey.spoeCheck"),
    });
    return false;
  }
};

export const getLastPublishedSurveyService = async (siteId: number): Promise<SurveyDto | null> => {
  try {
    const {
      data: { survey },
    } = await http.get<
      GetLastPublishedSurveyResponse,
      AxiosResponse<GetLastPublishedSurveyResponse, GetLastPublishedSurvey>
    >(`/sites/${siteId}/surveys/last-published`);

    return survey;
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 404) {
      return null;
    }
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("surveys.survey"),
    });
  }

  return null;
};

export const getSurveyService = async (surveyId: number): Promise<SurveyDto | null> => {
  try {
    const {
      data: { survey },
    } = await http.get<GetSurveyResponse, AxiosResponse<GetSurveyResponse, GetSurvey>>(
      `/surveys/${surveyId}`,
    );

    return survey;
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 404) {
      return null;
    }
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("surveys.survey"),
    });
  }

  return null;
};
