<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { toPercent } from "@/helpers/formatNumber";
import { BlueLossValueResultDto } from "@/types/_generated/api";
import BaseChart from "@/components/base/BaseChart/BaseChart.vue";
import { Y_AXIS_STYLE } from "@/components/base/BaseChart/constants/style";
import { DaatClientFilter } from "../composables/useFilterClient";
import { FilterChartOptions } from "../constants/FilterChartOptions";

interface Props {
  isLoading: boolean;
  currencyCode: string;
  data: BlueLossValueResultDto[];
  clientFilter: DaatClientFilter;
  lossScenarioName: string;
}

const { isLoading, currencyCode, data, clientFilter, lossScenarioName } = defineProps<Props>();

const { t } = useI18n({ useScope: "global" });

const boldText = (text: string) => `<b>${text}</b>`;

const tooltipFormatter = (params: { name: string; value: number[]; seriesName: string }) => {
  let base = boldText(params.name);

  if (params.seriesName.length > 0 && params.seriesName !== params.name) {
    base += boldText(` (${params.seriesName})`);
  }

  const score = clientFilter.selectedChartOptions.has(FilterChartOptions.Percentage)
    ? toPercent(params.value[1], false, 2)
    : `${params.value[1]} p`;

  base += `<br />
           <br />
           ${t("userWeb.daat.lossValuesChart.score")}: ${boldText(score)}`;

  base += `<br />
           ${t("userWeb.daat.lossValuesChart.lossValues")} (${currencyCode}): 
           <b>${params.value[0]}</b>`;

  return base;
};

const option = computed(() => ({
  grid: {
    left: 50,
    right: 50,
    containLabel: true,
  },
  tooltip: {
    trigger: "item",
    formatter: tooltipFormatter,
  },
  legend: {
    show: true,
    type: "scroll",
  },
  xAxis: {
    type: "value",
    name: `${t("userWeb.daat.lossValuesChart.lossValues")} (${currencyCode})`,
    axisLabel: {
      formatter: `${currencyCode} {value}`,
    },
    nameLocation: "middle",
    nameGap: 40,
    scale: clientFilter.selectedChartOptions.has(FilterChartOptions.Autoscale),
  },
  yAxis: {
    type: "value",
    interval: clientFilter.selectedChartOptions.has(FilterChartOptions.Percentage) ? 10 : null,
    name: t("userWeb.daat.lossValuesChart.score"),
    axisLabel: {
      formatter: clientFilter.selectedChartOptions.has(FilterChartOptions.Percentage)
        ? "{value} %"
        : "{value} p",
    },
    scale: !!clientFilter.selectedChartOptions.has(FilterChartOptions.Autoscale),
    ...Y_AXIS_STYLE,
  },
  series: [
    ...data.map((br) => ({
      data: br.dataPoints.map((dp) => ({
        name: dp.custom1,
        value: [
          dp.lossValue,
          clientFilter.selectedChartOptions.has(FilterChartOptions.Percentage)
            ? dp.scorePercent
            : dp.score,
        ],
      })),
      name: br.group,
      type: "scatter",
      symbolSize: 15,
    })),
  ],
}));
</script>

<template>
  <div class="daat-loss-chart__title">{{ lossScenarioName }}</div>
  <BaseChart class="daat-loss-chart" :option="option" :is-loading="isLoading" />
</template>

<style scoped lang="scss">
.daat-loss-chart {
  height: 40rem;

  &__title {
    margin-left: $spacing-12;
    font-size: $text-lg;
    font-weight: $font-bold;
  }
}
</style>
