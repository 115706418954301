<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import type { RecommendationTrackingFilter } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import RecTracking from "@/components/shared/RecTracking/RecTracking.vue";
import { useRecommendationTrackingColumns } from "./composables/useSiteRecTrackingColumns";

const props = defineProps<{
  isLoading: boolean;
  activeFilter: RecommendationTrackingFilter;
  moduleId: number;
  moduleCurrency: string;
  trackedRecommendations: TrackedRecommendation[];
}>();

defineEmits<{
  "open-filter": [void];
  "apply-filter": [RecommendationTrackingFilter];
}>();

const { t } = useI18n({ useScope: "global" });
const { columns } = useRecommendationTrackingColumns();

const totalCost = computed(() =>
  props.trackedRecommendations.reduce(
    (total, recommendation) => total + (recommendation.siteResponseCostToComplete ?? 0),
    0,
  ),
);
</script>

<template>
  <div class="view-site-rec-tracking">
    <RecTracking
      :columns="columns"
      :is-loading="isLoading"
      :tracked-recommendations="trackedRecommendations"
      @apply-filter="$emit('apply-filter', $event)"
    >
      <template #control-actions>
        <div class="view-site-rec-tracking__top">
          <div class="view-site-rec-tracking__top__cost">
            <BaseLabel>{{ t("userWeb.siteRecommendationTracking.totalCost") }}:</BaseLabel>
            <p class="view-site-rec-tracking__top__cost__value">
              {{ `${totalCost} ${moduleCurrency}` }}
            </p>
          </div>

          <BaseButton
            variant="outlined"
            size="medium"
            :disabled="isLoading"
            @click="$emit('open-filter')"
          >
            <BaseIcon icon="filter" size="medium" />
          </BaseButton>
        </div>
      </template>
    </RecTracking>
  </div>
</template>

<style scoped lang="scss">
.view-site-rec-tracking {
  display: flex;
  flex-direction: column;

  &__top {
    display: flex;
    align-items: center;
    gap: $spacing-8;

    &__cost {
      display: flex;
      gap: $spacing-1;
      font-size: $text-sm;

      &__value {
        color: $primary-7;
      }
    }
  }
}
</style>
