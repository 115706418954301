<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { getIconForFileType } from "@/helpers/getIconForFileType";
import { getLanguage } from "@/helpers/getLanguage";
import { Language } from "@/types/_generated/api";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseFileDeleteButton from "./BaseFileDeleteButton.vue";
import BaseFileLink from "./BaseFileLink.vue";
import BaseFileTags from "./BaseFileTags.vue";

const { t } = useI18n({ useScope: "global" });

export interface Props {
  file: { fileId: string; fileName: string; language?: Language; tags?: string[] };
  fileUrl: string;
  edit?: boolean;
}

defineProps<Props>();

defineEmits<{
  (e: "delete", fileId: string): void;
}>();
</script>

<template>
  <div class="base-file-attachment">
    <BaseIcon :icon="getIconForFileType(file.fileName)" size="medium" />

    <div class="base-file-attachment__content">
      <BaseFileLink
        :file-url="fileUrl"
        :file-name="file.fileName"
        class="base-file-attachment__content__link"
      />

      <small v-if="file?.language">
        {{ t("survey.preSurveyEmail.language", { language: getLanguage(file.language) }) }}
      </small>

      <BaseFileTags :tags="file.tags" />
    </div>

    <BaseFileDeleteButton v-if="edit" @delete="$emit('delete', file.fileId)" />
  </div>
</template>

<style scoped lang="scss">
.base-file-attachment {
  display: flex;
  gap: $spacing-2;
  align-items: center;
  background-color: $primary-2;
  border-radius: $rounded-base;
  border: 1px solid $primary-4;
  padding: $spacing-2 $spacing-3;
  font-size: $text-sm;
  line-height: $leading-none;
  width: max-content;

  &__content {
    display: flex;
    flex-direction: column;
    gap: $spacing-1;

    &__link {
      overflow-wrap: anywhere;
    }
  }
}
</style>
