<script setup lang="ts">
import { computed, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { SortOrder } from "@/constants/SortOrder";
import { formatDate } from "@/helpers/formatDate";
import { sortByKeys } from "@/helpers/sort";
import {
  deleteRecommendationResponseFile,
  getRecommendationResponse,
  updateRecommendationResponse,
} from "@/services/recommendationResponse";
import {
  RecommendationResponseDto,
  RecommendationRiskManagerCommentDto,
} from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseDateField from "@/components/base/BaseDateField.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import EditIconButton from "../EditIconButton.vue";
import { useRecommendationResponse } from "./composables/useRecommendationResponse";
import { useRiskManagerComment } from "./composables/useRiskManagerComment";
import ResponseFileList from "./ResponseFileList.vue";
import SiteResponseStatusDropdown from "./SiteResponseStatusDropdown.vue";

type TimelineEntry = {
  comment: RecommendationRiskManagerCommentDto | null;
  response: RecommendationResponseDto | null;
  timestamp: string;
};

const props = defineProps<{
  moduleId: number;
  canEdit?: boolean;
  responseHistory: RecommendationResponseDto[];
  riskManagerCommentHistory: RecommendationRiskManagerCommentDto[];
}>();

const emit = defineEmits<{
  "update:response": [response: RecommendationResponseDto];
}>();

const showCompleteHistory = ref(false);
const editCopy = ref<RecommendationResponseDto | null>(null);

const { t } = useI18n({ useScope: "global" });
const { lockedResponses, filteredResponses } = useRecommendationResponse(
  toRef(() => props.responseHistory),
);
const { lockedComments } = useRiskManagerComment(toRef(() => props.riskManagerCommentHistory));

const timeline = computed(() => {
  const responses: TimelineEntry[] = (
    showCompleteHistory.value ? lockedResponses.value : filteredResponses.value
  ).map((r) => ({
    comment: null,
    response: r,
    timestamp: r.updateDate,
  }));
  const comments: TimelineEntry[] = lockedComments.value.map((c) => ({
    comment: c,
    response: null,
    timestamp: c.insertDate,
  }));
  return sortByKeys([...responses, ...comments], {
    key: "timestamp",
    order: SortOrder.Descending,
  }).toReversed();
});

const beginEditing = (response: RecommendationResponseDto) => (editCopy.value = { ...response });

const cancelEditing = () => (editCopy.value = null);

const refreshFiles = async () => {
  if (!editCopy.value) {
    return;
  }

  const updatedResponse = await getRecommendationResponse(props.moduleId, editCopy.value.id);
  if (!updatedResponse) {
    return;
  }

  const newFile = updatedResponse.files.find(
    (f) => !editCopy.value!.files.some((ef) => ef.fileId === f.fileId),
  );
  if (!newFile) {
    return;
  }

  editCopy.value.files.push(newFile!);
};

const saveEditChanges = async () => {
  if (!editCopy.value) {
    return;
  }

  const originalResponse = await getRecommendationResponse(props.moduleId, editCopy.value.id);
  if (!originalResponse) {
    return;
  }

  const deletedFiles = originalResponse.files.filter(
    (f) => !editCopy.value!.files.some((ef) => ef.fileId === f.fileId),
  );

  for (const file of deletedFiles) {
    await deleteRecommendationResponseFile(props.moduleId, editCopy.value.id, file.fileId);
  }

  await updateRecommendationResponse(props.moduleId, {
    recommendationResponseId: editCopy.value.id,
    moduleId: props.moduleId,
    ...editCopy.value,
  });

  if (editCopy.value) {
    emit("update:response", editCopy.value);
    editCopy.value = null;
  }
};
</script>

<template>
  <div class="response-and-comment-timeline">
    <div v-if="!timeline.length" class="response-and-comment-timeline">
      <p>{{ t("recommendationResponse.noHistory") }}</p>
    </div>
    <div v-if="timeline.length" class="response-and-comment-timeline__header">
      <BaseCheckbox
        :checked="showCompleteHistory"
        :label="t('recommendationResponse.showCompleteHistory')"
        @change="showCompleteHistory = !showCompleteHistory"
      />
    </div>
    <div v-if="timeline.length" class="response-and-comment-timeline__entries">
      <div
        v-for="(entry, i) in timeline"
        :key="i"
        class="response-and-comment-timeline__entry"
        :class="{
          'response-and-comment-timeline__entry--right': !!entry.comment,
          'response-and-comment-timeline__entry--left': !!entry.response,
        }"
      >
        <div class="response-and-comment-timeline__entry__header">
          {{ entry.comment?.email || entry.response?.updateBy }}
          {{ formatDate.asDateAndTime(entry.timestamp) }}
        </div>
        <div class="response-and-comment-timeline__entry__content">
          <div v-if="entry.comment">
            {{ entry.comment.comment }}
          </div>

          <template v-if="entry.response && editCopy?.id !== entry.response.id">
            <div class="response-and-comment-timeline__entry__content__flex">
              <div>
                <BaseStatusBadge v-if="entry.response.status" :status="entry.response.status" />
              </div>
              <EditIconButton
                v-if="canEdit"
                :title="t('common.actions.edit')"
                @click="beginEditing(entry.response)"
              />
            </div>
            <div v-if="entry.response.comment">
              {{ entry.response.comment }}
            </div>
            <div v-if="entry.response.dueDate">
              {{ t("recommendationResponse.dueDate") }}:
              {{ formatDate.asDateOnly(entry.response.dueDate) }}
            </div>
            <div v-if="entry.response.costToComplete">
              {{ t("recommendationResponse.costToComplete") }}: {{ entry.response.costToComplete }}
            </div>
            <div v-if="entry.response.responsiblePerson">
              {{ t("recommendationResponse.responsiblePerson") }}:
              {{ entry.response.responsiblePerson }}
            </div>
            <ResponseFileList :module-id="moduleId" :response="entry.response" :can-edit="false" />
          </template>

          <template v-if="entry.response && editCopy?.id === entry.response.id">
            <div>
              <SiteResponseStatusDropdown
                :status="editCopy.status"
                @update="editCopy.status = $event"
              />
            </div>
            <div>
              <BaseTextArea
                :label="t('recommendationResponse.comment')"
                :value="editCopy.comment"
                @update:value="editCopy.comment = $event ?? undefined"
              ></BaseTextArea>
            </div>
            <div>
              <BaseDateField
                :label="t('recommendationResponse.dueDate')"
                :value="editCopy.dueDate"
                @update:value="editCopy.dueDate = $event ?? undefined"
              />
            </div>
            <div>
              <BaseTextField
                :label="t('recommendationResponse.costToComplete')"
                :value="editCopy.costToComplete"
                :type="'number'"
                @update:value="
                  editCopy.costToComplete =
                    typeof $event === 'string' ? Number($event) : ($event ?? undefined)
                "
              />
            </div>
            <div>
              <BaseTextField
                :label="t('recommendationResponse.responsiblePerson')"
                :value="editCopy.responsiblePerson"
                @update:value="
                  editCopy.responsiblePerson = typeof $event !== 'string' ? undefined : $event
                "
              />
            </div>
            <ResponseFileList
              :module-id="moduleId"
              :response="editCopy"
              :can-edit="true"
              @new="refreshFiles"
              @delete="editCopy.files = editCopy.files.filter((f) => f.fileId !== $event)"
            />
            <div class="response-and-comment-timeline__entry__content__flex">
              <BaseButton :variant="'outlined'" @click="cancelEditing">
                {{ t("common.actions.cancel") }}
              </BaseButton>
              <BaseButton @click="saveEditChanges">{{ t("common.actions.save") }}</BaseButton>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.response-and-comment-timeline {
  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $spacing-4;
  }

  &__entries {
    display: flex;
    flex-direction: column;
  }

  &__entry {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-4;
    width: 55%;

    &--right {
      align-self: flex-end;
    }

    &--left {
      align-self: flex-start;
    }

    &__header {
      font-style: italic;
      font-size: $text-xs;
      color: $primary-6;
      margin-bottom: $spacing-1;
    }

    &--right &__content {
      background-color: $info-2;
    }
    &__content {
      padding: $spacing-2;
      border-radius: $rounded-base;
      background-color: $primary-2;
      display: flex;
      flex-direction: column;
      gap: $spacing-2;

      &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
