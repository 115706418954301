import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import { ModuleSettingsDto, Status, SurveyDto } from "@/types/_generated/api";
import { emailRegex } from "@/validation/i18n-validators";
import { SurveyParticipant } from "../components/SurveyEditDrawer/SurveyEditDrawerParticipants.vue";
import { SurveySettings } from "./useInitializeSurveySettings";

export type ValidatedSurveySettings = "surveyDate" | "spoeCheckerUserId" | "status";

export type SurveySettingsValidationState = Record<ValidatedSurveySettings, string> & {
  participants: {
    [key: number]: {
      email: SurveyParticipant["email"];
      mobile: SurveyParticipant["mobile"];
    };
  };
};

const validationState = reactive({
  status: "",
  surveyDate: "",
  spoeCheckerUserId: "",
  participants: {},
}) as SurveySettingsValidationState;

export const useValidateSurveySettings = () => {
  const { t } = useI18n({ useScope: "global" });

  const validateSurveySettings = (
    settings: SurveySettings,
    moduleSettings: ModuleSettingsDto,
    survey?: SurveyDto,
  ) => {
    if (moduleSettings.requireSpoeCheck && !settings.spoeCheckerUserId) {
      validationState.spoeCheckerUserId = t("validations.requiresSpoeChecker");
    }

    if (!settings.surveyDate) {
      validationState.surveyDate = t("validations.requiresSurveyDate");
    }

    // Can only set status to Published if SPOE check is approved when required
    if (
      moduleSettings.requireSpoeCheck &&
      settings.status === Status.Published &&
      (!survey || survey.isSpoeCheckApproved !== true)
    ) {
      validationState.status = t("validations.requiresSpoeCheckApproved");
    }

    for (let i = 0; i < settings.participants.length; i++) {
      const participant = settings.participants[i];
      const participantValidationState = validationState.participants[i] || {};

      // Check if email is non-empty before validating length
      const trimmedEmail = participant.email.trim();
      if (trimmedEmail.length > 150) {
        participantValidationState.email = t("validations.maxLength", {
          length: 150,
        });
      }
      // Check if email is non-empty before validating its format
      if (trimmedEmail.length > 0) {
        const isValidEmail = new RegExp(emailRegex).test(trimmedEmail);

        if (!isValidEmail) {
          participantValidationState.email = t("validations.email");
        }
      }

      // Check if mobile is non-empty before validating its format
      if (participant.mobile.trim()) {
        const isValidMobile = new RegExp(/^\+\d{4,}$/).test(participant.mobile);

        if (!isValidMobile) {
          participantValidationState.mobile = t("validations.mobile");
        }
      }

      validationState.participants[i] = participantValidationState;
    }
  };

  const hasValidationError = () => {
    const flattenedErrors = [
      validationState.surveyDate,
      validationState.spoeCheckerUserId,
      validationState.status,
      ...Object.values(validationState.participants)
        .map((p) => [p.email, p.mobile])
        .flat(),
    ];

    return flattenedErrors.some((error) => !!error);
  };

  const resetValidationStateFor = (key: ValidatedSurveySettings | "participants") => {
    if (key === "participants") {
      validationState.participants = {};
    } else {
      validationState[key] = "";
    }
  };

  return {
    hasValidationError,
    validationState,
    validateSurveySettings,
    resetValidationStateFor,
  };
};
