import { Ref, computed } from "vue";
import { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";

export const useUniqueCategoryNames = (trackedRecommendations: Ref<TrackedRecommendation[]>) => {
  const categoryIdToNameMap = computed<Map<number, string>>(() => {
    const map = new Map<number, string>();

    trackedRecommendations.value.forEach(({ categoryId, categoryName }) => {
      if (categoryId && !map.has(categoryId)) {
        map.set(categoryId, categoryName);
      }
    });

    return map;
  });

  return {
    categoryIdToNameMap,
  };
};
