<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { formatDate } from "@/helpers/formatDate";
import { getLanguage } from "@/helpers/getLanguage";
import { useAppStore } from "@/stores/app";
import { LoginType, UserDto } from "@/types/_generated/api";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });
const app = useAppStore();

defineProps<{
  user: UserDto;
}>();
</script>

<template>
  <div class="user-details-general">
    <BaseDataDisplayList :title="t('common.account')">
      <BaseDataDisplayListItem :label="t('users.loginType')">
        <p>{{ user.loginType }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :label="t('common.email')">
        <p>{{ user.email }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem
        v-if="user.loginType !== LoginType.Blue"
        :label="t('common.username')"
      >
        <p>{{ user.userName }}</p>
      </BaseDataDisplayListItem>
    </BaseDataDisplayList>

    <BaseDataDisplayList :title="t('users.access')">
      <BaseDataDisplayListItem :label="t('common.role')">
        <p>{{ user.role }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem v-if="app.isAdmin" :label="t('users.allowUserWeb')">
        <BaseIcon icon="check" />
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem
        :label="t('users.allowRmComment')"
        :description="t('users.allowRmCommentDescription')"
      >
        <BaseIcon v-if="user.allowRmComment" icon="check" />
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :label="t('common.archived')">
        <BaseIcon v-if="user.isArchived" icon="check" />
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem v-if="app.isAdmin" :label="t('users.lockedOut')">
        <p>{{ formatDate.asDateAndTime(user.lockedDate) }}</p>
      </BaseDataDisplayListItem>
    </BaseDataDisplayList>

    <BaseDataDisplayList v-if="app.isAdmin" :title="t('users.editHistory')">
      <BaseDataDisplayListItem :label="t('users.lastLogin')">
        <p>{{ formatDate.asDateOnly(user.lastLogin) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem v-if="user.insert" :label="t('common.createdBy')">
        <p>{{ user.insert.name }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem v-if="user.insert" :label="t('common.createdAt')">
        <p>{{ formatDate.asDateOnly(user.insert.date) }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem v-if="user.update" :label="t('common.updatedBy')">
        <p>{{ user.update.name }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem v-if="user.update" :label="t('common.updatedAt')">
        <p>{{ formatDate.asDateOnly(user.update.date) }}</p>
      </BaseDataDisplayListItem>
    </BaseDataDisplayList>

    <BaseDataDisplayList :title="t('users.personalDetails')">
      <BaseDataDisplayListItem :label="t('common.name')">
        <p>{{ user.firstName }} {{ user.lastName }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :label="t('common.phone')">
        <p>{{ user.phoneNumber }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :label="t('common.mobile')">
        <p>{{ user.mobilePhoneNumber }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :label="t('common.language')">
        <p>{{ getLanguage(user.language) }}</p>
      </BaseDataDisplayListItem>
    </BaseDataDisplayList>

    <BaseDataDisplayList :title="t('users.location')">
      <BaseDataDisplayListItem :label="t('common.country')">
        <p>{{ user.country }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :label="t('common.city')">
        <p>{{ user.city }}</p>
      </BaseDataDisplayListItem>
    </BaseDataDisplayList>

    <BaseDataDisplayList :title="t('users.company')">
      <BaseDataDisplayListItem :label="t('common.name')">
        <p>{{ user.company }}</p>
      </BaseDataDisplayListItem>
      <BaseDataDisplayListItem :label="t('common.title')">
        <p>{{ user.title }}</p>
      </BaseDataDisplayListItem>
    </BaseDataDisplayList>
  </div>
</template>

<style scoped lang="scss">
.user-details-general {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
}
</style>
