export const downloadFile = (url: string, fileName?: string) => {
  // Create a temporary link element
  const link = document.createElement("a");
  link.href = url;

  link.download = fileName ?? url.substring(url.lastIndexOf("/") + 1);

  // Append the link to the document body
  document.body.appendChild(link);

  // Trigger the click event on the link
  link.click();

  // Remove the link from the document body
  document.body.removeChild(link);
};
