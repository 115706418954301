<script setup lang="ts">
import { ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { LossScenarioDto, ModuleDto } from "@/types/_generated/api";
import { useModuleValidation } from "@/views/Admin/Modules/composables/useModuleValidation";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseButtonRow from "@/components/base/BaseButtonRow.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import ModuleEditLossScenario from "./ModuleEditLossScenario.vue";

const props = defineProps<{
  module: ModuleDto;
}>();

defineEmits<{
  (event: "update:module", module: ModuleDto): void;
}>();

const { t } = useI18n({ useScope: "global" });

const { lossScenariosValidation } = useModuleValidation();

const { v$ } = useVuelidate(
  lossScenariosValidation,
  toRef(() => props.module),
  {
    $registerAs: "edit-module-loss-scenarios",
    $autoDirty: true,
  },
);

const showSelectTemplate = ref<boolean>(false);

const templates: LossScenarioDto[] = [
  {
    name: t("modules.lossScenarioTemplates.cle.name"),
    propertyDamage: t("modules.lossScenarioTemplates.cle.pd"),
    businessInterruption: t("modules.lossScenarioTemplates.cle.bi"),
  },
  {
    name: t("modules.lossScenarioTemplates.dle.name"),
    propertyDamage: t("modules.lossScenarioTemplates.dle.pd"),
    businessInterruption: t("modules.lossScenarioTemplates.dle.bi"),
  },
  {
    name: t("modules.lossScenarioTemplates.nle.name"),
    propertyDamage: t("modules.lossScenarioTemplates.nle.pd"),
    businessInterruption: t("modules.lossScenarioTemplates.nle.bi"),
  },
  {
    name: t("modules.lossScenarioTemplates.ole.name"),
    propertyDamage: t("modules.lossScenarioTemplates.ole.pd"),
    businessInterruption: t("modules.lossScenarioTemplates.ole.bi"),
  },
];
</script>

<template>
  <div
    class="edit-module-drawer-scenario"
    :class="{
      'edit-module-drawer-scenario--show-template': showSelectTemplate,
    }"
  >
    <div class="edit-module-drawer-scenario__form">
      <ModuleEditLossScenario
        v-for="(lossScenario, i) in module.lossScenarios"
        :key="i"
        :loss-scenario="lossScenario"
        :index="i"
        data-test="module-loss-scenario"
        @update:scenario="
          $emit('update:module', {
            ...module,
            lossScenarios: module.lossScenarios.map((s, j) => (j === i ? $event : s)),
          })
        "
        @delete:scenario="
          $emit('update:module', {
            ...module,
            lossScenarios: module.lossScenarios.filter((_, j) => j !== i),
          })
        "
      />

      <div v-if="v$?.lossScenarios?.$errors?.length">
        <p
          v-for="({ $message }, i) in v$.lossScenarios.$errors"
          :key="i"
          class="edit-module-drawer-scenario__error"
        >
          {{ $message }}
        </p>
      </div>

      <BaseButtonRow v-if="module.lossScenarios.length < 5">
        <BaseButton
          class="edit-module__add-button-row--button"
          variant="outlined"
          data-test="module-loss-scenario-add"
          @click="
            $emit('update:module', {
              ...module,
              lossScenarios: [
                ...module.lossScenarios,
                { name: '', propertyDamage: '', businessInterruption: '' },
              ],
            })
          "
        >
          <template #icon>
            <BaseIcon icon="plus" />
          </template>
          {{ t("modules.addLossScenario") }}
        </BaseButton>

        <BaseButton
          class="edit-module__add-button-row--button"
          variant="outlined"
          data-test="module-loss-scenario-add-template"
          @click="showSelectTemplate = true"
        >
          <BaseIcon icon="plus" />
          {{ t("modules.addFromTemplate") }}
        </BaseButton>
      </BaseButtonRow>
    </div>

    <div v-if="showSelectTemplate" class="edit-module-drawer-scenario__templates">
      <BaseButton variant="outlined" @click="() => (showSelectTemplate = false)">
        <BaseIcon icon="arrow-left" />
        {{ t("modules.backToTable") }}
      </BaseButton>

      <ModuleEditLossScenario
        v-for="(lossScenario, i) in templates"
        :key="i"
        :is-template-view="true"
        :loss-scenario="lossScenario"
        :index="i"
        @select:scenario="
          showSelectTemplate = false;
          $emit('update:module', {
            ...module,
            lossScenarios: [...module.lossScenarios, $event],
          });
        "
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.edit-module-drawer-scenario {
  overflow-x: visible;
  position: relative;
  display: grid;
  grid-template-columns: 100% 100%;
  gap: $spacing-5;
  transition-duration: 200ms;
  transition-property: transform;
  transition-timing-function: ease-out;

  &__error {
    color: $error-4;
    line-height: $leading-normal;
    margin-top: $spacing-1;
  }

  &__templates {
    display: none;
  }
  &--show-template {
    transform: translateX(calc(-100% - $spacing-5));
  }
  &--show-template &__templates {
    display: block;
  }
}
</style>
