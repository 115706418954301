import { reactive } from "vue";
import { isDeepEqual } from "@/helpers/isDeepEqual";
import { IDataAnalysisFilter } from "@/types/_generated/api";

export interface DaatServerFilter extends IDataAnalysisFilter {
  lossScenarioIds: Set<number>;
}

export const useFilterServer = (onUpdateFilter: () => Promise<void>) => {
  const serverFilter = reactive<DaatServerFilter>({
    surveyType: undefined,
    surveyDateMin: undefined,
    surveyDateMax: undefined,
    groupBySiteDefinition: undefined,
    lossScenarioIds: new Set<number>(),
    excludedSiteDefinitions: {
      custom1: [],
      custom2: [],
      custom3: [],
      custom4: [],
    },
  });

  const updateServerFilter = async (newFilter: DaatServerFilter) => {
    // If the filter has not changed, do nothing
    if (isDeepEqual(serverFilter, newFilter)) return;

    // Update the filter state with the incoming new values
    Object.assign(serverFilter, newFilter);

    await onUpdateFilter();
  };

  return {
    serverFilter,
    updateServerFilter,
  };
};
