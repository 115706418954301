<script setup lang="ts">
import { requiredIf } from "@vuelidate/validators";
import { Ref, computed, onBeforeMount, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import type { CustomDefinitionNumber } from "@/types/SiteDefinitions";
import { Tab, useTabs } from "@/composables/useTabs";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { toDegreesMinutesAndSeconds } from "@/helpers/coordinateConverter";
import { authorize } from "@/plugins/can";
import { getModule, getSiteDefinitionSuggestions } from "@/services/modules";
import { getSite, sitesEvents, updateSite } from "@/services/sites";
import {
  ModuleDto,
  Role,
  SiteDefinitionSuggestionsDto,
  SiteDto,
  Status,
  UpdateSite,
} from "@/types/_generated/api";
import { between, maxLength } from "@/validation/i18n-validators";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import BaseImage from "@/components/base/BaseImage.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseMap from "@/components/base/BaseMap/BaseMap.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import { useLocationLookup } from "../../composables/useLocationLookup";
import { SiteStatus, siteStatusOptions } from "../../constants/SiteStatusOptions";
import SiteEditDefinitions from "./SiteEditDefinitions.vue";
import SiteEditNextSurvey from "./SiteEditNextSurvey.vue";
import SiteEditReportImage from "./SiteEditReportImage.vue";

enum SiteEditTab {
  FacilityInformation = "FacilityInformation",
  SiteDefinitions = "SiteDefinitions",
  NextSurvey = "NextSurvey",
  ReportImage = "ReportImage",
}

const props = defineProps<{
  siteId: number;
  moduleId: number;
}>();

const emit = defineEmits<{ (event: "close"): void }>();
const { t } = useI18n({ useScope: "global" });

export type SiteDefinitionProperty = `moduleSiteDefinition${CustomDefinitionNumber}`;

const site = ref<SiteDto | null>();
const module = ref<ModuleDto | null>(null);
const isLoading = ref(false);
const siteDefinitionSuggestions = ref<SiteDefinitionSuggestionsDto[]>([]);

const { getAddressFromLocation, getLocationFromAddress } = useLocationLookup();

const isSiteDefinitionRequired = (customDefinitionNumber: CustomDefinitionNumber) => {
  const isPublished = site.value?.status === "Published";
  const siteDefinition = module.value?.[`siteDefinition${customDefinitionNumber}`];

  return isPublished && !!siteDefinition && siteDefinition.length > 0;
};

const tabErrors = reactive({
  facilityInformation: false,
  siteDefinitions: false,
  nextSurvey: false,
  reportImage: false,
});

// VALIDATION
const validations: { [k in keyof Partial<SiteDto>]: object } = {
  address: { maxLength: maxLength(400) },
  city: { maxLength: maxLength(150) },
  country: { maxLength: maxLength(100) },
  custom1: { required: requiredIf(() => site.value!.status === "Published") },
  custom2: { required: requiredIf(() => isSiteDefinitionRequired(2)) },
  custom3: { required: requiredIf(() => isSiteDefinitionRequired(3)) },
  custom4: { required: requiredIf(() => isSiteDefinitionRequired(4)) },
  latitude: { between: between(-90, 90) },
  longitude: { between: between(-180, 180) },
  phone: { maxLength: maxLength(50) },
  poBox: { maxLength: maxLength(20) },
  web: { maxLength: maxLength(150) },
  zipCode: { maxLength: maxLength(20) },
};

const { v$ } = useVuelidate(validations, site as Readonly<Ref<SiteDto>>, {
  $autoDirty: true,
});

onBeforeMount(async () => {
  isLoading.value = true;

  const [siteTemp, moduleTemp, suggestions] = await Promise.all([
    getSite(props.siteId),
    getModule(props.moduleId),
    getSiteDefinitionSuggestions(props.moduleId),
  ]);

  if (!siteTemp || !moduleTemp) {
    emit("close");
    return;
  }

  site.value = siteTemp;
  module.value = moduleTemp;
  siteDefinitionSuggestions.value = suggestions || [];

  isLoading.value = false;

  checkTabErrors();
});

// SAVE
const saveSite = async () => {
  if (!site.value) {
    return;
  }

  if (anyErrors.value) {
    return;
  }

  const formData: UpdateSite = {
    custom1: site.value.custom1 || "",
    custom2: site.value.custom2 || "",
    custom3: site.value.custom3 || "",
    custom4: site.value.custom4 || "",
    status: site.value.status as Status,
    surveyorId: site.value.surveyorId,
    nextSurveyDate: site.value.nextSurveyDate,
    address: site.value.address || "",
    city: site.value.city || "",
    country: site.value.country || "",
    latitude: site.value.latitude,
    longitude: site.value.longitude,
    phone: site.value.phone || "",
    poBox: site.value.poBox || "",
    zipCode: site.value.zipCode || "",
    web: site.value.web || "",
  } as UpdateSite;

  v$.value.$validate();

  if (v$.value.$invalid) {
    return;
  }

  await updateSite(formData, module.value!.moduleId, props.siteId);
};

const siteStatusOption = computed(() => {
  if (authorize.hasRole(Role.SystemOwner, Role.SA, Role.BA)) {
    return siteStatusOptions;
  }

  return siteStatusOptions.filter((status) => status.value !== SiteStatus.Archived);
});

const updateSiteDefinitions = (event: { siteDefinition: string; value: string }) => {
  site.value = {
    ...site.value!,
    [event.siteDefinition]: event.value,
  };
};

const hasErrors = (instanceName: string): boolean =>
  !!v$.value?.$getResultsForChild(instanceName)?.$errors.length;

const tabs = computed((): Tab<SiteEditTab>[] => [
  {
    label: t("sites.facilityInformation"),
    name: SiteEditTab.FacilityInformation,
  },
  {
    label: t("sites.siteDefinitions.title"),
    name: SiteEditTab.SiteDefinitions,
    icon: hasErrors("site-definitions") || tabErrors.siteDefinitions ? "warning" : undefined,
  },
  {
    label: t("sites.nextSurvey"),
    name: SiteEditTab.NextSurvey,
  },
  { label: t("sites.reportImageUpload"), name: SiteEditTab.ReportImage },
]);

const { activeTab, changeTab } = useTabs(tabs.value, SiteEditTab.FacilityInformation);

const anyErrors = computed(
  (): boolean =>
    ["site-definitions"].some((instanceName) => hasErrors(instanceName)) ||
    [1, 2, 3, 4].some((i) => hasErrors(`SiteDefinition-${i}`)),
);

const setAddressFromCoordinates = async () => {
  if (!site.value || !site.value.latitude || !site.value.longitude) {
    return;
  }

  const address = await getAddressFromLocation(site.value.latitude, site.value.longitude);

  if (address) {
    site.value.address = address.addressLine ? address.addressLine : site.value.address;
    site.value.city = address.locality ? address.locality : site.value.city;
    site.value.country = address.countryRegion ? address.countryRegion : site.value.country;
    site.value.zipCode = address.postalCode ? address.postalCode : site.value.zipCode;
  }
};

const setCoordinatesFromAddress = async () => {
  if (!site.value) {
    return;
  }

  const location = await getLocationFromAddress(
    site.value.address,
    site.value.city,
    site.value.country,
    site.value.zipCode,
  );

  if (location) {
    site.value.latitude = location.latitude;
    site.value.longitude = location.longitude;
  }
};

const updateReportImage = (fileId: string | undefined) => {
  if (site.value && fileId) {
    site.value.reportImageFileId = fileId;
    sitesEvents.post({ action: "update", id: props.siteId });
  }
};

const checkTabErrors = () => {
  const isCustom1Invalid = !!(v$.value?.custom1?.$validate && site.value?.custom1?.length === 0);

  if (v$.value?.custom1?.$pending) {
    return;
  }
  tabErrors.siteDefinitions = isCustom1Invalid;
};

watch(
  () => site.value?.custom1,
  (newVal) => {
    if (newVal && newVal.length > 0) {
      tabErrors.siteDefinitions = false;
    } else {
      checkTabErrors();
    }
  },
);
</script>

<template>
  <BaseDrawer :is-loading="isLoading" width="50" @close="$emit('close')">
    <template #title>
      <div class="edit-site-drawer__header">
        {{ t("sites.editSiteDrawer.editSite") }}
        <BaseStatusBadge
          v-if="site"
          :status="site.status"
          :title="t(`common.statuses.${site.status}`)"
        />
      </div>
    </template>

    <BaseDrawerTabs :current-tab="activeTab" :tabs="tabs" @change="changeTab" />

    <BaseCard v-show="activeTab === SiteEditTab.FacilityInformation" has-padding>
      <template v-if="site">
        <div class="edit-site-drawer__site-information">
          <BaseTextField
            v-model:value="site.address"
            :label="t('sites.address')"
            :errors="v$.address?.$errors"
          />
          <BaseTextField
            v-model:value="site.poBox"
            :label="t('sites.poBox')"
            :errors="v$.poBox?.$errors"
          />
          <BaseTextField
            v-model:value="site.zipCode"
            :label="t('sites.zipCode')"
            :errors="v$.zipCode?.$errors"
          />
          <BaseTextField
            v-model:value="site.city"
            :label="t('sites.city')"
            :errors="v$.city?.$errors"
          />
          <BaseTextField
            v-model:value="site.country"
            :label="t('sites.country')"
            :errors="v$.country?.$errors"
          />
          <BaseTextField
            v-model:value="site.phone"
            :label="t('sites.phone')"
            :errors="v$.phone?.$errors"
          />
          <BaseTextField
            v-model:value="site.web"
            :label="t('sites.web')"
            :errors="v$.web?.$errors"
          />
          <BaseSelect
            :label="t('sites.status')"
            :value="siteStatusOption.find((s) => s.value === site?.status)?.value"
            @change="(event) => (site!.status = event as Status)"
          >
            <BaseOption
              v-for="option in siteStatusOption"
              :key="option.value"
              :value="option.value"
            >
              {{ option.title }}
            </BaseOption>
          </BaseSelect>

          <div class="edit-site-drawer__site-information__input-pair">
            <BaseTextField
              v-model:value="site.latitude"
              type="number"
              :label="t('sites.latitude')"
              :min="-90"
              :max="90"
              :errors="v$.latitude?.$errors"
            />
            <BaseTextField
              v-model:value="site.longitude"
              type="number"
              :label="t('sites.longitude')"
              :min="-180"
              :max="180"
              :errors="v$.longitude?.$errors"
            />
          </div>
        </div>

        <div class="edit-site-drawer__site-information__input-pair-buttons">
          <BaseButton
            variant="outlined"
            :disabled="!site.latitude || !site.longitude"
            @click="setAddressFromCoordinates"
          >
            {{ t("sites.editSiteDrawer.getAddressFromCoordinates") }}
          </BaseButton>

          <BaseButton
            variant="outlined"
            :disabled="!site.address && !site.zipCode && !site.city && !site.country"
            @click="setCoordinatesFromAddress"
          >
            {{ t("sites.editSiteDrawer.getCoordinatesFromAddress") }}
          </BaseButton>
        </div>

        <div class="edit-site-drawer__site-information__coordinates">
          <BaseLabel>
            {{ t("sites.coordinates") }}
          </BaseLabel>
          {{
            site.latitude && site.longitude
              ? (toDegreesMinutesAndSeconds(site.latitude, site.longitude) ??
                t("map.status.invalidCoordinates"))
              : ""
          }}
        </div>
      </template>

      <BaseMap
        v-if="site"
        height="small"
        movable-pins
        :coordinates="[{ latitude: site.latitude, longitude: site.longitude }]"
        @update:pin="
          site.latitude = $event.latitude;
          site.longitude = $event.longitude;
        "
      />
    </BaseCard>

    <BaseCard v-show="activeTab === SiteEditTab.SiteDefinitions" has-padding>
      <SiteEditDefinitions
        v-if="site"
        :is-loading="isLoading"
        :site="site"
        :site-definition-suggestions="siteDefinitionSuggestions"
        @update:site-definition="updateSiteDefinitions($event)"
      />

      <div
        v-if="
          v$.$errors?.length &&
          [1, 2, 3, 4].every((i) => !site?.[`moduleSiteDefinition${i}` as SiteDefinitionProperty])
        "
      >
        <p v-for="({ $message }, i) in v$.$errors" :key="i" class="edit-site-drawer__error">
          {{ $message }}
        </p>
      </div>
    </BaseCard>

    <BaseCard v-show="activeTab === SiteEditTab.NextSurvey" has-padding>
      <SiteEditNextSurvey
        :module-id="moduleId"
        :surveyor-id="site?.surveyorId"
        :next-survey-date="site?.nextSurveyDate"
        @update:surveyor-id="site!.surveyorId = $event"
        @update:next-survey-date="site!.nextSurveyDate = $event"
      />
    </BaseCard>

    <BaseCard v-show="activeTab === SiteEditTab.ReportImage" has-padding>
      <SiteEditReportImage
        :site-id="siteId"
        :module-id="moduleId"
        :current-file-id="site?.reportImageFileId"
        @files-uploaded="updateReportImage"
      />
      <BaseImage
        v-if="site?.reportImageFileId"
        :src="`/v1/sites/${props.siteId}/files/${site?.reportImageFileId}`"
        :alt="t('sites.siteReportImage')"
        max-width="25rem"
      />
    </BaseCard>

    <template #footer-left>
      <BaseButton :disabled="tabErrors.siteDefinitions" @click="saveSite">
        {{ t("common.actions.save") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="$emit('close')">
        {{ t("common.actions.cancel") }}
      </BaseButton>
      <span v-if="tabErrors.siteDefinitions" class="edit-site-drawer__error">{{
        t("common.fixValidationErrorsBeforeSave")
      }}</span>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.edit-site-drawer {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__error {
    color: $error-4;
    line-height: $leading-normal;
    margin-top: $spacing-1;
  }

  &__site-information {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-4;
    margin-bottom: $spacing-4;

    &__coordinates {
      display: flex;
      gap: $spacing-3;
    }

    &__input-pair,
    &__input-pair-buttons {
      display: grid;
      gap: $spacing-4;
      grid-template-columns: 1fr 1fr;
    }

    &__input-pair-buttons {
      height: 2.5rem;
      align-self: end;
      margin-bottom: $spacing-4;
    }
  }
}
</style>
