<script setup lang="ts">
import { computed, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import {
  BlueRatingSurveyTab,
  useQueryNavigation,
} from "@/composables/query-navigation/useQueryNavigation";
import { isSurveyPublished } from "@/helpers/survey-helpers";
import { applyStandardRecommendation } from "@/services/recommendations";
import {
  ModuleDto,
  BlueRatingCategoryDto,
  BlueRatingDto,
  BlueRatingQuestionDto,
} from "@/types/_generated/api";
import AppOverflowContainer from "@/components/app/AppOverflowContainer.vue";
import { useBlueRatingReply } from "../composables/useBlueRatingReply";
import { useCreateRecommendationWithDefaults } from "../composables/useCreateRecommendationWithDefaults";
import BlueRatingArea from "./BlueRatingArea.vue";
import BlueRatingCategory from "./BlueRatingCategory/BlueRatingCategory.vue";
import BlueRatingCategoryColorColumn from "./BlueRatingCategory/BlueRatingCategoryColorColumn.vue";
import BlueRatingQuestion from "./BlueRatingQuestion/BlueRatingQuestion.vue";
import BlueRatingRecommendationDrawer from "./BlueRatingRecommendations/BlueRatingRecommendationDrawer.vue";
import BlueRatingRecommendationsTable from "./BlueRatingRecommendations/BlueRatingRecommendationsTable.vue";
import AddFromStandardRecommendationsTableDrawer from "./BlueRatingStandardRecommendations/AddFromStandardRecommendationsTableDrawer.vue";

const props = withDefaults(
  defineProps<{
    survey: BlueRatingDto;
    module: ModuleDto;
    tab?: BlueRatingSurveyTab;
  }>(),
  {
    tab: BlueRatingSurveyTab.Survey,
  },
);

const showRecommendationDrawer = ref(false);
const includeAllClients = ref(false);
const showCopyStandardRecDrawer = ref(false);
const hideDescription = ref<number[]>([]);

const currentRecommendationId = ref<number | null>(null);
const currentCategory = ref<BlueRatingCategoryDto | null>(null);
const currentQuestion = ref<BlueRatingQuestionDto | null>(null);

const standardRecFilter = computed(() => ({
  clientId: props.module.clientId,
  categoryHeader: currentCategory.value?.name ?? "",
  questionHeader: currentQuestion.value?.text,
  includeAllClients: includeAllClients.value,
}));

const navigation = useQueryNavigation();
const { t } = useI18n({ useScope: "global" });

const { toggleAllQuestionsNa, setQuestionValue, replySaveStates } = useBlueRatingReply(
  toRef(() => props.survey),
);

const createRecommendation = async (
  surveyId: number,
  category: BlueRatingCategoryDto | null,
  questionId: number | null,
) => await useCreateRecommendationWithDefaults(t, surveyId, category, questionId);

const createAndEditRecommendation = async (
  surveyId: number,
  category: BlueRatingCategoryDto,
  questionId: number | null,
) => {
  currentRecommendationId.value = await createRecommendation(surveyId, category, questionId);

  if (currentRecommendationId.value) {
    editRecommendation(currentRecommendationId.value);
  }
};

const createFromStandardRecommendation = async (standardRecommendationId: number) => {
  if (!currentCategory.value) {
    throw new Error("No category selected");
  }

  const { surveyId } = props.survey;

  currentRecommendationId.value = await createRecommendation(
    surveyId,
    currentCategory.value,
    currentQuestion.value?.questionId || null,
  );

  if (currentRecommendationId.value === null) {
    throw new Error("Failed to create recommendation");
  }

  await applyStandardRecommendation(
    surveyId,
    currentRecommendationId.value,
    standardRecommendationId,
  );

  closeStandardRecDrawer();

  editRecommendation(currentRecommendationId.value);
};

const closeStandardRecDrawer = () => {
  showCopyStandardRecDrawer.value = false;
  currentCategory.value = null;
  currentQuestion.value = null;
};

const openStandardRecDrawer = (
  category: BlueRatingCategoryDto,
  question: BlueRatingQuestionDto | null,
) => {
  currentCategory.value = category;
  currentQuestion.value = question;
  showCopyStandardRecDrawer.value = true;
};

const editRecommendation = (recommendationIdToEdit: number) => {
  currentRecommendationId.value = recommendationIdToEdit;
  showRecommendationDrawer.value = true;
};

const createGeneralRecommendation = async (surveyId: number) => {
  currentRecommendationId.value = await useCreateRecommendationWithDefaults(
    t,
    surveyId,
    null,
    null,
  );

  if (currentRecommendationId.value !== null) {
    editRecommendation(currentRecommendationId.value);
    showRecommendationDrawer.value = true;
  }
};
</script>

<template>
  <section class="blue-rating-survey">
    <div class="blue-rating-survey__controls">
      <div class="blue-rating-survey__tabs">
        <span
          class="blue-rating-survey__tabs__tab truncate"
          :class="{
            'blue-rating-survey__tabs__tab--active': tab === BlueRatingSurveyTab.Survey,
          }"
          @click="() => navigation.changeTab(BlueRatingSurveyTab.Survey)"
        >
          {{ t("surveys.survey") }}
        </span>

        <span
          class="blue-rating-survey__tabs__tab truncate"
          :class="{
            'blue-rating-survey__tabs__tab--active': tab === BlueRatingSurveyTab.Recommendations,
          }"
          @click="() => navigation.changeTab(BlueRatingSurveyTab.Recommendations)"
        >
          {{ t("blueRating.recommendation.recommendations") }}
        </span>
      </div>

      <div class="blue-rating-survey__controls__actions">
        <slot name="actions"></slot>
      </div>
    </div>

    <div
      v-if="!survey.areas.length || !survey.areas.flatMap((a) => a.categories).length"
      class="blue-rating-survey__not-complete-message"
    >
      {{ t("blueRating.surveyNotCompleteMessage") }}
    </div>

    <AppOverflowContainer :remove-navigation="true" :padding="'3rem'">
      <div class="blue-rating-survey__view">
        <div v-if="tab === BlueRatingSurveyTab.Survey" class="blue-rating-survey__view__survey">
          <template v-for="(area, ai) in survey?.areas" :key="ai">
            <BlueRatingArea :area="area" />

            <BlueRatingCategoryColorColumn
              v-for="(category, ci) in area.categories"
              :key="ci"
              :category="category"
            >
              <BlueRatingCategory
                :survey-id="survey.surveyId"
                :is-survey-published="isSurveyPublished(survey)"
                :category="category"
                :hide-description="hideDescription.includes(category.categoryId)"
                :reply-save-states="replySaveStates"
                @new-recommendation="createAndEditRecommendation(survey.surveyId, category, null)"
                @copy-standard-rec="openStandardRecDrawer(category, null)"
                @edit-recommendation="editRecommendation"
                @toggle-all-questions-to-na="
                  () => {
                    toggleAllQuestionsNa(category);
                    hideDescription = [...hideDescription, category.categoryId];
                  }
                "
                @toggle-description="
                  () => {
                    if (hideDescription.includes(category.categoryId)) {
                      hideDescription = hideDescription.filter((id) => id !== category.categoryId);
                    } else {
                      hideDescription = [...hideDescription, category.categoryId];
                    }
                  }
                "
              />
              <div
                v-for="(question, qi) in category.questions"
                :key="qi"
                :class="[
                  'blue-rating-survey__view__survey__question',
                  `blue-rating-survey__view__survey__question--${question.value?.toLowerCase()}`,
                ]"
              >
                <BlueRatingQuestion
                  :survey-id="survey.surveyId"
                  :category="category"
                  :read-only="isSurveyPublished(survey)"
                  :question="question"
                  :show-description="!hideDescription.includes(category.categoryId)"
                  :set-reply-save-state="replySaveStates[question.questionId] || 'idle'"
                  @new-recommendation="
                    createAndEditRecommendation(survey.surveyId, category, question.questionId)
                  "
                  @copy-standard-rec="openStandardRecDrawer(category, question)"
                  @edit-recommendation="editRecommendation"
                  @update:value="setQuestionValue($event.questionId, $event.value)"
                />
              </div>
            </BlueRatingCategoryColorColumn>
          </template>
        </div>

        <BlueRatingRecommendationsTable
          v-if="tab === BlueRatingSurveyTab.Recommendations"
          :survey="survey"
          :module="module"
          @create-general-recommendation="createGeneralRecommendation(survey.surveyId)"
          @edit-recommendation="
            (id: number) => {
              currentRecommendationId = id;
              showRecommendationDrawer = true;
            }
          "
        />
      </div>
    </AppOverflowContainer>
  </section>

  <BlueRatingRecommendationDrawer
    v-if="showRecommendationDrawer && currentRecommendationId"
    :survey="survey"
    :recommendation-id="currentRecommendationId"
    :module="module"
    @close="showRecommendationDrawer = false"
  />

  <AddFromStandardRecommendationsTableDrawer
    v-if="showCopyStandardRecDrawer"
    :filter="standardRecFilter"
    @close="closeStandardRecDrawer"
    @insert="createFromStandardRecommendation"
    @update:include-all-clients="includeAllClients = $event"
  />
</template>

<style scoped lang="scss">
.blue-rating-survey {
  display: grid;
  grid-template-rows: auto 1fr;
  max-height: 100%;
  height: 100%;
  overflow: hidden;

  &__not-complete-message {
    width: fit-content;
    height: fit-content;
    padding: $spacing-4;
    font-size: $text-2xl;
  }

  &__view {
    padding: $spacing-4;

    &__survey__question {
      margin-left: $spacing-12;
    }
  }

  &__controls {
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $primary-2;
    border-bottom: 1px solid $primary-3;

    &__actions {
      display: flex;
      align-items: center;
      gap: $spacing-4;
      padding: 0 $spacing-4;
    }
  }

  &__tabs {
    display: flex;

    &__tab {
      font-size: $text-sm;
      font-weight: $font-medium;
      padding: $spacing-4;
      min-width: 10rem;
      text-align: center;
      cursor: pointer;
      border-top: 1px solid $primary-4;
      border-right: 1px solid $primary-4;

      &:first-of-type {
        border-left: 1px solid $primary-4;
      }

      &--active {
        background-color: $primary-1;
        color: $secondary-4;
      }
    }
  }
}
</style>
