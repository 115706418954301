import { computed, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import type { Option } from "@/types/Option";
import { RecSortOrder } from "@/types/_generated/api";
import type { SectionsDto, SectionKeys } from "../types";
import { FieldType } from "../constants";

type FormSectionField<T extends keyof SectionsDto> = {
  key: keyof SectionsDto[T];
  label: string;
  type: FieldType;
  options?: Option[];
  enableImages?: boolean;
  isCustom?: boolean;
};

type FormStructure = { [K in keyof SectionsDto]: FormSectionField<K>[] };

export const useFormStructure = (selectedSectionKey: Ref<SectionKeys>) => {
  const { t } = useI18n({ useScope: "global" });

  const formStructure: FormStructure = {
    coverPageSection: [
      {
        key: "showBlueStripe",
        label: t("module.recReportSettings.formStructure.coverPage.blueStripe"),
        type: FieldType.Checkbox,
      },
      {
        key: "showSiteImage",
        label: t("module.recReportSettings.formStructure.coverPage.siteImage"),
        type: FieldType.Checkbox,
      },
    ],
    importantNoticeSection: [
      {
        key: "title",
        label: t("module.recReportSettings.formStructure.common.title"),
        type: FieldType.Text,
      },
      {
        key: "text",
        label: t("module.recReportSettings.formStructure.common.text"),
        type: FieldType.Editor,
        enableImages: false,
      },
    ],
    blueRatingSection: [
      {
        key: "showScore",
        label: t("module.recReportSettings.formStructure.blueRating.score"),
        type: FieldType.Checkbox,
      },
      {
        key: "showScorePercent",
        label: t("module.recReportSettings.formStructure.blueRating.scorePercent"),
        type: FieldType.Checkbox,
      },
      {
        key: "title",
        label: t("module.recReportSettings.formStructure.common.title"),
        type: FieldType.Text,
      },
      {
        key: "introduction",
        label: t("module.recReportSettings.formStructure.common.introduction"),
        type: FieldType.Editor,
      },
      {
        key: "blueTitle",
        label: t("module.recReportSettings.formStructure.blueRating.blueTitle"),
        type: FieldType.Text,
      },
      {
        key: "blueText",
        label: t("module.recReportSettings.formStructure.blueRating.blueText"),
        type: FieldType.Editor,
      },
      {
        key: "greenTitle",
        label: t("module.recReportSettings.formStructure.blueRating.greenTitle"),
        type: FieldType.Text,
      },
      {
        key: "greenText",
        label: t("module.recReportSettings.formStructure.blueRating.greenText"),
        type: FieldType.Editor,
      },
      {
        key: "yellowTitle",
        label: t("module.recReportSettings.formStructure.blueRating.yellowTitle"),
        type: FieldType.Text,
      },
      {
        key: "yellowText",
        label: t("module.recReportSettings.formStructure.blueRating.yellowText"),
        type: FieldType.Editor,
      },
      {
        key: "redTitle",
        label: t("module.recReportSettings.formStructure.blueRating.redTitle"),
        type: FieldType.Text,
      },
      {
        key: "redText",
        label: t("module.recReportSettings.formStructure.blueRating.redText"),
        type: FieldType.Editor,
      },
      {
        key: "grayTitle",
        label: t("module.recReportSettings.formStructure.blueRating.grayTitle"),
        type: FieldType.Text,
      },
      {
        key: "grayText",
        label: t("module.recReportSettings.formStructure.blueRating.grayText"),
        type: FieldType.Editor,
      },
      {
        key: "closing",
        label: t("module.recReportSettings.formStructure.common.closing"),
        type: FieldType.Editor,
      },
    ],
    recSection: [
      {
        key: "recSortOrder",
        label: t("module.recReportSettings.formStructure.rec.recSortOrder.label"),
        type: FieldType.Select,
        options: [
          {
            value: RecSortOrder.AgeAscending,
            title: t("module.recReportSettings.formStructure.rec.recSortOrder.ageAscending"),
          },
          {
            value: RecSortOrder.AgeDescending,
            title: t("module.recReportSettings.formStructure.rec.recSortOrder.ageDescending"),
          },
          {
            value: RecSortOrder.Grading,
            title: t("module.recReportSettings.formStructure.rec.recSortOrder.grading"),
          },
          {
            value: RecSortOrder.Questionnaire,
            title: t("module.recReportSettings.formStructure.rec.recSortOrder.questionnaire"),
          },
        ],
      },
      {
        key: "title",
        label: t("module.recReportSettings.formStructure.common.title"),
        type: FieldType.Text,
      },
      {
        key: "introduction",
        label: t("module.recReportSettings.formStructure.common.introduction"),
        type: FieldType.Editor,
        enableImages: true,
      },
      {
        key: "priorityTitle1",
        label: t("module.recReportSettings.formStructure.rec.priorityTitle1"),
        type: FieldType.Text,
      },
      {
        key: "priorityText1",
        label: t("module.recReportSettings.formStructure.rec.priorityText1"),
        type: FieldType.TextArea,
      },
      {
        key: "priorityTitle2",
        label: t("module.recReportSettings.formStructure.rec.priorityTitle2"),
        type: FieldType.Text,
      },
      {
        key: "priorityText2",
        label: t("module.recReportSettings.formStructure.rec.priorityText2"),
        type: FieldType.TextArea,
      },
      {
        key: "priorityTitle3",
        label: t("module.recReportSettings.formStructure.rec.priorityTitle3"),
        type: FieldType.Text,
      },
      {
        key: "priorityText3",
        label: t("module.recReportSettings.formStructure.rec.priorityText3"),
        type: FieldType.TextArea,
      },
      {
        key: "priorityTitle4",
        label: t("module.recReportSettings.formStructure.rec.priorityTitle4"),
        type: FieldType.Text,
      },
      {
        key: "priorityText4",
        label: t("module.recReportSettings.formStructure.rec.priorityText4"),
        type: FieldType.TextArea,
      },
      {
        key: "priorityTitle5",
        label: t("module.recReportSettings.formStructure.rec.priorityTitle5"),
        type: FieldType.Text,
      },
      {
        key: "priorityText5",
        label: t("module.recReportSettings.formStructure.rec.priorityText5"),
        type: FieldType.TextArea,
      },
      {
        key: "closing",
        label: t("module.recReportSettings.formStructure.common.closing"),
        type: FieldType.Editor,
        enableImages: true,
      },
    ],
    customSection1: [
      {
        key: "title",
        label: t("module.recReportSettings.formStructure.common.title"),
        type: FieldType.Text,
        isCustom: true,
      },
      {
        key: "text",
        label: t("module.recReportSettings.formStructure.common.text"),
        type: FieldType.Editor,
        isCustom: true,
        enableImages: true,
      },
    ],
    customSection2: [
      {
        key: "title",
        label: t("module.recReportSettings.formStructure.common.title"),
        type: FieldType.Text,
        isCustom: true,
      },
      {
        key: "text",
        label: t("module.recReportSettings.formStructure.common.text"),
        type: FieldType.Editor,
        isCustom: true,
        enableImages: true,
      },
    ],
    customSection3: [
      {
        key: "title",
        label: t("module.recReportSettings.formStructure.common.title"),
        type: FieldType.Text,
        isCustom: true,
      },
      {
        key: "text",
        label: t("module.recReportSettings.formStructure.common.text"),
        type: FieldType.Editor,
        isCustom: true,
        enableImages: true,
      },
    ],
    customSection4: [
      {
        key: "title",
        label: t("module.recReportSettings.formStructure.common.title"),
        type: FieldType.Text,
        isCustom: true,
      },
      {
        key: "text",
        label: t("module.recReportSettings.formStructure.common.text"),
        type: FieldType.Editor,
        isCustom: true,
        enableImages: true,
      },
    ],
    customSection5: [
      {
        key: "title",
        label: t("module.recReportSettings.formStructure.common.title"),
        type: FieldType.Text,
        isCustom: true,
      },
      {
        key: "text",
        label: t("module.recReportSettings.formStructure.common.text"),
        type: FieldType.Editor,
        isCustom: true,
        enableImages: true,
      },
    ],
  };

  const currentSectionData = computed(() => {
    return formStructure[selectedSectionKey.value];
  });

  return {
    currentSectionData,
  };
};
