import { readonly, ref } from "vue";
import { getSite as getSiteService } from "@/services/sites";
import { SiteDto } from "@/types/_generated/api";

export const useGetSite = () => {
  const isLoadingSite = ref(false);
  const errorFetchingSite = ref(false);
  const siteData = ref<SiteDto | null>(null);

  const getSite = async (siteId: number) => {
    isLoadingSite.value = true;
    errorFetchingSite.value = false;

    try {
      siteData.value = await getSiteService(siteId);
    } catch (error) {
      errorFetchingSite.value = true;
    } finally {
      isLoadingSite.value = false;
    }
  };

  return {
    isLoadingSite: readonly(isLoadingSite),
    errorFetchingSite,
    siteData: readonly(siteData),
    getSite,
  };
};
