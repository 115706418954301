import { Ref, computed, ref } from "vue";
import { SortOrder } from "@/constants/SortOrder";
import { Sorting, sortByKeys } from "@/helpers/sort";

export const useSortRows = <T>(rows: Ref<T[]>, defaultSort?: Sorting<T>[]) => {
  const sortKey = ref<Sorting<T>["key"]>();
  const sortOrder = ref<SortOrder>(SortOrder.None);

  const getNextSortOrder = () => {
    if (sortOrder.value === SortOrder.None) {
      return SortOrder.Descending;
    }

    return sortOrder.value == SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending;
  };

  const sortedRows = computed(() => {
    const sortings = [];

    if (sortKey.value) {
      sortings.push({
        key: sortKey.value,
        order: sortOrder.value,
      });
    }

    const effectiveDefaultSort = defaultSort ? defaultSort : [];

    if (effectiveDefaultSort.length) {
      sortings.push(...effectiveDefaultSort);
    }

    return sortByKeys(rows.value ?? [], ...sortings);
  });

  const sortRows = (key: Sorting<T>["key"]) => {
    const sortOrderNotSet = sortOrder.value === SortOrder.None || !sortKey.value;

    if (sortOrderNotSet || sortKey.value === key) {
      sortOrder.value = getNextSortOrder();
    }

    sortKey.value = key;
  };

  return {
    sortOrder,
    sortKey,
    sortedRows,
    sortRows,
  };
};
