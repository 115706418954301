<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { ExecutiveReportAreaDto, ExecutiveReportCategoryDto } from "@/types/_generated/api";
import { useCombinedAreaData } from "@/components/shared/BlueRatingTable//composables/useCombinedAreaData";
import BlueRatingTableFooter from "@/components/shared/BlueRatingTable/BlueRatingTableFooter.vue";
import BlueRatingTableFooterAreas from "@/components/shared/BlueRatingTable/BlueRatingTableFooterAreas.vue";
import type { ClientFilter } from "../../types/FilterTypes";
import { FilterColumn } from "../../constants/FilterColumn";

const props = defineProps<{
  clientFilter: ClientFilter;
  areas: ExecutiveReportAreaDto[];
  categories: ExecutiveReportCategoryDto[];
  totalAverage: number;
}>();

const { t } = useI18n({ useScope: "global" });

const combinedAreaData = useCombinedAreaData(props.areas, props.categories);

const formatTitleWithAveragePercent = (percent: string) =>
  `${percent} ${t("userWeb.blueRatingTable.average.short")} (%)`;

const showPercentage = computed(() =>
  props.clientFilter.selectedColumns.has(FilterColumn.Percentage),
);
</script>

<template>
  <BlueRatingTableFooter>
    <BlueRatingTableFooterAreas :areas="areas" :categories="categories" />

    <template v-if="showPercentage">
      <tr>
        <td data-footer-title>
          {{ formatTitleWithAveragePercent(t("userWeb.blueRatingTable.category")) }}:
        </td>
        <td v-for="(category, index) in props.categories" :key="index" data-footer-cell>
          <span data-footer-cell-content>
            <span>{{ category.scorePercentAverage }}</span>
          </span>
        </td>
      </tr>

      <tr>
        <td data-footer-title>
          {{ formatTitleWithAveragePercent(t("userWeb.blueRatingTable.area")) }}:
        </td>
        <td
          v-for="(area, index) in combinedAreaData"
          :key="index"
          :colspan="area.count"
          data-footer-cell
        >
          <span data-footer-cell-content>
            <span>{{ area.percent }}</span>
          </span>
        </td>
      </tr>

      <tr>
        <td data-footer-title>
          {{ formatTitleWithAveragePercent(t("userWeb.blueRatingTable.total")) }}:
        </td>
        <td data-footer-cell :colspan="categories.length">
          <span data-footer-cell-content>
            <span>{{ totalAverage }}</span>
          </span>
        </td>
      </tr>
    </template>
  </BlueRatingTableFooter>
</template>

<style scoped lang="scss">
@import "@/components/shared/BlueRatingTable/styles/styles";
</style>
