<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useModuleUsersOptions } from "@/composables/useModuleUsersOptions";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

interface Props {
  moduleId: number;
  surveyorId?: number;
  nextSurveyDate?: string;
}

const props = defineProps<Props>();

defineEmits<{
  "update:surveyorId": [number];
  "update:nextSurveyDate": [string];
}>();

const { moduleUserOptions } = useModuleUsersOptions(props.moduleId);
</script>

<template>
  <div class="site-edit-next-survey">
    <BaseTextField
      :label="t('sites.nextSurveyDate')"
      type="date"
      :value="nextSurveyDate"
      @change:value="$emit('update:nextSurveyDate', $event as string)"
    />

    <BaseSelect
      :label="t('sites.surveyor')"
      :value="moduleUserOptions.find((u) => u.value === surveyorId)?.value"
      @change="$emit('update:surveyorId', Number($event))"
    >
      <BaseOption v-for="option in moduleUserOptions" :key="option.value" :value="option.value">
        {{ option.title }}
      </BaseOption>
    </BaseSelect>
  </div>
</template>

<style scoped lang="scss">
.site-edit-next-survey {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
}
</style>
