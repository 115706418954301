<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { TableLegend } from "./types/types";

interface Props {
  legend: TableLegend;
}

defineProps<Props>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <caption class="blue-rating-table-legend">
    <ul>
      <li v-for="[color, description] in Object.entries(legend)" :key="color">
        <span
          :class="`blue-rating-table-legend__dot blue-rating-table-legend__dot--${color}`"
        ></span>

        {{ description || t(`blueRating.colors.${color}`) }}
      </li>
    </ul>
  </caption>
</template>

<style scoped lang="scss">
.blue-rating-table-legend {
  caption-side: bottom;

  ul {
    display: flex;
    flex-direction: column;
    gap: $spacing-2;
    margin-top: $spacing-8;

    li {
      display: flex;
      align-items: center;
      gap: $spacing-2;
    }
  }

  &__dot {
    display: flex;
    width: 1rem;
    height: 1rem;
    border-radius: $rounded-full;
    flex-shrink: 0;

    @include blue-rating-styles;
  }
}
</style>
