import { ref } from "vue";
import { getSurveyService } from "@/services/surveys";
import { SurveyDto } from "@/types/_generated/api";

export const useGetSurvey = () => {
  const isLoadingSurvey = ref(false);
  const hasErrorFetchingSurvey = ref(false);
  const survey = ref<SurveyDto | null>(null);

  const getSurvey = async (surveyId: number) => {
    isLoadingSurvey.value = true;
    hasErrorFetchingSurvey.value = false;

    try {
      survey.value = await getSurveyService(surveyId);
    } catch (error) {
      hasErrorFetchingSurvey.value = true;
    } finally {
      isLoadingSurvey.value = false;
    }
  };

  return {
    isLoadingSurvey,
    hasErrorFetchingSurvey,
    survey,
    getSurvey,
  };
};
