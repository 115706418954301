import { reactive, computed } from "vue";
import type { ServerFilter } from "@/views/UserWeb/ExecutiveSummary/types/FilterTypes";
import { isDeepEqual } from "@/helpers/isDeepEqual";

export const useFilterServer = (applyUpdatedServerFilter: (filter: ServerFilter) => void) => {
  const serverFilter = reactive<ServerFilter>({
    startDate: undefined,
    endDate: undefined,
    surveyRound: 0,
    excludedSiteDefinitions: {
      custom1: [],
      custom2: [],
      custom3: [],
      custom4: [],
    },
  });

  const updateServerFilter = (newFilter: ServerFilter) => {
    // If the filter has not changed, do nothing
    if (isDeepEqual(serverFilter, newFilter)) return;

    // Fetches updated table data based on new filter settings
    applyUpdatedServerFilter(newFilter);

    // Update the filter state with the incoming new values
    Object.assign(serverFilter, newFilter);
  };

  return {
    serverFilter: computed(() => serverFilter),
    updateServerFilter,
  };
};
