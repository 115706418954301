<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useNotificationsStore } from "@/stores/notifications";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import { requestResendService } from "../services/requestResend";

const { t } = useI18n({ useScope: "global" });
const notifications = useNotificationsStore();
const email = ref("");
const requestSent = ref(false);

const isSubmitDisabled = computed(() => email.value.trim().length <= 4);

const requestResend = async () => {
  notifications.toasts = [];

  await requestResendService(email.value.trim());

  requestSent.value = true;
};
</script>

<template>
  <div class="login-request-resend">
    <BaseAlert v-if="requestSent" severity="info">
      {{ t("recommendationResponse.requestedResendOfSurveyReportEmail") }}
    </BaseAlert>

    <form v-if="!requestSent" class="login-request-resend__form" @submit.prevent="requestResend">
      <div>
        <BaseLabel has-spacing>{{
          t("recommendationResponse.requestResendOfSurveyReportEmail")
        }}</BaseLabel>
        <BaseTextField
          type="email"
          autofocus
          autocomplete="email"
          :value="email"
          @update:value="email = String($event)"
        />
      </div>

      <BaseButton type="submit" :disabled="isSubmitDisabled">
        {{ t("common.actions.confirm") }}
      </BaseButton>
    </form>
  </div>
</template>

<style scoped lang="scss">
.login-request-resend {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;
  width: 100%;

  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }
}
</style>
