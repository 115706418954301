<script setup lang="ts">
import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { Mfa, ModuleDto } from "@/types/_generated/api";
import { useModuleValidation } from "@/views/Admin/Modules/composables/useModuleValidation";
import BaseCheckbox from "@/components/base/BaseCheckbox/BaseCheckbox.vue";
import BaseEditor from "@/components/base/BaseEditor/BaseEditor.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  module: ModuleDto;
}>();

defineEmits<{
  (event: "update:module", module: ModuleDto): void;
}>();

const { userWebValidation } = useModuleValidation();

const { v$ } = useVuelidate(
  userWebValidation,
  toRef(() => props.module),
  {
    $registerAs: "edit-module-user-web",
    $autoDirty: true,
  },
);
</script>

<template>
  <div class="edit-module-drawer-user-web">
    <div>
      <BaseLabel has-spacing>
        {{ t("modules.userWebText") }}
      </BaseLabel>
      <BaseEditor
        :custom-height="{ min: '150px' }"
        :errors="v$.userWebText?.$errors"
        :value="module.userWebText"
        data-test="module-user-web-text"
        @input="
          $emit('update:module', {
            ...module,
            userWebText: $event,
          })
        "
      />
    </div>

    <hr />

    <div class="edit-module-drawer-user-web__two-columns">
      <BaseCheckbox
        :label="t('modules.hasExecSumIncludeSiteSummary')"
        :checked="module.hasExecSumIncludeSiteSummary"
        @change="
          $emit('update:module', {
            ...module,
            hasExecSumIncludeSiteSummary: $event,
          })
        "
      />

      <BaseSelect
        :label="t('modules.userWebLoginType')"
        :value="module.userWebLoginType"
        @change="
          $emit('update:module', {
            ...module,
            userWebLoginType: $event as Mfa,
          })
        "
      >
        <BaseOption v-for="option in Object.values(Mfa)" :key="option" :value="option">
          {{ t(`common.mfa.${option.toLowerCase()}`) }}
        </BaseOption>
      </BaseSelect>
    </div>
    <BaseCheckbox
      :label="t('modules.hasSiteResponseBlueRating')"
      :checked="module.hasSiteResponseBlueRating"
      @change="
        $emit('update:module', {
          ...module,
          hasSiteResponseBlueRating: $event,
        })
      "
    />
  </div>
</template>

<style scoped lang="scss">
.edit-module-drawer-user-web {
  @include grid-columns(1);

  &__two-columns {
    @include grid-columns(2);
  }

  hr {
    margin: $spacing-2 0;
  }
}
</style>
