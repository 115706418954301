<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTableFilter } from "@/composables/useTableFilter";
import { SortOrder } from "@/constants/SortOrder";
import { getErrorLog } from "@/services/system-logs";
import { ErrorLogEntryDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";

const { t } = useI18n({ useScope: "global" });

const isLoading = ref(false);
const log = ref<ErrorLogEntryDto[]>([]);

onBeforeMount(async () => {
  isLoading.value = true;
  log.value = (await getErrorLog()) ?? [];
  isLoading.value = false;
});

const columns: ColumnDefinition<ErrorLogEntryDto>[] = [
  new ColumnDefinition({
    title: t("systemLog.id"),
    key: "id",
    autoWidth: true,
    alignContent: "right",
  }),
  new ColumnDefinition({
    title: t("common.date"),
    key: "timeStamp",
    autoWidth: true,
    type: ColumnDataType.DateTime,
  }),
  new ColumnDefinition({
    title: "User ID",
    key: "userId",
    autoWidth: true,
    alignContent: "right",
  }),
  new ColumnDefinition({
    title: "Impersonated By User ID",
    key: "impersonatedByUserId",
    autoWidth: true,
    alignContent: "right",
  }),
  new ColumnDefinition({
    title: "Module ID",
    key: "moduleId",
    autoWidth: true,
    alignContent: "right",
  }),
  new ColumnDefinition({
    title: "Site ID",
    key: "siteId",
    autoWidth: true,
    alignContent: "right",
  }),
  new ColumnDefinition({
    title: "Survey ID",
    key: "surveyId",
    autoWidth: true,
    alignContent: "right",
  }),
  new ColumnDefinition({
    title: t("systemLog.errorLog.activity"),
    key: "activity",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("systemLog.errorLog.exception"),
    key: "exception",
  }),
  new ColumnDefinition({
    title: t("systemLog.errorLog.message"),
    key: "message",
  }),
];

const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(log, columns);
</script>

<template>
  <BaseGridTable
    :rows="result.result"
    :columns="columns"
    :is-loading="isLoading"
    :default-sort="[{ key: 'id', order: SortOrder.Descending }]"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>
  </BaseGridTable>
</template>
