<script setup lang="ts">
import { computed, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/helpers/formatDate";
import { RecommendationRiskManagerCommentDto } from "@/types/_generated/api";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseEmailLink from "@/components/base/BaseEmailLink.vue";
import { useRiskManagerComment } from "./composables/useRiskManagerComment";

const props = defineProps<{
  comments: RecommendationRiskManagerCommentDto[];
  title?: string;
}>();

const { t } = useI18n({ useScope: "global" });

const { lockedComments } = useRiskManagerComment(toRef(() => props.comments));

const comment = computed(() => (lockedComments.value.length ? lockedComments.value[0] : null));
</script>

<template>
  <BaseDataDisplayList v-if="comment" :title="title" class="latest-risk-manager-comment">
    <BaseDataDisplayListItem v-if="comment.email" :label="t('common.email')">
      <BaseEmailLink :label="comment.email" :email="comment.email" />
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem v-if="comment.insertDate" :label="t('common.date')">
      <p>{{ formatDate.asDateAndTime(comment.insertDate) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem v-if="comment.comment" :label="t('recommendationResponse.comment')">
      <p>{{ comment.comment }}</p>
    </BaseDataDisplayListItem>
  </BaseDataDisplayList>
</template>
