<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import LogoBlueWTWPurple from "@/assets/logos/LogoBlueWTWPurple.vue";
import router from "@/router";
import { RecRespRouteName } from "@/router/RouteName";
import { signOutService } from "@/views/RecResponse/Login/services/auth";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n();

const signOut = async () => {
  await signOutService();
  router.push({ name: RecRespRouteName.SignedOut });
};

const heading = computed(() => {
  switch (router.currentRoute.value.name) {
    case RecRespRouteName.Summary:
      return t("recResponse.summary.heading");
    case RecRespRouteName.Respond:
      return t("recResponse.respond.heading");
    default:
      return "";
  }
});
</script>

<template>
  <header class="rec-resp-header">
    <div class="rec-resp-header__side">
      <LogoBlueWTWPurple class="rec-resp-header__logo" />
      <BaseIcon icon="divider" size="medium" class="rec-resp-header__divider" />
      <h1 v-if="heading" class="rec-resp-header__heading">{{ heading }}</h1>
    </div>

    <div class="rec-resp-header__side">
      <BaseButton
        v-if="router.currentRoute.value.name === RecRespRouteName.Respond"
        size="small"
        variant="outlined"
        @click="router.push({ name: RecRespRouteName.Summary })"
      >
        {{ t("recommendationResponse.responseSummary") }}
      </BaseButton>
      <BaseButton size="small" variant="outlined" @click="signOut">
        {{ t("userMenu.signOut") }}
      </BaseButton>
    </div>
  </header>
</template>

<style scoped lang="scss">
.rec-resp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacing-8;
  height: $header-height;

  &__side {
    display: flex;
    align-items: center;
    gap: $spacing-4;
  }

  &__logo {
    height: 1rem;
    flex-shrink: 0;
  }

  &__divider {
    margin: 0 $spacing-4;
  }

  &__heading {
    font-size: $text-sm;
    font-weight: $font-medium;
  }
}
</style>
