import { ref } from "vue";
import { sortByRole } from "@/helpers/sortByRole";
import { getUsers } from "@/services/users";
import { UserLeanDto } from "@/types/_generated/api";
import { Option } from "@/types/Option";

export const useModuleUsersOptions = (moduleId: number) => {
  const moduleUsers = ref<UserLeanDto[] | null>([]);
  const moduleUserOptions = ref<Option[]>([]);

  const loadModuleUsers = async () => {
    moduleUsers.value = await getUsers({ moduleId });

    moduleUsers.value!.sort(sortByRole);

    moduleUserOptions.value = moduleUsers.value!.map((user) => ({
      title: `(${user.role}) ${user.fullName}`,
      value: user.id,
    }));
  };

  loadModuleUsers();

  return { moduleUserOptions, moduleUsers };
};
