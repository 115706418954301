<script setup lang="ts">
import { AxiosResponse } from "axios";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { getFileExtension } from "@/helpers/getFileExtension";
import http from "@/helpers/http";
import { getResponseStatusErrors, getResponseStatusMessage } from "@/services/shared/handleError";
import { useAppStore } from "@/stores/app";
import { RotateDegrees } from "@/types/_generated/api";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseButton from "../BaseButton.vue";
import BaseDivider from "../BaseDivider.vue";
import BaseFileUploadDropArea from "./BaseFileUploadDropArea.vue";
import BaseFileUploadErrorOverlay from "./BaseFileUploadErrorOverlay.vue";
import BaseFileUploadList from "./BaseFileUploadList.vue";
import { FileFormat } from "./FileFormat";

const props = defineProps<{
  maxFiles?: number;
  fileType: FileFormat;
  url: string;
  disabled?: boolean;
  useFormData?: boolean;
  body: (files: FileUpload[]) => Promise<Record<string, unknown>>;
  customValidation?: (addedFiles: File[], files: FileUpload[]) => string[];
  showDetailedErrors?: boolean;
  vertical?: boolean;
}>();

const { t } = useI18n({ useScope: "global" });
const app = useAppStore();

export interface FileUpload {
  file: File;
  rotation: RotateDegrees;
  isImage: boolean;
}

const files = ref<FileUpload[]>([]);
const isUploading = ref(false);
const uploadProgress = ref(0);
const errors = ref<string[]>([]);
const hasUploadError = ref(false);

const emit = defineEmits<{
  "files-uploaded": [fileId?: string];
}>();

const allowedFileTypes = computed(() => {
  switch (props.fileType) {
    case FileFormat.Image:
      return app.settings.imageFileExtensions;
    case FileFormat.Document:
      return app.settings.documentFileExtensions;
    case FileFormat.Excel:
      return [".xlsx"];
    case FileFormat.RecommendationResponseDocument:
      return app.settings.recommendationResponseFileExtensions;
    case FileFormat.Any:
      return [
        ...new Set(
          ...app.settings.imageFileExtensions,
          ...app.settings.documentFileExtensions,
          ...app.settings.recommendationResponseFileExtensions,
        ),
      ];
    default:
      return [".*"];
  }
});

const defaultMaxSize = 5_242_880;
const maxSize = computed(() => {
  switch (props.fileType) {
    case FileFormat.Image:
      return app.settings.imageMaxSizeInBytes || defaultMaxSize;
    case FileFormat.Document:
      return app.settings.documentMaxSizeInBytes || defaultMaxSize;
    case FileFormat.Excel:
      return app.settings.documentMaxSizeInBytes || defaultMaxSize;
    case FileFormat.Any:
      return Math.max(
        app.settings.imageMaxSizeInBytes ?? defaultMaxSize,
        app.settings.documentMaxSizeInBytes ?? defaultMaxSize,
      );
    default:
      return defaultMaxSize;
  }
});

const uploadBase64Encoded = async () => {
  await uploadFiles(
    http.post(props.url, await props.body(files.value), {
      onUploadProgress: ({ progress }) => {
        uploadProgress.value = Math.trunc((progress ?? 0) * 100);
      },
    }),
  );
};

const uploadFormData = async () => {
  const formData = new FormData();

  for (let i = 0; i < files.value.length; i++) {
    formData.append("file", files.value[i].file);
  }

  await uploadFiles(
    http.post(props.url, formData, {
      onUploadProgress: ({ progress }) => {
        uploadProgress.value = Math.trunc((progress ?? 0) * 100);
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  );
};

const uploadFiles = async (upload: Promise<AxiosResponse<{ fileId: string }>>) => {
  try {
    isUploading.value = true;

    const response = await upload;

    files.value = [];

    emit("files-uploaded", response?.data?.fileId);
  } catch (error) {
    if (props.showDetailedErrors) {
      const responseErrors = getResponseStatusErrors(error);

      if (responseErrors.length) {
        errors.value.push(...responseErrors);
      } else {
        errors.value.push(
          getResponseStatusMessage(error) ?? t("library.fileUpload.errors.uploadError"),
        );
      }
    } else {
      errors.value.push(t("library.fileUpload.errors.uploadError"));
    }

    hasUploadError.value = true;

    if (!props.showDetailedErrors) {
      setTimeout(() => {
        hasUploadError.value = false;
        errors.value = [];
      }, 3000);
    }
  } finally {
    isUploading.value = false;
    uploadProgress.value = 0;
  }
};

const nextRotation = (rotation: RotateDegrees) => {
  switch (rotation) {
    case RotateDegrees.None:
      return RotateDegrees.Rotate90;
    case RotateDegrees.Rotate90:
      return RotateDegrees.Rotate180;
    case RotateDegrees.Rotate180:
      return RotateDegrees.Rotate270;
    case RotateDegrees.Rotate270:
      return RotateDegrees.None;
  }
};
</script>

<template>
  <div class="base-file-upload">
    <div :class="props.vertical ? 'base-file-upload__main--vertical' : 'base-file-upload__main'">
      <BaseFileUploadDropArea
        :allowed-file-types="allowedFileTypes"
        :max-size="maxSize"
        :min-size="1"
        :max-files="maxFiles"
        :files="files"
        :disabled="isUploading || disabled"
        :custom-validation="customValidation"
        @on-drop="
          $event.forEach((file) => {
            files.push({
              file,
              rotation: RotateDegrees.None,
              isImage: app.settings.imageFileExtensions.includes(
                getFileExtension(file.name).toLowerCase(),
              ),
            });
          })
        "
      >
        <template #file-icon>
          <slot name="file-icon"></slot>
        </template>
      </BaseFileUploadDropArea>
      <div class="base-file-upload__main__list-container">
        <BaseFileUploadErrorOverlay
          v-if="hasUploadError"
          :errors="errors"
          @close="
            () => {
              hasUploadError = false;
              errors = [];
            }
          "
        />
        <BaseFileUploadList
          :files="files"
          :disabled="isUploading || disabled"
          @on-rotate="$event.rotation = nextRotation($event.rotation)"
          @on-delete="files = files.filter((f) => f !== $event)"
        />
      </div>
    </div>

    <BaseDivider />

    <div class="base-file-upload__controls">
      <div v-if="isUploading && uploadProgress > 0" class="progress-bar">
        <span>{{ t("library.fileUpload.progress", { uploadProgress }) }}</span>
        <progress :value="uploadProgress" max="100"></progress>
      </div>

      <small>
        {{
          t("library.fileUpload.uploadTypesDialog", {
            types: allowedFileTypes?.join(", "),
          })
        }}
      </small>

      <BaseButton
        data-test="base-file-upload-button"
        class="base-file-upload__controls--upload-button"
        variant="outlined"
        :disabled="!files.length || isUploading || disabled"
        @click="useFormData ? uploadFormData() : uploadBase64Encoded()"
      >
        <BaseIcon icon="upload" />
        {{ t("library.fileUpload.upload") }}
      </BaseButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.base-file-upload {
  margin: $spacing-4 0;

  .progress-bar {
    width: 100%;

    progress {
      width: 100%;
    }
  }

  &__main {
    display: grid;
    padding-bottom: $spacing-6;
    gap: $spacing-4;
    grid-template-columns: 1fr 2fr;

    &--vertical {
      display: flex;
      flex-direction: column;
    }

    &__list-container {
      position: relative;

      &__upload-error {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        opacity: 0.8;
        justify-content: center;
        gap: $spacing-2;
        padding: $spacing-4;
        position: absolute;
        z-index: 1;
        color: $primary-2;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.9);
      }
    }
  }

  &__controls {
    display: flex;
    gap: $spacing-4;
    align-items: center;
    justify-content: space-between;
    padding-top: $spacing-2;

    &--upload-button {
      display: flex;
      align-self: flex-end;
    }
  }
}
</style>
