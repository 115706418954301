import { ref } from "vue";
import { getBlueRatingService } from "@/services/blueRating";
import { BlueRatingDto } from "@/types/_generated/api";

export const useGetBlueRating = () => {
  const isLoadingBlueRating = ref(false);
  const hasErrorFetchingBlueRating = ref(false);
  const blueRating = ref<BlueRatingDto | null>(null);

  const getBlueRating = async (surveyId: number) => {
    isLoadingBlueRating.value = true;
    hasErrorFetchingBlueRating.value = false;

    try {
      blueRating.value = await getBlueRatingService(surveyId);
    } catch (error) {
      hasErrorFetchingBlueRating.value = true;
    } finally {
      isLoadingBlueRating.value = false;
    }
  };

  return {
    isLoadingBlueRating,
    hasErrorFetchingSurvey: hasErrorFetchingBlueRating,
    blueRating,
    getBlueRating,
  };
};
