<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useCategoryRecommendations } from "@/composables/useCategoryRecommendations";
import {
  BlueColor,
  type BlueRatingCategoryDto,
  type BlueRatingRecommendationDto,
} from "@/types/_generated/api";
import BaseBlueRatingStatusBadge from "@/components/base/BaseBlueRatingStatusBadge.vue";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseImage from "@/components/base/BaseImage.vue";
import BaseSafeHtml from "@/components/base/BaseSafeHtml/BaseSafeHtml.vue";

const props = defineProps<{
  recommendation: BlueRatingRecommendationDto;
  categories: BlueRatingCategoryDto[];
}>();
const { t } = useI18n({ useScope: "global" });
const { getCategoryNameByRecommendation } = useCategoryRecommendations();

const src = `/v1/surveys/${props.recommendation.surveyId}/recommendations/${props.recommendation.recommendationId}/images?fileId=`;

const blueRatingColor = computed((): BlueColor => {
  if (props.recommendation.categoryColorOverride !== BlueColor.NotSet) {
    return props.recommendation.categoryColorOverride;
  }
  const category = props.categories.find(
    (category) => category.categoryId === props.recommendation.categoryId,
  );
  return category?.color ?? BlueColor.NotSet;
});
</script>

<template>
  <BaseDataDisplayList class="online-report-recommendation-properties">
    <BaseDataDisplayListItem
      v-if="recommendation.isKeyRecommendation"
      :label="t('userWeb.onlineReport.recommendations.isKeyRecommendation')"
    >
      <BaseIcon icon="check" />
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem
      v-if="recommendation.isGeneralRecommendation"
      :label="t('userWeb.onlineReport.recommendations.isGeneralRecommendation')"
    >
      <BaseIcon icon="check" />
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem
      v-if="recommendation.categoryId"
      :label="t('userWeb.onlineReport.recommendations.category')"
    >
      <p>
        {{ getCategoryNameByRecommendation(recommendation.recommendationId, categories) }}
      </p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.blueRating')">
      <BaseBlueRatingStatusBadge :status="blueRatingColor" />
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.name')">
      <p>{{ recommendation.header }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.location')">
      <p>{{ recommendation.location }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.priority')">
      <p>{{ recommendation.priority }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.recommendation')">
      <BaseSafeHtml :html="recommendation.text" />
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.comment')">
      <BaseSafeHtml :html="recommendation.comment" />
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.type')">
      <p>{{ recommendation.type }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem
      v-if="recommendation.images?.length"
      :label="t('userWeb.onlineReport.recommendations.images')"
    >
      <div class="online-report-recommendation-properties__images">
        <BaseImage
          v-for="(image, index) of recommendation.images"
          :key="`${image.fileId}-${index}`"
          class="online-report-recommendation-properties__images__image"
          :src="`${src}${image.fileId}`"
          :alt="image.caption"
          max-width="100%"
          :title="image.fileName"
        />
      </div>
    </BaseDataDisplayListItem>
  </BaseDataDisplayList>
</template>

<style scoped lang="scss">
.online-report-recommendation-properties {
  &__images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: $spacing-2;
    &__image {
      border: 4px solid $primary-0;
    }
  }
}
</style>
