import { SiteResponseStatus } from "@/types/_generated/api";

export const ORDERED_SITE_RESPONSE_STATUSES: SiteResponseStatus[] = [
  SiteResponseStatus.NotSet,
  SiteResponseStatus.NoProgress,
  SiteResponseStatus.InProgress,
  SiteResponseStatus.UnderReview,
  SiteResponseStatus.Completed,
  SiteResponseStatus.Rejected,
];
