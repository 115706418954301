<script setup lang="ts">
import { onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import type { NavTab } from "@/components/base/BaseNavTabs/BaseNavTabs.vue";
import { useGetModule } from "@/composables/services/useGetModule";
import { useGetSitesData } from "@/composables/services/useGetSitesData";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import { useDrawerState } from "@/composables/useDrawerState";
import { UserWebRouteName } from "@/router/RouteName";
import { BlueRatingRecommendationDto } from "@/types/_generated/api";
import UserWebViewLayout from "@/components/app/UserWeb/UserWebViewLayout.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import DAATFilterDrawer from "./components/DAATFilterDrawer.vue";
import { useFilterClient } from "./composables/useFilterClient";
import { useFilterServer } from "./composables/useFilterServer";
import { useGetBlueLossValues } from "./composables/useGetBlueLossValues";
import { useGetBlueTrend } from "./composables/useGetBlueTrend";
import DAATBlueTrendView from "./DAATBlueTrendView.vue";
import DAATLossView from "./DAATLossView.vue";
import DAATRawDataView from "./DAATRawDataView.vue";

const props = defineProps<{
  moduleId: number;
}>();

const { t } = useI18n({ useScope: "global" });
const route = useRoute();
const { setBreadcrumbs } = useBreadcrumbs();
const { sitesData, isLoadingSites, getSitesData } = useGetSitesData();

const {
  closeDrawer: closeFilterDrawer,
  openDrawer: openFilterDrawer,
  showDrawer: showFilterDrawer,
} = useDrawerState<BlueRatingRecommendationDto>();

const { module, getModule } = useGetModule();

const { serverFilter, updateServerFilter } = useFilterServer(async () => {
  getBlueTrend(props.moduleId, serverFilter);
  getBlueLossValues(props.moduleId, serverFilter);
});

const { clientFilter, updateClientFilter } = useFilterClient();

const {
  getBlueTrend,
  blueTrend,
  isLoadingBlueTrend,
  hasError: hasBlueTrendError,
} = useGetBlueTrend();

const {
  blueLossValues,
  getBlueLossValues,
  hasError: hasLossValuesError,
  isLoadingBlueLossValues,
} = useGetBlueLossValues();

const TABS: NavTab[] = [
  {
    title: t("userWeb.daat.tabs.blueTrend"),
    path: UserWebRouteName.DAATBlueTrend,
  },
  {
    title: t("userWeb.daat.tabs.lossValues"),
    path: UserWebRouteName.DAATLossValues,
  },
  {
    title: t("userWeb.daat.tabs.rawData"),
    path: UserWebRouteName.DAATRawData,
  },
];

watch(
  () => route.name,
  () => {
    setBreadcrumbs([{ label: t("userWeb.navigation.dataAnalysisAndTrends") }]);
  },
  { immediate: true },
);

onMounted(async () => {
  await getModule(props.moduleId);

  // Set all loss scenarios selected by default
  serverFilter.lossScenarioIds = new Set(
    module.value?.lossScenarios.map((ls) => {
      if (!ls.id) {
        throw new Error("Loss scenario ID is required");
      }

      return ls.id;
    }) ?? [],
  );

  getSitesData(props.moduleId);
  getBlueTrend(props.moduleId, serverFilter);
  getBlueLossValues(props.moduleId, serverFilter);
});
</script>

<template>
  <UserWebViewLayout :tabs="TABS">
    <template #controls>
      <BaseButton
        :disabled="isLoadingSites"
        variant="outlined"
        size="small"
        @click="openFilterDrawer()"
      >
        <BaseIcon icon="filter" size="small" />
        {{ t("common.filter") }}
      </BaseButton>
    </template>

    <DAATBlueTrendView
      v-if="route.name === UserWebRouteName.DAATBlueTrend"
      :data="blueTrend"
      :client-filter="clientFilter"
      :has-error="hasBlueTrendError"
      :is-loading="isLoadingBlueTrend"
    />

    <DAATLossView
      v-if="route.name === UserWebRouteName.DAATLossValues"
      :currency-code="module?.currencyCode ?? 'EUR'"
      :data="blueLossValues ?? []"
      :client-filter="clientFilter"
      :loss-scenarios="module?.lossScenarios ?? []"
      :has-error="hasLossValuesError"
      :is-loading="isLoadingBlueLossValues"
    />

    <DAATRawDataView v-if="route.name === UserWebRouteName.DAATRawData" />

    <DAATFilterDrawer
      v-if="showFilterDrawer"
      :sites-data="sitesData"
      :client-filter="clientFilter"
      :server-filter="serverFilter"
      :show-loss-scenarios="route.name !== UserWebRouteName.DAATBlueTrend"
      :loss-scenarios="module?.lossScenarios ?? []"
      @close-drawer="closeFilterDrawer"
      @update:client-filter="updateClientFilter"
      @update:server-filter="updateServerFilter"
    />
  </UserWebViewLayout>
</template>
