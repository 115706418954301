<script setup lang="ts">
import { computed, type DeepReadonly } from "vue";
import type { SitesData } from "@/composables/services/useGetSitesData";
import type { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import RecTracking from "@/components/shared/RecTracking/RecTracking.vue";
import { useRecommendationTrackingColumns } from "./composables/useRecommendationTrackingColumns";

const props = defineProps<{
  isLoading: boolean;
  recommendationTracking: TrackedRecommendation[];
  sitesData: DeepReadonly<SitesData>;
}>();

const siteDefinitions = computed(() => ({
  siteDefinition1: props.sitesData.siteDefinition1,
  siteDefinition2: props.sitesData.siteDefinition2,
  siteDefinition3: props.sitesData.siteDefinition3,
  siteDefinition4: props.sitesData.siteDefinition4,
}));

const { columns } = useRecommendationTrackingColumns(siteDefinitions);
</script>

<template>
  <RecTracking
    :columns="columns"
    :is-loading="isLoading"
    :tracked-recommendations="recommendationTracking"
  />
</template>
