import { App, Plugin } from "vue";
import { useAppStore } from "@/stores/app";
import { BaseModulePermissionDto, Role } from "@/types/_generated/api";

type Rule = Extract<
  keyof BaseModulePermissionDto,
  | "createSite"
  | "createSurvey"
  | "editQuestionnaire"
  | "editSurvey"
  | "uploadFiles"
  | "uploadStdRec"
>;

export const isSuperior = (role: Role, compareTo: Role) => {
  const lookup = {
    [Role.SystemOwner]: 0,
    [Role.SA]: 1,
    [Role.BA]: 2,
    [Role.INT]: 3,
    [Role.EXT]: 4,
  };

  if (isNaN(lookup[role])) {
    throw new Error(`Argument r1 ${role} is not a known role`);
  }

  if (isNaN(lookup[compareTo])) {
    throw new Error(`Argument r2 ${compareTo} is not a known role`);
  }

  // Lower is better
  return lookup[role] < lookup[compareTo];
};

const isSelf = (userId: number) => useAppStore().user?.id === userId;

const hasRole = (...roles: Role[]) => roles.includes(useAppStore().user!.role);

const hasAllowRule = (rule: "allowReportMail" | "allowRiskManagerComment" | "allowUserWeb") => {
  const { user } = useAppStore();

  return user?.[rule] ?? false;
};

const isAdmin = () => hasRole(Role.SystemOwner, Role.SA, Role.BA);
const isSystemOwner = () => useAppStore().user!.role === Role.SystemOwner;

const isSuperiorTo = (role: Role) => isSuperior(useAppStore().user!.role, role);
const isSuperiorOrEqualTo = (role: Role) => {
  const userRole = useAppStore().user!.role;

  return userRole === role || isSuperior(userRole, role);
};

const hasModulePermissions = (): boolean => {
  if (isSuperiorTo(Role.BA)) {
    return true;
  }

  const { user } = useAppStore();

  if (user?.modulePermissions && user.modulePermissions.length > 0) {
    return true;
  }

  return false;
};

const hasModulePermission = (moduleId: number | null | undefined, rule?: Rule) => {
  if (!moduleId) {
    return false;
  }

  const user = useAppStore().user!;

  // User is SA or System Owner
  if (isSuperior(user.role, Role.BA)) {
    return true;
  }

  // User has no module permissions
  if (!user.modulePermissions || user.modulePermissions.length === 0) {
    return false;
  }

  const modulePermission = user.modulePermissions.find((mp) => mp.moduleId === moduleId);

  // No permission for module
  if (!modulePermission) {
    return false;
  }

  // User is BA - no rule needed
  if (user.role === Role.BA) {
    return true;
  }

  // User has permission and there is no rule given
  if (modulePermission && !rule) {
    return true;
  }

  const hasRule = modulePermission[rule!];

  const isExtRule = ["createSite", "createSurvey", "editSurvey", "uploadStdRec"].includes(
    rule ?? "",
  );

  // User is EXT and rule is for EXT
  if (user.role === Role.EXT && isExtRule && hasRule) {
    return true;
  }

  // User is INT and rule is for INT or EXT
  if (user.role === Role.INT && hasRule) {
    return true;
  }

  return false;
};

export const authorize = {
  isSelf,
  isSuperiorTo,
  isSuperiorOrEqualTo,
  isAdmin,
  isSystemOwner,
  hasRole,
  hasAllowRule,
  hasModulePermission,
  hasModulePermissions,
};

export const CanPlugin: Plugin = {
  install(app: App) {
    app.config.globalProperties.$authorize = authorize;
  },
};
