import type { BaseFilterKey, ValueFunc } from "@/types/Models";
import type { HorizontalSizes } from "./CSSUnits";
import type { Option } from "./Option";

export enum ColumnDataType {
  None = "none",
  Boolean = "boolean",
  Array = "array",
  Date = "Date",
  DateTime = "DateTime",
}

type CellAlignment = "center" | "left" | "right" | undefined;

interface ColumnSettings<T> {
  title: string;
  key: Extract<keyof T, string>;
  type?: ColumnDataType;
  alignHeader?: CellAlignment;
  alignContent?: CellAlignment;
  autoWidth?: boolean;
  searchValueFunc?: ValueFunc;
  searchOptions?: Option[];
  maxWidth?: `${number}${HorizontalSizes}`;
  defaultHidden?: boolean;
  sortable?: boolean;
}

export class ColumnDefinition<T> {
  key: Extract<keyof T, string>;
  title: string;
  type?: ColumnDataType;
  searchKey?: BaseFilterKey<T>;
  autoWidth?: boolean;
  maxWidth?: `${number}${HorizontalSizes}`;
  alignContent?: CellAlignment;
  defaultHidden?: boolean;
  sortable?: boolean;

  constructor({
    title,
    key,
    type,
    searchValueFunc,
    searchOptions,
    autoWidth,
    maxWidth,
    alignContent,
    defaultHidden,
    sortable,
  }: ColumnSettings<T>) {
    this.key = key;
    this.title = title;
    this.type = type;
    this.autoWidth = autoWidth;
    this.searchKey = {
      name: key,
      title,
      getCompareValue: searchValueFunc,
      fixedOptions: searchOptions,
    };
    this.maxWidth = maxWidth;
    this.alignContent = alignContent;
    this.defaultHidden = defaultHidden;
    this.sortable = sortable ?? true;
  }
}
