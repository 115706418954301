import { readonly, ref } from "vue";
import { searchRecommendationTrackingService } from "@/services/recommendation-tracking";
import {
  BlueColor,
  SiteResponseStatus,
  SurveyorStatus,
  type RecommendationTrackingFilter,
  type SearchRecTrackingResponse,
} from "@/types/_generated/api";

export interface TrackedRecommendation {
  id: number;
  surveyId: number;
  custom1?: string;
  custom2?: string;
  custom3?: string;
  custom4?: string;
  surveyDate?: string;
  categoryName: string;
  categoryId?: number;
  color: BlueColor;
  priority: string;
  fullPosition: string;
  heading: string;
  rcbaCostToComplete?: number;
  costBenefitRatio: string;
  type: string;
  surveyorName?: string;
  siteResponseComment?: string;
  siteResponseCostToComplete?: number;
  siteResponseStatus: SiteResponseStatus;
  responsiblePerson?: string;
  dueDate?: string;
  riskManagerComment?: string;
  surveyorStatus: SurveyorStatus;
  isKeyRecommendation: boolean;
}

export const useSearchRecommendationTracking = () => {
  const isLoadingRecommendationTracking = ref(false);
  const recommendationTracking = ref<TrackedRecommendation[]>([]);
  const rawData = ref<SearchRecTrackingResponse | null>(null);
  const errorFetchingRecTracking = ref<boolean>(false);

  const searchRecommendationTracking = async (
    moduleId: number,
    filter: RecommendationTrackingFilter,
    siteId?: number,
  ) => {
    try {
      isLoadingRecommendationTracking.value = true;
      errorFetchingRecTracking.value = false;

      const response = await searchRecommendationTrackingService(moduleId, filter, siteId);

      if (response === null) {
        throw new Error("Failed to fetch recommendation tracking");
      }

      rawData.value = response;
      recommendationTracking.value = convertToTrackedRecommendations(response);
    } catch (error) {
      errorFetchingRecTracking.value = true;
    } finally {
      isLoadingRecommendationTracking.value = false;
    }
  };

  const convertToTrackedRecommendations = (recommendationTracking: SearchRecTrackingResponse) => {
    const trackedRecommendations: TrackedRecommendation[] = [];

    for (const recommendation of recommendationTracking.trackedRecommendations) {
      const metaData = recommendationTracking.surveyMetaData.find(
        (survey) => survey.surveyId === recommendation.surveyId,
      );

      const trackedRecommendation: TrackedRecommendation = {
        id: recommendation.id,
        surveyId: recommendation.surveyId,
        custom1: metaData?.custom1,
        custom2: metaData?.custom2,
        custom3: metaData?.custom3,
        custom4: metaData?.custom4,
        surveyDate: metaData?.surveyDate,
        surveyorName: metaData?.surveyorName,
        categoryName: getCategoryName(
          recommendation.latestCategoryId,
          recommendationTracking.categoryNames,
        ),
        categoryId: recommendation.latestCategoryId,
        color: recommendation.color,
        priority: recommendation.priority,
        fullPosition: recommendation.fullPosition,
        heading: recommendation.heading,
        type: recommendation.type,
        rcbaCostToComplete: recommendation.rcbaCostToComplete,
        costBenefitRatio: recommendation.costBenefitRatio,
        siteResponseComment: recommendation.siteResponseComment,
        siteResponseCostToComplete: recommendation.siteResponseCostToComplete,
        siteResponseStatus: recommendation.siteResponseStatus,
        riskManagerComment: recommendation.riskManagerComment,
        responsiblePerson: recommendation.siteResponseResponsiblePerson,
        dueDate: recommendation.siteResponseDueDate,
        surveyorStatus: recommendation.surveyorStatus,
        isKeyRecommendation: recommendation.isKeyRecommendation,
      };

      trackedRecommendations.push(trackedRecommendation);
    }
    return trackedRecommendations;
  };

  const getCategoryName = (latestCategoryId: number | undefined, names: Record<number, string>) => {
    if (latestCategoryId) {
      return names[latestCategoryId];
    }

    return "";
  };

  return {
    searchRecommendationTracking,
    recommendationTracking,
    isLoadingRecommendationTracking,
    rawData,
    errorFetchingRecTracking: readonly(errorFetchingRecTracking),
  };
};
