import { createI18nMessage, helpers } from "@vuelidate/validators";
import { i18n } from "@/i18n";
import { UpdateModule, LossScenarioDto } from "@/types/_generated/api";
import { maxLength, required } from "@/validation/i18n-validators";

// reportMailLoginType: ReportMailLoginType;

export const useModuleValidation = () => {
  const withI18nMessage = createI18nMessage({
    t: i18n.global.t.bind(i18n),
  });

  const generalValidation: { [k in keyof Partial<UpdateModule>]: object } = {
    name: [{ required }, { maxLength: maxLength(100) }],
    // status
    // language:
    // contactId
    // accountEngineerId
    willisOffice: { maxLength: maxLength(100) },
    willisCountryOrUnit: { maxLength: maxLength(50) },
    poBox: { maxLength: maxLength(250) },
    address: { maxLength: maxLength(400) },
    zipCode: { maxLength: maxLength(100) },
    city: { maxLength: maxLength(150) },
    country: { maxLength: maxLength(100) },
    web: { maxLength: maxLength(150) },
    phone: { maxLength: maxLength(100) },
  };

  const blueRatingValidation: { [k in keyof Partial<UpdateModule>]: object } = {
    // requireSpoeCheck
    // allowSpoeCheckByExt
    recommendationTypes: {
      maxLength: withI18nMessage({
        $validator: (value: string[]) => value.join(";").length <= 500,
        $params: { max: 500 },
      }),
    },
    // forceOverrideCategoryColor
    // forceName
    // forceLocation
    // forcePriority
    // forceType
    abeyanceShort: { maxLength: maxLength(1) },
    abeyanceTranslation: { maxLength: maxLength(50) },
    nameTranslation: { maxLength: maxLength(50) },
    priorityTranslation: { maxLength: maxLength(50) },
    locationTranslation: { maxLength: maxLength(50) },
    textForBlue: { maxLength: maxLength(100) },
    textForGreen: { maxLength: maxLength(100) },
    textForRed: { maxLength: maxLength(100) },
    textForYellow: { maxLength: maxLength(100) },
    currencyCode: { maxLength: maxLength(4) },
    // showAForAbeyance
    // showCForAllCompletedOnCategory
    // autoNotifyStakeholders
    // hasSiteResponseBlueRating
  };

  const emailTemplatesValidation: {
    [k in keyof Partial<UpdateModule>]: object;
  } = {
    preSurveyMailSubject: { maxLength: maxLength(100) },
    preSurveyMailBody: { maxLength: maxLength(32767) },
    reportMailSubject: { maxLength: maxLength(100) },
    reportMailBody: { maxLength: maxLength(32767) },
  };

  const lossScenarioValidation: {
    [k in keyof Partial<LossScenarioDto>]: object;
  } = {
    name: { maxLength: maxLength(50), required },
    businessInterruption: { maxLength: maxLength(1000), required },
    propertyDamage: { maxLength: maxLength(1000), required },
  };

  const lossScenariosValidation: {
    [k in keyof Partial<UpdateModule>]: object;
  } = {
    lossScenarios: {
      maxArrayLength: maxLength(5),
    },
  };

  const siteDefinitionsValidation: {
    [k in keyof Partial<UpdateModule>]: object;
  } = {
    siteDefinition1: { maxLength: maxLength(100) },
    siteDefinition2: { maxLength: maxLength(100) },
    siteDefinition3: { maxLength: maxLength(100) },
    siteDefinition4: { maxLength: maxLength(100) },

    suggestions1: {
      $each: helpers.forEach({ maxLength: maxLength(50), required }),
    },
    suggestions2: {
      $each: helpers.forEach({ maxLength: maxLength(50), required }),
    },
    suggestions3: {
      $each: helpers.forEach({ maxLength: maxLength(50), required }),
    },
    suggestions4: {
      $each: helpers.forEach({ maxLength: maxLength(50), required }),
    },
    // forceSuggestion1
    // forceSuggestion2
    // forceSuggestion3
    // forceSuggestion4
  };

  const userWebValidation: { [k in keyof Partial<UpdateModule>]: object } = {
    userWebText: { maxLength: maxLength(32767) },
    // hasExecSumIncludeSiteSummary
  };

  const statusValidation: { [k in keyof Partial<UpdateModule>]: object } = {
    status: {
      // If status is 'Published', require siteDefinition1 to exist
      requiredIfPublished: withI18nMessage({
        $validator: (value: string, module: UpdateModule) => {
          return module.status !== "Published" || !!module.siteDefinition1.length;
        },
        $params: {},
      }),
    },
  };

  return {
    generalValidation,
    blueRatingValidation,
    emailTemplatesValidation,
    lossScenarioValidation,
    lossScenariosValidation,
    siteDefinitionsValidation,
    userWebValidation,
    statusValidation,
  };
};
