<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { DeepReadonly } from "vue";
import type { ExecutiveReportLossScenarioDto } from "@/types/_generated/api";
import {
  type SortBy,
  SortOption,
} from "@/views/UserWeb/ExecutiveSummary/composables/useSortExecSummary";
import UWOption from "@/components/base/UserWeb/Inputs/Select/UWOption.vue";
import UWOptionPlaceholder from "@/components/base/UserWeb/Inputs/Select/UWOptionPlaceholder.vue";
import UWSelect from "@/components/base/UserWeb/Inputs/Select/UWSelect.vue";

defineProps<{
  currentSortOrder: SortBy;
  lossScenarios?: DeepReadonly<ExecutiveReportLossScenarioDto[]>;
}>();

defineEmits<{
  sortTable: [SortBy];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <UWSelect :value="currentSortOrder" @change="$emit('sortTable', $event)">
    <UWOptionPlaceholder>
      {{ t("userWeb.executiveSummary.toolbar.sortBy.title") }}
    </UWOptionPlaceholder>
    <UWOption :value="SortOption.Alphabetical">
      {{ t("userWeb.executiveSummary.toolbar.sortBy.alphabetical") }}
    </UWOption>
    <UWOption :value="SortOption.Score">
      {{ t("userWeb.executiveSummary.toolbar.sortBy.score") }}
    </UWOption>
    <UWOption
      v-for="lossScenario in lossScenarios"
      :key="lossScenario.id"
      :value="lossScenario.id.toString()"
    >
      {{ lossScenario.name }}
    </UWOption>
  </UWSelect>
</template>
