<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useDebounce } from "@/composables/useDebounce";
import { bytesToSize } from "@/helpers/bytesToSize";
import { toBase64 } from "@/helpers/toBase64";
import { RecommendationImageDto, RecommendationImageMetaDataDto } from "@/types/_generated/api";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseFileUpload, { FileUpload } from "@/components/base/BaseFileUpload/BaseFileUpload.vue";
import { FileFormat } from "@/components/base/BaseFileUpload/FileFormat";
import BaseImage from "@/components/base/BaseImage.vue";
import BaseOrderedList from "@/components/base/BaseOrderedList.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  disabled?: boolean;
  recommendationId: number;
  images: RecommendationImageMetaDataDto[];
  url: string;
  src: string;
}>();

const emit = defineEmits<{
  (e: "delete", item: RecommendationImageMetaDataDto): void;
  (e: "files-uploaded"): void;
  (e: "update:caption", item: RecommendationImageMetaDataDto): void;
  (
    e: "update-order",
    value: {
      id: RecommendationImageMetaDataDto[keyof RecommendationImageMetaDataDto];
      position: number;
    }[],
  ): void;
}>();

const getBody = async (files: FileUpload[]) => {
  const images = [];

  for (const file of files) {
    images.push({
      fileName: file.file.name,
      file: await toBase64(file.file),
      rotation: file.rotation,
    } as RecommendationImageDto);
  }

  return {
    recommendationId: props.recommendationId,
    images,
  };
};
const debounce = useDebounce();

const updateCaption = (value: string, item: RecommendationImageMetaDataDto) => {
  item.caption = value;
  debounce(() => emit("update:caption", item));
};
</script>
<template>
  <BaseCard has-padding>
    <BaseFileUpload
      :disabled="disabled"
      :body="getBody"
      :max-files="5"
      :file-type="FileFormat.Image"
      :url="url"
      @files-uploaded="$emit('files-uploaded')"
    />
  </BaseCard>
  <BaseCard :title="t('blueRating.recommendation.uploadedPhotos')" has-padding>
    <BaseOrderedList
      v-if="images.length > 0"
      :id="(item: RecommendationImageMetaDataDto) => item.fileId"
      class="recommendation__image-list"
      :items="images"
      :actions="[
        {
          icon: 'trash',
          label: t('common.actions.delete'),
          event: 'delete',
        },
      ]"
      @action:delete="$emit('delete', $event)"
      @update-order="$emit('update-order', $event)"
    >
      <template #item="{ item }">
        <div class="recommendation__image-list__item">
          <span class="recommendation__image-list__item__position"> {{ item.position }}</span>

          <BaseImage
            class="recommendation__image-list__item__image"
            :src="`${src}${item.fileId}`"
            :alt="item.caption"
            :max-width="'200px'"
            :max-height="'200px'"
            :title="item.fileName"
          >
          </BaseImage>

          <div class="recommendation__image-list__item__caption">
            <BaseTextArea
              :disabled="disabled"
              :maxlength="200"
              :value="item.caption"
              :placeholder="t('blueRating.recommendation.imageCaption')"
              @update:value="updateCaption($event as string, item)"
            />

            <small>
              {{ bytesToSize(item.fileSize) }}
            </small>
          </div>
        </div>
      </template>
    </BaseOrderedList>
  </BaseCard>
</template>

<style scoped lang="scss">
.recommendation__image-list {
  margin: $spacing-4;

  &__item {
    display: flex;
    gap: $spacing-4;
    margin-bottom: $spacing-4;

    &__position {
      font-size: $text-lg;
      margin-right: $spacing-4;
    }

    &__image {
      align-self: start;

      &:hover {
        cursor: zoom-in;
      }
    }

    &__caption {
      width: 100%;
    }
  }
}
</style>
