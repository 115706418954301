<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const { t } = useI18n({ useScope: "global" });

defineProps<{ showDescription: boolean }>();

const emit = defineEmits<{ (event: "toggle"): void }>();

const toggle = () => emit("toggle");
</script>

<template>
  <BaseButton
    v-if="showDescription"
    :title="t('blueRating.descriptionToggle.hide')"
    variant="outlined"
    @click="toggle"
  >
    <BaseIcon icon="eye-closed" />
  </BaseButton>

  <BaseButton
    v-else
    :title="t('blueRating.descriptionToggle.show')"
    variant="outlined"
    @click="toggle"
  >
    <BaseIcon icon="eye" />
  </BaseButton>
</template>
