import { ref } from "vue";
import { getUsers as getUsersService } from "@/services/users";

export const useGetUsers = () => {
  const isLoadingUsers = ref(false);

  const getUsers = async (filter?: {
    clientId?: number;
    moduleId?: number;
    onlyActive?: boolean;
  }) => {
    isLoadingUsers.value = true;

    const users = await getUsersService(filter);

    isLoadingUsers.value = false;

    return users;
  };

  return {
    isLoadingUsers,
    getUsers,
  };
};
