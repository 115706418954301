<script setup lang="ts">
import SiteEditDrawer from "@/views/Admin/Sites/components/SiteEditDrawer/SiteEditDrawer.vue";
import StandardRecEditDrawer from "@/views/Admin/Templates/components/StandardRecommendationEditDrawer/StandardRecEditDrawer.vue";
import { ModuleDrawer } from "../../constants";
import ModuleEditDrawer from "./ModuleEditDrawer/ModuleEditDrawer.vue";
import ModuleImportSitesDrawer from "./ModuleImportSitesDrawer/ModuleImportSitesDrawer.vue";
import RecReportSettingsDrawer from "./RecReportSettingsDrawer/RecReportSettingsDrawer.vue";

defineProps<{
  currentDrawer: ModuleDrawer | null;
  moduleId: number;
  siteId: number | null;
  clientId?: number;
}>();

const emit = defineEmits<{
  closeDrawer: [void];
}>();

const closeDrawer = () => emit("closeDrawer");
</script>

<template>
  <RecReportSettingsDrawer
    v-if="currentDrawer === ModuleDrawer.RecReportSettings"
    :module-id="moduleId"
    @close-drawer="closeDrawer"
  />

  <ModuleEditDrawer
    v-if="currentDrawer === ModuleDrawer.ModuleEdit"
    :module-id="moduleId"
    @close="closeDrawer"
  />

  <ModuleImportSitesDrawer
    v-if="currentDrawer === ModuleDrawer.ModuleImportSites"
    :module-id="moduleId"
    @close="closeDrawer"
  />

  <SiteEditDrawer
    v-if="currentDrawer === ModuleDrawer.SiteEdit && siteId"
    :site-id="siteId"
    :module-id="moduleId"
    @close="closeDrawer"
  />

  <StandardRecEditDrawer
    v-if="currentDrawer === ModuleDrawer.StandardRecEdit && clientId"
    :pre-selected-client-id="clientId"
    :pre-selected-module-id="moduleId"
    @close="closeDrawer"
  />
</template>
