<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { bytesToSize } from "@/helpers/bytesToSize";
import { getFileExtension } from "@/helpers/getFileExtension";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseButton from "../BaseButton.vue";
import { FileUpload } from "./BaseFileUpload.vue";
import BaseFileUploadErrorOverlay from "./BaseFileUploadErrorOverlay.vue";

const props = defineProps<{
  maxSize?: number;
  minSize?: number;
  maxFiles?: number;
  allowedFileTypes?: string[];
  disabled?: boolean;
  files: FileUpload[];
  customValidation?: (addedFiles: File[], files: FileUpload[]) => string[];
}>();

const emits = defineEmits<{ (e: "on-drop", files: File[]): void }>();

const { t } = useI18n({ useScope: "global" });

const input = ref<HTMLInputElement>();
const isOverDropArea = ref(0);
const errors = ref<string[]>([]);

const onUpload = (uploadFiles: File[] | FileList | null) => {
  const inputFiles = Array.from(uploadFiles || []);

  const {
    allowedFileTypes,
    customValidation,
    maxSize,
    minSize,
    maxFiles,
    files: addedFiles,
  } = props;

  errors.value = customValidation ? customValidation(inputFiles, props.files) : [];

  if (
    allowedFileTypes?.length &&
    inputFiles.some(
      (f) => !props.allowedFileTypes?.includes(getFileExtension(f.name).toLowerCase()),
    )
  ) {
    errors.value.push(
      t("library.fileUpload.errors.fileType", {
        types: allowedFileTypes.join(", "),
      }),
    );
  }

  if (maxSize && inputFiles.some((f) => f.size > props.maxSize!)) {
    errors.value.push(
      t("library.fileUpload.errors.fileSize", {
        size: `${bytesToSize(maxSize)}`,
      }),
    );
  }

  if (minSize && inputFiles.some((f) => f.size < props.minSize!)) {
    errors.value.push(
      t("library.fileUpload.errors.fileMinSize", {
        size: `${bytesToSize(minSize)}`,
      }),
    );
  }

  if (maxFiles && addedFiles.length + inputFiles.length > maxFiles) {
    const maxFilesText =
      maxFiles === 1 ? t("library.fileUpload.file") : t("library.fileUpload.files");
    errors.value.push(
      t("library.fileUpload.errors.maxFiles", {
        maxFiles: props.maxFiles,
        text: `${maxFilesText}`,
      }),
    );
  }

  if (errors.value.length) {
    setTimeout(() => {
      errors.value = [];
    }, 3000);
  }

  if (!errors.value.length) {
    emits("on-drop", inputFiles);
  }

  input.value!.value = "";
};

const onDragEnter = () => {
  isOverDropArea.value++;
};

const onDragLeave = () => {
  isOverDropArea.value--;
};

const onDrop = (event: DragEvent) => {
  isOverDropArea.value = 0;

  onUpload(event.dataTransfer?.files || []);
};
</script>

<template>
  <div
    class="base-file-upload__drop-area"
    :class="{
      'base-file-upload__drop-area--disabled': disabled,
      'base-file-upload__drop-area--drag-over': isOverDropArea && !disabled,
    }"
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="onDragLeave"
    @dragover.prevent
    @drop.prevent="onDrop"
  >
    <slot name="file-icon">
      <BaseIcon icon="file" />
    </slot>

    <BaseFileUploadErrorOverlay v-if="errors.length" :errors="errors" @close="errors = []" />

    <div class="base-file-upload__drop-area__text">
      <div>
        {{ t("library.fileUpload.dropHere") }}
      </div>
      <div>
        {{ t("library.fileUpload.dropHereOr") }}
      </div>
    </div>
    <BaseButton :disabled="disabled" variant="outlined" @click="() => input?.click()">
      <BaseIcon icon="folder-open" />
      {{ t("library.fileUpload.browse") }}
    </BaseButton>

    <input
      ref="input"
      class="base-file-upload__drop-area__hidden-input"
      type="file"
      :accept="allowedFileTypes?.join(', ')"
      multiple="true"
      @change="onUpload(($event.target as HTMLInputElement).files)"
    />
  </div>
</template>

<style scoped lang="scss">
.base-file-upload__drop-area {
  display: flex;
  position: relative;

  border-radius: $rounded-base;
  padding: 20%;
  gap: 2rem;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 2px dashed $primary-4;
  background-color: $primary-2;
  transition: all 0.2s ease-in-out;

  &--drag-over {
    border: 2px dashed $primary-6;
    background-color: $primary-3;
  }

  &--disabled {
    opacity: 0.5;
  }

  &__hidden-input {
    display: none;
  }

  :slotted(svg) {
    color: $primary-6;
    width: 30%;
    height: 30%;
  }

  &__text {
    color: $primary-6;
    font-size: $text-lg;
    line-height: $leading-normal;
    text-align: center;
  }
}
</style>
