<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { RecRespRouteName } from "@/router/RouteName";
import { type RecResponseSummaryDto } from "@/types/_generated/api";
import RecResponseViewLayout from "@/components/app/RecResponse/RecResponseViewLayout.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import SummaryCharts from "./components/SummaryCharts.vue";
import SummaryFiles from "./components/SummaryFiles.vue";
import { getRecResponseSummaryService } from "./services/recResponse";
import { SiteResponseColorCount, SiteResponseStatusCount } from "./types";

const router = useRouter();
const { t } = useI18n({ useScope: "global" });

const isLoadingSummary = ref(false);
const summary = ref<RecResponseSummaryDto | null>(null);

onMounted(async () => {
  isLoadingSummary.value = true;
  summary.value = (await getRecResponseSummaryService()) ?? null;
  isLoadingSummary.value = false;
});
</script>

<template>
  <RecResponseViewLayout v-if="!isLoadingSummary && summary" class="view-rec-resp-summary">
    <SummaryFiles
      v-if="summary.report || summary.attachments"
      :report="summary.report"
      :attachments="summary.attachments"
    />

    <div v-if="summary.showResponse" class="view-rec-resp-summary__response">
      <p
        v-if="summary.recommendationsNeedsResponse"
        class="view-rec-resp-summary__response__header"
      >
        {{ t("recommendationResponse.recommendationsNeedResponse") }}
      </p>
      <p v-else class="view-rec-resp-summary__response__header">
        {{ t("recommendationResponse.allRecommendationsHaveResponse") }}
      </p>
      <BaseButton @click="router.push({ name: RecRespRouteName.Respond })">
        {{ t("recommendationResponse.respondToRecommendations") }}
      </BaseButton>
    </div>

    <hr v-if="summary.showResponse" />

    <SummaryCharts
      v-if="summary.siteResponseStatuses && summary.siteResponseColors"
      :site-response-statuses="summary.siteResponseStatuses as SiteResponseStatusCount"
      :site-response-status-colors="summary.siteResponseColors as SiteResponseColorCount"
    />
  </RecResponseViewLayout>
</template>

<style scoped lang="scss">
.view-rec-resp-summary {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;

  &__response {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__header {
      font-size: $text-base;
      font-weight: $font-semibold;
      margin-bottom: $spacing-2;
      white-space: nowrap;
    }
  }
}
</style>
