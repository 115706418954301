import { reactive, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { authorize } from "@/plugins/can";
import { Role, RotateDegrees, StandardRecommendationDto } from "@/types/_generated/api";
import { EditRecImage } from "@/components/shared/RecImageList/RecImageList.vue";

export class StandardRecValidationState {
  header?: string;
  text?: string;
  clientId?: string;

  hasErrors(): boolean {
    return !!this.header || !!this.text || !!this.clientId;
  }
}

export type StandardRecForm = Omit<StandardRecommendationDto, "images">;

export const useStandardRecommendationForm = (
  standardRec: Ref<StandardRecommendationDto | null | undefined>,
) => {
  const { t } = useI18n({ useScope: "global" });

  const form = reactive<StandardRecForm>({
    standardRecommendationId: 0,
    header: "",
    text: "",
    country: "",
    type: "",
    clientId: undefined,
    moduleId: undefined,
    categories: [],
    questions: [],
  });

  const images = ref<EditRecImage[]>([]);

  const standardRecValue = standardRec.value;

  if (standardRecValue) {
    form.standardRecommendationId = standardRecValue.standardRecommendationId;
    form.header = standardRecValue.header;
    form.text = standardRecValue.text;
    form.country = standardRecValue.country;
    form.type = standardRecValue.type;
    form.clientId = standardRecValue.clientId;
    form.moduleId = standardRecValue.moduleId;
    form.categories = [...standardRecValue.categories];
    form.questions = [...standardRecValue.questions];

    images.value = standardRecValue.images.map((image, index) => ({
      fileId: image.fileId,
      fileName: image.fileName,
      fileSize: image.fileSize,
      caption: image.caption,
      rotation: RotateDegrees.None,
    }));
  }

  const errors = ref<StandardRecValidationState>(new StandardRecValidationState());

  const resetValidation = () => {
    errors.value = new StandardRecValidationState();
  };

  const validate = () => {
    errors.value = new StandardRecValidationState();

    if (!form.header || form.header.length < 1) {
      errors.value.header = t("validations.required");
    }

    if (!form.text || form.text.length < 1) {
      errors.value.text = t("validations.required");
    }

    if (authorize.hasRole(Role.INT) && !form.clientId) {
      errors.value.clientId = t("validations.required");
    }
  };

  watch(form, () => resetValidation(), { deep: true });

  return { form, validate, errors, images };
};
