import useVuelidate from "@vuelidate/core";
import { Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useDialog } from "@/composables/useDialog";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { AdminRouteName } from "@/router/RouteName";
import {
  deleteBlueRatingRecommendation,
  getBlueRatingRecommendationService,
  updateBlueRatingRecommendation,
} from "@/services/recommendations";
import { copyToStandardRecommendation as copyToStandardRecService } from "@/services/standardRecommendations";
import {
  BlueColor,
  CopyToStandardRecommendation,
  LossEstimateType,
  ModuleDto,
  Status,
  BlueRatingDto,
  BlueRatingRecommendationDto,
  SurveyorStatus,
} from "@/types/_generated/api";
import { requiredIf, withI18nMessage } from "@/validation/i18n-validators";

type RecommendationValidation = {
  [k in keyof Partial<BlueRatingRecommendationDto | null>]: object;
};

export const useHandleRecommendations = (
  survey: Ref<BlueRatingDto | null>,
  recommendation: Ref<BlueRatingRecommendationDto | null>,
  module: Ref<ModuleDto | null>,
) => {
  const isLoading = ref(false);
  const dialog = useDialog();
  const router = useRouter();
  const { t } = useI18n({ useScope: "global" });
  const { setCompareBase, discardUnsavedChanges, isModified } = useUnsavedChanges();

  const validation: RecommendationValidation = {
    priority: { required: requiredIf(() => !!module.value?.forcePriority) },
    type: { required: requiredIf(() => !!module.value?.forceType) },
    header: { required: requiredIf(() => !!module.value?.forceName) },
    location: { required: requiredIf(() => !!module.value?.forceLocation) },
    color: {
      colorNotSet: withI18nMessage(() => {
        if (module.value?.forceOverrideCategoryColor) {
          return (
            !!recommendation.value?.categoryColorOverride &&
            recommendation.value?.categoryColorOverride !== "NotSet"
          );
        }
        return true;
      }),
    },
  };

  const v$ = useVuelidate(validation, recommendation, {
    $autoDirty: true,
  });

  const loadRecommendation = async (surveyId: number, recommendationId: number) => {
    isLoading.value = true;

    recommendation.value = await getBlueRatingRecommendationService(surveyId, recommendationId);

    setCompareBase(recommendation);

    isLoading.value = false;
  };

  const save = async () => {
    if (!recommendation.value) {
      return;
    }

    if (!module.value) {
      return;
    }

    v$.value.$validate();
    if (v$.value.$invalid) {
      return;
    }

    isLoading.value = true;

    if (recommendation.value!.surveyorStatus !== SurveyorStatus.Abeyance) {
      recommendation.value!.abeyanceDate = undefined;
      recommendation.value!.abeyanceComment = "";
    }

    if (recommendation.value!.surveyorStatus !== SurveyorStatus.Completed) {
      recommendation.value!.completedDate = undefined;
      recommendation.value!.completedComment = "";
    }

    const id = await updateBlueRatingRecommendation(
      recommendation.value.surveyId,
      recommendation.value,
    );

    if (id !== null) {
      setCompareBase(recommendation);
    }

    isLoading.value = false;
  };

  const updateValue = (
    key: keyof BlueRatingRecommendationDto,
    value: BlueRatingRecommendationDto[keyof BlueRatingRecommendationDto],
  ) => {
    switch (key) {
      case "header":
      case "location":
      case "priority":
      case "abeyanceDate":
      case "abeyanceComment":
      case "completedDate":
      case "completedComment":
      case "text":
      case "comment":
      case "rcbaCurrency":
      case "rcbaDescription":
      case "rcbaProbability":
        recommendation.value![key] = value as string;
        break;
      case "isKeyRecommendation":
        recommendation.value![key] = value as boolean;
        break;
      case "categoryColorOverride":
        recommendation.value![key] = value as BlueColor;
        break;
      case "type": {
        recommendation.value![key] = value as string;
        break;
      }
      case "surveyorStatus":
        recommendation.value![key] = value as SurveyorStatus;
        break;
      case "questionIds":
        recommendation.value![key] = value as number[];
        break;
      case "rcbaLossEstimateType":
        recommendation.value![key] = value as LossEstimateType;
        break;
      case "rcbaPropertyPrior":
      case "rcbaPropertyAfter":
      case "rcbaBiPrior":
      case "rcbaBiAfter":
      case "rcbaExtraPrior":
      case "rcbaExtraAfter":
      case "rcbaCostToComplete":
        recommendation.value![key] = value as number;
        break;
    }
  };

  const removeRecommendation = async () => {
    if (!recommendation.value) {
      return;
    }

    if (survey.value?.status === Status.Published) {
      await dialog.openDialog({
        title: t("blueRating.recommendation.cannotDeletePublishedTitle"),
        description: t("blueRating.recommendation.cannotDeletePublished"),
        confirmText: t("common.actions.ok"),
        hideCancelButton: true,
      });
      return;
    }

    const isConfirmed = await dialog.openDeletePrompt(
      t("blueRating.recommendation.recommendation"),
    );

    if (isConfirmed) {
      await deleteBlueRatingRecommendation(
        recommendation.value.surveyId,
        recommendation.value!.recommendationId,
      );
    }
  };

  const copyToStandardRecommendation = async () => {
    if (!recommendation.value) {
      return;
    }

    if (!module.value) {
      return;
    }

    if (!survey.value) {
      return;
    }

    v$.value.$validate();

    if (v$.value.$invalid) {
      return;
    }

    const carryOn = await dialog.openDialog({
      title: t("blueRating.copyToStandardRecommendationDialog.createTitle"),
      description: t("blueRating.copyToStandardRecommendationDialog.createDescription"),
      confirmText: t("common.actions.create"),
    });

    if (!carryOn) {
      return;
    }

    isLoading.value = true;

    // Only update the recommendation if it's in an editable state
    if (
      isModified(recommendation.value) &&
      (await updateBlueRatingRecommendation(survey.value.surveyId, recommendation.value)) === null
    ) {
      isLoading.value = false;
      return;
    }

    const standardRecommendation: CopyToStandardRecommendation = {
      recommendationId: recommendation.value.recommendationId,
      clientId: module.value.clientId,
      moduleId: module.value.moduleId,
    };

    const id = await copyToStandardRecService(standardRecommendation);

    const viewStdRec = id
      ? await dialog.openDialog({
          title: t("blueRating.copyToStandardRecommendationDialog.editTitle"),
          description: t("blueRating.copyToStandardRecommendationDialog.editDescription"),
          confirmText: t("common.actions.view"),
        })
      : false;

    if (viewStdRec) {
      router.push({
        name: AdminRouteName.StandardRecommendations,
        query: { standardRecommendationId: id },
      });
    }

    isLoading.value = false;
  };

  return {
    v$,
    isHandlingRecommendation: isLoading,
    loadRecommendation,
    saveRecommendation: save,
    updateRecommendationValue: updateValue,
    deleteRecommendation: removeRecommendation,
    copyToStandardRecommendation,
    discardUnsavedChanges,
  };
};
