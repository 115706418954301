import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { handleError } from "@/services/shared/handleError";
import { useNotificationsStore } from "@/stores/notifications";
import {
  type GetMfaOptionsResponse,
  RecResponseMfa,
  type RequestMfaResponse,
} from "@/types/_generated/api";

const { t } = i18n.global;

export const hasSessionService = async (): Promise<boolean> => {
  try {
    await http.get(`/recommendation-response/auth/has-session`);
    return true;
  } catch (error) {
    return false;
  }
};

export const getMfaOptionsService = async (
  linkId: string,
): Promise<GetMfaOptionsResponse | undefined> => {
  try {
    const { data } = await http.get<GetMfaOptionsResponse>(
      `/recommendation-response/auth/mfa-options/${linkId}`,
    );
    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("recResponse.mfa.mfaOptions"),
    });
  }
  return undefined;
};

export const requestMfaCodeService = async (
  linkId: string,
  mfaOption: RecResponseMfa,
): Promise<RequestMfaResponse | undefined> => {
  try {
    const { data } = await http.post<RequestMfaResponse>(
      "/recommendation-response/auth/request-mfa",
      {
        mfaOption,
        linkId,
      },
    );
    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.request").toLowerCase(),
      entity: t("recResponse.mfa.mfaCode"),
    });
  }

  return undefined;
};

export const verifyMfaCodeService = async (code: string): Promise<boolean> => {
  try {
    await http.post("/recommendation-response/auth/verify-mfa", { code });
    return true;
  } catch (error) {
    return false;
  }
};

export const signOutService = async (): Promise<void> => {
  try {
    await http.post("/recommendation-response/auth/logout");
  } catch (error) {
    const notifications = useNotificationsStore();
    notifications.addToast("error", t("errors.unexpectedErrorTitle"), t("signIn.signOutError"));
  }
};
