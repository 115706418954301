<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import type { ErrorObject } from "@vuelidate/core";
import BaseEditorToolbar from "./components/BaseEditorToolbar.vue";
import { useEditor } from "./composables/useEditor";
import { useEventHandler } from "./composables/useEventHandler";

export interface Props {
  value: string;
  disabled?: boolean;
  customHeight?: {
    default?: string;
    min?: string;
    max?: string;
  };
  enableImageUpload?: boolean;
  errors?: ErrorObject[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
  input: [string];
}>();

const editorContainer = ref<HTMLElement | null>(null);

const { editor } = useEditor(editorContainer, props);
const { setupEventListeners } = useEventHandler(editor, editorContainer, emit);

onMounted(() => setupEventListeners());

const customStyle = computed(() => {
  return {
    height: props.customHeight?.default,
    minHeight: props.customHeight?.min,
    maxHeight: props.customHeight?.max || "400px",
  };
});
</script>

<template>
  <div
    class="base-editor"
    :aria-invalid="errors?.length ? true : undefined"
    :disabled="disabled || undefined"
  >
    <BaseEditorToolbar
      v-if="editor"
      :disabled="disabled"
      :editor="editor"
      :enable-image-upload="enableImageUpload"
    />

    <div
      ref="editorContainer"
      class="base-editor__rich-text"
      :style="customStyle"
      role="textbox"
      aria-multiline="true"
      aria-label="Body"
      :contenteditable="disabled ? false : true"
    ></div>

    <div v-if="errors?.length && !disabled" class="base-editor__error-message">
      <p v-for="({ $message }, i) in errors" :key="i">
        {{ $message }}
      </p>
    </div>
  </div>
</template>

<style lang="scss">
@import "../styles";

.base-editor {
  &__rich-text {
    overflow-y: auto;
    padding: $spacing-3;
    line-height: $leading-normal;
    overflow-wrap: break-word;
    font-size: $text-sm;
    cursor: text;
    border-radius: 0 0 $rounded-base $rounded-base !important;
    @include input;

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    ul,
    ol {
      padding-left: $spacing-10;
    }
  }

  &__error-message {
    color: $error-4;
    margin-top: $spacing-2;
    line-height: $leading-tight;
  }

  &[disabled] {
    .base-editor__rich-text {
      @include input-disabled;
    }
  }
}
</style>
