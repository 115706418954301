import axios from "axios";
import { downloadFile } from "@/helpers/downloadFile";

export const useDownloadFile = () => {
  const downloadFileWithPostRequest = async (url: string): Promise<void> => {
    try {
      const response = await axios.post(url, null, {
        responseType: "blob",
      });

      // Extract filename from Content-Disposition header or use a default
      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.match(/filename="(.+)"/)?.[1] || "downloaded-file"
        : "downloaded-file";

      // Create a blob URL for the file
      const blobUrl = URL.createObjectURL(response.data);

      // Use the helper to download the file
      downloadFile(blobUrl, filename);

      // Clean up the object URL after download
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      throw new Error(`Failed to download file: ${error}`);
    }
  };

  return {
    downloadFileWithPostRequest,
  };
};
