import { Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useModuleTranslations } from "@/composables/useModuleTranslations";
import { binaryOptions } from "@/helpers/options";
import {
  ModuleDto,
  BlueRatingCategoryDto,
  BlueRatingDto,
  BlueRatingRecommendationLeanDto,
} from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import { Option } from "@/types/Option";
import { useRecommendationSelectOptions } from "@/views/Admin/BlueRating/composables/useRecommendationSelectOptions";

export const useRecommendationTableColumns = (
  survey: Ref<BlueRatingDto>,
  recommendations: Ref<BlueRatingRecommendationLeanDto[]>,
  module: Ref<ModuleDto>,
) => {
  const { t } = useI18n({ useScope: "global" });
  const { moduleTranslations } = useModuleTranslations(module);
  const { surveyorStatusOptions } = useRecommendationSelectOptions(module);

  const allCategories = computed(() => {
    const categories: BlueRatingCategoryDto[] = [];

    if (!survey) {
      return categories;
    }

    return survey.value.areas.reduce(
      (acc, a) => acc.concat(a.categories),
      [] as BlueRatingCategoryDto[],
    );
  });

  const columns = computed((): ColumnDefinition<BlueRatingRecommendationLeanDto>[] => {
    const usedQuestionIds = recommendations.value.map((x) => x.questionIds).flat();

    const questions = (
      survey?.value.areas.flatMap((x) => x.categories.flatMap((y) => y.questions)) || []
    ).filter((x) => usedQuestionIds.includes(x.questionId));

    const columns: ColumnDefinition<BlueRatingRecommendationLeanDto>[] = [
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.position"),
        key: "fullPosition",
        autoWidth: true,
      }),
      new ColumnDefinition({
        title: moduleTranslations.value.name,
        key: "header",
        autoWidth: true,
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.category"),
        key: "categoryId",
        autoWidth: true,
        searchOptions: allCategories.value.map((category) => ({
          title: category.name,
          value: category.categoryId.toString(),
        })),
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.question"),
        key: "questionIds",
        autoWidth: true,
        searchOptions: [
          { title: t("blueRating.recommendation.anyQuestion"), value: "" },
          ...questions.map((x) => ({
            title: `${x.fullPosition} ${x.description}`,
            value: x.questionId.toString(),
          })),
        ],
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.text"),
        key: "text",
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.status"),
        key: "surveyorStatus",
        autoWidth: true,
        searchOptions: surveyorStatusOptions as Option[],
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.isKeyRecommendation"),
        key: "isKeyRecommendation",
        type: ColumnDataType.Boolean,
        autoWidth: true,
        searchOptions: binaryOptions,
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.priority"),
        key: "priority",
        autoWidth: true,
        defaultHidden: true,
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.comment"),
        key: "comment",
        autoWidth: true,
        defaultHidden: true,
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.location"),
        key: "location",
        autoWidth: true,
        defaultHidden: true,
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.abeyanceDate"),
        key: "abeyanceDate",
        autoWidth: true,
        defaultHidden: true,
        type: ColumnDataType.Date,
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.abeyanceComment"),
        key: "abeyanceComment",
        autoWidth: true,
        defaultHidden: true,
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.completedDate"),
        key: "completedDate",
        autoWidth: true,
        defaultHidden: true,
        type: ColumnDataType.Date,
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.completedComment"),
        key: "completedComment",
        autoWidth: true,
        defaultHidden: true,
      }),
      new ColumnDefinition({
        title: t("blueRating.recommendation.properties.isGeneralRecommendation"),
        key: "isGeneralRecommendation",
        autoWidth: true,
        type: ColumnDataType.Boolean,
        searchOptions: binaryOptions,
      }),
    ];

    return columns;
  });

  return { columns };
};
