<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useGetBlueRating } from "@/composables/services/useGetBlueRating";
import { useGetModule } from "@/composables/services/useGetModule";
import { useGetRecommendations } from "@/composables/services/useGetRecommendations";
import { useGetSite } from "@/composables/services/useGetSite";
import { useGetSurvey } from "@/composables/services/useGetSurvey";
import { useSaveSpoeCheck } from "@/composables/services/useSaveSpoeCheck";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import { useRecommendationNavigation } from "@/composables/useRecommendationNavigation";
import { filterAndSortRecommendationsForReports } from "@/helpers/recommendationSorting";
import { UserWebRouteName } from "@/router/RouteName";
import { useAppStore } from "@/stores/app";
import { RecommendationResponseDto, Status, SurveyorStatus } from "@/types/_generated/api";
import UserWebViewLayout from "@/components/app/UserWeb/UserWebViewLayout.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import OnlineRecReportHeader from "@/components/shared/OnlineRecReport/OnlineRecReportHeader.vue";
import OnlineRecReportHeaderSettings from "@/components/shared/OnlineRecReport/OnlineRecReportHeaderSettings.vue";
import OnlineRecReportLoading from "@/components/shared/OnlineRecReport/OnlineRecReportLoading.vue";
import OnlineRecReportMenu from "@/components/shared/OnlineRecReport/OnlineRecReportMenu.vue";
import OnlineRecReportRecommendation from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendation.vue";
import OnlineRecReportRecommendationAbeyanceProperties from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendationAbeyanceProperties.vue";
import OnlineRecReportRecommendationProperties from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendationProperties.vue";
import OnlineRecReportRecommendationRcbaProperties from "@/components/shared/OnlineRecReport/OnlineRecReportRecommendationRcbaProperties.vue";
import LatestRecommendationResponse from "@/components/shared/RecommendationResponse/LatestRecommendationResponse.vue";
import LatestRiskManagerComment from "@/components/shared/RecommendationResponse/LatestRiskManagerComment.vue";
import ResponseAndCommentTimeline from "@/components/shared/RecommendationResponse/ResponseAndCommentTimeline.vue";
import ResponseFileList from "@/components/shared/RecommendationResponse/ResponseFileList.vue";
import SpoeCheckNoAccess from "./components/SpoeCheck/SpoeCheckNoAccess.vue";
import SpoeCheckSave from "./components/SpoeCheck/SpoeCheckSave.vue";
import { useSpoeCheckComments } from "./composables/useSpoeCheckComments";

const props = defineProps<{
  moduleId: number;
  siteId: number;
  surveyId: number;
}>();

const { t } = useI18n({ useScope: "global" });
const { user } = useAppStore();
const { setBreadcrumbs } = useBreadcrumbs();

setBreadcrumbs(
  computed(() => [
    { label: t("userWeb.navigation.sites"), name: UserWebRouteName.Sites },
    { label: site.value?.custom1 || "", name: UserWebRouteName.Site },
    { label: `${t("survey.spoeCheck")} ${survey.value?.surveyDate ?? ""}` },
  ]),
);

// Composables for fetching data
const { module, getModule, isLoadingModule } = useGetModule();
const { siteData: site, getSite, isLoadingSite } = useGetSite();
const { survey, getSurvey, isLoadingSurvey } = useGetSurvey();
const { blueRating, getBlueRating, isLoadingBlueRating } = useGetBlueRating();
const { recommendations, getRecommendations, isLoadingRecommendations } = useGetRecommendations();

const isLoading = computed(
  () =>
    isLoadingModule.value ||
    isLoadingSite.value ||
    isLoadingSurvey.value ||
    isLoadingRecommendations.value ||
    isLoadingBlueRating.value,
);

// View Settings
const settings = ref({
  approved: false,
  notifySurveyor: false,
  publishRiskManagerComments: true,
  showTimeline: false,
  showAbeyance: false,
});

// Access status
const surveyReadyForCheck = computed(() => survey.value?.status === Status.Complete);
const hasAccess = computed(() => user && user?.id === survey.value?.spoeCheckerUserId);
const canViewPage = computed(() => hasAccess.value && surveyReadyForCheck.value);

// Filter and sort recommendations
const categories = computed(() => blueRating.value?.areas.flatMap((a) => a.categories) ?? []);
const sortedRecommendations = computed(() =>
  filterAndSortRecommendationsForReports(
    recommendations.value,
    categories.value,
    settings.value.showAbeyance,
  ),
);

// Spoe check
const hasSpoeCheck = computed(() => survey.value?.isSpoeCheckApproved ?? false);
const { spoeCheckComments, setSpoeCheckComments } = useSpoeCheckComments(user?.id ?? 0);

const { isSavingSpoeCheck, saveSpoeCheck } = useSaveSpoeCheck();

// Navigation
const {
  selectedRecommendation,
  selectRecommendation,
  indexOfSelectedRecommendation,
  selectPreviousRecommendation,
  selectNextRecommendation,
} = useRecommendationNavigation(sortedRecommendations);

onBeforeMount(async () => {
  await Promise.all([
    getModule(props.moduleId),
    getSite(props.siteId),
    getSurvey(props.surveyId),
    getRecommendations(props.surveyId),
    getBlueRating(props.surveyId),
  ]);

  selectedRecommendation.value = sortedRecommendations.value[0] ?? null;
  settings.value.approved = hasSpoeCheck.value;
  setSpoeCheckComments(recommendations.value);
});

const showRcba = computed(
  () =>
    !!(
      selectedRecommendation.value?.rcbaPropertyPrior ||
      selectedRecommendation.value?.rcbaPropertyAfter ||
      selectedRecommendation.value?.rcbaBiPrior ||
      selectedRecommendation.value?.rcbaBiAfter ||
      selectedRecommendation.value?.rcbaExtraPrior ||
      selectedRecommendation.value?.rcbaExtraAfter
    ),
);

const save = async () => {
  await saveSpoeCheck({
    surveyId: props.surveyId,
    isApproved: settings.value.approved,
    notifySurveyor: settings.value.notifySurveyor,
    recommendationSpoeComments: spoeCheckComments.value,
  });

  // Reload survey to get the updated spoe check status
  await getSurvey(props.surveyId);
  settings.value.approved = hasSpoeCheck.value;
};

const replaceRecommendationResponse = (response: RecommendationResponseDto) => {
  if (!response?.id) {
    return;
  }

  const index =
    selectedRecommendation.value?.responseHistory.history.findIndex((r) => r.id === response.id) ??
    -1;

  if (index >= 0) {
    selectedRecommendation.value!.responseHistory.history[index] = response;
  }
};
</script>

<template>
  <UserWebViewLayout>
    <SpoeCheckNoAccess
      v-if="!isLoading && !canViewPage"
      :no-access="!hasAccess"
      :survey-not-ready="!surveyReadyForCheck"
    />

    <OnlineRecReportLoading v-if="isLoading" />

    <div v-if="canViewPage && !isLoading && module && site && survey" class="view-spoe-check">
      <OnlineRecReportHeader
        :module-name="module.name"
        :site-name="site.name"
        :survey-date="survey.surveyDate"
      >
        <template #settings>
          <OnlineRecReportHeaderSettings
            :abeyance-value="settings.showAbeyance"
            :timeline-value="settings.showTimeline"
            show-abeyance
            show-timeline
            @update:abeyance="settings.showAbeyance = $event"
            @update:timeline="settings.showTimeline = $event"
          />
        </template>
      </OnlineRecReportHeader>

      <div class="view-spoe-check__content">
        <div class="view-spoe-check__content__nav">
          <OnlineRecReportMenu
            status="surveyor"
            :recommendations="sortedRecommendations"
            :selected="selectedRecommendation ?? undefined"
            @select="selectRecommendation"
          />

          <div class="view-spoe-check__content__nav__buttons">
            <BaseButton
              variant="outlined"
              :disabled="indexOfSelectedRecommendation <= 0"
              @click="selectPreviousRecommendation"
            >
              {{ t("common.actions.previous") }}
            </BaseButton>

            <BaseButton
              variant="outlined"
              :disabled="indexOfSelectedRecommendation >= sortedRecommendations.length - 1"
              @click="selectNextRecommendation"
            >
              {{ t("common.actions.next") }}
            </BaseButton>
          </div>

          <SpoeCheckSave
            :approved="settings.approved ?? false"
            :notify-surveyor="settings.notifySurveyor"
            :is-saving-spoe-check="isSavingSpoeCheck"
            :has-spoe-check="hasSpoeCheck"
            @save="save"
            @update:approved="settings.approved = $event"
            @update:notify-surveyor="settings.notifySurveyor = $event"
          />
        </div>

        <template
          v-for="recommendation in sortedRecommendations"
          :key="`rec_${recommendation.recommendationId}`"
        >
          <div
            class="view-spoe-check__content__recommendation"
            :class="{
              'view-spoe-check__content__recommendation--current':
                selectedRecommendation === recommendation,
            }"
          >
            <OnlineRecReportRecommendation
              v-if="recommendation.surveyorStatus === SurveyorStatus.Abeyance"
              :recommendation="recommendation"
            >
              <template #properties>
                <OnlineRecReportRecommendationAbeyanceProperties :recommendation="recommendation" />
              </template>
            </OnlineRecReportRecommendation>

            <OnlineRecReportRecommendation
              v-if="recommendation.surveyorStatus !== SurveyorStatus.Abeyance"
              :recommendation="recommendation"
            >
              <template #properties>
                <OnlineRecReportRecommendationProperties
                  :recommendation="recommendation"
                  :categories="categories"
                />
              </template>

              <template v-if="showRcba" #rcba>
                <OnlineRecReportRecommendationRcbaProperties :rcba="recommendation" />
              </template>

              <template
                v-if="!settings.showTimeline && recommendation.responseHistory.history.length"
                #recommendation-response
              >
                <LatestRecommendationResponse
                  :recommendation-response="recommendation.responseHistory"
                  :title="t('recommendationResponse.responseSummary')"
                >
                  <template #filelist="{ response }">
                    <ResponseFileList :module-id="moduleId" :response="response" />
                  </template>
                </LatestRecommendationResponse>
              </template>
              <template
                v-if="!settings.showTimeline && recommendation.riskManagerCommentHistory.length"
                #risk-manager-comment
              >
                <LatestRiskManagerComment
                  :comments="recommendation.riskManagerCommentHistory"
                  :title="t('recommendationResponse.commentSummary')"
                />
              </template>
              <template v-if="settings.showTimeline" #response-timeline>
                <ResponseAndCommentTimeline
                  :can-edit="true"
                  :module-id="moduleId"
                  :response-history="recommendation.responseHistory.history"
                  :risk-manager-comment-history="recommendation.riskManagerCommentHistory"
                  @update:response="replaceRecommendationResponse"
                />
              </template>

              <template #comments>
                <BaseTextArea
                  :label="t('userWeb.spoeCheck.spoeComment')"
                  :value="spoeCheckComments[recommendation.recommendationId] ?? ''"
                  @update:value="
                    spoeCheckComments[recommendation.recommendationId] = $event as string
                  "
                />
              </template>
            </OnlineRecReportRecommendation>
          </div>
        </template>
      </div>
    </div>
  </UserWebViewLayout>
</template>

<style scoped lang="scss">
.view-spoe-check {
  $nav-width: max(20rem, 30vw);
  $content-width: min(62rem, 60vw);
  $max-width: calc($nav-width + $content-width + $spacing-8);

  &__content {
    display: grid;
    gap: $spacing-8;
    max-width: $max-width;
    grid-template-columns: $nav-width $content-width;

    &__recommendation {
      display: none;
      &--current {
        display: block;
      }
    }

    &__nav {
      display: flex;
      flex-direction: column;
      gap: $spacing-4;

      &__buttons {
        display: flex;
        gap: $spacing-2;
        justify-content: space-between;
      }
    }

    @media print {
      display: block;
      &__nav {
        display: none;
      }
      &__recommendation {
        display: block;
        overflow-y: visible;
      }
    }
  }
}
</style>
