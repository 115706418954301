import axios, { AxiosError } from "axios";
import { i18n } from "@/i18n";
import router from "@/router";
import { AppRouteName } from "@/router/RouteName";
import { useAuthStore } from "@/stores/auth";
import { notify, useNotificationsStore } from "@/stores/notifications";

export const getResponseStatusMessage = (error: unknown): string | null =>
  (error as AxiosError<{ responseStatus: { message: string } }>).response?.data?.responseStatus
    ?.message || null;

export const getResponseStatusErrors = (error: unknown): string[] =>
  (
    (error as AxiosError<{ responseStatus: { errors: { message: string }[] } }>).response?.data
      ?.responseStatus?.errors || []
  ).map((e) => e.message);

const getResponseStatusMessageWithFallback = (error: unknown): string =>
  getResponseStatusMessage(error) ?? t("errors.unexpectedError");

const { t } = i18n.global;

export const handleError = (
  error: unknown | AxiosError,
  on?: { action: string; entity: string },
): void => {
  const isAxiosError = axios.isAxiosError(error);

  if (isAxiosError && error.response?.status && axiosErrorHandlers[error.response?.status]) {
    axiosErrorHandlers[error.response?.status]();
  } else if (isAxiosError && on) {
    handleErrorOnAction(error, on);
  } else {
    handleUnexpectedError(error);
  }
};

const axiosErrorHandlers: Record<number, () => void> = {
  401: () => {
    if (router.currentRoute.value.name !== AppRouteName.Auth) {
      router.push({ name: AppRouteName.Auth });
      useAuthStore().reset();
    }
  },
};

const handleErrorOnAction = (error: unknown | AxiosError, on: { action: string; entity: string }) =>
  notify.failure(on.entity, on.action, getResponseStatusMessageWithFallback(error));

const handleUnexpectedError = (error?: unknown) => {
  useNotificationsStore().addToast(
    "error",
    t("errors.unexpectedErrorTitle"),
    t("errors.unexpectedError"),
  );

  // eslint-disable-next-line no-console
  console.error(error);

  const auth = useAuthStore();

  if (!auth.isAuthenticated) {
    axiosErrorHandlers[401]();
  }
};
