import { ref, onMounted } from "vue";
import { useGetSites } from "@/composables/services/useGetSites";
import { type SiteLeanDto } from "@/types/_generated/api";
import type { DashboardMapSite } from "../types";

// transform SiteDto to DashboardMapSite
const transformCoordinates = (sites: SiteLeanDto[]): DashboardMapSite[] => {
  return sites.map(({ latitude, longitude }) => ({
    latitude: latitude || 0,
    longitude: longitude || 0,
  }));
};

export const useGetCoordinates = (moduleId: number) => {
  const isLoadingCoordinates = ref(false);
  const coordinates = ref<DashboardMapSite[]>([]);

  const { sites, getSites } = useGetSites();

  const fetchSitesWithCoordinates = async () => {
    isLoadingCoordinates.value = true;

    try {
      await getSites(moduleId, true);

      const sitesWithCoordinates = sites.value.filter(
        (s) => s.latitude != null || s.longitude != null,
      );

      coordinates.value = transformCoordinates(sitesWithCoordinates);
    } catch (err) {
      throw new Error(`Failed to fetch site coordinates: ${err}`);
    } finally {
      isLoadingCoordinates.value = false;
    }
  };

  onMounted(() => {
    fetchSitesWithCoordinates();
  });

  return {
    isLoadingCoordinates,
    coordinates,
  };
};
