<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { getStandardRecommendation } from "@/services/standardRecommendations";
import { StandardRecommendationDto, StandardRecommendationFilter } from "@/types/_generated/api";
import StandardRecommendationDetail from "@/views/Admin/Templates/components/StandardRecommendationDetail.vue";
import {
  useStandardRecommendationDetailDrawerTabs,
  StandardRecommendationDetailDrawerTab,
} from "@/views/Admin/Templates/composables/useStandardRecommendationDetailDrawerTabs";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseDrawerTabs from "@/components/base/BaseDrawerTabs/BaseDrawerTabs.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import ModImageList from "@/components/mods/ImageList/ModImageList.vue";
import AddFromStandardRecommendationTable from "./AddFromStandardRecommendationTable.vue";

defineProps<{
  filter: StandardRecommendationFilter;
}>();

const emits = defineEmits<{
  close: [void];
  insert: [standardRecommendationId: number];
  "update:includeAllClients": [includeAllClients: boolean];
}>();

const { t } = useI18n({ useScope: "global" });

const animationTimeMs = "200ms";

const isLoadingTemplate = ref(false);

const standardRecommendation = ref<StandardRecommendationDto | null>(null);

const goBackToTable = () => {
  standardRecommendation.value = null;
  changeTab(StandardRecommendationDetailDrawerTab.General);
};

const setSelected = async (id: number) => {
  standardRecommendation.value = await getStandardRecommendation(id);
  changeTab(StandardRecommendationDetailDrawerTab.General);
};

const { activeTab, changeTab, tabs } = useStandardRecommendationDetailDrawerTabs();
</script>
<template>
  <BaseDrawer
    :title="t('standardRecommendations.standardRecommendations')"
    :width="'50'"
    class="apply-standard-rec-drawer"
    :class="{
      'apply-standard-rec-drawer--selected': !!standardRecommendation,
    }"
    @close="$emit('close')"
  >
    <div class="apply-standard-rec-drawer__container">
      <div class="apply-standard-rec-drawer__container__table">
        <AddFromStandardRecommendationTable
          :filter="filter"
          @update:selected="setSelected"
          @update:include-all-clients="$emit('update:includeAllClients', $event)"
        />
      </div>

      <div class="apply-standard-rec-drawer__container__details">
        <BaseDrawerTabs
          v-if="standardRecommendation"
          :current-tab="activeTab"
          :tabs="tabs"
          @change="changeTab"
        />

        <div class="apply-standard-rec-drawer__container__details__content">
          <StandardRecommendationDetail
            v-if="
              activeTab === StandardRecommendationDetailDrawerTab.General && standardRecommendation
            "
            :is-loading="isLoadingTemplate"
            :standard-recommendation="standardRecommendation!"
          />

          <div class="apply-standard-rec-drawer__images">
            <ModImageList
              v-if="
                standardRecommendation && activeTab === StandardRecommendationDetailDrawerTab.Images
              "
              :images="standardRecommendation?.images || []"
              :url-builder="
                (image) =>
                  `/v1/standard-recommendations/${standardRecommendation?.standardRecommendationId}/images/${image.fileId}`
              "
            />
          </div>
        </div>
      </div>
    </div>

    <template #footer-left>
      <BaseButton v-if="standardRecommendation" variant="outlined" @click="goBackToTable">
        <BaseIcon icon="arrow-left" />
        {{ t("blueRating.recommendation.backToTable") }}
      </BaseButton>
    </template>
    <template #footer-right>
      <BaseButton
        v-if="standardRecommendation"
        @click="() => emits('insert', standardRecommendation!.standardRecommendationId)"
      >
        {{ t("blueRating.recommendation.insertToRecommendation") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.apply-standard-rec-drawer {
  overflow: hidden;

  &__container {
    position: relative;
    display: grid;
    grid-template-columns: 100% 100%;
    gap: $spacing-4;
    transition-duration: v-bind("animationTimeMs");
    transition-property: transform;
    transition-timing-function: ease-out;

    &__table,
    &__details__content {
      padding: $spacing-4;
    }
  }

  &--selected &__container {
    transform: translateX(calc(-100% - $spacing-5));
  }

  &__images {
    padding: $spacing-6;
  }
}
</style>
