import { defineStore } from "pinia";
import { i18n } from "@/i18n";

const { t } = i18n.global;

export type Severity = "default" | "success" | "info" | "warning" | "error";

const closeAfterMs: { [K in Severity]: number } = {
  default: -1,
  success: 4_000,
  info: 20_000,
  warning: 30_000,
  error: 120_000,
};

interface NotificationsState {
  toasts: {
    severity: Severity;
    title: string;
    message: string;
    id: number;
  }[];
}

export const useNotificationsStore = defineStore("notificationsStore", {
  state: () =>
    ({
      errors: [],
      toasts: [],
    }) as NotificationsState,
  getters: {},
  actions: {
    addToast(severity: Severity, title: string, message: string) {
      const id = Math.floor(Math.random() * 10000);

      const closeTimeInMs = closeAfterMs[severity] ?? -1;
      if (closeTimeInMs > 0) {
        setTimeout(() => this.removeToast(id), closeTimeInMs);
      }

      this.toasts.unshift({
        severity,
        title,
        message,
        id,
      });
    },
    removeToast(id: number) {
      const removeIndex = this.toasts.map((item) => item.id).indexOf(id);
      this.toasts.splice(removeIndex, 1);
    },
  },
});

export const notify = {
  failure: (entity: string, action: string, message: string) =>
    createErrorNotification(entity, action, message),
  success: (entity: string, action: string) => {
    useNotificationsStore().addToast(
      "success",
      t("notifications.success.title", { entity, action }),
      t("notifications.success.description", { entity, action }),
    );
  },
};

const createErrorNotification = (entity: string, action: string, message: string) => {
  useNotificationsStore().addToast(
    "error",
    t("notifications.failure.title", { entity, action, message }),
    `${t("notifications.failure.description", {
      entity,
      action,
      message,
    }).replace(/\.$/, "")}. ${t("notifications.failure.ifProblemPersists")}`,
  );
};
