<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { loginTypes } from "@/helpers/options";
import { LoginType } from "@/types/_generated/api";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const { t } = useI18n({ useScope: "global" });

interface Props {
  value: string;
  errors?: ErrorObject[];
}

withDefaults(defineProps<Props>(), { errors: () => [] });

const emit = defineEmits<{
  (event: "update:value", option: LoginType): void;
}>();
</script>

<template>
  <BaseSelect
    data-test="user-login-type-select"
    :label="t('users.loginType')"
    :value="value"
    :errors="errors"
    @change="emit('update:value', $event as LoginType)"
  >
    <BaseOption v-for="loginType in loginTypes" :key="loginType.value" :value="loginType.value">
      {{ loginType.title }}
    </BaseOption>
  </BaseSelect>
</template>
