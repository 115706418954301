<script setup lang="ts">
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import { useTableFilter } from "@/composables/useTableFilter";
import { QuestionnaireLeanDto } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

const props = withDefaults(
  defineProps<{
    templates: QuestionnaireLeanDto[];
    isLoading: boolean;
    selected: QuestionnaireLeanDto | null;
  }>(),
  {
    templates: () => [],
    isLoading: false,
    selected: null,
  },
);

defineEmits<{
  (event: "select", selected: QuestionnaireLeanDto | null): void;
}>();

const { t } = useI18n({ useScope: "global" });

const columns = [
  new ColumnDefinition({
    title: t("questionnaireTableTemplates.area"),
    key: "name",
  }),
  new ColumnDefinition({
    title: t("questionnaireTableTemplates.questionnaireName"),
    key: "questionnaireName",
  }),
  new ColumnDefinition({
    title: t("questionnaireTableTemplates.industry"),
    key: "industry",
  }),
  new ColumnDefinition({
    title: t("questionnaireTableTemplates.riskType"),
    key: "riskType",
  }),

  new ColumnDefinition({
    title: t("questionnaireTableTemplates.tags"),
    key: "tags",
    type: ColumnDataType.Array,
  }),
  new ColumnDefinition({
    title: t("questionnaireTableTemplates.insertBy"),
    key: "insertBy",
  }),
  new ColumnDefinition({
    title: t("questionnaireTableTemplates.isTemplate"),
    key: "isTemplate",
    type: ColumnDataType.Boolean,
  }),
] as ColumnDefinition<QuestionnaireLeanDto>[];

let { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } = useTableFilter(
  props.templates,
  columns,
);

watch(
  () => props.templates,
  (templates) => {
    ({ result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
      useTableFilter(templates, columns));
  },
);
</script>

<template>
  <BaseGridTable
    :rows="result.result"
    :total-row-count="result.all.length"
    :columns="columns"
    :is-loading="isLoading"
    storage-key="questionnaireDraftTemplateTable"
    :default-sort="[{ key: 'name' }]"
    :enable-row-click="true"
    @click:row="$emit('select', $event)"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #column-tags="{ row }">
      {{ [...row.tags].join(", ") }}
    </template>
    <template #column-isTemplate="{ row }">
      <BaseIcon v-if="row.isTemplate" icon="check" />
    </template>
  </BaseGridTable>
</template>

<style scoped lang="scss">
.questionnaire-draft__add-from-template__table {
  &__hover-list {
    left: 100%;
    top: -$spacing-2;
    z-index: 1;
    position: absolute;
    padding: $spacing-2;
    background-color: #fff;
    border: 1px solid $primary-3;
  }
}
</style>
