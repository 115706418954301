<script setup lang="ts">
import { computed, toRef } from "vue";
import { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { useSiteResponseStatusChartMetaData } from "@/composables/useSiteStatusMetaData";
import { SiteResponseStatus } from "@/types/_generated/api";
import { useTrackedRecsGroupedBySiteResponseStatus } from "../composables/useTrackedRecsGroupedBySiteResponseStatus";
import { useUniqueCategoryNames } from "../composables/useUniqueCategoryNames";
import { ORDERED_SITE_RESPONSE_STATUSES } from "../constants/orderedSiteResponseStatuses";
import RecTrackingStackBarChart from "./RecTrackingStackBarChart.vue";

const props = defineProps<{
  chartData: TrackedRecommendation[];
}>();

const { trackedRecsGroupedBySiteResponseStatus } = useTrackedRecsGroupedBySiteResponseStatus(
  toRef(props.chartData),
);
const { categoryIdToNameMap } = useUniqueCategoryNames(toRef(props.chartData));
const { statusData } = useSiteResponseStatusChartMetaData();

const generateSeriesDataForCategories = (
  categorizedData: Record<SiteResponseStatus, TrackedRecommendation[]>,
  siteResponseStatuses: SiteResponseStatus[],
  categoryIdToNameMap: Map<number, string>,
) => {
  const categoryIds = Array.from(categoryIdToNameMap.keys());

  // Initialize a 2D array to hold the data
  // The first dimension represents the possible site response statuses
  // The second dimension represents the number of recommendations with that status for each category
  const data: number[][] = Array.from({ length: siteResponseStatuses.length }, () =>
    Array(categoryIds.length).fill(0),
  );

  // Fill the data array
  siteResponseStatuses.forEach((status, statusIndex) => {
    const dataForStatus = categorizedData[status] || [];

    dataForStatus.forEach(({ categoryId }) => {
      const categoryIndex = categoryId ? categoryIds.indexOf(categoryId) : -1;
      if (categoryIndex !== -1) {
        data[statusIndex][categoryIndex]++;
      }
    });
  });

  // Calculate the total values for each category
  const totals = data[0].map((_, colIndex) => data.reduce((sum, row) => sum + row[colIndex], 0));

  // Create an array of category indices sorted by their total values in descending order
  const sortedIndices = totals
    .map((total, index) => ({ total, index }))
    .sort((a, b) => b.total - a.total)
    .map(({ index }) => index);

  // Sort the category columns by maximum total value
  const sortedData = data.map((row) => sortedIndices.map((index) => row[index]));

  // Sorting category names by total value
  const sortedCategoryNames = sortedIndices.map(
    (index) => Array.from(categoryIdToNameMap.values())[index],
  );

  return { sortedData, sortedCategoryNames };
};

const combinedChartData = computed(() => {
  return generateSeriesDataForCategories(
    trackedRecsGroupedBySiteResponseStatus.value,
    ORDERED_SITE_RESPONSE_STATUSES,
    categoryIdToNameMap.value,
  );
});

// Extract colors for the chart from useSiteResponseStatusChartMetaData
const seriesColorsSiteResponseStatus = Object.fromEntries(
  ORDERED_SITE_RESPONSE_STATUSES.map((status) => [
    statusData[status].label,
    statusData[status].color,
  ]),
);

const chartSeries = computed(() =>
  ORDERED_SITE_RESPONSE_STATUSES.map((status) => statusData[status].label),
);
</script>

<template>
  <RecTrackingStackBarChart
    :labels="combinedChartData.sortedCategoryNames"
    :colors="seriesColorsSiteResponseStatus"
    :series="chartSeries"
    :data="combinedChartData.sortedData"
  />
</template>
