<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import {
  createExemption,
  updateExemption,
  deleteExemption as deleteExemptionService,
} from "@/services/siteExemptions";
import { SiteExemptionDto } from "@/types/_generated/api";
import { required } from "@/validation/i18n-validators";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  siteId: number;
  exemption: SiteExemptionDto | null;
}>();

const emit = defineEmits(["close"]);

const exemption = ref<SiteExemptionDto>(
  props.exemption ?? {
    exemptionId: 0,
    number: "",
    location: "",
    comment: "",
    text: "",
    addedDate: new Date().toISOString().substring(0, 10),
    reviewedDate: undefined,
    addedByFullName: "",
    reviewedByFullName: undefined,
    addedByUserId: 0,
    reviewedByUserId: undefined,
  },
);
const { setCompareBase, discardUnsavedChanges } = useUnsavedChanges(exemption);

const isEditMode = computed(() => !!exemption.value.exemptionId);

const { v$ } = useVuelidate(
  { number: { required }, text: { required }, addedDate: { required } },
  exemption,
  {
    $autoDirty: true,
  },
);

const saveExemption = async () => {
  v$.value.$validate();

  if (v$.value.$invalid) {
    return;
  }

  if (isEditMode.value) {
    await updateExemption({ siteId: props.siteId, ...exemption.value });
    setCompareBase(exemption);
    return;
  }

  if (await createExemption({ siteId: props.siteId, ...exemption.value })) {
    emit("close");
  }
};

const deleteExemption = async () => {
  if (await deleteExemptionService(props.siteId, exemption.value.exemptionId)) {
    emit("close");
  }
};

const closeDrawer = async () => {
  if (await discardUnsavedChanges(exemption)) {
    emit("close");
  }
};

const setReviewDate = (date: string) =>
  (exemption.value.reviewedDate = date.length ? date : undefined);
</script>

<template>
  <BaseDrawer
    width="30"
    data-test="exemption-edit-drawer"
    :title="`${isEditMode ? t('common.actions.edit') : t('common.actions.create')} ${t('sites.exemptions.exemption').toLowerCase()}`"
    @close="closeDrawer"
  >
    <div class="exemption-edit-drawer">
      <div class="exemption-edit-drawer__inputs">
        <BaseTextField
          required
          :errors="v$.number?.$errors"
          :label="t('sites.exemptions.number')"
          :value="exemption.number"
          @change:value="exemption.number = $event as string"
        />

        <BaseTextField
          :label="t('sites.exemptions.location')"
          :value="exemption.location"
          @change:value="exemption.location = $event as string"
        />

        <BaseTextArea
          :label="t('sites.exemptions.comment')"
          :value="exemption.comment"
          @change:value="exemption.comment = $event as string"
        />

        <BaseTextArea
          required
          :errors="v$.text?.$errors"
          :label="t('sites.exemptions.text')"
          :value="exemption.text"
          @change:value="exemption.text = $event as string"
        />

        <BaseTextField
          v-if="!isEditMode"
          required
          type="date"
          :errors="v$.addedDate?.$errors"
          :label="t('sites.exemptions.addedDate')"
          :value="exemption.addedDate"
          @change:value="exemption.addedDate = $event as string"
        />

        <BaseTextField
          v-if="isEditMode"
          type="date"
          :min="new Date().toISOString().substring(0, 10)"
          :label="t('sites.exemptions.reviewedDate')"
          :value="exemption.reviewedDate"
          @change:value="setReviewDate($event as string)"
        />
      </div>

      <BaseDataDisplayList v-if="isEditMode">
        <BaseDataDisplayListItem :label="t('sites.exemptions.addedBy')">
          <p>{{ exemption.addedByFullName }}</p>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem :label="t('sites.exemptions.addedDate')">
          <p>{{ exemption.addedDate }}</p>
        </BaseDataDisplayListItem>
        <BaseDataDisplayListItem
          v-if="exemption.reviewedByFullName"
          :label="t('sites.exemptions.reviewedBy')"
        >
          <p>{{ exemption.reviewedByFullName }}</p>
        </BaseDataDisplayListItem>
      </BaseDataDisplayList>
    </div>

    <template #footer-left>
      <BaseButton data-test="exemption-save-button" @click="saveExemption">
        {{ isEditMode ? t("common.actions.save") : t("common.actions.create") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="closeDrawer">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>

    <template #footer-right>
      <BaseButton v-if="isEditMode" color="error" variant="outlined" @click="deleteExemption">
        {{ t("common.actions.delete") }}
      </BaseButton>
    </template>
  </BaseDrawer>
</template>

<style lang="scss" scoped>
.exemption-edit-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;
  padding: $spacing-6;

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }
}
</style>
