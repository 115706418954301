<script setup lang="ts">
import { onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { selectedTemplateParts } from "@/helpers/questionnaireTreeSelectHelper";
import { getQuestionnaire, getQuestionnaires } from "@/services/questionnaire";
import {
  insertIntoQuestionnaireDraft,
  insertIntoQuestionnaireDraftArea,
  insertIntoQuestionnaireDraftCategory,
} from "@/services/questionnaire-draft";
import { QuestionnaireDto, QuestionnaireLeanDto } from "@/types/_generated/api";
import { InsertPoint } from "@/types/InsertPoint";
import AppOverflowContainer from "@/components/app/AppOverflowContainer.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import QuestionnaireViewer from "@/components/shared/Questionnaire/QuestionnaireViewer.vue";
import QuestionnaireCheckboxNav from "../../QuestionnaireCheckboxNav/QuestionnaireCheckboxNav.vue";
import QuestionnaireDraftAddFromTemplateTable from "./QuestionnaireDraftAddFromTemplateTable.vue";

const props = defineProps<{
  questionnaireId: number;
  insertPoint: InsertPoint;
  insertIntoId: number;
}>();

const $emit = defineEmits<{
  (event: "close"): void;
}>();

const isLoadingList = ref(false);
const isLoadingTemplate = ref(false);
const questionnaires = ref<QuestionnaireLeanDto[]>([]);
const selected = ref<QuestionnaireLeanDto | null>(null);
const template = ref<QuestionnaireDto | null>(null);
const animationTimeMs = ref("200ms");
const { t } = useI18n({ useScope: "global" });

onBeforeMount(async () => {
  isLoadingList.value = true;

  questionnaires.value = [];
  questionnaires.value = (await getQuestionnaires(true)) || [];

  isLoadingList.value = false;
});

watch(
  () => selected.value,
  async (selectedItem) => {
    if (!selectedItem) {
      // Wait for animation
      setTimeout(() => (template.value = null), parseInt(animationTimeMs.value));
      return;
    }

    isLoadingTemplate.value = true;

    const questionnaire = await getQuestionnaire(selectedItem.questionnaireId);

    // Check selected.value instead of selectedItem because it might have changed
    if (questionnaire?.questionnaireId === selected.value?.questionnaireId) {
      template.value = questionnaire;

      // Reset selected template parts
      selectedTemplateParts.value = [];
    }

    isLoadingTemplate.value = false;
  },
);

// Insert selected template parts into draft
const insertInDraft = async () => {
  let success = false;
  switch (props.insertPoint) {
    case "Questionnaire":
      success = await insertIntoQuestionnaireDraft({
        questionnaireId: props.questionnaireId,
        from: {
          questionnaireId: template.value!.questionnaireId,
          areaIds: selectedTemplateParts.value.filter((x) => x.type === "area").map((x) => x.id),
          categoryIds: selectedTemplateParts.value
            .filter((x) => x.type === "category")
            .map((x) => x.id),
          questionIds: selectedTemplateParts.value
            .filter((x) => x.type === "question")
            .map((x) => x.id),
        },
      });

      break;
    case "Area":
      success = await insertIntoQuestionnaireDraftArea({
        questionnaireId: props.questionnaireId,
        toAreaId: props.insertIntoId,
        from: {
          questionnaireId: template.value!.questionnaireId,
          categoryIds: selectedTemplateParts.value
            .filter((x) => x.type === "category")
            .map((x) => x.id),
          questionIds: selectedTemplateParts.value
            .filter((x) => x.type === "question")
            .map((x) => x.id),
        },
      });
      break;
    case "Category":
      success = await insertIntoQuestionnaireDraftCategory({
        questionnaireId: props.questionnaireId,
        toCategoryId: props.insertIntoId,
        from: {
          questionnaireId: template.value!.questionnaireId,
          questionIds: selectedTemplateParts.value
            .filter((x) => x.type === "question")
            .map((x) => x.id),
        },
      });
      break;
  }

  if (success) {
    $emit("close");
  }
};

const getInsertTypes = (): string => {
  switch (props.insertPoint) {
    case "Questionnaire":
      return [
        [t("questionnaire.types.areas"), t("questionnaire.types.categories")].join(", "),
        t("questionnaire.types.questions"),
      ].join(" & ");
    case "Area":
      return [t("questionnaire.types.categories"), t("questionnaire.types.questions")].join(" & ");
    case "Category":
      return t("questionnaire.types.questions");
    default:
      throw new Error("InsertPoint not found");
  }
};
</script>

<template>
  <BaseDrawer
    class="questionnaire-draft__add-from-template-drawer"
    :class="{
      'questionnaire-draft__add-from-template-drawer--selected': !!selected,
    }"
    :title="
      t('questionnaire.draft.template.drawerTitle', {
        types: getInsertTypes(),
      })
    "
    :width="'75'"
    @close="$emit('close')"
  >
    <div class="questionnaire-draft__add-from-template-drawer__container">
      <div class="questionnaire-draft__add-from-template-drawer__container__table">
        <QuestionnaireDraftAddFromTemplateTable
          :templates="questionnaires"
          :is-loading="isLoadingList"
          :selected="selected"
          @select="($event) => (selected = $event)"
        />
      </div>
      <div class="questionnaire-draft__add-from-template-drawer__container__template">
        <div class="questionnaire-draft__add-from-template-drawer__container__template__back">
          <BaseButton variant="outlined" @click="selected = null">
            <BaseIcon icon="arrow-left" />
            {{ t("questionnaire.draft.template.backToTable") }}
          </BaseButton>
        </div>

        <div class="questionnaire-draft__add-from-template-drawer__container__template__nav">
          <AppOverflowContainer :margin="'191px'">
            <QuestionnaireCheckboxNav
              :questionnaire="template || undefined"
              :insert-point="insertPoint"
            />
          </AppOverflowContainer>
        </div>
        <div class="questionnaire-draft__add-from-template-drawer__container__template__view">
          <AppOverflowContainer :margin="'150px'">
            <QuestionnaireViewer :questionnaire="template || undefined" />
          </AppOverflowContainer>
        </div>
      </div>
    </div>

    <template #footer-left>
      <BaseButton
        :disabled="!template || selectedTemplateParts.length === 0"
        @click="() => insertInDraft()"
      >
        {{ t("questionnaire.draft.template.add") }}
      </BaseButton>
    </template>

    <template #footer-right>
      <div v-if="template" class="questionnaire-draft__add-from-template-drawer__footer">
        <span v-if="insertPoint === 'Questionnaire'">
          {{ t("questionnaire.draft.template.selectedAreas") }}
          {{ selectedTemplateParts.filter((x) => x.type === "area").length }}
        </span>
        <span v-if="['Questionnaire', 'Area'].includes(insertPoint)">
          {{ t("questionnaire.draft.template.selectedCategories") }}
          {{ selectedTemplateParts.filter((x) => x.type === "category").length }}
        </span>
        <span v-if="['Questionnaire', 'Area', 'Category'].includes(insertPoint)">
          {{ t("questionnaire.draft.template.selectedQuestions") }}
          {{ selectedTemplateParts.filter((x) => x.type === "question").length }}
        </span>
      </div>
    </template>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.questionnaire-draft__add-from-template-drawer {
  overflow: hidden;

  &__container {
    position: relative;
    display: grid;
    grid-template-columns: 100% 100%;
    gap: $spacing-5;
    transition-duration: v-bind("animationTimeMs");
    transition-property: transform;
    transition-timing-function: ease-out;

    &__template {
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: auto 1fr;
      grid-template-areas:
        "back view"
        "nav view";

      &__back {
        grid-area: back;
      }
      &__nav {
        grid-area: nav;
      }
      &__view {
        grid-area: view;
      }
    }
  }

  &--selected &__container {
    transform: translateX(calc(-100% - $spacing-5));
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}
</style>
