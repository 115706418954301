import http from "@/helpers/http";
import { i18n } from "@/i18n";
import {
  BlueTrendResultDto,
  IDataAnalysisFilter,
  SearchBlueLossValuesResponse,
  SearchBlueTrendResponse,
} from "@/types/_generated/api";
import { DaatServerFilter } from "@/views/UserWeb/DataAnalysisAndTrends/composables/useFilterServer";
import { BlueLossValueLookup } from "@/views/UserWeb/DataAnalysisAndTrends/types/BlueLossValueLookup";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const getBlueTrendService = async (
  moduleId: number,
  filter: IDataAnalysisFilter,
): Promise<BlueTrendResultDto[] | null> => {
  try {
    const { data } = await http.post<SearchBlueTrendResponse>(
      `/modules/${moduleId}/data-analysis/blue-trend`,
      filter,
    );

    return data.blueTrend;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("userWeb.daat.blueTrend"),
    });
  }
  return null;
};

export const getBlueLossValuesService = async (
  moduleId: number,
  filter: DaatServerFilter,
): Promise<BlueLossValueLookup> => {
  try {
    const { data } = await http.post<SearchBlueLossValuesResponse>(
      `/modules/${moduleId}/data-analysis/blue-loss-values`,
      { ...filter, lossScenarioIds: Array.from(filter.lossScenarioIds) },
    );

    return data.blueLossValues;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("userWeb.daat.tabs.lossValues"),
    });
    throw error;
  }
};
