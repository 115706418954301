import { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useModuleTranslations } from "@/composables/useModuleTranslations";
import { BlueColor, ModuleDto, SurveyorStatus } from "@/types/_generated/api";
import { Option } from "@/types/Option";

export const useRecommendationSelectOptions = (module: Ref<ModuleDto>) => {
  const { t } = useI18n({ useScope: "global" });

  const { moduleTranslations } = useModuleTranslations(module);

  const surveyorStatusOptions: readonly Option[] = [
    {
      title: moduleTranslations.value.abeyance,
      value: SurveyorStatus.Abeyance,
    },
    {
      title: t("blueRating.surveyStatus.completed"),
      value: SurveyorStatus.Completed,
    },
    {
      title: t("blueRating.surveyStatus.inProgress"),
      value: SurveyorStatus.InProgress,
    },
    {
      title: t("blueRating.surveyStatus.noProgress"),
      value: SurveyorStatus.NoProgress,
    },
    {
      title: t("blueRating.surveyStatus.notSet"),
      value: SurveyorStatus.NotSet,
    },
    {
      title: t("blueRating.surveyStatus.rejected"),
      value: SurveyorStatus.Rejected,
    },
    {
      title: t("blueRating.surveyStatus.underReview"),
      value: SurveyorStatus.UnderReview,
    },
    {
      title: t("blueRating.surveyStatus.withdrawn"),
      value: SurveyorStatus.Withdrawn,
    },
  ];

  const defaultRecommendationTypeOptions: readonly string[] = [
    t("blueRating.recommendationTypes.humanElement"),
    t("blueRating.recommendationTypes.investment"),
    t("blueRating.recommendationTypes.produce"),
  ];

  const colorOverrideOptions: readonly Option[] = [
    {
      title: moduleTranslations.value.textForGreen,
      value: BlueColor.Green,
    },
    {
      title: moduleTranslations.value.textForYellow,
      value: BlueColor.Yellow,
    },
    {
      title: moduleTranslations.value.textForRed,
      value: BlueColor.Red,
    },
  ];

  return {
    colorOverrideOptions,
    defaultRecommendationTypeOptions,
    surveyorStatusOptions,
  };
};
