import { ref } from "vue";
import { SurveyReportRecipientDto } from "@/types/_generated/api";

export type ReportMailRecipient = SurveyReportRecipientDto & {
  firstName?: string;
  lastName?: string;
  company?: string;
  title?: string;
};

export const useReportMailRecipients = () => {
  const recipient = ref<ReportMailRecipient>();
  const recipients = ref<ReportMailRecipient[]>([]);

  const addRecipient = (addedRecipient: ReportMailRecipient) => {
    recipients.value = [...recipients.value, addedRecipient];
    recipient.value = newRecipient();
  };

  const newRecipient = () => ({
    email: "",
    mobile: "",
    includeRecommendationResponseLink: false,
    continueRemindingWhenSiteResponseIsGiven: false,
    reminderDates: [],
  });

  const deleteRecipient = (deletedRecipient: ReportMailRecipient) => {
    recipients.value = recipients.value.filter((r) => r !== deletedRecipient);
  };

  const popRecipient = (selectedRecipient: ReportMailRecipient) => {
    recipient.value = recipients.value.find((r) => r === selectedRecipient);
    recipients.value = recipients.value.filter((r) => r !== selectedRecipient);
  };

  const updateRecipient = (updatedRecipient: ReportMailRecipient) => {
    const recipientsWithoutUpdated = recipients.value.filter(
      (r) => r.email !== updatedRecipient.email,
    );

    recipients.value = [...recipientsWithoutUpdated, updatedRecipient];
  };

  return {
    addRecipient,
    deleteRecipient,
    updateRecipient,
    popRecipient,
    recipients,
    recipient,
  };
};
