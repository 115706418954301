<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { SurveyParticipant } from "./SurveyEditDrawerParticipants.vue";
import { type SurveySettingsValidationState } from "../../composables/useValidateSurveySettings";
import SurveyEditDrawerParticipantEmail from "./SurveyEditDrawerParticipantEmail.vue";
import SurveyEditDrawerParticipantMobile from "./SurveyEditDrawerParticipantMobile.vue";

const { t } = useI18n({ useScope: "global" });

export type ParticipantKey = keyof Omit<SurveyParticipant, "participantId">;

const props = defineProps<{
  index: number;
  participant: SurveyParticipant;
  errors?: SurveySettingsValidationState["participants"][number];
}>();

const emit = defineEmits<{
  delete: [number];
  update: [SurveyParticipant];
}>();

const update = (key: ParticipantKey, value: string | number | null | undefined) => {
  emit("update", {
    ...props.participant,
    [key]: value,
  });
};
</script>

<template>
  <div class="survey-edit-drawer__participant">
    <div class="survey-edit-drawer__participant__info">
      <span class="survey-edit-drawer__participant__info__number">
        {{ index + 1 }}
      </span>
      <div v-if="participant.inherited" class="survey-edit-drawer__participant__info__inherited">
        <BaseIcon icon="copy" size="medium" />
      </div>
    </div>
    <div class="survey-edit-drawer__participant__fields">
      <SurveyEditDrawerParticipantEmail
        :value="participant.email"
        :error-message="errors?.email"
        @update:value="update('email', $event)"
      />

      <SurveyEditDrawerParticipantMobile
        :value="participant.mobile"
        :error-message="errors?.mobile"
        @update:value="update('mobile', $event)"
      />

      <BaseTextField
        :placeholder="t('common.company')"
        :value="participant.company"
        @update:value="update('company', $event)"
      />

      <BaseTextField
        :placeholder="t('common.firstName')"
        :value="participant.firstName"
        autocomplete="given-name"
        @update:value="update('firstName', $event)"
      />

      <BaseTextField
        :placeholder="t('common.lastName')"
        :value="participant.lastName"
        @update:value="update('lastName', $event)"
      />

      <BaseTextField
        :placeholder="t('common.title')"
        :value="participant.title"
        @update:value="update('title', $event)"
      />
    </div>

    <BaseButton
      class="survey-edit-drawer__participant__delete-btn"
      variant="text"
      @click="$emit('delete', index)"
    >
      <BaseIcon icon="cross" size="medium" />
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.survey-edit-drawer {
  &__participant {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: $spacing-3;
    padding: $spacing-3;

    background-color: $primary-2;
    border-radius: $rounded-base;

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing-3;
      padding: $spacing-3;

      &__number {
        font-size: $text-lg;
      }

      &__inherited {
        svg {
          fill: $primary-5;
        }
      }
    }

    &__fields {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $spacing-4;
    }

    &__delete-btn {
      align-self: center;
      justify-self: end;
    }
  }
}
</style>
