<script setup lang="ts">
import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { LossScenarioDto } from "@/types/_generated/api";
import { useModuleValidation } from "@/views/Admin/Modules/composables/useModuleValidation";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCard from "@/components/base/BaseCard/BaseCard.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseTextArea from "@/components/base/BaseTextArea.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  lossScenario: LossScenarioDto;
  isTemplateView?: boolean;
  index: number;
}>();

defineEmits<{
  (event: "update:scenario", scenario: LossScenarioDto): void;
  (event: "delete:scenario"): void;
  (event: "select:scenario", scenario: LossScenarioDto): void;
}>();

const { lossScenarioValidation } = useModuleValidation();

const { v$ } = props.isTemplateView
  ? { v$: null }
  : useVuelidate(
      lossScenarioValidation,
      toRef(() => props.lossScenario),
      {
        $registerAs: `edit-module-loss-scenario-${props.index}`,
        $autoDirty: true,
      },
    );
</script>
<template>
  <BaseCard>
    <div class="edit-module__add-loss-scenario">
      <div class="edit-module__add-loss-scenario__form">
        <BaseTextField
          :label="t('common.name')"
          :value="lossScenario.name"
          :maxlength="50"
          :disabled="isTemplateView"
          :errors="v$?.name?.$errors"
          @change:value="
            $emit('update:scenario', {
              ...lossScenario,
              name: $event as string,
            })
          "
        />
        <BaseTextArea
          :label="t('modules.propertyDamage')"
          :value="lossScenario.propertyDamage"
          :maxlength="1000"
          :disabled="isTemplateView"
          :errors="v$?.propertyDamage?.$errors"
          @change:value="
            $emit('update:scenario', {
              ...lossScenario,
              propertyDamage: $event as string,
            })
          "
        />
        <BaseTextArea
          :label="t('modules.businessInterruption')"
          :value="lossScenario.businessInterruption"
          :maxlength="1000"
          :disabled="isTemplateView"
          :errors="v$?.businessInterruption?.$errors"
          @change:value="
            $emit('update:scenario', {
              ...lossScenario,
              businessInterruption: $event as string,
            })
          "
        />
      </div>
      <div v-if="!isTemplateView" class="edit-module__add-loss-scenario__button">
        <BaseButton
          variant="text"
          color="error"
          :title="`${t('common.actions.delete')} ${t('modules.lossScenario')}`"
          @click="$emit('delete:scenario')"
        >
          <BaseIcon icon="trash" />
        </BaseButton>
      </div>

      <div v-if="isTemplateView" class="edit-module__add-loss-scenario__button">
        <BaseButton variant="outlined" @click="$emit('select:scenario', lossScenario)">
          {{ t("common.actions.select") }} {{ t("modules.lossScenario") }}
        </BaseButton>
      </div>
    </div>
  </BaseCard>
</template>
<style scoped lang="scss">
.edit-module {
  &__add-loss-scenario {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: $spacing-4;

    &__form {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $spacing-4;
    }

    &__button {
      margin: $spacing-6 0 0 $spacing-4;
    }
  }
}
</style>
