<script setup lang="ts">
import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { languages } from "@/helpers/options";
import { Language, ModuleDto, Status } from "@/types/_generated/api";
import { Option } from "@/types/Option";
import { useModuleValidation } from "@/views/Admin/Modules/composables/useModuleValidation";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseOptionGroup from "@/components/base/BaseSelect/BaseOptionGroup.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";
import type { UserGroup } from "../../../composables/useModuleUsers";

const props = defineProps<{
  module: ModuleDto;
  accountEngineers: UserGroup[];
  contacts: UserGroup[];
}>();

const emits = defineEmits<{
  (event: "update:module", module: ModuleDto): void;
}>();

const { t } = useI18n({ useScope: "global" });

const { generalValidation } = useModuleValidation();

const { v$ } = useVuelidate(
  generalValidation,
  toRef(() => props.module),
  {
    $registerAs: "edit-module-general",
    $autoDirty: true,
  },
);

const setEngineerById = (userId: number) => {
  const users = props.accountEngineers.flatMap((group) => group.users);
  const user = users.find((user) => user.id === userId);

  if (user) {
    emits(
      "update:module",
      Object.assign(props.module, {
        accountEngineerId: user.id,
        accountEngineerRole: user.role,
        accountEngineerFullName: user.fullName,
        accountEngineerEmail: user.email,
      }),
    );
  }
};

const setContactById = (userId: number | string) => {
  const id = parseInt(userId as string);

  if (isNaN(id)) {
    emits(
      "update:module",
      Object.assign(props.module, {
        contactFullName: "",
        contactId: null,
        contactEmail: "",
      }),
    );

    return;
  }

  const users = props.contacts.flatMap((group) => group.users);
  const user = users.find((user) => user.id === id);

  if (user) {
    emits(
      "update:module",
      Object.assign(props.module, {
        contactFullName: user.fullName,
        contactId: user.id,
        contactEmail: user.email,
      }),
    );
  }
};

const statusOptions: readonly Option[] = [
  {
    title: t(`common.statuses.${Status.Published}`),
    value: Status.Published,
  },
  {
    title: t(`common.statuses.${Status.UnderConstruction}`),
    value: Status.UnderConstruction,
  },
  {
    title: t(`common.archived`),
    value: Status.History,
  },
];
</script>

<template>
  <div class="edit-module-general">
    <div class="edit-module-general__two-columns">
      <BaseTextField
        :label="t('common.name')"
        :value="module.name"
        data-test="module-general-name"
        :errors="v$.name?.$errors"
        :required="module.name === ''"
        @update:value="$emit('update:module', { ...module, name: $event as string })"
      />
      <BaseSelect
        :label="t('modules.status')"
        :value="module.status"
        data-test="module-general-status"
        @change="$emit('update:module', { ...module, status: $event as Status })"
      >
        <BaseOption v-for="option in statusOptions" :key="option.value" :value="option.value">
          {{ option.title }}
        </BaseOption>
      </BaseSelect>

      <BaseSelect
        :required="!module.language"
        :errors="v$.language?.$errors"
        :label="t('common.language')"
        :value="module.language"
        data-test="module-general-language"
        @change="$emit('update:module', { ...module, language: $event as Language })"
      >
        <BaseOption v-for="language in languages" :key="language.value" :value="language.value">
          {{ language.title }}
        </BaseOption>
      </BaseSelect>

      <BaseSelect
        :label="t('modules.moduleContact')"
        :value="module.contactId"
        data-test="module-general-contact"
        @change="(event) => setContactById(Number(event))"
      >
        <BaseOptionGroup
          v-for="(group, i) in contacts"
          :key="`contact-grp-${i}`"
          :label="group.title"
        >
          <BaseOption v-for="(user, j) in group.users" :key="`contact-${i}-${j}`" :value="user.id">
            {{ user.fullName }}
          </BaseOption>
        </BaseOptionGroup>
      </BaseSelect>
    </div>

    <hr />
    <BaseSelect
      :label="t('modules.accountEngineer')"
      :value="module.accountEngineerId"
      data-test="module-general-engineer"
      @change="(event) => setEngineerById(Number(event))"
    >
      <BaseOptionGroup
        v-for="(group, i) in accountEngineers"
        :key="`accountEngineer-grp-${i}`"
        :label="group.title"
      >
        <BaseOption
          v-for="(user, j) in group.users"
          :key="`accountEngineer-${i}-${j}`"
          :value="user.id"
        >
          {{ user.fullName }}
        </BaseOption>
      </BaseOptionGroup>
    </BaseSelect>

    <div class="edit-module-general__two-columns">
      <BaseTextField
        :label="t('modules.wtwOffice')"
        :value="module.willisOffice"
        :errors="v$.willisOffice?.$errors"
        @update:value="
          $emit('update:module', {
            ...module,
            willisOffice: $event as string,
          })
        "
      />
      <BaseTextField
        :label="t('modules.willisCountryOrUnit')"
        :value="module.willisCountryOrUnit"
        :errors="v$.willisCountryOrUnit?.$errors"
        @update:value="
          $emit('update:module', {
            ...module,
            willisCountryOrUnit: $event as string,
          })
        "
      />
    </div>

    <hr />

    <div class="edit-module-general__two-columns">
      <BaseTextField
        :label="t('modules.pOBox')"
        :description="t('modules.poBoxDescription')"
        :value="module.poBox"
        data-test="module-general-pobox"
        :errors="v$.poBox?.$errors"
        @update:value="$emit('update:module', { ...module, poBox: $event as string })"
      />
      <BaseTextField
        :label="t('modules.address')"
        :value="module.address"
        data-test="module-general-address"
        :errors="v$.address?.$errors"
        @update:value="$emit('update:module', { ...module, address: $event as string })"
      />
      <BaseTextField
        :label="t('modules.zipCode')"
        :value="module.zipCode"
        data-test="module-general-zipcode"
        :errors="v$.zipCode?.$errors"
        @update:value="$emit('update:module', { ...module, zipCode: $event as string })"
      />
      <BaseTextField
        :label="t('modules.city')"
        :value="module.city"
        data-test="module-general-city"
        :errors="v$.city?.$errors"
        @update:value="$emit('update:module', { ...module, city: $event as string })"
      />
      <BaseTextField
        :label="t('modules.country')"
        :value="module.country"
        data-test="module-general-country"
        :errors="v$.country?.$errors"
        @update:value="$emit('update:module', { ...module, country: $event as string })"
      />
    </div>

    <hr />

    <div class="edit-module-general__two-columns">
      <BaseTextField
        :label="t('modules.web')"
        :value="module.web"
        :errors="v$.web?.$errors"
        @update:value="$emit('update:module', { ...module, web: $event as string })"
      />
      <BaseTextField
        :label="t('modules.phone')"
        :value="module.phone"
        data-test="module-general-phone"
        :errors="v$.phone?.$errors"
        @update:value="$emit('update:module', { ...module, phone: $event as string })"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.edit-module-general {
  @include grid-columns(1);

  &__two-columns {
    @include grid-columns(2);
  }
  &__three-columns {
    @include grid-columns(3);
  }

  hr {
    margin-top: $spacing-4;
  }
}
</style>
