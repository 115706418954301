<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import http from "@/helpers/http";
import { handleError } from "@/services/shared/handleError";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseTextField from "@/components/base/BaseTextField.vue";

const { t } = useI18n({ useScope: "global" });

const props = withDefaults(
  defineProps<{
    email: string;
  }>(),
  {
    email: "",
  },
);

defineEmits<{
  (event: "email", value: string): void;
  (event: "return"): void;
}>();

const isLoading = ref(false);
const isSuccess = ref(false);

const sendResetPasswordEmail = async (event: Event) => {
  event.preventDefault();
  isLoading.value = true;

  // validate inputs
  try {
    await http.post(`auth/forgot-password`, { email: props.email });
    isSuccess.value = true;
  } catch (error) {
    handleError(error);
  }

  isLoading.value = false;
};
</script>

<template>
  <BaseAlert v-if="isSuccess" class="auth-forgot__alert" severity="success">
    <div>
      <p>{{ t("signIn.passwordReset.success.message1") }}</p>
      <p>{{ t("signIn.passwordReset.success.message2") }}</p>
      <p>
        {{ t("signIn.passwordReset.success.message3") }}
        <a href="mailto:bluesupport@willistowerswatson.com"> bluesupport@willistowerswatson.com </a>
      </p>
    </div>
  </BaseAlert>

  <BaseAlert class="auth-forgot__alert" severity="info">
    <p>{{ t("signIn.passwordReset.success.info") }}</p>
  </BaseAlert>

  <form v-if="!isLoading" class="auth-forgot__form" @submit="sendResetPasswordEmail">
    <BaseTextField
      v-if="!isSuccess"
      class="auth-forgot__form__input"
      :label="t('common.email')"
      :value="email"
      :disabled="isLoading"
      @update:value="$emit('email', $event as string)"
    />
    <BaseButton v-if="!isSuccess" :disabled="isLoading" type="submit">
      {{ t("signIn.passwordReset.cta") }}
    </BaseButton>
  </form>

  <BaseButton variant="text" @click="$emit('return')">
    {{ t("signIn.return") }}
  </BaseButton>
</template>

<style scoped lang="scss">
.auth-forgot {
  &__alert {
    margin-bottom: $spacing-4;

    p {
      margin-bottom: $spacing-4;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: $spacing-4;

    &__input {
      margin-bottom: $spacing-4;
    }
  }
}
</style>
