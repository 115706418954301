<script setup lang="ts">
import { onBeforeMount, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@/composables/useVuelidateWithFocusError";
import { ModuleDto } from "@/types/_generated/api";
import { CustomDefinitionNumber } from "@/types/SiteDefinitions";
import { useModuleValidation } from "@/views/Admin/Modules/composables/useModuleValidation";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseButtonRow from "@/components/base/BaseButtonRow.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import ModuleEditSiteDefinition from "./ModuleEditSiteDefinition.vue";

const { t } = useI18n({ useScope: "global" });

// This is used to loop through the site definitions while being type safe
const definitionNumbers: CustomDefinitionNumber[] = [1, 2, 3, 4];

const props = defineProps<{
  module: ModuleDto;
}>();

const emits = defineEmits<{
  (event: "update:module", module: ModuleDto): void;
}>();

// Number of visible site definitions
const visibleSiteDefinitions = ref(0);

onBeforeMount(() => {
  // Find the last defined site definition to set the number of visible site definitions
  for (const i of definitionNumbers.toReversed()) {
    if (props.module[`siteDefinition${i}`].length) {
      visibleSiteDefinitions.value = i;
      break;
    }
  }
});

// Re-arrange site definitions to always have the defined ones first
const reArrangeSiteDefinitions = (module: ModuleDto) => {
  const siteDefinitions = definitionNumbers.map((i) => ({
    definition: module[`siteDefinition${i}`],
    force: module[`forceSuggestion${i}`],
    suggestions: module[`suggestions${i}`],
  }));

  const definedSiteDefinitions = siteDefinitions.filter(
    (siteDefinition) => siteDefinition.definition.length,
  );
  const emptySiteDefinitions = siteDefinitions.filter(
    (siteDefinition) => !siteDefinition.definition.length,
  );

  const newSiteDefinitions = definedSiteDefinitions.concat(emptySiteDefinitions);

  for (const i of definitionNumbers) {
    module[`siteDefinition${i}`] = newSiteDefinitions[i - 1].definition;
    module[`forceSuggestion${i}`] = newSiteDefinitions[i - 1].force;
    module[`suggestions${i}`] = newSiteDefinitions[i - 1].suggestions;
  }

  return module;
};

const clear = (i: CustomDefinitionNumber, module: ModuleDto) => {
  visibleSiteDefinitions.value -= 1;
  emits("update:module", reArrangeSiteDefinitions(module));
};
const { statusValidation } = useModuleValidation();

const { v$ } = useVuelidate(
  statusValidation,
  toRef(() => props.module),
  {
    $registerAs: "edit-module-site-definitions",
    $autoDirty: true,
  },
);
</script>

<template>
  <div v-for="i of definitionNumbers" :key="i" class="edit-module-site-definitions">
    <ModuleEditSiteDefinition
      v-if="i <= visibleSiteDefinitions"
      :number="i"
      :module="module"
      :show-clear="i === visibleSiteDefinitions"
      @update:module="$emit('update:module', $event)"
      @clear="clear(i, $event)"
    />
  </div>

  <div v-if="v$.status?.$errors[0]?.$message" class="edit-module-site-definitions__error">
    {{ v$.status?.$errors[0]?.$message }}
  </div>

  <BaseButtonRow v-if="visibleSiteDefinitions < 4">
    <BaseButton
      class="edit-module__add-button-row--button"
      variant="outlined"
      data-test="module-edit-add-site-definition-button"
      @click="visibleSiteDefinitions += 1"
    >
      <BaseIcon icon="plus" />
      {{ t("modules.addSiteDefinition") }}
    </BaseButton>
  </BaseButtonRow>
</template>

<style scoped lang="scss">
.edit-module-site-definitions {
  margin-bottom: $spacing-6;

  &__error {
    color: $error-5;
  }
}
</style>
