import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import {
  BlueRatingRecommendationDto,
  GetBlueRatingRecommendationResponse,
  CreateBlueRatingRecommendation,
  UpdateBlueRatingRecommendation,
  RecommendationFilter,
  SearchBlueRatingRecommendationsResponse,
} from "@/types/_generated/api";
import { BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const recommendationEvents = new BroadcastService("survey-recommendations");

export const getBlueRatingRecommendationService = async (
  surveyId: number,
  recommendationId: number,
): Promise<BlueRatingRecommendationDto | null> => {
  try {
    const {
      data: { recommendation },
    } = await http.get<GetBlueRatingRecommendationResponse>(
      `/surveys/${surveyId}/recommendations/${recommendationId}`,
    );

    return recommendation || null;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("blueRating.recommendation.recommendation"),
    });
  }

  return null;
};

export const getBlueRatingRecommendationsService = async (
  surveyId: number,
  filter?: RecommendationFilter,
): Promise<BlueRatingRecommendationDto[] | null> => {
  try {
    const {
      data: { recommendations },
    } = await http.post<SearchBlueRatingRecommendationsResponse>(
      `/surveys/${surveyId}/recommendations/search`,
      { filter },
    );

    return recommendations ?? null;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("blueRating.recommendation.recommendations"),
    });
  }

  return null;
};

export const createBlueRatingRecommendation = async (
  surveyId: number,
  recommendation: CreateBlueRatingRecommendation,
): Promise<number | null> => {
  try {
    const {
      data: { recommendationId },
    } = await http.post(`/surveys/${surveyId}/recommendations`, recommendation);

    recommendationEvents.post({ action: "create", id: recommendationId });

    return recommendationId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("blueRating.recommendation.recommendation"),
    });
  }

  return null;
};

export const updateBlueRatingRecommendation = async (
  surveyId: number,
  recommendation: UpdateBlueRatingRecommendation,
): Promise<number | null> => {
  try {
    const {
      data: { recommendationId },
    } = await http.put(
      `/surveys/${surveyId}/recommendations/${recommendation.recommendationId}`,
      recommendation,
    );

    recommendationEvents.post({ action: "update", id: recommendationId });

    notify.success(
      t("blueRating.recommendation.properties.text"),
      t("common.updated").toLowerCase(),
    );

    return recommendationId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update").toLowerCase(),
      entity: t("blueRating.recommendation.recommendation"),
    });
  }

  return null;
};

export const applyStandardRecommendation = async (
  surveyId: number,
  recommendationId: number,
  standardRecommendationId: number,
) => {
  try {
    await http.put(
      `/surveys/${surveyId}/recommendations/${recommendationId}/apply-standard-recommendation/${standardRecommendationId}`,
    );

    recommendationEvents.post({ action: "update", id: recommendationId });

    notify.success(
      t("blueRating.recommendation.properties.text"),
      t("common.updated").toLowerCase(),
    );
  } catch (error) {
    handleError(error, {
      action: t("common.actions.delete").toLowerCase(),
      entity: t("blueRating.recommendation.recommendation"),
    });
  }
};

export const deleteBlueRatingRecommendation = async (
  surveyId: number,
  recommendationId: number,
) => {
  try {
    await http.delete(`/surveys/${surveyId}/recommendations/${recommendationId}`);

    recommendationEvents.post({ action: "delete", id: recommendationId });
  } catch (error) {
    handleError(error, {
      action: t("common.actions.delete").toLowerCase(),
      entity: t("blueRating.recommendation.recommendation"),
    });
  }
};

export const deleteBlueRatingRecommendationImage = async (
  surveyId: number,
  recommendationId: number,
  fileName: string,
) => {
  try {
    await http.delete(
      `/surveys/${surveyId}/recommendations/${recommendationId}/images/${fileName}`,
    );
  } catch (error) {
    handleError(error, {
      action: t("common.actions.delete").toLowerCase(),
      entity: t("blueRating.recommendation.image"),
    });
  }
};

export const updateBlueRatingRecommendationImageCaption = async (
  surveyId: number,
  recommendationId: number,
  fileId: string,
  caption: string,
) => {
  try {
    await http.put(
      `/surveys/${surveyId}/recommendations/${recommendationId}/images/${fileId}/caption`,
      {
        recommendationId,
        fileId,
        caption,
      },
    );
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update").toLowerCase(),
      entity: t("blueRating.recommendation.imageCaption"),
    });
  }
};
