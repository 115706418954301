<script setup lang="ts">
import { onBeforeMount } from "vue";
import type { CustomDefinitionNumber } from "@/types/SiteDefinitions";
import { useGetSites } from "@/composables/services/useGetSites";
import { useTableFilter } from "@/composables/useTableFilter";
import { UserWebRouteName } from "@/router/RouteName";
import BaseFilter from "@/components/base/BaseFilter.vue";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";
import RecommendationResponseColumnHeading from "@/components/shared/Sites/RecommendationResponseColumnHeading.vue";
import ReminderEmailCountColumnHeading from "@/components/shared/Sites/ReminderEmailCountColumnHeading.vue";
import ReportDownLoadStatusColumnHeading from "@/components/shared/Sites/ReportDownLoadStatusColumnHeading.vue";
import SiteRecommendationStatusCell from "@/components/shared/Sites/SiteRecommendationStatusCell.vue";
import { useSiteTableColumns } from "../composables/useSitesTableColumns";

const props = defineProps<{
  moduleId: number;
}>();

const { sites, isLoadingSites, getSites, siteDefinitions } = useGetSites();
const { columns } = useSiteTableColumns(siteDefinitions);
const { result, searchPhrase, searchKey, setSearchPhrase, setSearchKey, searchKeys } =
  useTableFilter(sites, columns);

const getCustomColumnSlotName = (i: number): `column-custom${CustomDefinitionNumber}` => {
  return `column-custom${i as CustomDefinitionNumber}`;
};

onBeforeMount(async () => {
  await getSites(props.moduleId, true);
});
</script>

<template>
  <BaseGridTable
    :rows="result.result"
    :total-row-count="result.all.length"
    :columns="columns"
    :is-loading="isLoadingSites"
    storage-key="moduleSitesTable"
  >
    <template #controls>
      <BaseFilter
        :search-phrase="searchPhrase"
        :search-key="searchKey"
        :search-keys="searchKeys"
        @update:search-phrase="setSearchPhrase($event)"
        @update:search-key="setSearchKey($event)"
      />
    </template>

    <template #header-reportDownloadStatus>
      <ReportDownLoadStatusColumnHeading />
    </template>

    <template #header-recommendationResponseStatus>
      <RecommendationResponseColumnHeading />
    </template>

    <template #header-reminderEmailCount>
      <ReminderEmailCountColumnHeading />
    </template>

    <template
      v-for="i in [1, 2, 3, 4] as CustomDefinitionNumber[]"
      :key="i"
      #[getCustomColumnSlotName(i)]="{ cell, row }"
    >
      <router-link
        :to="{ name: UserWebRouteName.Site, params: { siteId: row.siteId } }"
        :title="row[`custom${i}`]"
      >
        {{ cell }}
      </router-link>
    </template>
    <template #column-latestSurveyStatus="{ row }">
      <BaseStatusBadge v-if="row.latestSurveyStatus" :status="row.latestSurveyStatus" />
    </template>
    <template #column-reportDownloadStatus="{ row }">
      <SiteRecommendationStatusCell :value="row.reportDownloadStatus" />
    </template>
    <template #column-recommendationResponseStatus="{ row }">
      <SiteRecommendationStatusCell :value="row.recommendationResponseStatus" />
    </template>
  </BaseGridTable>
</template>

<style scoped lang="scss">
a {
  text-decoration: underline;
}
</style>
