<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";

const { t } = useI18n({ useScope: "global" });

interface Props {
  view: string;
}

withDefaults(defineProps<Props>(), {
  view: "",
});

defineEmits<{
  (event: "redirect"): void;
}>();
</script>

<template>
  <BaseAlert v-if="view === ViewAuthStatus.SessionExpired" severity="warning">
    {{ t("signIn.sessionExpired") }}
  </BaseAlert>

  <BaseAlert v-if="view === ViewAuthStatus.SsoFailed" severity="warning">
    <div>
      <strong>{{ t("signIn.ssoFailed") }}</strong>

      <p>
        {{ t("notifications.failure.ifProblemPersists") }}
      </p>
    </div>
  </BaseAlert>

  <BaseAlert v-if="view === ViewAuthStatus.SignedOut" severity="info">
    {{ t("signIn.signedOutMessage") }}.
  </BaseAlert>

  <BaseButton class="sign-in__signed-out__button" @click="$emit('redirect')">
    {{ t("signIn.signInAgain") }}
  </BaseButton>
</template>

<style scoped lang="scss">
.base-alert,
.base-button {
  width: 100%;
}

.base-button {
  margin-top: $spacing-4;
}
</style>
