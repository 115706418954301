import { Language, LoginType, Role, UserDto } from "@/types/_generated/api";

export type EditUserForm = Omit<
  UserDto,
  "clients" | "insert" | "update" | "languageCode" | "lastLogin" | "lockedDate" | "mfa"
> & {
  isLockedOut: boolean;
  password?: string;
};

export const useInitializeUserForm = () => {
  const initializeUserForm = (user?: UserDto) => {
    const form: EditUserForm = {
      allowReportMail: user?.allowReportMail ?? false,
      allowRmComment: user?.allowRmComment ?? false,
      allowUserWeb: user?.allowUserWeb ?? false,
      city: user?.city ?? "",
      company: user?.company ?? "",
      country: user?.country ?? "",
      email: user?.email ?? "",
      firstName: user?.firstName ?? "",
      fullName: user?.fullName ?? "",
      id: user?.id ?? 0,
      isArchived: user?.isArchived ?? false,
      isLockedOut: !!user?.lockedDate,
      language: user?.language ?? Language.English,
      lastName: user?.lastName ?? "",
      loginType: user?.loginType ?? LoginType.Blue,
      mobilePhoneNumber: user?.mobilePhoneNumber ?? "",
      phoneNumber: user?.phoneNumber ?? "",
      role: user?.role ?? Role.EXT,
      title: user?.title ?? "",
      userName: user?.userName ?? "",
    };

    return form;
  };

  return {
    initializeUserForm,
  };
};
