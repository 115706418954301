import { ref, onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import type { DashboardInsuredSite } from "../types";
import mockData from "./mockdata.json";

interface MockdataCurrency {
  currency: string;
}

interface MockdataInsuredSite {
  site: string;
  insuredValue: number;
  score: number;
  loremIpsum: string;
}

const transformInsuredSites = (insuredSites: MockdataInsuredSite[]): DashboardInsuredSite[] => {
  return insuredSites.map((insuredSite) => ({
    site: insuredSite.site,
    insuredValue: insuredSite.insuredValue,
    score: insuredSite.score,
  }));
};

export const useGetInsuredSites = () => {
  const { t } = useI18n({ useScope: "global" });

  const isLoadingInsuredSites = ref(false);
  const currency = ref<MockdataCurrency["currency"]>("");
  const insuredSites = ref<DashboardInsuredSite[]>([]);

  const insuredSitesColumns = computed<{ title: string; key: keyof DashboardInsuredSite }[]>(() => [
    { title: t("userWeb.dashboard.table.site"), key: "site" },
    {
      title: `${t("userWeb.dashboard.table.insuredValue")}${currency.value ? ` (${currency.value})` : ""}`,
      key: "insuredValue",
    },
    { title: t("userWeb.dashboard.table.score"), key: "score" },
  ]);

  const fetchData = async () => {
    isLoadingInsuredSites.value = true;

    try {
      // Simulate an asynchronous operation
      await new Promise((resolve) => setTimeout(resolve, 3000));

      currency.value = mockData.currency;
      insuredSites.value = transformInsuredSites(mockData.insuredSites);
    } finally {
      isLoadingInsuredSites.value = false;
    }
  };

  onMounted(fetchData);

  return {
    isLoadingInsuredSites,
    insuredSitesColumns,
    currency,
    insuredSites,
  };
};
