<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { RecResponseMfa } from "@/types/_generated/api";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import { requestMfaCodeService } from "../services/auth";
import { MfaSelectionResult } from "../types";

defineProps<{
  availableMfaOptions: RecResponseMfa[];
}>();

const emit = defineEmits<{
  "mfa-requested": [MfaSelectionResult];
}>();

const { t } = useI18n({ useScope: "global" });
const router = useRouter();
const isLoading = ref(false);

const requestMfaVerification = async (method: RecResponseMfa) => {
  isLoading.value = true;

  const { linkId } = router.currentRoute.value.query;
  if (!linkId || typeof linkId !== "string") {
    throw new Error("Missing or invalid linkId");
  }

  const request = await requestMfaCodeService(linkId, method);
  if (!request) {
    throw new Error("Failed to request MFA verification");
  }

  emit("mfa-requested", { method, expirationTimeHours: request.expirationTimeHours ?? 0 });

  isLoading.value = false;
};
</script>

<template>
  <div class="login-mfa-selector">
    <BaseAlert v-if="availableMfaOptions.length === 0" severity="error">
      <p>{{ t("recResponse.mfa.noMfaOptions") }}</p>
    </BaseAlert>

    <div v-else class="login-mfa-selector__buttons">
      <BaseButton
        v-if="availableMfaOptions.includes(RecResponseMfa.Email)"
        :disabled="isLoading"
        @click="requestMfaVerification(RecResponseMfa.Email)"
      >
        {{ t("common.mfa.email") }}
      </BaseButton>

      <BaseButton
        v-if="availableMfaOptions.includes(RecResponseMfa.Sms)"
        :variant="availableMfaOptions.length === 1 ? 'contained' : 'outlined'"
        :disabled="isLoading"
        @click="requestMfaVerification(RecResponseMfa.Sms)"
      >
        {{ t("common.mfa.sms") }}
      </BaseButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.login-mfa-selector {
  width: 100%;

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }
}
</style>
