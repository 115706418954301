<script setup lang="ts">
import { computed, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import type { NavTab } from "@/components/base/BaseNavTabs/BaseNavTabs.vue";
import type { BlueRatingRecommendationDto } from "@/types/_generated/api";
import { useGetModule } from "@/composables/services/useGetModule";
import { useGetSitesData } from "@/composables/services/useGetSitesData";
import { useSearchRecommendationTracking } from "@/composables/services/useSearchRecommendationTracking";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import { useDrawerState } from "@/composables/useDrawerState";
import { UserWebRouteName } from "@/router/RouteName";
import UserWebViewLayout from "@/components/app/UserWeb/UserWebViewLayout.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import RecTrackingFilterDrawer from "@/components/shared/RecTracking/components/Filter/RecTrackingFilterDrawer.vue";
import { useFilterRecTracking } from "@/components/shared/RecTracking/composables/useFilterRecTracking";
import ViewRecTrackingDetailed from "./ViewRecTrackingDetailed.vue";
import ViewRecTrackingOverview from "./ViewRecTrackingOverview.vue";

const props = defineProps<{
  moduleId: number;
}>();

const { t } = useI18n({ useScope: "global" });
const route = useRoute();
const { setBreadcrumbs } = useBreadcrumbs();
const {
  searchRecommendationTracking,
  recommendationTracking,
  isLoadingRecommendationTracking,
  errorFetchingRecTracking,
} = useSearchRecommendationTracking();
const { getModule, isLoadingModule, module } = useGetModule();
const { sitesData, isLoadingSites, getSitesData } = useGetSitesData();
const { activeFilter, updateFilter } = useFilterRecTracking((filter) =>
  searchRecommendationTracking(props.moduleId, filter),
);

const {
  closeDrawer: closeFilterDrawer,
  openDrawer: openFilterDrawer,
  showDrawer: showFilterDrawer,
} = useDrawerState<BlueRatingRecommendationDto>();

const TABS: NavTab[] = [
  {
    title: t("userWeb.recommendationTracking.overview"),
    path: UserWebRouteName.RecTrackingOverview,
  },
  {
    title: t("userWeb.recommendationTracking.detailed"),
    path: UserWebRouteName.RecTrackingDetailed,
  },
];

const isLoading = computed(
  () => isLoadingModule.value || isLoadingSites.value || isLoadingRecommendationTracking.value,
);

watch(
  () => route.name,
  () => {
    setBreadcrumbs([{ label: t("userWeb.navigation.recommendationTracking") }]);
  },
  { immediate: true },
);

onMounted(() => {
  getModule(props.moduleId);
  getSitesData(props.moduleId);
  searchRecommendationTracking(props.moduleId, activeFilter.value);
});
</script>

<template>
  <UserWebViewLayout :tabs="TABS">
    <template #controls>
      <BaseButton :disabled="isLoading" variant="outlined" size="small" @click="openFilterDrawer">
        <BaseIcon icon="filter" size="small" />
        {{ t("common.filter") }}
      </BaseButton>
    </template>

    <ViewRecTrackingOverview
      v-if="route.name === UserWebRouteName.RecTrackingOverview"
      :is-loading="isLoading"
      :recommendation-tracking="recommendationTracking"
      :error-fetching-rec-tracking="errorFetchingRecTracking"
    />

    <ViewRecTrackingDetailed
      v-if="route.name === UserWebRouteName.RecTrackingDetailed"
      :is-loading="isLoading"
      :recommendation-tracking="recommendationTracking"
      :sites-data="sitesData"
    />

    <RecTrackingFilterDrawer
      v-if="showFilterDrawer"
      :sites-data="sitesData"
      :module="module"
      :filter="activeFilter"
      @close-drawer="closeFilterDrawer"
      @apply-filter="updateFilter"
    />
  </UserWebViewLayout>
</template>
