import { RouteRecordRaw } from "vue-router";
import { authorize } from "@/plugins/can";
import { AdminRouteName } from "@/router/RouteName";
import { Role } from "@/types/_generated/api";
import ViewBlueRating from "@/views/Admin/BlueRating/ViewBlueRating.vue";
import ClientDetails from "@/views/Admin/Clients/components/ClientDetails.vue";
import ClientUsersTable from "@/views/Admin/Clients/components/ClientUsersTable.vue";
import ViewClient from "@/views/Admin/Clients/ViewClient.vue";
import ViewClients from "@/views/Admin/Clients/ViewClients.vue";
import ViewStart from "@/views/Admin/Dashboard/ViewDashboard.vue";
import ModuleDetails from "@/views/Admin/Modules/components/ModuleDetails.vue";
import ModulePermissionsTable from "@/views/Admin/Modules/components/ModulePermissionsTable.vue";
import ModuleSitesTable from "@/views/Admin/Modules/components/ModuleSitesTable.vue";
import ModulesTable from "@/views/Admin/Modules/components/ModulesTable.vue";
import ViewModule from "@/views/Admin/Modules/ViewModule.vue";
import ViewModules from "@/views/Admin/Modules/ViewModules.vue";
import ViewQuestionnaire from "@/views/Admin/Questionnaire/ViewQuestionnaire.vue";
import ViewQuestionnaireCurrent from "@/views/Admin/Questionnaire/ViewQuestionnaireCurrent.vue";
import ViewQuestionnaireDraft from "@/views/Admin/Questionnaire/ViewQuestionnaireDraft.vue";
import SiteDetails from "@/views/Admin/Sites/components/SiteDetails.vue";
import SiteExemptionsTable from "@/views/Admin/Sites/components/SiteExemptionsTable.vue";
import SitePermissionTable from "@/views/Admin/Sites/components/SitePermissionsTable.vue";
import SiteSurveysTable from "@/views/Admin/Sites/components/SiteSurveysTable.vue";
import ViewSite from "@/views/Admin/Sites/ViewSite.vue";
import SystemActivityLog from "@/views/Admin/System/components/SystemActivityLog.vue";
import SystemErrorLog from "@/views/Admin/System/components/SystemErrorLog.vue";
import SystemLoginAttemptLog from "@/views/Admin/System/components/SystemLoginAttemptLog.vue";
import SystemServiceMessages from "@/views/Admin/System/components/SystemServiceMessages.vue";
import ViewSystemAdmin from "@/views/Admin/System/ViewSystemAdmin.vue";
import QuestionnaireTemplatesTable from "@/views/Admin/Templates/components/QuestionnaireTemplatesTable.vue";
import StandardRecTable from "@/views/Admin/Templates/components/StandardRecTable.vue";
import ViewTemplates from "@/views/Admin/Templates/ViewTemplates.vue";
import ViewUsers from "@/views/Admin/Users/ViewUsers.vue";
import ViewAdmin from "@/views/Admin/ViewAdmin.vue";
import ModuleLibrary from "@/components/shared/ModuleLibrary/ModuleLibrary.vue";

export const adminWebRoutes: Array<RouteRecordRaw> = [
  {
    path: "/admin",
    name: AdminRouteName.Root,
    component: ViewAdmin,
    redirect: { name: AdminRouteName.AdminDashboard },
    children: [
      {
        path: "dashboard",
        name: AdminRouteName.AdminDashboard,
        component: ViewStart,
      },
      {
        path: "users",
        name: AdminRouteName.Users,
        beforeEnter: (to, from, next) =>
          authorize.hasRole(Role.SystemOwner, Role.SA, Role.BA, Role.INT) && next(),
        component: ViewUsers,
      },
      {
        path: "clients",
        name: AdminRouteName.Clients,
        component: ViewClients,
      },
      {
        path: "clients/:clientId",
        name: AdminRouteName.Client,
        beforeEnter: (to, from, next) => {
          authorize.hasModulePermissions() && next();
        },
        component: ViewClient,
        props: (route) => ({
          clientId: Number(route.params.clientId),
        }),
        redirect: { name: AdminRouteName.ClientModules },
        children: [
          {
            name: AdminRouteName.ClientModules,
            path: "modules",
            component: ModulesTable,
            props: (route) => ({
              clientId: Number(route.params.clientId),
              showClientName: false,
            }),
          },
          {
            name: AdminRouteName.ClientUsers,
            path: "users",
            component: ClientUsersTable,
            props: (route) => ({
              clientId: Number(route.params.clientId),
            }),
          },
          {
            name: AdminRouteName.ClientDetails,
            path: "client-details",
            component: ClientDetails,
            props: (route) => ({
              clientId: Number(route.params.clientId),
            }),
          },
        ],
      },
      {
        // Not named "modules" to avoid conflict with ServiceStack's virtual directories
        // https://stackoverflow.com/questions/75767096/servicestack-virtual-directories-collide-with-spa-routing
        path: "module-list",
        name: AdminRouteName.Modules,
        component: ViewModules,
      },
      {
        path: "modules/:moduleId",
        name: AdminRouteName.Module,
        props: (route) => ({
          moduleId: Number(route.params.moduleId),
        }),
        redirect: { name: AdminRouteName.ModuleSites },
        component: ViewModule,
        children: [
          {
            name: AdminRouteName.ModuleSites,
            path: "sites",
            component: ModuleSitesTable,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
          },
          {
            name: AdminRouteName.ModuleUsers,
            path: "users",
            component: ModulePermissionsTable,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
          },
          {
            name: AdminRouteName.ModuleDetails,
            path: "details",
            component: ModuleDetails,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
          },
          {
            name: AdminRouteName.ModuleLibrary,
            path: "library",
            component: ModuleLibrary,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              isUserWeb: false,
            }),
          },
        ],
      },
      {
        path: "modules/:moduleId/questionnaire",
        name: AdminRouteName.Questionnaire,
        props: (route) => ({
          moduleId: Number(route.params.moduleId),
          questionnaireId: null,
        }),
        redirect: { name: AdminRouteName.QuestionnaireCurrent },
        component: ViewQuestionnaire,
        children: [
          {
            path: "",
            name: AdminRouteName.QuestionnaireCurrent,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
            component: ViewQuestionnaireCurrent,
          },
          {
            path: "draft",
            name: AdminRouteName.QuestionnaireDraft,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              questionnaireId: null,
            }),
            component: ViewQuestionnaireDraft,
            beforeEnter: (to, from, next) =>
              authorize.hasModulePermission(Number(to.params.moduleId), "editQuestionnaire") &&
              next(),
          },
        ],
      },
      {
        path: "modules/:moduleId/sites/:siteId",
        name: AdminRouteName.Site,
        props: (route) => ({
          moduleId: Number(route.params.moduleId),
          siteId: Number(route.params.siteId),
        }),
        redirect: { name: AdminRouteName.SiteSurveys },
        component: ViewSite,
        children: [
          {
            name: AdminRouteName.SiteSurveys,
            path: "surveys",
            component: SiteSurveysTable,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              siteId: Number(route.params.siteId),
            }),
          },
          {
            name: AdminRouteName.SitePermissionTable,
            path: "permissions",
            component: SitePermissionTable,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              siteId: Number(route.params.siteId),
            }),
          },
          {
            name: AdminRouteName.SiteExemptionsTable,
            path: "exemptions",
            component: SiteExemptionsTable,
            props: (route) => ({
              siteId: Number(route.params.siteId),
            }),
          },
          {
            name: AdminRouteName.SiteDetails,
            path: "details",
            component: SiteDetails,
            props: (route) => ({
              siteId: Number(route.params.siteId),
            }),
          },
        ],
      },
      {
        path: "modules/:moduleId/sites/:siteId/surveys/:surveyId",
        name: AdminRouteName.BlueRating,
        component: ViewBlueRating,
        props: ({ params, query }) => ({
          moduleId: Number(params.moduleId),
          siteId: Number(params.siteId),
          surveyId: Number(params.surveyId),
          tab: query.tab,
        }),
      },
      {
        name: AdminRouteName.Templates,
        path: "templates",
        redirect: { name: AdminRouteName.QuestionnaireTemplates },
        component: ViewTemplates,
        children: [
          {
            name: AdminRouteName.QuestionnaireTemplates,
            path: "questionnaire",
            component: QuestionnaireTemplatesTable,
            beforeEnter: (to, from, next) => {
              if (authorize.isSuperiorOrEqualTo(Role.BA)) {
                next();
              } else {
                next({ name: AdminRouteName.StandardRecommendations });
              }
            },
          },
          {
            name: AdminRouteName.StandardRecommendations,
            path: "standard-recommendations",
            props: (route) => ({
              standardRecommendationId: Number(route.query.standardRecommendationId),
            }),
            component: StandardRecTable,
          },
        ],
      },
      {
        name: AdminRouteName.QuestionnaireTemplate,
        path: "templates/questionnaire/:questionnaireId",
        component: ViewQuestionnaire,
        redirect: { name: AdminRouteName.QuestionnaireTemplateCurrent },
        props: (route) => ({
          questionnaireId: Number(route.params.questionnaireId),
          moduleId: null,
        }),
        children: [
          {
            path: "current",
            name: AdminRouteName.QuestionnaireTemplateCurrent,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
            component: ViewQuestionnaireCurrent,
          },
          {
            path: "draft",
            name: AdminRouteName.QuestionnaireTemplateDraft,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
            component: ViewQuestionnaireDraft,
          },
        ],
      },
      {
        name: AdminRouteName.SystemAdmin,
        path: "/system-admin",
        component: ViewSystemAdmin,
        redirect: { name: AdminRouteName.LoginAttemptLog },
        children: [
          {
            path: "logs/login-attempts",
            name: AdminRouteName.LoginAttemptLog,
            component: SystemLoginAttemptLog,
          },
          {
            path: "logs/errors",
            name: AdminRouteName.ErrorLog,
            component: SystemErrorLog,
          },
          {
            path: "logs/activities",
            name: AdminRouteName.ActivityLog,
            component: SystemActivityLog,
          },
          {
            path: "service-messages",
            name: AdminRouteName.ServiceMessages,
            component: SystemServiceMessages,
          },
        ],
      },
    ],
  },
];
