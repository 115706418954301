<script setup lang="ts">
import { onMounted, computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useGetBlueRatingSummary } from "@/composables/services/useGetBlueRatingSummary";
import { useGetModule } from "@/composables/services/useGetModule";
import { useGetSite } from "@/composables/services/useGetSite";
import { useGetSurveyReportFiles } from "@/composables/services/useGetSurveyReportFiles";
import { useGetSurveys } from "@/composables/services/useGetSurveys";
import { useSearchRecommendationTracking } from "@/composables/services/useSearchRecommendationTracking";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import { useDrawerState } from "@/composables/useDrawerState";
import { SortOrder } from "@/constants/SortOrder";
import { sortByKeys } from "@/helpers/sort";
import { UserWebRouteName } from "@/router/RouteName";
import { BlueRatingRecommendationDto, Status } from "@/types/_generated/api";
import RecTrackingFilterDrawer from "@/components/shared/RecTracking/components/Filter/RecTrackingFilterDrawer.vue";
import { useFilterRecTracking } from "@/components/shared/RecTracking/composables/useFilterRecTracking";
import ViewSiteBlueRating from "./ViewSiteBlueRating.vue";
import ViewSiteDetails from "./ViewSiteDetails.vue";
import ViewSiteRecTracking from "./ViewSiteRecTracking.vue";
import ViewSiteReports from "./ViewSiteReports.vue";

const props = defineProps<{
  moduleId: number;
  siteId: number;
}>();

const { t } = useI18n({ useScope: "global" });
const route = useRoute();
const { setBreadcrumbs } = useBreadcrumbs();

const { isLoadingSite, siteData, getSite } = useGetSite();

const { searchRecommendationTracking, recommendationTracking, isLoadingRecommendationTracking } =
  useSearchRecommendationTracking();

const { getModule, isLoadingModule, module } = useGetModule();

const {
  getBlueRatingSummary,
  blueRatingSummary,
  isLoadingBlueRatingSummary,
  hasError: hasErrorLoadingSummary,
} = useGetBlueRatingSummary();

const {
  getSurveyReportFiles,
  surveyReportFiles,
  isLoadingSurveyReportFiles,
  hasError: hasErrorLoadingFiles,
} = useGetSurveyReportFiles();

const { getSurveys, surveys, isLoadingSurveys, hasError: hasErrorLoadingSurveys } = useGetSurveys();

const { activeFilter, updateFilter } = useFilterRecTracking((filter) =>
  searchRecommendationTracking(props.moduleId, filter, props.siteId),
);

const {
  closeDrawer: closeFilterDrawer,
  openDrawer: openFilterDrawer,
  showDrawer: showFilterDrawer,
} = useDrawerState<BlueRatingRecommendationDto>();

const selectedReportSurveyId = ref<number | null>(null);

watch(
  () => route.name,
  (newRouteName) => {
    if (newRouteName !== UserWebRouteName.Sites) {
      setBreadcrumbs(
        computed(() => [
          { label: t("userWeb.navigation.sites"), name: UserWebRouteName.Sites },
          { label: siteData.value?.custom1 || "" },
        ]),
      );
    }
  },
  { immediate: true },
);

const updateSelectedReportSurvey = async (surveyId: number) => {
  selectedReportSurveyId.value = surveyId;
  await getSurveyReportFiles(surveyId);
};

onMounted(async () => {
  await Promise.allSettled([
    getSite(props.siteId),
    searchRecommendationTracking(props.moduleId, activeFilter.value, props.siteId),
    getBlueRatingSummary(props.siteId),
    getSurveys(props.siteId),
    getModule(props.moduleId),
  ]);

  if (!hasErrorLoadingSurveys.value) {
    // Only published surveys should be visible
    surveys.value = (surveys.value ?? []).filter((survey) => survey.status === Status.Published);

    // Sort by survey date to set the latest survey first
    surveys.value = sortByKeys(surveys.value ?? [], {
      key: "surveyDate",
      order: SortOrder.Descending,
    });

    const [latestSurvey] = surveys.value;

    if (latestSurvey) {
      // Set the latest survey as the selected survey
      updateSelectedReportSurvey(latestSurvey.surveyId);
    }
  }
});
</script>

<template>
  <ViewSiteDetails
    v-if="route.name === UserWebRouteName.SiteDetails"
    :is-loading-site="isLoadingSite"
    :site="siteData"
  />

  <ViewSiteRecTracking
    v-if="route.name === UserWebRouteName.SiteRecTracking"
    :active-filter="activeFilter"
    :module-id="props.moduleId"
    :site="siteData"
    :is-loading="isLoadingModule || isLoadingRecommendationTracking"
    :module-currency="module?.currencyCode ?? ''"
    :tracked-recommendations="recommendationTracking ?? []"
    @open-filter="openFilterDrawer"
    @apply-filter="updateFilter"
  />

  <ViewSiteReports
    v-if="route.name === UserWebRouteName.SiteReports"
    :is-loading="isLoadingSurveys || isLoadingSurveyReportFiles"
    :has-loading-error="hasErrorLoadingSurveys || hasErrorLoadingFiles"
    :current-survey-id="selectedReportSurveyId"
    :current-survey-report-files="surveyReportFiles ?? []"
    :surveys="surveys ?? []"
    :module-id="moduleId"
    @update:survey-id="updateSelectedReportSurvey"
  />

  <ViewSiteBlueRating
    v-if="route.name === UserWebRouteName.SiteBlueRating"
    :is-loading="isLoadingBlueRatingSummary"
    :has-loading-error="hasErrorLoadingSummary"
    :blue-rating-summary="blueRatingSummary"
  />

  <RecTrackingFilterDrawer
    v-if="showFilterDrawer"
    :module="module"
    :filter="activeFilter"
    @close-drawer="closeFilterDrawer"
    @apply-filter="updateFilter"
  />
</template>
