<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { SurveyParticipantDto, UserLeanDto } from "@/types/_generated/api";
import AppModuleUsersSelect from "@/components/app/AppModuleUsersSelect.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseButtonRow from "@/components/base/BaseButtonRow.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import { type SurveySettingsValidationState } from "../../composables/useValidateSurveySettings";
import SurveyEditDrawerParticipant from "./SurveyEditDrawerParticipant.vue";
import SurveyEditDrawerParticipantsCopyMessage from "./SurveyEditDrawerParticipantsCopyMessage.vue";

const { t } = useI18n({ useScope: "global" });

export type SurveyParticipant = SurveyParticipantDto & { inherited?: boolean };

const props = withDefaults(
  defineProps<{
    participants?: SurveyParticipant[];
    moduleUsers?: UserLeanDto[] | null;
    errors: SurveySettingsValidationState["participants"];
  }>(),
  {
    participants: () => [],
    moduleUsers: () => [],
  },
);

const emit = defineEmits<{
  update: [SurveyParticipant[]];
}>();

const addParticipant = (userId?: number) => {
  const user = props.moduleUsers?.find((u) => u.id === userId);

  emit("update", [
    {
      inherited: false,
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      email: user?.email ?? "",
      mobile: user?.mobilePhoneNumber ?? "",
      title: user?.title ?? "",
      company: user?.company ?? "",
    },
    ...props.participants,
  ]);
};

const updateParticipant = (index: number, participant: SurveyParticipant) => {
  const participants = replaceParticipantAt(index, participant);
  emit("update", participants);
};

const replaceParticipantAt = (index: number, participant: SurveyParticipant) => {
  if (props.participants.length === 0 || index >= props.participants.length) {
    return [participant];
  }

  return [
    ...props.participants.slice(0, index),
    participant,
    ...props.participants.slice(index + 1),
  ];
};
</script>

<template>
  <div class="survey-edit-drawer__participants">
    <div class="survey-edit-drawer__participants__list">
      <SurveyEditDrawerParticipantsCopyMessage v-if="participants.some((p) => p.inherited)" />
      <BaseButtonRow v-if="participants!.length < 15">
        <BaseButton
          class="survey-participants-list__add-button"
          variant="outlined"
          @click="addParticipant()"
        >
          <BaseIcon icon="plus" size="medium" />
          {{ t("survey.addParticipant") }}
        </BaseButton>

        <span>or</span>

        <AppModuleUsersSelect
          :module-users="moduleUsers"
          :filter="(user) => !participants!.some((p) => p.email === user.email)"
          @update:value="addParticipant($event)"
        />
      </BaseButtonRow>

      <SurveyEditDrawerParticipant
        v-for="(participant, i) in participants"
        :key="participant.participantId"
        :index="i"
        :participant="participant"
        :errors="errors[i]"
        @delete="
          $emit(
            'update',
            participants!.filter((_, index) => index !== i),
          )
        "
        @update="updateParticipant(i, $event)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.survey-edit-drawer__participants {
  &__list {
    display: flex;
    margin-top: $spacing-3;
    flex-direction: column;
    gap: $spacing-6;
  }
}
</style>
