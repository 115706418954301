import { ref } from "vue";
import {
  deleteSurveyReport,
  getSurveyFiles as getSurveyFilesService,
  uploadSurveyReport as uploadSurveyReportService,
} from "@/services/surveys";
import { FileDto, SurveyFileType, SurveyReportFileDto } from "@/types/_generated/api";

export const useHandleSurveyReport = () => {
  const isLoadingSurveyReports = ref(false);
  const reportFiles = ref<SurveyReportFileDto[]>([]);

  const deleteReport = async (surveyId: number, currentReportFileId: string) => {
    await deleteSurveyReport(surveyId, currentReportFileId);
    await getSurveyFiles(surveyId);
  };

  const getSurveyFiles = async (surveyId: number) => {
    isLoadingSurveyReports.value = true;

    const response = await getSurveyFilesService(surveyId);

    reportFiles.value = response?.files ?? [];

    isLoadingSurveyReports.value = false;
  };

  const uploadSurveyReport = async (surveyId: number, file: FileDto, type: SurveyFileType) => {
    await uploadSurveyReportService({ surveyId, file, type });
    await getSurveyFiles(surveyId);
  };

  return {
    deleteReport,
    getSurveyFiles,
    uploadSurveyReport,
    reportFiles,
    isLoadingSurveyReports,
  };
};
