import { ref } from "vue";
import type { SiteLeanDto, GetSitesResponse } from "@/types/_generated/api";
import { getModuleSites as getModuleSitesService } from "@/services/sites";

export type SiteDefinitions = {
  siteDefinition1?: GetSitesResponse["moduleSiteDefinition1"];
  siteDefinition2?: GetSitesResponse["moduleSiteDefinition2"];
  siteDefinition3?: GetSitesResponse["moduleSiteDefinition3"];
  siteDefinition4?: GetSitesResponse["moduleSiteDefinition4"];
};

export const useGetSites = () => {
  const sites = ref<SiteLeanDto[]>([]);
  const siteDefinitions = ref<SiteDefinitions | null>(null);
  const isLoadingSites = ref(false);
  const hasError = ref(false);

  const getSites = async (moduleId: number, includeOnlyPublished?: boolean) => {
    isLoadingSites.value = true;
    hasError.value = false;

    try {
      const response = await getModuleSitesService(moduleId, includeOnlyPublished);

      if (response === null) {
        throw new Error("Failed to fetch sites");
      }

      sites.value = response.sites;

      siteDefinitions.value = {
        siteDefinition1: response.moduleSiteDefinition1,
        siteDefinition2: response.moduleSiteDefinition2,
        siteDefinition3: response.moduleSiteDefinition3,
        siteDefinition4: response.moduleSiteDefinition4,
      };
    } catch (error) {
      hasError.value = true;
    } finally {
      isLoadingSites.value = false;
    }
  };

  return { sites, siteDefinitions, isLoadingSites, hasError, getSites };
};
