<script setup lang="ts">
import { computed } from "vue";
import type { ExecutiveReportSurveyDto } from "@/types/_generated/api";
import BlueRatingTableCellColor from "@/components/shared/BlueRatingTable/BlueRatingTableCellColor.vue";
import ExecSummaryTableCellSite from "../ExecSummaryTable/ExecSummaryTableCells/ExecSummaryTableCellSite.vue";

interface Props {
  categoryIndex: number;
  survey: ExecutiveReportSurveyDto;
  showScore: boolean;
  showPercentage: boolean;
}

const props = defineProps<Props>();

const categoryData = computed(() => props.survey.categoryData[props.categoryIndex]);
</script>

<template>
  <tr>
    <BlueRatingTableCellColor :color="categoryData.color" />

    <ExecSummaryTableCellSite :row="survey" />

    <BlueRatingTableCellColor
      v-for="(reply, index) in categoryData.replies"
      :key="`${survey.surveyId}_${index}`"
      :color="reply.color"
    />

    <td>{{ survey.type }}</td>

    <td v-if="showScore" data-right-align>
      {{ categoryData.score }}
    </td>

    <td v-if="showPercentage" data-right-align>
      {{ categoryData.scorePercent }}
    </td>
  </tr>
</template>

<style scoped lang="scss">
@import "@/components/shared/BlueRatingTable/styles/styles";
</style>
