<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import type { RecommendationResponseDto, UploadRecResponseFile } from "@/types/_generated/api";
import { toBase64 } from "@/helpers/toBase64";
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseFile from "@/components/base/BaseFile/BaseFile.vue";
import BaseFileUpload, { FileUpload } from "@/components/base/BaseFileUpload/BaseFileUpload.vue";
import { FileFormat } from "@/components/base/BaseFileUpload/FileFormat";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseImage from "@/components/base/BaseImage.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import BaseModal from "@/components/base/BaseModal.vue";

const imageFileExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp", "tiff"];

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  response: RecommendationResponseDto;
  canEdit?: boolean;
  allowedFileTypes?: string[];
}>();

const emit = defineEmits<{
  delete: [fileId: string];
  new: [];
}>();

const showUploadModal = ref(false);

const getBody = async (
  files: FileUpload[],
): Promise<Record<keyof UploadRecResponseFile, unknown>> => ({
  file: {
    fileName: files[0].file.name,
    file: await toBase64(files[0].file),
    fileSize: files[0].file.size,
  },
  recommendationResponseId: props.response.id,
});

const imageFiles = computed(() =>
  props.response.files.filter(
    (f) =>
      !!imageFileExtensions.find((e) => f.fileName?.length && f.fileName.toLowerCase().endsWith(e)),
  ),
);

const documentFiles = computed(() =>
  props.response.files.filter((f) => !imageFiles.value.find((i) => i.fileId === f.fileId)),
);

const deleteFile = (fileId: string) => emit("delete", fileId);

const fileUploaded = () => emit("new");

const openUploadModal = () => {
  showUploadModal.value = true;
};
</script>

<template>
  <div class="recommendation-response-files">
    <div v-if="imageFiles.length" class="recommendation-response-files__images">
      <BaseLabel v-if="canEdit">{{ t("recommendationResponse.images") }}</BaseLabel>
      <span v-else>{{ t("recommendationResponse.images") }}:</span>

      <div
        v-for="(file, i) in imageFiles"
        :key="`response_${response.id}_image_${i}`"
        class="recommendation-response-files__images__image"
      >
        <BaseImage
          :src="`/v1/recommendation-response/response/${response.id}/files/${file.fileId}`"
          :alt="file.fileName"
          :max-width="'200px'"
          :max-height="'200px'"
          :title="file.fileName"
        />
        <BaseButton
          v-if="canEdit"
          class="recommendation-response-files__images__image__delete"
          :title="t('common.actions.delete')"
          variant="text"
          color="error"
          @click="() => deleteFile(file.fileId)"
        >
          <BaseIcon icon="trash" />
        </BaseButton>
      </div>
    </div>

    <div v-if="documentFiles.length" class="recommendation-response-files__files">
      <BaseLabel v-if="canEdit">{{ t("recommendationResponse.documents") }}</BaseLabel>
      <span v-else>{{ t("recommendationResponse.documents") }}:</span>

      <BaseFile
        v-for="(file, i) in documentFiles"
        :key="`response_${response.id}_file_${i}`"
        :file="{ fileId: file.fileId, fileName: file.fileName }"
        :edit="canEdit"
        :file-url="`/recommendation-response/response/${response.id}/files/${file.fileId}`"
        @delete="deleteFile"
      />
    </div>

    <div v-if="canEdit" class="recommendation-response-files__upload">
      <BaseButton variant="outlined" @click="openUploadModal">
        <BaseIcon icon="upload" />
        {{ t("common.actions.uploadFiles") }}
      </BaseButton>

      <BaseModal
        v-if="showUploadModal"
        size="large"
        :hide-cancel="true"
        :confirm-text="t('common.actions.close')"
        @confirm="showUploadModal = false"
      >
        <div class="recommendation-response-files__upload__modal-body">
          <BaseAlert
            severity="info"
            class="recommendation-response-files__upload__modal-body__info-label"
          >
            {{ t("recommendationResponse.filesAreSavedImmediately") }}
          </BaseAlert>
          <BaseFileUpload
            :body="getBody"
            :max-files="1"
            :file-type="FileFormat.RecommendationResponseDocument"
            :url="`/recommendation-response/response/${response.id}/files`"
            @files-uploaded="fileUploaded"
          />
        </div>
      </BaseModal>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.recommendation-response-files {
  overflow-x: hidden;
  display: flex;
  gap: $spacing-4;
  flex-wrap: wrap;

  &__images {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-4;

    &__image {
      position: relative;
      min-width: 4rem;
      min-height: 4rem;

      &__delete {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  &__files {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-4;
  }

  &__upload {
    margin-top: $spacing-4;
  }
}
</style>
