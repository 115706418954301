import http from "@/helpers/http";
import { i18n } from "@/i18n";
import { notify } from "@/stores/notifications";
import {
  UserDto,
  GetUserResponse,
  UserLeanDto,
  GetUsersResponse,
  CreateUser,
  CreateUserResponse,
  UpdateUser,
} from "@/types/_generated/api";
import { BroadcastService } from "./shared/BroadcastService";
import { handleError } from "./shared/handleError";

const { t } = i18n.global;

export const userEvents = new BroadcastService("users");

export const getUser = async (id: number): Promise<UserDto | null> => {
  try {
    const {
      data: { user },
    } = await http.get<GetUserResponse>(`users/${id}`);
    return user ?? null;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("users.user"),
    });
  }
  return null;
};

export const getUsers = async (params?: {
  clientId?: number;
  moduleId?: number;
  onlyActive?: boolean;
}): Promise<UserLeanDto[] | null> => {
  try {
    const {
      data: { users },
    } = await http.get<GetUsersResponse>("/users", {
      params,
    });
    return users;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("users.users"),
    });
  }
  return null;
};

export const createUser = async (request: CreateUser): Promise<number | null> => {
  try {
    const {
      data: { userId },
    } = await http.post<CreateUserResponse>(`/users/`, request);

    notify.success(t("users.user"), t("common.created").toLowerCase());
    userEvents.post({ action: "create", id: userId });

    return userId;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.create").toLowerCase(),
      entity: t("users.user"),
    });
  }
  return null;
};

export const updateUser = async (request: UpdateUser): Promise<boolean> => {
  try {
    await http.put(`/users/${request.userId}`, request);

    notify.success(t("users.user"), t("common.updated").toLowerCase());
    userEvents.post({ action: "update", id: request.userId });

    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update").toLowerCase(),
      entity: t("users.user"),
    });
  }
  return false;
};

export const updateUserPassword = async (request: {
  password: string;
  userId: number;
}): Promise<boolean> => {
  try {
    await http.put(`/users/${request.userId}/password`, {
      password: request.password,
    });
    notify.success(t("users.password"), t("common.updated").toLowerCase());
    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.update").toLowerCase(),
      entity: t("users.password"),
    });
  }
  return false;
};

export const archiveUser = async (userId: number): Promise<boolean> => {
  try {
    await http.put(`/users/${userId}/archive`);
    notify.success(t("users.user"), t("common.archived").toLowerCase());
    userEvents.post({ action: "delete", id: userId });
    return true;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.archive").toLowerCase(),
      entity: t("users.user"),
    });
  }
  return false;
};
