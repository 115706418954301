<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

interface Props {
  title: string;
  subTitle?: string;
}

defineProps<Props>();

defineEmits<{
  close: [void];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="base-drawer-header">
    <div class="base-drawer-header__titles">
      <p class="base-drawer-header__titles__title">
        {{ title }}
      </p>
      <p v-if="subTitle" class="base-drawer-header__titles__subtitle">
        {{ subTitle }}
      </p>
    </div>

    <BaseButton :aria-label="t('common.actions.close')" variant="text" @click="$emit('close')">
      <BaseIcon icon="close" />
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.base-drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-4 $spacing-6;
  border-bottom: 1px solid $primary-5;

  &__titles {
    display: flex;
    flex-direction: column;
    gap: $spacing-2;

    &__title {
      font-size: $text-base;
      font-weight: $font-semibold;
    }
  }
}
</style>
