<script setup lang="ts">
import { toBase64 } from "@/helpers/toBase64";
import { SiteFileType } from "@/types/_generated/api";
import BaseFileUpload, { FileUpload } from "@/components/base/BaseFileUpload/BaseFileUpload.vue";
import { FileFormat } from "@/components/base/BaseFileUpload/FileFormat";

interface Props {
  moduleId: number;
  siteId: number;
  currentFileId?: string;
}

const props = defineProps<Props>();

defineEmits<{ "files-uploaded": [fileId?: string] }>();

const uploadUrl = `/sites/${props.siteId}/files`;

const getBody = async (files: FileUpload[]) => {
  const images = [];
  for (const file of files) {
    images.push({
      fileName: file.file.name,
      file: await toBase64(file.file),
      rotation: file.rotation,
    });
  }

  return {
    moduleId: props.moduleId,
    siteId: props.siteId,
    file: images[0],
    type: SiteFileType.ReportImage,
  };
};
</script>

<template>
  <BaseFileUpload
    :max-files="1"
    :file-type="FileFormat.Image"
    :body="getBody"
    :url="uploadUrl"
    @files-uploaded="$emit('files-uploaded', $event)"
  />
</template>
