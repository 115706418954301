<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { TrackedRecommendation } from "@/composables/services/useSearchRecommendationTracking";
import { BlueRatingRecommendationDto } from "@/types/_generated/api";
import BaseBlueRatingStatusBadge from "@/components/base/BaseBlueRatingStatusBadge.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseSafeHtml from "@/components/base/BaseSafeHtml/BaseSafeHtml.vue";
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";
import ModImageList from "@/components/mods/ImageList/ModImageList.vue";

defineProps<{
  isLoading: boolean;
  trackedRecommendation: TrackedRecommendation;
  recommendation: BlueRatingRecommendationDto | null;
}>();

defineEmits<{
  closeDrawer: [void];
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <UWDrawer
    :drawer-title="`${trackedRecommendation.fullPosition} - ${trackedRecommendation.heading}`"
    @close-drawer="$emit('closeDrawer')"
  >
    <div class="rec-tracking-table-drawer">
      <BaseDataDisplayListItem :label="t('userWeb.recommendation.surveyDate')">
        <p>{{ trackedRecommendation.surveyDate }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem :label="t('userWeb.recommendation.category')">
        <p>{{ trackedRecommendation.categoryName }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem :label="t('userWeb.recommendation.blueRating')">
        <BaseBlueRatingStatusBadge :status="trackedRecommendation.color" :size="'medium'" />
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem
        v-if="trackedRecommendation.priority"
        :label="t('userWeb.recommendation.priority')"
      >
        <p>{{ trackedRecommendation.priority }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem
        v-if="trackedRecommendation.type"
        :label="t('userWeb.recommendation.type')"
      >
        <p>{{ trackedRecommendation.type }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem :label="t('userWeb.recommendation.name')">
        <p>{{ trackedRecommendation.heading }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem :label="t('userWeb.recommendation.recommmendation')">
        <div v-if="isLoading">
          <BaseSkeleton mb="5px" height="20px" width="400px" />
          <BaseSkeleton mb="5px" height="20px" width="375px" />
          <BaseSkeleton height="20px" width="390px" />
        </div>
        <BaseSafeHtml v-else-if="!isLoading && recommendation" :html="recommendation?.text" />
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem
        v-if="recommendation?.location"
        :is-loading="isLoading"
        :label="t('userWeb.recommendation.location')"
      >
        <p>{{ recommendation?.location }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem
        v-if="trackedRecommendation.costBenefitRatio"
        label="Cost-benefit ratio"
      >
        <p>{{ trackedRecommendation.costBenefitRatio }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem :label="t('userWeb.recommendation.surveyorStatus')">
        <p>{{ t(`common.statuses.${trackedRecommendation.surveyorStatus}`) }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem :label="t('userWeb.recommendation.responseStatus')">
        <p>{{ t(`common.statuses.${trackedRecommendation.siteResponseStatus}`) }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem
        v-if="trackedRecommendation.siteResponseComment"
        label="Site Response Comment"
      >
        <p>{{ trackedRecommendation.siteResponseComment }}</p>
      </BaseDataDisplayListItem>

      <!-- Site Response cost to complete -->
      <BaseDataDisplayListItem
        v-if="trackedRecommendation.siteResponseCostToComplete"
        label="Site Response Cost to Complete"
      >
        <p>{{ trackedRecommendation.siteResponseCostToComplete }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem
        v-if="trackedRecommendation.responsiblePerson"
        label="Site Response Responsible Person"
      >
        <p>{{ trackedRecommendation.responsiblePerson }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem v-if="trackedRecommendation.dueDate" label="Site Response Due Date">
        <p>{{ trackedRecommendation.dueDate }}</p>
      </BaseDataDisplayListItem>

      <!-- Company comment -->
      <BaseDataDisplayListItem
        v-if="trackedRecommendation.riskManagerComment"
        label="Company Comment"
      >
        <p>{{ trackedRecommendation.riskManagerComment }}</p>
      </BaseDataDisplayListItem>

      <BaseDataDisplayListItem label="Uploaded photos">
        <ModImageList
          :url-builder="
            (image) =>
              `/v1/surveys/${trackedRecommendation.surveyId}/recommendations/${trackedRecommendation.id}/images?fileId=${image.fileId}`
          "
          :images="recommendation?.images ?? []"
        />
      </BaseDataDisplayListItem>
    </div>
  </UWDrawer>
</template>

<style scoped lang="scss">
.rec-tracking-table-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
}
</style>
