<script setup lang="ts">
import { BlueRatingRecommendationDto, SiteResponseStatus } from "@/types/_generated/api";
import BaseStatusBadge from "@/components/base/BaseStatusBadge.vue";

defineProps<{
  recommendations: BlueRatingRecommendationDto[];
  selected?: BlueRatingRecommendationDto;
  status: "surveyor" | "response";
}>();
defineEmits<{ select: [number] }>();

const getTitle = (recommendation: BlueRatingRecommendationDto) => {
  return `${recommendation.fullPosition} ${recommendation.header}`;
};
</script>

<template>
  <div class="online-report-menu">
    <div
      v-for="(recommendation, i) of recommendations"
      :key="`online-report-menu-rec-${i}`"
      :title="getTitle(recommendation)"
      class="online-report-menu__item"
      :class="{
        'online-report-menu__item--odd-row': i % 2 === 0,
        'online-report-menu__item--selected':
          selected?.recommendationId === recommendation.recommendationId,
      }"
      @click.stop="$emit('select', recommendation.recommendationId)"
    >
      <div class="online-report-menu__item__title">
        {{ getTitle(recommendation) }}
      </div>

      <BaseStatusBadge
        v-if="status === 'surveyor'"
        class="online-report-menu__item__status"
        :status="recommendation.surveyorStatus"
      />

      <BaseStatusBadge
        v-if="status === 'response'"
        class="online-report-menu__item__status"
        :status="recommendation.responseHistory.history[0]?.status ?? SiteResponseStatus.NotSet"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.online-report-menu {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-2 $spacing-2;
    gap: $spacing-2;
    border-left: 2px solid transparent;

    cursor: pointer;
    line-height: $leading-relaxed;

    &__title {
      font-size: $text-sm;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__status {
      line-height: $leading-snug;
    }

    &--odd-row {
      background-color: $primary-2;
    }

    &--selected {
      border-color: $secondary-5;
      background-color: $primary-3;
    }

    &:hover {
      background-color: $primary-3;
    }
  }
}
</style>
