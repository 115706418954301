<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useBlueColorTranslations } from "@/composables/translations/useBlueColorTranslations";
import { BlueColor } from "@/types/_generated/api";
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion.vue";
import ModMultiselect from "@/components/mods/Multiselect/ModMultiselect.vue";

interface Props {
  recommendationColors: Set<BlueColor>;
}

defineProps<Props>();

defineEmits<{
  "update:selected": [options: BlueColor[]];
}>();

const { t } = useI18n({ useScope: "global" });

const { translations } = useBlueColorTranslations();

const blueColors = Object.values(BlueColor).map((color) => ({
  value: color,
  label: translations[color],
}));
</script>

<template>
  <BaseAccordion :label="t('userWeb.recommendation.blueRating')">
    <ModMultiselect
      :options="blueColors"
      :selected-options="recommendationColors"
      @update:selected="$emit('update:selected', $event)"
    />
  </BaseAccordion>
</template>
