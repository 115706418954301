<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type {
  ExecutiveReportLossScenarioDto,
  ExecutiveReportSurveyDto,
  ModuleDto,
} from "@/types/_generated/api";
import { NO_VALUE_GROUP } from "../../../composables/useGroupedSurveys";
import { ClientFilter } from "../../../types/FilterTypes";
import ExecSummaryTableSurveyRows from "./ExecSummaryTableSurveyRows.vue";

interface Props {
  group: string;
  clientFilter: ClientFilter;
  rows: ExecutiveReportSurveyDto[];
  lossScenarios: ExecutiveReportLossScenarioDto[];
  abeyanceShort: ModuleDto["abeyanceShort"];
}

const props = defineProps<Props>();

defineEmits<{
  openDrawer: [{ surveyId: number; index: number }];
}>();

const { t } = useI18n({ useScope: "global" });

const label = computed(() => {
  if (props.group === NO_VALUE_GROUP) {
    return t("userWeb.blueRatingTable.group.noValue");
  }

  return props.group;
});
</script>

<template>
  <tr>
    <td :colspan="Number.MAX_SAFE_INTEGER" class="exec-summary-table-group-header">
      {{ label }}
    </td>
  </tr>

  <ExecSummaryTableSurveyRows
    :rows="rows"
    :loss-scenarios="lossScenarios"
    :client-filter="clientFilter"
    :abeyance-short="abeyanceShort"
    @open-drawer="$emit('openDrawer', $event)"
  />
</template>

<style lang="scss" scoped>
@import "@/components/shared/BlueRatingTable/styles/styles";

.exec-summary-table-group-header {
  font-weight: $font-medium;
  background: $primary-3;
  padding-left: $spacing-2;
}
</style>
