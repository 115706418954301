<script setup lang="ts">
import { reactive, type DeepReadonly } from "vue";
import { useI18n } from "vue-i18n";
import type { SitesData } from "@/composables/services/useGetSitesData";
import type { CustomDefinitionKey } from "@/types/SiteDefinitions";
import type {
  ServerFilter,
  ClientFilter,
} from "@/views/UserWeb/ExecutiveSummary/types/FilterTypes";
import {
  type ExecutiveReportCategoryDto,
  type ExecutiveReportLossScenarioDto,
  SurveyType,
} from "@/types/_generated/api";
import BaseAccordion from "@/components/base/BaseAccordion/BaseAccordion.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDivider from "@/components/base/BaseDivider.vue";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";
import ModFilterDateRange from "@/components/mods/Filter/DateRange/ModFilterDateRange.vue";
import ModFilterSiteDefinitions from "@/components/mods/Filter/SiteDefinitions/ModFilterSiteDefinitions.vue";
import ModFilterSurveyType from "@/components/mods/Filter/SurveyType/ModFilterSurveyType.vue";
import ExecSummaryFilterCategories from "./ExecSummaryFilterOptions/ExecSummaryFilterCategories.vue";
import ExecSummaryFilterColumns from "./ExecSummaryFilterOptions/ExecSummaryFilterColumns.vue";
import ExecSummaryFilterSurveyRound from "./ExecSummaryFilterOptions/ExecSummaryFilterSurveyRound.vue";

const props = defineProps<{
  categories?: ExecutiveReportCategoryDto[];
  lossScenarios: ExecutiveReportLossScenarioDto[];
  sitesData: DeepReadonly<SitesData>;
  serverFilter: ServerFilter;
  clientFilter: ClientFilter;
}>();

const emit = defineEmits<{
  closeDrawer: [void];
  applyClientFilter: [ClientFilter];
  applyServerFilter: [ServerFilter];
}>();

const { t } = useI18n({ useScope: "global" });

const pendingServerFilter = reactive<ServerFilter>({
  ...props.serverFilter,
  excludedSiteDefinitions: { ...props.serverFilter.excludedSiteDefinitions },
});
const pendingClientFilter = reactive<ClientFilter>({ ...props.clientFilter });

const applyFilter = (closeDrawer: () => void) => {
  emit("applyClientFilter", pendingClientFilter);
  emit("applyServerFilter", pendingServerFilter);
  closeDrawer();
};

const updateExcludedSiteDefinitions = (key: CustomDefinitionKey, value: Set<string>) => {
  pendingServerFilter.excludedSiteDefinitions[key] = Array.from(value);
};
</script>

<template>
  <UWDrawer size="small" :drawer-title="t('common.filter')" @close-drawer="$emit('closeDrawer')">
    <div class="exec-summary-filter-drawer">
      <div class="exec-summary-filter-drawer__row">
        <ModFilterSurveyType
          :selected-survey-type="pendingClientFilter.surveyType || SurveyType.WEXT"
          @update:survey-type="pendingClientFilter.surveyType = $event"
        />
        <ExecSummaryFilterSurveyRound
          :selected-survey-round="pendingServerFilter.surveyRound?.toString() || ''"
          @update:survey-round="pendingServerFilter.surveyRound = $event"
        />
      </div>
      <BaseDivider />

      <ModFilterDateRange
        :start-date="pendingServerFilter.startDate ?? ''"
        :end-date="pendingServerFilter.endDate ?? ''"
        @update:start-date="pendingServerFilter.startDate = $event"
        @update:end-date="pendingServerFilter.endDate = $event"
      />
      <BaseDivider />

      <BaseAccordion :label="t('userWeb.filter.columns')" expanded-by-default>
        <ExecSummaryFilterColumns
          :loss-scenarios="lossScenarios"
          :selected-columns="pendingClientFilter.selectedColumns"
          @update:selected-columns="pendingClientFilter.selectedColumns = $event"
        />
      </BaseAccordion>

      <BaseDivider />

      <BaseAccordion v-if="categories && categories.length" :label="t('userWeb.filter.categories')">
        <ExecSummaryFilterCategories
          :categories="categories"
          :deselected-categories="pendingClientFilter.deselectedCategories"
          @update:deselected-categories="pendingClientFilter.deselectedCategories = $event"
        />
      </BaseAccordion>

      <BaseDivider />

      <ModFilterSiteDefinitions
        :sites-data="sitesData"
        :excluded-sets="{
          custom1: new Set(pendingServerFilter.excludedSiteDefinitions.custom1),
          custom2: new Set(pendingServerFilter.excludedSiteDefinitions.custom2),
          custom3: new Set(pendingServerFilter.excludedSiteDefinitions.custom3),
          custom4: new Set(pendingServerFilter.excludedSiteDefinitions.custom4),
        }"
        @update:excluded-sets="updateExcludedSiteDefinitions"
      />
    </div>

    <template #footer="{ onCloseDrawer }">
      <BaseButton @click="applyFilter(onCloseDrawer)">
        {{ t("userWeb.filter.apply") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="onCloseDrawer">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </UWDrawer>
</template>

<style scoped lang="scss">
.exec-summary-filter-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-4;
  }
}
</style>
