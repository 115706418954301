<script setup lang="ts">
import { ref, reactive, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { RecResponseMfa } from "@/types/_generated/api";
import AuthWrapper from "@/views/Auth/components/AuthWrapper.vue";
import type { MfaSelectionResult } from "./types";
import LoginMfaForm from "./components/LoginMfaForm.vue";
import LoginMfaSelector from "./components/LoginMfaSelector.vue";
import LoginRequestResend from "./components/LoginRequestResend.vue";
import LoginValidation from "./components/LoginValidation.vue";

enum AuthStatus {
  ParamMissing = "paramMissing",
  ValidatingParam = "validatingParam",
  ChoosingMfaMethod = "choosingMfaMethod",
  EnteringMfaCode = "enteringMfaCode",
}

const { t } = useI18n({ useScope: "global" });
const router = useRouter();
const { linkId } = router.currentRoute.value.query;

const authStatus = ref<AuthStatus>(
  typeof linkId === "string" && linkId.length
    ? AuthStatus.ValidatingParam
    : AuthStatus.ParamMissing,
);
const mfaOptions = ref<RecResponseMfa[]>([]);
const verification = reactive<MfaSelectionResult>({
  method: null,
  expirationTimeHours: 0,
});

const heading = computed(() => {
  switch (authStatus.value) {
    case AuthStatus.ChoosingMfaMethod:
      return t("signIn.2fa.method");
    case AuthStatus.EnteringMfaCode:
      return t("signIn.2fa.enterCode");
    default:
      return "";
  }
});

const handleVerificationMethod = (verificationResult: MfaSelectionResult) => {
  const { method, expirationTimeHours } = verificationResult;
  verification.method = method;
  verification.expirationTimeHours = expirationTimeHours;

  authStatus.value = AuthStatus.EnteringMfaCode;
};

const handleMfaOptionsReceived = (options: RecResponseMfa[]) => {
  mfaOptions.value = options;

  authStatus.value = AuthStatus.ChoosingMfaMethod;
};
</script>

<template>
  <AuthWrapper :heading="heading">
    <LoginValidation
      v-if="authStatus === AuthStatus.ValidatingParam"
      @mfa-options-received="handleMfaOptionsReceived"
    />

    <LoginRequestResend v-if="authStatus === AuthStatus.ParamMissing" />

    <LoginMfaSelector
      v-if="authStatus === AuthStatus.ChoosingMfaMethod"
      :available-mfa-options="mfaOptions"
      @mfa-requested="handleVerificationMethod"
    />

    <LoginMfaForm v-if="authStatus === AuthStatus.EnteringMfaCode" :verification="verification" />
  </AuthWrapper>
</template>
