<script setup lang="ts">
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import { SurveyType } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDivider from "@/components/base/BaseDivider.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";
import ModFilterSurveyType from "@/components/mods/Filter/SurveyType/ModFilterSurveyType.vue";
import ModMultiselect from "@/components/mods/Multiselect/ModMultiselect.vue";
import type { SiteBlueRatingFilter } from "../../types/SiteBlueRatingFilter";
import { FilterColumn } from "../../constants/FilterColumn";

const props = defineProps<{
  tableFilter: SiteBlueRatingFilter;
}>();

const emit = defineEmits<{
  closeDrawer: [void];
  applyTableFilter: [SiteBlueRatingFilter];
}>();

const { t } = useI18n({ useScope: "global" });

const pendingTableFilter = reactive<SiteBlueRatingFilter>({ ...props.tableFilter });

const applyFilter = (closeDrawer: () => void) => {
  emit("applyTableFilter", pendingTableFilter);
  closeDrawer();
};
</script>

<template>
  <UWDrawer size="small" :drawer-title="t('common.filter')" @close-drawer="$emit('closeDrawer')">
    <div class="site-blue-rating-filter-drawer">
      <ModFilterSurveyType
        :selected-survey-type="pendingTableFilter.surveyType || SurveyType.WEXT"
        @update:survey-type="pendingTableFilter.surveyType = $event"
      />

      <BaseDivider />

      <div>
        <BaseLabel has-spacing>{{ t("userWeb.filter.columns") }}</BaseLabel>
        <ModMultiselect
          no-spacing
          :options="[
            { label: t('userWeb.blueRatingTable.score'), value: FilterColumn.Score },
            { label: t('userWeb.blueRatingTable.percentage'), value: FilterColumn.Percentage },
          ]"
          :selected-options="pendingTableFilter.selectedColumns"
          @update:selected="pendingTableFilter.selectedColumns = new Set($event)"
        />
      </div>
    </div>

    <template #footer="{ onCloseDrawer }">
      <BaseButton @click="applyFilter(onCloseDrawer)">
        {{ t("userWeb.filter.apply") }}
      </BaseButton>

      <BaseButton variant="outlined" @click="onCloseDrawer">
        {{ t("common.actions.cancel") }}
      </BaseButton>
    </template>
  </UWDrawer>
</template>

<style scoped lang="scss">
.site-blue-rating-filter-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-6;
}
</style>
