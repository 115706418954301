<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { DeepReadonly } from "vue";
import type { GetBlueRatingSummaryResponse } from "@/types/_generated/api";
import BlueRatingTableCellColor from "@/components/shared/BlueRatingTable/BlueRatingTableCellColor.vue";
import BlueRatingTableDynamicHeight from "@/components/shared/BlueRatingTable/BlueRatingTableDynamicHeight.vue";
import BlueRatingTableFooter from "@/components/shared/BlueRatingTable/BlueRatingTableFooter.vue";
import BlueRatingTableFooterAreas from "@/components/shared/BlueRatingTable/BlueRatingTableFooterAreas.vue";
import BlueRatingTableRotatedHeader from "@/components/shared/BlueRatingTable/BlueRatingTableRotatedHeader.vue";
import type { SiteBlueRatingFilter } from "../../types/SiteBlueRatingFilter";
import { FilterColumn } from "../../constants/FilterColumn";

defineProps<{
  tableFilter: SiteBlueRatingFilter;
  blueRatingSummary: DeepReadonly<GetBlueRatingSummaryResponse>;
}>();

const { t } = useI18n({ useScope: "global" });
</script>

<template>
  <div class="site-blue-rating-table">
    <BlueRatingTableDynamicHeight>
      <table>
        <thead>
          <tr>
            <th></th>
            <BlueRatingTableRotatedHeader
              v-for="category in blueRatingSummary?.categories"
              :key="category.position"
            >
              {{ `${category.position}: ${category.categoryName}` }}
            </BlueRatingTableRotatedHeader>

            <th>{{ t("userWeb.blueRatingTable.surveyType") }}</th>

            <th v-if="tableFilter.selectedColumns.has(FilterColumn.Score)">
              {{ t("userWeb.blueRatingTable.score") }}
            </th>

            <th v-if="tableFilter.selectedColumns.has(FilterColumn.Percentage)">
              {{ `${t("userWeb.blueRatingTable.score")} (%)` }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(survey, i) in blueRatingSummary?.surveys" :key="i">
            <td data-first-column>{{ survey.surveyDate }}</td>

            <BlueRatingTableCellColor
              v-for="(cell, idx) in survey.categoryData"
              :key="idx"
              :color="cell.color"
              :title="cell.score?.toString()"
            />

            <td>{{ survey.type }}</td>

            <td v-if="tableFilter.selectedColumns.has(FilterColumn.Score)" data-right-align>
              {{ survey.totalScore }}
            </td>

            <td v-if="tableFilter.selectedColumns.has(FilterColumn.Percentage)" data-right-align>
              {{ survey.scorePercent }}
            </td>
          </tr>
        </tbody>

        <BlueRatingTableFooter>
          <BlueRatingTableFooterAreas
            :areas="blueRatingSummary.areas"
            :categories="blueRatingSummary.categories"
          />
        </BlueRatingTableFooter>
      </table>
    </BlueRatingTableDynamicHeight>
  </div>
</template>

<style scoped lang="scss">
@import "@/components/shared/BlueRatingTable/styles/styles";

.site-blue-rating-table {
  padding-top: $table-padding-top;
  overflow: auto;
}
</style>
