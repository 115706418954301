<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BaseChart from "@/components/base/BaseChart/BaseChart.vue";
import { BORDER, Y_AXIS_STYLE } from "@/components/base/BaseChart/constants/style";
import { truncateLabel, tooltipFormatter } from "@/components/base/BaseChart/helpers/";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  labels: string[];
  colors: Record<string, string>;
  series: string[];
  data: number[][];
}>();

// Rotate labels depending on the number of labels and the screen width
const rotateLabels = props.labels.length > 10 || window.innerWidth < 1536;

const option = {
  legend: { selectedMode: true, type: "scroll" },
  grid: {
    top: 50,
    left: 35,
    right: 50,
    bottom: 0,
    containLabel: true,
  },
  yAxis: {
    type: "value",
    name: t("userWeb.recommendationTracking.siteResponseBlueRatingChart.yAxisName"),
    ...Y_AXIS_STYLE,
  },
  xAxis: {
    type: "category",
    data: props.labels,
    axisLabel: {
      rotate: rotateLabels ? -45 : 0,
      formatter: (value: string) => {
        if (rotateLabels) {
          return truncateLabel(value, 20);
        }
        return value;
      },
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: tooltipFormatter,
  },
  series: props.series.map((label, index) => ({
    name: label,
    type: "bar",
    stack: "total",
    data: props.data[index],
    itemStyle: {
      color: props.colors[label],
      borderColor: BORDER.COLOR,
      borderWidth: BORDER.WIDTH,
    },
  })),
};
</script>

<template>
  <BaseChart :option="option" />
</template>
