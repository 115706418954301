<script setup lang="ts">
import { computed, ref } from "vue";
import type { RouteParamsRaw } from "vue-router";
import { useAuth } from "@/composables/useAuth";
import { UserWebRouteName } from "@/router/RouteName";
import { useAppStore } from "@/stores/app";
import { Role } from "@/types/_generated/api";
import { Breadcrumb } from "@/types/Breadcrumb";
import UserDetailsDrawer from "@/views/Admin/Users/components/UserDetailsDrawer/UserDetailsDrawer.vue";
import AdminHeaderBreadcrumbs from "./AdminHeaderBreadcrumbs.vue";
import AdminHeaderMenu from "./AdminHeaderMenu.vue";
import AdminHeaderMore from "./AdminHeaderMore.vue";

type Props = {
  breadcrumbs?: Breadcrumb[];
  userWeb?: { name: UserWebRouteName; params?: RouteParamsRaw } | false;
};

const props = defineProps<Props>();

const app = useAppStore();
const { isFullyAuthenticated } = useAuth();

const showUserDetails = ref(false);

const filteredUserWebRoute = computed((): Props["userWeb"] => {
  if (!props.userWeb) {
    return false;
  }

  if (app.user?.role === Role.EXT && !app.user?.allowUserWeb) {
    return false;
  }

  return {
    name: props.userWeb.name,
    params: props.userWeb.params,
  };
});
</script>

<template>
  <header class="admin-header">
    <div class="admin-header__side">
      <AdminHeaderMenu v-if="isFullyAuthenticated" />

      <AdminHeaderBreadcrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs" />
    </div>

    <div class="admin-header__side">
      <AdminHeaderMore
        v-if="isFullyAuthenticated"
        :full-name="`${app.user?.firstName} ${app.user?.lastName}`"
        :user-web="filteredUserWebRoute"
        @open-user-details="showUserDetails = true"
      />

      <UserDetailsDrawer
        v-if="showUserDetails && app.user"
        width="30"
        :user-id="app.user?.id"
        @close="showUserDetails = false"
      />
    </div>
  </header>
</template>

<style scoped lang="scss">
.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $spacing-8;
  height: $header-height;
  background-color: $secondary-6;

  &__side {
    display: flex;
    align-items: center;
  }
}
</style>
