<script setup lang="ts">
import { ErrorObject } from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { Status } from "@/types/_generated/api";
import { Option } from "@/types/Option";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";

const props = defineProps<{
  value: Status;
  error?: string;
  requireSpoeCheck: boolean;
}>();

const { t } = useI18n({ useScope: "global" });

const emit = defineEmits<{
  "update:value": [Status];
}>();

const options: readonly Option[] = [
  {
    title: t(`common.statuses.${Status.Published}`),
    value: Status.Published,
  },
  {
    title: t(`common.statuses.${Status.Complete}`),
    value: Status.Complete,
  },
  {
    title: t(`common.statuses.${Status.UnderConstruction}`),
    value: Status.UnderConstruction,
  },
  {
    title: t(`common.archived`),
    value: Status.History,
  },
];

const errors = computed(() =>
  props.error ? [{ $message: props.error } as ErrorObject] : undefined,
);
</script>

<template>
  <BaseSelect
    :value="value"
    :label="t('common.status')"
    data-test="survey-settings-status"
    :errors="errors"
    @change="emit('update:value', $event as Status)"
  >
    <BaseOption v-for="option in options" :key="option.value" :value="option.value">
      {{ option.title }}
    </BaseOption>
  </BaseSelect>
</template>
