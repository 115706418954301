<script setup lang="ts">
import { useI18n } from "vue-i18n";
import UserWebViewLayout from "@/components/app/UserWeb/UserWebViewLayout.vue";
import DashboardTable from "@/components/shared/Dashboard/DashboardTable.vue";
import DashboardMap from "./components/DashboardMap.vue";
import DashboardPercentage from "./components/DashboardPercentage.vue";
import { useGetCoordinates } from "./composables/useGetCoordinates";
import { useGetDashboardAverageScore } from "./composables/useGetDashboardAverageScore";
import { useGetDashboardRecResponseRatio } from "./composables/useGetDashboardRecResponseRatio";
import { useGetDashboardSurveyHistory } from "./composables/useGetDashboardSurveyHistory";
import { useGetHistory } from "./composables/useGetHistory";
import { useGetInsuredSites } from "./composables/useGetInsuredSites";

const props = defineProps<{
  moduleId: number;
}>();

const { t } = useI18n({ useScope: "global" });

const { isLoadingAverageScore, averageScore } = useGetDashboardAverageScore(props.moduleId);
const { isLoadingRatio, ratio } = useGetDashboardRecResponseRatio(props.moduleId);
const { isLoadingSurveyHistory, historyColumns, surveyHistory } = useGetDashboardSurveyHistory(
  props.moduleId,
);
const { isLoadingHistory, siteResponseHistory } = useGetHistory();
const { isLoadingInsuredSites, insuredSitesColumns, insuredSites } = useGetInsuredSites();
const { isLoadingCoordinates, coordinates } = useGetCoordinates(props.moduleId);
</script>

<template>
  <UserWebViewLayout>
    <div class="dashboard">
      <DashboardTable
        class="dashboard__surveyHistory"
        :is-loading="isLoadingSurveyHistory"
        sort-by="date"
        :title="t('userWeb.dashboard.surveyHistory')"
        :columns="historyColumns"
        :rows="surveyHistory"
      />

      <DashboardTable
        class="dashboard__siteResponseHistory"
        :is-loading="isLoadingHistory"
        sort-by="date"
        :title="t('userWeb.dashboard.siteResponseHistory')"
        :columns="historyColumns"
        :rows="siteResponseHistory"
      />

      <DashboardMap
        class="dashboard__map"
        :is-loading-data="isLoadingCoordinates"
        :coordinates="coordinates"
      />

      <DashboardPercentage
        class="dashboard__recResponseRatio"
        :is-loading="isLoadingRatio"
        :title="t('userWeb.dashboard.recResponseRatio')"
        :percentage="ratio"
      />

      <DashboardPercentage
        class="dashboard__avgTotalScore"
        :is-loading="isLoadingAverageScore"
        :title="t('userWeb.dashboard.averageTotalScore')"
        :percentage="averageScore"
      />

      <DashboardTable
        class="dashboard__insuredSites"
        :is-loading="isLoadingInsuredSites"
        sort-by="insuredValue"
        :title="t('userWeb.dashboard.insuredSites')"
        :columns="insuredSitesColumns"
        :rows="insuredSites"
      />
    </div>
  </UserWebViewLayout>
</template>

<style scoped lang="scss">
$spacing: $spacing-4;

.dashboard {
  display: grid;
  grid-template-areas:
    "surveyHistory siteResponseHistory map map"
    "surveyHistory siteResponseHistory recResponseRatio avgTotalScore"
    "surveyHistory siteResponseHistory insuredSites insuredSites";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr auto 1fr;
  align-items: start;
  gap: $spacing;
  height: 100%;

  &__surveyHistory {
    grid-area: surveyHistory;
  }
  &__siteResponseHistory {
    grid-area: siteResponseHistory;
  }
  &__map {
    grid-area: map;
  }
  &__recResponseRatio {
    grid-area: recResponseRatio;
  }
  &__avgTotalScore {
    grid-area: avgTotalScore;
  }
  &__insuredSites {
    grid-area: insuredSites;
  }
}
</style>
