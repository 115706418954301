<script setup lang="ts">
import { computed } from "vue";
import type { ExecutiveReportCategoryDto, ExecutiveReportSurveyDto } from "@/types/_generated/api";
import UWDrawer from "@/components/base/UserWeb/Drawer/UWDrawer.vue";
import ExecSummaryCatRecList from "./ExecSummaryCatRecList.vue";
import ExecSummaryCatRecQuestions from "./ExecSummaryCatRecQuestions.vue";

const props = defineProps<{
  moduleId: number;
  surveyId: number;
  categoryIndex: number;
  surveys: ExecutiveReportSurveyDto[];
  category: ExecutiveReportCategoryDto;
}>();

defineEmits<{
  closeDrawer: [void];
}>();

const categoryData = computed(() => {
  const survey = props.surveys.find((survey) => survey.surveyId === props.surveyId);

  if (!survey) {
    throw new Error("Survey not found");
  }

  const index = props.categoryIndex ?? -1;

  return index >= 0 ? survey?.categoryData[index] : null;
});

const drawerTitle = computed(() => {
  const { position, categoryName } = props.category;

  const categoryTitle = `${position}. ${categoryName}`;

  return `${categoryTitle}`;
});

const drawerSubTitle = computed(() => {
  const survey = props.surveys.find((survey) => survey.surveyId === props.surveyId);

  if (!survey) return "";

  return survey.siteDefinitions.join(" / ");
});
</script>

<template>
  <UWDrawer
    :drawer-title="drawerTitle"
    :sub-title="drawerSubTitle"
    @close-drawer="$emit('closeDrawer')"
  >
    <div class="exec-summary-cat-rec-drawer">
      <ExecSummaryCatRecQuestions
        :question-data="categoryData?.replies"
        :questions="category?.questions"
      />

      <ExecSummaryCatRecList
        :module-id="moduleId"
        :survey-id="surveyId"
        :category-id="category.categoryId"
      />
    </div>
  </UWDrawer>
</template>

<style scoped lang="scss">
.exec-summary-cat-rec-drawer {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;

  & > * {
    padding-bottom: $spacing-8;
    border-bottom: 1px solid $primary-4;
  }

  & > *:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}
</style>
