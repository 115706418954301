<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDrawerState } from "@/composables/useDrawerState";
import { SortOrder } from "@/constants/SortOrder";
import { enumToOptions } from "@/helpers/mappers/enumToOptions";
import { getLoginAttemptLog } from "@/services/system-logs";
import { LoginAttemptDto, LoginAttemptResult } from "@/types/_generated/api";
import { ColumnDataType, ColumnDefinition } from "@/types/ColumnDefinition";
import BaseGridTable from "@/components/base/BaseGridTable/BaseGridTable.vue";
import UserLink from "@/components/shared/UserLink.vue";
import UserDetailsDrawer from "../../Users/components/UserDetailsDrawer/UserDetailsDrawer.vue";

const { t } = useI18n({ useScope: "global" });

const isLoading = ref(false);
const log = ref<LoginAttemptDto[] | null>([]);

onBeforeMount(async () => {
  isLoading.value = true;
  log.value = await getLoginAttemptLog();
  isLoading.value = false;
});

const columns: ColumnDefinition<LoginAttemptDto>[] = [
  new ColumnDefinition({
    title: t("systemLog.loginAttemptID"),
    key: "loginAttemptId",
    autoWidth: true,
    alignContent: "right",
  }),
  new ColumnDefinition({
    title: "User ID",
    key: "userId",
    autoWidth: true,
    alignContent: "right",
  }),
  new ColumnDefinition({
    title: "Username",
    key: "userAuthName",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("systemLog.userIp"),
    key: "ip",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("systemLog.isSingleSignOn"),
    key: "isSingleSignOn",
    autoWidth: true,
    type: ColumnDataType.Boolean,
  }),
  new ColumnDefinition({
    title: t("common.result"),
    key: "result",
    searchOptions: enumToOptions(LoginAttemptResult),
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("systemLog.userAgent"),
    key: "userAgent",
    autoWidth: true,
  }),
  new ColumnDefinition({
    title: t("common.date"),
    key: "insertDate",
    autoWidth: true,
    type: ColumnDataType.DateTime,
  }),
];

const {
  closeDrawer: closeUserDetailDrawer,
  drawerData: userId,
  openDrawer: openUserDetailDrawer,
  showDrawer: showUserDetailDrawer,
} = useDrawerState<number>();
</script>

<template>
  <BaseGridTable
    :rows="log ?? []"
    :columns="columns"
    :is-loading="isLoading"
    :default-sort="[{ key: 'loginAttemptId', order: SortOrder.Descending }]"
  >
    <template #column-userAuthName="{ row }">
      <UserLink
        :label="row.userAuthName"
        :user-id="row.userId"
        :user-role="row.userRole"
        @click="openUserDetailDrawer"
      />
    </template>
    <template #column-result="{ row }">
      {{ t(`systemLog.loginAttemptResults.${row.result}`) }}
    </template>
  </BaseGridTable>

  <UserDetailsDrawer
    v-if="showUserDetailDrawer && userId"
    :user-id="userId"
    @close="closeUserDetailDrawer"
  />
</template>
