import { ref } from "vue";
import { getUser as getUserService } from "@/services/users";

export const useGetUser = () => {
  const isLoadingUser = ref(false);

  const getUser = async (userId: number) => {
    isLoadingUser.value = true;

    const user = await getUserService(userId);

    isLoadingUser.value = false;

    if (!user) {
      throw Error("User not found");
    }

    return user;
  };

  return {
    getUser,
    isLoadingUser,
  };
};
