<script setup lang="ts">
import { toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRcba } from "@/composables/useRcba";
import { toCurrency } from "@/helpers/formatNumber";
import { type RiskCostBenefitAnalysisDto, LossEstimateType } from "@/types/_generated/api";
import BaseDataDisplayList from "@/components/base/BaseDataDisplay/BaseDataDisplayList.vue";
import BaseDataDisplayListItem from "@/components/base/BaseDataDisplay/BaseDataDisplayListItem.vue";
import BaseSafeHtml from "@/components/base/BaseSafeHtml/BaseSafeHtml.vue";

type Props = {
  rcba: RiskCostBenefitAnalysisDto;
};

const props = defineProps<Props>();

const { t } = useI18n({ useScope: "global" });

const { priorSum, afterSum, formatSum, costBenefitRatio, hasExtra, hasProbability } = useRcba(
  toRef(props.rcba),
);
</script>

<template>
  <BaseDataDisplayList v-if="priorSum > 0 || afterSum > 0">
    <BaseDataDisplayListItem
      v-if="hasProbability"
      :label="t('userWeb.onlineReport.recommendations.rcba.scenarioDescription')"
    >
      <BaseSafeHtml :html="rcba.rcbaDescription" />
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem
      v-if="hasProbability"
      :label="t('userWeb.onlineReport.recommendations.rcba.probability')"
    >
      <p>{{ rcba.rcbaProbability }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.rcba.propertyPrior')">
      <p>{{ formatSum(rcba.rcbaPropertyPrior) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.rcba.propertyAfter')">
      <p>{{ formatSum(rcba.rcbaPropertyAfter) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.rcba.biPrior')">
      <p>{{ formatSum(rcba.rcbaBiPrior) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.rcba.biAfter')">
      <p>{{ formatSum(rcba.rcbaBiAfter) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem
      v-if="hasExtra"
      :label="t('userWeb.onlineReport.recommendations.rcba.extraPrior')"
    >
      <p>{{ formatSum(rcba.rcbaExtraPrior) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem
      v-if="hasExtra"
      :label="t('userWeb.onlineReport.recommendations.rcba.extraAfter')"
    >
      <p>{{ formatSum(rcba.rcbaExtraAfter) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.rcba.totalPrior')">
      <p>{{ formatSum(priorSum) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.rcba.totalAfter')">
      <p>{{ formatSum(afterSum) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.rcba.costToComplete')">
      <p>{{ toCurrency(rcba.rcbaCostToComplete, rcba.rcbaCurrency) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem :label="t('userWeb.onlineReport.recommendations.rcba.riskReduction')">
      <p>{{ formatSum(priorSum - afterSum) }}</p>
    </BaseDataDisplayListItem>
    <BaseDataDisplayListItem
      :label="t('userWeb.onlineReport.recommendations.rcba.costBenefitRatio')"
    >
      <p v-if="rcba.rcbaLossEstimateType === LossEstimateType.Monetary">
        {{ costBenefitRatio }}
      </p>
      <p v-else>
        {{
          t("blueRating.recommendation.investmentGivesRiskReduction", {
            cost: toCurrency(rcba.rcbaCostToComplete, props.rcba.rcbaCurrency),
            riskReduction: formatSum(priorSum - afterSum),
          })
        }}
      </p>
    </BaseDataDisplayListItem>
  </BaseDataDisplayList>
</template>
