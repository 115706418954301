<script setup lang="ts">
import { computed } from "vue";
import { useUniqueId } from "@/composables/useUniqueId";
import BaseLabel from "@/components/base/BaseLabel.vue";

interface Props {
  id?: string;
  checked: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  label?: string;
  ariaLabel?: string;
  description?: string;
  dataTest?: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  checked: false,
  indeterminate: false,
  disabled: false,
  label: "",
  ariaLabel: "",
  description: "",
  dataTest: "",
});

const emit = defineEmits<{
  change: [boolean];
}>();

const classes = computed(() => {
  return {
    "base-checkbox__input": true,
    "base-checkbox__input--indeterminate": props.indeterminate,
    "base-checkbox__input--disabled": props.disabled,
  };
});

const uid = props.id || useUniqueId("base_checkbox");

const toggle = () => {
  if (props.disabled) return;

  emit("change", !props.checked);
};
</script>

<template>
  <span class="base-checkbox" @click="toggle">
    <input
      :id="uid"
      :data-test="dataTest"
      type="checkbox"
      :class="classes"
      :disabled="disabled"
      :checked="checked"
      :aria-label="ariaLabel"
    />
    <BaseLabel v-if="label" class="base-checkbox__label" :for-id="uid">
      {{ label }}
    </BaseLabel>
  </span>

  <span v-if="description" class="base-checkbox__description">
    {{ description }}
  </span>
</template>

<style scoped lang="scss">
.base-checkbox {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin-top: $spacing-1;

  &__input {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    user-select: none;
    background-origin: border-box;
    border: 1px solid $primary-5;
    border-radius: $rounded-base;
    appearance: none;
    background-color: white;

    &--disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &:checked {
      background-image: url("./assets/base-checkbox-checked.svg");
    }

    &--indeterminate {
      background-image: url("./assets/base-checkbox-indeterminate.svg");
    }

    &:checked,
    &--indeterminate {
      background-color: $secondary-4;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-color: transparent;
    }
  }

  &__label {
    user-select: none;
    white-space: nowrap;
    margin-left: $spacing-2;
    user-select: none;
  }

  &__description {
    margin-top: $spacing-1;
    margin-left: $spacing-8;
    font-size: $text-sm;
    color: $primary-7;
    max-width: 18rem;
  }
}
</style>
