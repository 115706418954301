<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { vTruncationTitle } from "@/directives/truncationTitle";
import {
  AreaDto,
  BlueColor,
  CategoryDto,
  QuestionDto,
  QuestionnaireDto,
} from "@/types/_generated/api";
import { type QuestionnaireNavValidationError } from "@/views/Admin/Questionnaire/composables/useQuestionnaireNavValidationErrors";
import BaseBlueRatingStatusBadge from "@/components/base/BaseBlueRatingStatusBadge.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BaseSkeleton from "@/components/base/BaseSkeleton.vue";
import { categoryColors, questionColors } from "./constants/colorConfig";
import { calculateTotalBlueScore } from "./helpers/scoreUtils";
import QuestionnaireNavChevron from "./QuestionnaireNavChevron.vue";
import QuestionnaireNavFoldingButton from "./QuestionnaireNavFoldingButton.vue";
import QuestionnaireNavTogglePointsButton from "./QuestionnaireNavTogglePointsButton.vue";

type Collapsable = "area" | "category";

const props = withDefaults(
  defineProps<{
    questionnaire?: QuestionnaireDto;
    isLoading?: boolean;
    errors?: QuestionnaireNavValidationError[];
  }>(),
  {
    questionnaire: undefined,
    isLoading: false,
    errors: () => [],
  },
);

const toggleShowPoints = ref(false);
const { t } = useI18n({ useScope: "global" });

const collapsed = ref<Set<{ type: Collapsable; id: number }>>(new Set());

const isExpanded = (type: Collapsable, id: number) => !isCollapsed(type, id);

const isCollapsed = (type: Collapsable, id: number) =>
  Array.from(collapsed.value).some((c) => c.type === type && c.id === id);

const toggle = (type: "area" | "category", id: number, value: boolean) => {
  if (value) {
    collapsed.value = new Set([...collapsed.value, { type, id }]);
  } else {
    collapsed.value = new Set(
      Array.from(collapsed.value).filter((c) => !(c.type === type && c.id === id)),
    );
  }
};

const toggleAllCategories = (value: boolean) => {
  if (!props.questionnaire) {
    return;
  }

  if (value) {
    collapsed.value = new Set([
      ...collapsed.value,
      ...(props.questionnaire.areas.flatMap((a) =>
        a.categories.map((c) => ({
          type: "category",
          id: c.categoryId,
        })),
      ) as { type: Collapsable; id: number }[]),
    ]);
  } else {
    collapsed.value = new Set(Array.from(collapsed.value).filter((c) => c.type !== "category"));
  }
};

const areaHasErrors = (area: AreaDto) =>
  props.errors?.some((error) => error.id === `area_${area.areaId}`);

const categoryHasErrors = (category: CategoryDto) =>
  props.errors?.some((error) => error.id === `category_${category.categoryId}`);

const questionHasErrors = (question: QuestionDto) =>
  props.errors?.some((error) => error.id === `question_${question.questionId}`);

const getValidationErrorTitle = (id: `${"area" | "category" | "question"}_${number}`) => {
  const errors = props.errors.filter((error) => error.id === id);

  if (errors.length) {
    return errors
      .map((error) => `${t(`questionnaire.draft.labels.${error.propertyPath}`)} - ${error.message}`)
      .join("\n");
  }

  return "";
};
</script>

<template>
  <div class="questionnaire-nav">
    <div class="questionnaire-nav__controls">
      <QuestionnaireNavTogglePointsButton
        :show-points="toggleShowPoints"
        @toggle="toggleShowPoints = !toggleShowPoints"
      />

      <QuestionnaireNavFoldingButton @toggle="toggleAllCategories" />
    </div>

    <div v-if="!isLoading && !!questionnaire" class="questionnaire-nav__grid">
      <template v-for="(area, ai) in questionnaire?.areas" :key="ai">
        <!-- AREA -->
        <div class="questionnaire-nav__grid__area__collapse">
          <QuestionnaireNavChevron
            :expanded="isExpanded('area', area.areaId)"
            @toggle="toggle('area', area.areaId, $event)"
          />
        </div>
        <span class="questionnaire-nav__grid__area__position">
          {{ area.position }}
        </span>
        <div class="questionnaire-nav__grid__area__content">
          <a
            class="questionnaire-nav__grid__area__content__name"
            :href="`#area_${area.areaId}`"
            :title="area.name"
          >
            {{ area.name }}
          </a>
          <BaseBlueRatingStatusBadge
            v-if="toggleShowPoints"
            class="questionnaire-nav__grid__area__content__points"
            :size="'small'"
            :status="BlueColor.NotSet"
          >
            {{ calculateTotalBlueScore(area) || "" }}
          </BaseBlueRatingStatusBadge>
        </div>

        <div
          class="questionnaire-nav__grid__area__errors"
          :title="getValidationErrorTitle(`area_${area.areaId}`)"
        >
          <BaseIcon v-if="areaHasErrors(area)" icon="warning" />
        </div>

        <template v-for="(category, ci) in area.categories" :key="ci">
          <template v-if="isExpanded('area', area.areaId)">
            <!-- CATEGORY -->
            <div><!--area pos-->&nbsp;</div>
            <div class="questionnaire-nav__grid__category__collapse">
              <QuestionnaireNavChevron
                :expanded="isExpanded('category', category.categoryId)"
                @toggle="toggle('category', category.categoryId, $event)"
              />
            </div>
            <span class="questionnaire-nav__grid__category__position">
              {{ category.position.toString() }}
            </span>
            <div class="questionnaire-nav__grid__category__content">
              <div v-truncation-title>
                <a
                  class="questionnaire-nav__grid__category__content__name"
                  :href="`#category_${category.categoryId}`"
                  :title="category.name"
                >
                  {{ category.name }}
                </a>
              </div>

              <div
                v-if="toggleShowPoints"
                class="questionnaire-nav__grid__category__content__points"
              >
                <BaseBlueRatingStatusBadge
                  v-for="(colorData, index) in categoryColors"
                  :key="index"
                  :size="'small'"
                  :status="colorData.color as BlueColor"
                >
                  {{ category[colorData.limit as keyof CategoryDto]?.toString() || "" }}
                </BaseBlueRatingStatusBadge>

                <BaseBlueRatingStatusBadge
                  class="questionnaire-nav__grid__category__content__points--totalt"
                  :status="BlueColor.NotSet"
                  :size="'small'"
                >
                  {{ calculateTotalBlueScore(category) || "" }}
                </BaseBlueRatingStatusBadge>
              </div>
            </div>

            <div
              class="questionnaire-nav__grid__category__errors"
              :title="getValidationErrorTitle(`category_${category.categoryId}`)"
            >
              <BaseIcon v-if="categoryHasErrors(category)" icon="warning" />
            </div>

            <template v-for="(question, qi) in category.questions" :key="qi">
              <template v-if="isExpanded('category', category.categoryId)">
                <div><!--collapse pos-->&nbsp;</div>
                <div><!--area pos-->&nbsp;</div>
                <div><!--category pos-->&nbsp;</div>
                <span class="questionnaire-nav__grid__question__position">
                  {{ question.position.toString() }}
                </span>
                <div class="questionnaire-nav__grid__question__content">
                  <div v-truncation-title>
                    <a
                      class="questionnaire-nav__grid__question__content__name"
                      :href="`#question_${question.questionId}`"
                      :title="question.text"
                    >
                      {{ question.text }}
                    </a>
                  </div>

                  <div
                    v-if="toggleShowPoints"
                    class="questionnaire-nav__grid__question__content__points"
                  >
                    <BaseBlueRatingStatusBadge
                      v-for="(colorData, index) in questionColors"
                      :key="index"
                      :size="'small'"
                      :status="colorData.color as BlueColor"
                    >
                      {{ question[colorData.score as keyof QuestionDto]?.toString() || "" }}
                    </BaseBlueRatingStatusBadge>
                  </div>
                </div>

                <div
                  v-if="questionHasErrors(question)"
                  class="questionnaire-nav__grid__question__errors"
                >
                  <BaseIcon icon="warning" />
                </div>
              </template>
            </template>
          </template>
        </template>
      </template>
    </div>

    <div v-if="isLoading" class="questionnaire-nav__skeleton">
      <BaseSkeleton :set="10" height="30px" mb="1rem" width="100%" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.questionnaire-nav {
  $lineHeight: 38px;

  a {
    text-decoration: none;
  }

  &__controls {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: right;
    border-bottom: 1px solid $primary-3;
  }

  &__grid {
    display: grid;
    padding: $spacing-3;
    max-width: 22rem;
    overflow-x: hidden;
    align-items: center;
    // area:  collapse | position | name     | name     | name | errors
    // category: empty | collapse | position | name     | name | errors
    // question: empty | empty    | empty    | position | name | errors
    grid-template-columns: minmax($lineHeight, auto) minmax($lineHeight, auto) auto auto 1fr auto;

    // All rows
    > * {
      line-height: $lineHeight;
    }

    &__area,
    &__category,
    &__question {
      // All collapse
      &__collapse > * {
        color: $primary-5;
        &:hover {
          color: $primary-7;
          background-color: transparent;
        }
      }
      // All positions
      &__position {
        text-align: center;
      }
      // All errors
      &__errors {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $error-4;
      }
    }

    // All area rows
    &__area {
      &__collapse,
      &__position,
      &__content,
      &__name,
      &__errors {
        margin-top: $spacing-4;
        margin-bottom: $spacing-2;
        cursor: pointer;
      }
    }

    // area
    &__area__content {
      grid-column: span 3;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__name {
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: $text_xl;
      }
      &__points {
        max-height: 16px;
        align-self: center;
        display: flex;
      }
    }

    // All category/question rows
    &__category,
    &__question {
      &__collapse,
      &__position,
      &__content,
      &__name,
      &__errors {
        cursor: pointer;
        margin-bottom: $spacing-3;
      }

      &__content:hover,
      &__errors:hover {
        background-color: $primary_1;
      }
    }

    // category & question
    $border: 1px solid $primary-4;

    &__category,
    &__question {
      &__name,
      &__content,
      &__position,
      &__errors {
        height: $lineHeight + 2px;
        border-top: $border;
        border-bottom: $border;
        padding: 0 $spacing-2;
      }

      &__position {
        border: $border;
        font-size: $text-sm;
        border-radius: $rounded-base 0 0 $rounded-base;
      }

      &__content {
        border-top: $border;
        border-bottom: $border;
        height: $lineHeight + 2px;
        overflow-x: hidden;
        line-height: normal;
        padding: 0 $spacing-2;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__name {
          grid-column: span 2;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &__points {
          display: flex;
          gap: $spacing-1;
          margin-top: auto;
          margin-bottom: $spacing-1;
          align-items: center;

          &--totalt {
            margin-left: auto;
          }
        }
      }

      &__errors {
        border-right: $border;
        background-color: $primary-0;
        border-radius: 0 $rounded-base $rounded-base 0;
      }
    }

    // category
    &__category__content {
      grid-column: span 2;
    }
  }

  &__skeleton {
    padding: $spacing-5;
  }
}
</style>
