<script setup lang="ts">
import type { RouteRecordRaw } from "vue-router";
import type { Breadcrumb } from "@/types/Breadcrumb";
import useDesign from "@/composables/useDesign";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";

defineProps<{
  breadcrumbs: Breadcrumb[];
}>();

const design = useDesign();
</script>

<template>
  <ul class="admin-header-breadcrumbs">
    <BaseIcon icon="divider" size="medium" class="admin-header-breadcrumbs__divider" />

    <li v-for="(breadcrumb, index) in breadcrumbs" :key="index">
      <RouterLink
        v-if="breadcrumb.route && breadcrumbs.length > 1"
        :to="breadcrumb.route as RouteRecordRaw"
      >
        {{ breadcrumb.title }}
      </RouterLink>
      <span v-else>{{ breadcrumb.title }}</span>

      <BaseIcon
        v-if="index < breadcrumbs.length - 1"
        icon="slash"
        class="admin-header-breadcrumbs__slash"
      />
    </li>
  </ul>
</template>

<style scoped lang="scss">
.admin-header-breadcrumbs {
  display: flex;
  align-items: center;
  font-weight: $font-medium;
  font-size: $text-sm;
  color: $primary-0;

  // Height of top nav is set in useDesign.ts
  $nav-height: v-bind("design.defaults.navigationHeight");
  height: $nav-height;
  max-height: $nav-height;
  overflow-y: hidden;

  &__divider {
    margin: 0 $spacing-6;
  }

  &__slash {
    margin: 0 $spacing-3;
  }

  li {
    display: flex;
    align-items: center;

    &:last-child {
      font-weight: $font-medium;
    }

    a {
      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}
</style>
