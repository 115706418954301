<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon/BaseIcon.vue";
import BasePill from "@/components/base/BasePills/BasePill.vue";
import { type ReportMailRecipient } from "../composables/useReportMailRecipients";

const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  recipient: ReportMailRecipient;
}>();

defineEmits<{
  delete: [ReportMailRecipient];
  edit: [ReportMailRecipient];
}>();

const existingUserInfo = computed(() =>
  [
    `${props.recipient.firstName} ${props.recipient.lastName}`,
    props.recipient.title,
    props.recipient.company,
  ]
    .filter((v) => v?.length)
    .join(", "),
);
</script>

<template>
  <div
    class="survey-report-mail__recipient"
    :title="t('survey.surveyReportMail.clickToEdit')"
    @click="$emit('edit', recipient)"
  >
    <span v-if="!!props.recipient.firstName" class="survey-report-mail__recipient__name">{{
      existingUserInfo
    }}</span>
    <span>{{ recipient.email }}</span>
    <span>{{ recipient.mobile }}</span>
    <span>
      {{ t("survey.surveyReportMail.includeLink") }}
      <BaseIcon v-if="recipient.includeRecommendationResponseLink" icon="check" />
    </span>

    <BaseButton variant="text" color="error" @click="$emit('delete', recipient)">
      <BaseIcon icon="trash" />
    </BaseButton>

    <div v-if="recipient.reminderDates.length" class="survey-report-mail__recipient__response-link">
      <BasePill
        v-for="(date, i) in recipient.reminderDates"
        :key="i"
        clickable
        :label="date"
        background-color="#e2e8f0"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.survey-report-mail__recipient {
  @include grid-columns(6);
  grid-template-columns: repeat(3, 1fr) auto;
  gap: $spacing-2 $spacing-4;

  min-height: 50px;
  align-items: center;
  padding: $spacing-2;
  margin-bottom: $spacing-4;
  cursor: pointer;

  background-color: white;
  border-radius: $rounded-base;
  border: 1px solid $primary-4;

  &:hover {
    cursor: pointer;
    border-color: $secondary-4;
  }

  &__name,
  &__response-link {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
  }

  &__response-link {
    gap: $spacing-2;
  }
}
</style>
