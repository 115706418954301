/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Creates a new object with only the properties from obj1 that differ from the property on obj2
 * @param obj1
 * @param obj2
 * @param include Names of additional properties on obj2 to include
 * @returns
 */
export const cloneChangedProperties = (obj1: any, obj2: any, ...include: string[]) => {
  const clone = Object.entries(obj1).reduce((acc: any, [k, v]) => {
    if (obj2[k] !== v) {
      acc[k] = v;
    }
    return acc;
  }, {});

  if (include.length) {
    for (const key of include) {
      clone[key] = obj2[key];
    }
  }

  return clone;
};
