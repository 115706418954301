<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDialog } from "@/composables/useDialog";
import { downloadFile } from "@/helpers/downloadFile";
import { useAppStore } from "@/stores/app";
import { FileDto, SurveyFileType } from "@/types/_generated/api";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDrawer from "@/components/base/BaseDrawer/BaseDrawer.vue";
import BaseFile from "@/components/base/BaseFile/BaseFile.vue";
import BaseFileArea from "@/components/base/BaseFileArea/BaseFileArea.vue";
import BaseOption from "@/components/base/BaseSelect/BaseOption.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import { useHandleSurveyReport } from "../composables/useHandleSurveyReport";

const props = defineProps<{
  surveyId: number;
}>();

defineEmits<{
  (event: "close"): void;
}>();

const app = useAppStore();
const { t } = useI18n({ useScope: "global" });
const dialog = useDialog();

const { reportFiles, deleteReport, getSurveyFiles, uploadSurveyReport, isLoadingSurveyReports } =
  useHandleSurveyReport();

const selectedReportType = ref<SurveyFileType>(SurveyFileType.RecommendationReport);

const reportOptions = [
  {
    title: t("survey.uploadReport.recommendationReport"),
    value: SurveyFileType.RecommendationReport,
  },
  { title: t("survey.uploadReport.uWReport"), value: SurveyFileType.UwReport },
  { title: t("survey.uploadReport.otherReport"), value: SurveyFileType.OtherReport },
];

const currentReportFile = computed(() =>
  selectedReportType.value
    ? reportFiles.value.find((file) => file.type === selectedReportType.value)
    : null,
);

const generateRecReport = (surveyId: number) => {
  downloadFile(`/v1/surveys/${surveyId}/recommendation-report`);
};

const onDelete = async (fileId: string) => {
  const isConfirmed = await dialog.openDeletePrompt(t("survey.uploadReport.file"));

  if (isConfirmed) {
    await deleteReport(props.surveyId, fileId);
    await getSurveyFiles(props.surveyId);
  }
};

const onUpload = async (files: FileDto[]) => {
  const [file] = files;
  await uploadSurveyReport(props.surveyId, file, selectedReportType.value);
};

onMounted(async () => await getSurveyFiles(props.surveyId));
</script>

<template>
  <BaseDrawer
    :title="t('survey.uploadReport.uploadSurveyReport')"
    :is-loading="isLoadingSurveyReports"
    width="30"
    @close="$emit('close')"
  >
    <BaseSelect
      class="survey-report-drawer__select"
      :label="t('survey.uploadReport.selectReportType')"
      :value="selectedReportType"
      @change="(option) => (selectedReportType = option as SurveyFileType)"
    >
      <BaseOption v-for="option in reportOptions" :key="option.value" :value="option.value">
        {{ option.title }}
      </BaseOption>
    </BaseSelect>

    <div v-if="selectedReportType" class="survey-report-drawer__content">
      <BaseButton
        v-if="selectedReportType === 'RecommendationReport'"
        @click="generateRecReport(surveyId)"
      >
        {{ t("survey.uploadReport.generateRecommendationReport") }}
      </BaseButton>

      <p>{{ t("survey.uploadReport.onlyOneReportNote") }}</p>

      <BaseFileArea
        :max-files="1"
        :allowed-file-types="app.settings.documentFileExtensions"
        @on-add="onUpload"
      />

      <template v-if="currentReportFile">
        <BaseFile
          edit
          :file="{ ...currentReportFile, fileId: currentReportFile.id }"
          :file-url="`/v1/surveys/${props.surveyId}/files/${currentReportFile.id}`"
          @delete="onDelete"
        />
      </template>
    </div>
  </BaseDrawer>
</template>

<style scoped lang="scss">
.survey-report-drawer {
  &__select {
    margin: $spacing-4;
  }

  &__content {
    padding: $spacing-4;
    display: flex;
    flex-direction: column;
    gap: $spacing-8;
  }
}
</style>
