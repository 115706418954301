import { Ref, computed } from "vue";
import { getRecommendations } from "@/helpers/survey-helpers";
import { BlueRatingDto, BlueRatingRecommendationLeanDto } from "@/types/_generated/api";

export const useFlattenedRecommendations = (survey: Ref<BlueRatingDto>) => {
  const recommendations = computed(() => {
    const recommendations: BlueRatingRecommendationLeanDto[] = [];

    if (!survey.value) {
      return recommendations;
    }

    recommendations.push(...survey.value.generalRecommendations);
    recommendations.push(...getRecommendations(survey.value));

    // Remove duplicates
    for (let i = recommendations.length - 1; i >= 0; i -= 1) {
      const id = recommendations[i].recommendationId;
      if (recommendations.findIndex((x) => x.recommendationId === id) !== i) {
        recommendations.splice(i, 1);
      }
    }

    return recommendations;
  });

  return { recommendations };
};
